import styled from 'styled-components'
import {
  fonts,
  colours,
  ScrollStyledBox,
  themes,
  breakpoints
} from '../../styles/global.styles'
import VisibilityElement from '../../components/shared/visibility'

export const Container = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 85px);
  width: 100%;
  background-color: ${colours.light2};
  cursor: default;

  @media (max-width: ${breakpoints.sm}px) {
  }
`

export const MessagesContainer = styled(VisibilityElement)`
  display:flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0px 14px;
  width: 100%;
  height: calc(100% - 76px);
`

export const ScrollWrapper = styled(ScrollStyledBox)`
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  overflow: auto; // Add this line
  display: flex;
  flex-direction: column-reverse;
`;

export const MessagesScrolling = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
`

export const DateDivider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height: 12px;
  color: ${colours.lightgrey2};
  padding-top: 20px;
  padding-bottom: 20px;

  &:before {
    content: '';
    width:30%;
    margin-right: 12px;
    display: block;
    border-bottom:1px solid ${colours.text};
  }

  &:after {
    content: '';
    margin-left: 12px;
    width:30%;
    display: block;
    border-bottom:1px solid ${colours.text};
  }
`

export const MessageRow = styled(VisibilityElement)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;

  .message-bubble {
    background-color: ${props => props.theme === themes.Metro ? colours.darkergrey2 : colours.lightpink2};
    color: ${props => props.theme === themes.Metro ? colours.light : colours.darkergrey2};;
  }

  &.is-others {
    flex-direction: row-reverse;
    justify-content: end;

    .message-avatar {
      margin-right:0;
      margin-left:6px;
    }

    .message-bubble {
      background-color: ${colours.light4};
      color: ${colours.darkergrey2};
    }
  }
`

export const MessageBubble = styled.div`
  padding: 10px;
  font-family: ${fonts.content.regular};
  font-size: 13px;
  line-height: 17px;
  text-align: left;
  max-width: 80%;
  word-break: break-word;

  .message-pink-bold {
    color: ${colours.pink};
  }

  a {
    color: ${colours.pink};
    text-decoration: underline;
  }
`

export const SmallAvatar = styled.div`
  flex: 0 0 ${props => props.isItch ? 24:32}px;
  border-radius: ${props => props.isItch ? 0:50}%;
  width: ${props => props.isItch ? 24:32}px;
  height: ${props => props.isItch ? 24:32}px;
  overflow: hidden;
  margin-right: 6px;
`

export const UnreadMessagesCount = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height: 12px;
  color: ${colours.pink};
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
`

export const MessageInputContainer = styled.div`
  flex:0 0 76px;
  width:100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  height:76px;
  margin-top:auto;
  box-shadow: 0px 0px 3px rgba(51, 51, 51, 0.2);
`

export const MyAvatar = styled.div`
  flex: 0 0 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
`

export const InputArea = styled.div`
  flex: 1 1 auto;
  padding-left:10px;
  padding-right:10px;
`

export const TextArea = styled.textarea`
  pointer-events: ${props => props.disabled ? 'none': 'all'};
  width: 100%;
  height: 100%;
  padding: 7px 10px;
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height: 12px;
  color: ${colours.black};
  border: 1px solid ${colours.text};
  
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(236, 15, 105, 1);
  }

  &:focus {
    border-color: ${colours.pink};
  }
`

export const ActionArea = styled.div`
  flex: 0 0 56px;
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  justify-content: space-around;
`

export const Minimise = styled.div``


export const Edit = styled.div`
  pointer-events: ${props => props.disabled ? 'none': 'all'}
`
