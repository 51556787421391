import { useContext } from "react";
import { FullScreenContext } from "../contexts/fullscreen.context";

const useFullScreen = () => {
  const [fullScreen, setFullScreen] = useContext(FullScreenContext);
  return {
    fullScreen,
    setFullScreen
  }
}

export default useFullScreen;