import React, { useState, useEffect } from 'react'
import Modal from '../components/shared/modal';
import CenteredFormLayout from '../components/shared/modal-form/centered-form.layout';

import {
  EditContainer,
  Error,
  FormActions,
  FormButtonGeneric,
  FormReset,
  Row,
  Col
} from '../components/shared/modal-form/modal-form.styles'
import ButtonSave from 'components/form/button-save';
import {
  FormStyled,
  FieldRow,
  Label,
  RadioButtonsStyled,
  PrimaryButton,
  SecondaryButtons
} from './edit-conversation.styles'

import { useForm, Controller } from 'react-hook-form'
import useActionAlert from '../hooks/action-alert.hook';
import useModal from '../hooks/modal.hook'

import {
  icons
} from '../constants'

import useGetOneConversation from 'hooks/get-one-conversation.hook';
import useSetConversationMute from 'hooks/set-conversation-mute.hook';
import useDeleteConversation from 'hooks/delete-conversation.hook';

// import use

export default function EditConversation({
  id,
}) {
  const { isSuccess, isLoading, conversation } = useGetOneConversation(id)
  const { 
    setMute
  } = useSetConversationMute(id)
  const { 
    deleteConversation
  } = useDeleteConversation()
  const { setModal } = useModal()
  const [ submitting, setSubmitting ] = useState(false)
  const { setActionAlert, closeAlert } = useActionAlert()
  
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    trigger,
    formState: {
      errors,
      isDirty
    }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      muted: isSuccess ? conversation.muted ? 'mute' : 'unmute' : 'unmute'
    }
  })

  useEffect(() => {
    if (isSuccess && conversation) {
      window.gtag('event', 'modal_view', {
        page_title: 'edit-conversation',
        id
      });

      reset({
        muted: conversation.muted ? 'mute':'unmute' 
      })
    }
  }, [isSuccess, conversation])

  const onSubmit = (data) => {
    setMute(data.muted === 'mute')
    setModal(null)
  }

  const handleDelete = () => {
    setActionAlert({
      noClose: true,
      theme: 'dark',
      icon: icons['pink-alert-trash-triangle.svg'],
      active: true,
      title: 'Delete this conversation?',
      body: `Your chat history will be lost.<br/><br/>
        <b>Continue anyway</b>`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        deleteConversation(id)
        closeAlert()
        setModal(null)
      }
    })
  }

  function onClose(){
    setModal(null);
  }
  
  return (
    <Modal onClose={onClose}>
      <EditContainer>
        <CenteredFormLayout
          icon={icons['chat-17x12.svg']}
          title="edit conversation"
        >
          {isSuccess ? <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <FieldRow>
              <Label>Conversation Status</Label>

              <Controller
                name="muted"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => 
                  <RadioButtonsStyled
                    {...field}
                    options={[
                      {
                        icon: "",
                        name: "Unmute",
                        value: 'unmute'
                      },
                      {
                        icon: "",
                        name: "Mute",
                        value: 'mute'
                      }
                    ]}
                  />
                }
              />
              
            </FieldRow>
            <FormActions>
              <SecondaryButtons>
                <FormButtonGeneric type="button" onClick={handleDelete}>Delete</FormButtonGeneric>
                <FormReset disabled={!isDirty} type="reset">Reset</FormReset>
              </SecondaryButtons>
              <PrimaryButton>
                <ButtonSave active={isDirty} loading={isLoading}>Save</ButtonSave>
              </PrimaryButton>
            </FormActions>
          </FormStyled> : null}
        </CenteredFormLayout>
      </EditContainer>
    </Modal>
  )
}