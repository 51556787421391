import React from 'react'
import styled from 'styled-components'
import { colours, fonts } from 'styles/global.styles'
import {
  icons
} from '../../constants'

const Container = styled.a`
  text-align: center;
  margin-bottom: 6px;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-family: ${fonts.content.regular};

  b {
    font-family: ${fonts.title.xbold};
    font-weight: 800;
    a {
      text-decoration: none;
    }
  }

  img {
    display:block;
    width: 36px;
    margin-bottom:6px;
  }

  p {
    font-weight: 400;
    margin:0;
    padding:0;
    color: ${colours.black};
    
  }
`

export default function FooterIcon() {
  return (
    <Container href="https://www.itch.me">
      <img src={icons['small_logo.png']} alt="itch"/>
      <b><a href="https://www.itch.me" target='_blank'>www.itch.me</a></b>
      <p>Your decentralised fashion channel</p>
    </Container>
  )
}