import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  colours,
  fonts,
  breakpoints
} from '../../styles/global.styles'

const ToastWrapper = styled.div`
  border-bottom: 1px solid ${colours.pink};
  text-align: center;
  background-color: ${colours.lightgreypink};
  color: ${colours.pink};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  position: fixed;
  z-index: 100;
  top: 45px;
  min-height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  b {
    font-weight: 700;
  }

  span.action {
    color: ${colours.lightgrey4};
    cursor: pointer;

    
    &:hover {
      text-decoration: underline;
    }
  
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    top: 65px;
    min-height: 36px;
  }
`;

export default function Toast({
  children,
  ...rest
}) {

  useEffect(() => {
    const mainBody = document.getElementById('main-body')
    if (mainBody)
      mainBody.classList.add('toast-active')    

    return (() => {
      const mainBody = document.getElementById('main-body')
      if (mainBody)
        document.getElementById('main-body').classList.remove('toast-active')    
    })
  }, [])

  return (
    <ToastWrapper {...rest}>
      {children}
    </ToastWrapper>
  )
}