import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'

import useModal from 'hooks/modal.hook'
import useAuth from 'hooks/auth.hook'

import SubNavItem from './sub-nav'

import { useQueryClient } from '@tanstack/react-query'

import {
    breakpoints
} from 'styles/global.styles'


import useGetConversationUnreadCount from 'hooks/get-conversation-unread-count.hook'
import useGetArtworkUnseenCount from 'hooks/get-artwork-unseen-count.hook';
import useGetArticleUnseenCount from 'hooks/get-article-unseen-count.hook';

import useDrawers from 'hooks/drawers.hook'
import { useWindowDimensions } from 'utils/helpers';

const Menu = styled.ul`
    position: fixed;
    z-index: 1090;
    top: 65px;
    right:0;
    width: 260px;
    height: calc(100vh - 65px);
    transform: translateX(${props => props.active ? 0: 100}vw);
    transition: transform 0.3s;
    overflow: hidden;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.8);
    color: #A5A5A5;
    text-align: left;
    overscroll-behavior: contain;

    li {
        font-size: 16px;
        color: #FFF;
        border-bottom: solid thin #000;
        height: 50px;
        display:flex;
        align-items:center;

        a {
            width:100%;
            padding:15px 35px;

            span {
                position: relative;
            }
        }
    }

    li.submenu-item:not(.selected) {
        color: #B4B4B4;
    }

    li.submenu-item {
        a {
            padding-left:50px;
        }
    }

    li.submenu-item a {
        display: inline-block;
    }

    li:hover {
        color: #EC0F69 !important;
        background: rgba(0, 0, 0, 0.9);
    }

    li.submenu-item a span:first-child:after {
        position: absolute;
        bottom: -3px;
        left: 0px;
        content: '';
        opacity: 0;
        width: 100%;
        border-bottom: 2px solid #EC0F69;
    }

    li.submenu-item:hover a span:first-child:after {
        opacity: 1;
    }

    @media screen and (max-width: ${breakpoints.sm}px) {
        top: 45px;
        height: calc(100vh - 45px);
    }
`

export default function RightDrawer({
    active,
    excludeClickRefs,
    ...rest
}) {
    const { isMobile } = useWindowDimensions();
    const queryClient = useQueryClient()
    const { setModal } = useModal()
    const { push } = useHistory()
    const { setSession } = useAuth()
    const conversationCounter = useGetConversationUnreadCount()
    const artworkCounter = useGetArtworkUnseenCount()
    const articleCounter = useGetArticleUnseenCount()
    const { activeDrawer, setActiveDrawer } = useDrawers()
    const menuRef = useRef(null)

    const closeRightDrawer = (event) => {
        if (menuRef.current 
            && !menuRef.current.contains(event.target) 
            && activeDrawer === 'right'
            && !excludeClickRefs.includes(event.target)) {
            setActiveDrawer(null)
        }
    }

    const handleScroll = (event) => {
        if (isMobile) {
            if (menuRef.current && menuRef.current.contains(event.target) && activeDrawer === 'right') {
                event.preventDefault()
            }
        }   
    }

    useEffect(() => {
        const menuRefElem = menuRef.current

        if (activeDrawer === 'right') {
            // document.body.style.overflow = 'hidden'
        }

        document.body.addEventListener('click', closeRightDrawer)
        document.body.addEventListener('touchstart', closeRightDrawer)
        menuRefElem.addEventListener('touchmove', handleScroll)
        menuRefElem.addEventListener('mousewheel', handleScroll)

        return () => {
            // document.body.style.overflow = 'auto'
            document.body.removeEventListener('click', closeRightDrawer)
            document.body.removeEventListener('touchstart', closeRightDrawer)
            menuRefElem.removeEventListener('touchmove', handleScroll)
            menuRefElem.removeEventListener('mousewheel', handleScroll)
            
        }
    }, [activeDrawer])
    
    return (
        <Menu active={active} ref={menuRef} {...rest} className={`nexa-x-bold`}>
            <li>
                <Link to="/my-profile" onClick={() => {
                    window.scrollTo(0,0);
                }}>my account</Link>
            </li>
            <SubNavItem
                label="my feed"
                to="/my-profile"
                activeOnlyWhenExact={true}
            />
            <SubNavItem
                label="conversations"
                to="/my-profile/conversations"
                counter={conversationCounter.isSuccess ? conversationCounter.value : 0}
            />
            <SubNavItem
                label="itchbook"
                to="/my-profile/itchbook"
            />
            <SubNavItem
                label="gallery"
                to="/my-profile/gallery"
                counter={artworkCounter.isSuccess ? artworkCounter.value : 0}
            />
            <SubNavItem
                label="articles"
                to="/my-profile/articles"
                counter={articleCounter.isSuccess ? articleCounter.value : 0}
            />
            <li>
                <Link to="#" onClick={() => {
                    // onClick()
                    setModal({
                        name: 'settings'
                    })
                }}>settings</Link>
            </li>
            <li>
                <Link to="#">
                    <div 
                        onClick={() => {
                            setSession(null)
                            localStorage.clear()
                            queryClient.clear();
                            queryClient.removeQueries();
                            push('/')
                        }}
                    >logout</div>
                </Link>
            </li>
        </Menu>
    )
}