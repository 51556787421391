import { useQuery } from '@tanstack/react-query';
import { getOneBySlug } from '../apis/article.api';

export default function useGetOneArticle(slug) {
  const { isLoading, error, data, isSuccess, isFetching } = useQuery(
    ['articles', slug], 
    () => getOneBySlug(slug)
  )
  
  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    article: {
      ...data?.data
    }
  }
}