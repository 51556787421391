import { useInfiniteQuery } from '@tanstack/react-query';
import { searchArtworksByKeywords } from '../apis/artwork.api';

export default function useSearchArtworksByKeywords({
  query,
  page = 0,
  size = 10
}) {
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: ['search-artworks-by-keywords'],
    queryFn: ({ pageParam = page }) => searchArtworksByKeywords({
      query,
      size,
      page: pageParam
    }),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    }
  })

  const flattenedData = isSuccess ? (data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])) : []

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    artworks: flattenedData,
    hasNext: hasNextPage,
    nextPage: fetchNextPage,
    refresh: refetch
  }
}