import React, { useContext, useEffect } from 'react'
import useGetActivitiesOnCurrentUser from 'hooks/get-current-user-activities.hook'
// import usePsst from 'hooks/psst.hook';

export const ActivitiesContext = React.createContext(null);

export const ActivitiesProvider = ({ children }) => {
  // const { ready, readAt } = usePsst()
  const {
    isLoading,
    isSuccess,
    data,
    totalItems,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    getData
  } = useGetActivitiesOnCurrentUser()

  /*
  useEffect(() => {
    if (ready) {
      getData(readAt)
    }
  }, [ready])
  */

  // this can be hooked up with a socket listening and refresh to provide live updates

  return (
    <ActivitiesContext.Provider value={{
      isLoading,
      isSuccess,
      data,
      totalItems,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      getData
    }}>
      {children}
    </ActivitiesContext.Provider>
  )
}

export const useActivities = () => {
  return useContext(ActivitiesContext)
}
