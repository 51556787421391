import { useInfiniteQuery } from '@tanstack/react-query';
import { getArtworkActivityHistory } from 'apis/activity.api'

const getMethod = (itemType) => {
  if (itemType === 'artworks' )
    return getArtworkActivityHistory
  
  // could have other activities
  return () => {}
}

export default function useGetActivityHistory(
  itemType = null, 
  itemId = null, 
  action = null
) {
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [`activity-history-${itemType}-${itemId}-${action ?? 'all'}`],
    queryFn: ({ pageParam = 0 }) => getMethod(itemType)({
      id: itemId,
      action, 
      page: pageParam,
      size: 20
    }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage !== undefined) {
        if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
          return Number(lastPage.page) + 1 
      }
    }
  })

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr?.data ?? [])
  }), [])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching || isFetchingNextPage,
    error,
    activities: flattenedData,
    nextPage: fetchNextPage,
    hasNextPage
  }
}