import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import LeftDrawer from '../left-drawer/left-drawer'
import {
  icons
} from '../../../constants'
import {
  breakpoints
} from 'styles/global.styles'

import {
  Container
} from './logo.styles'

import { useTopNavTheme } from './top-nav-theme-context'

import { 
  useHistory,
  useLocation 
} from 'react-router-dom'
import useSwipe from 'hooks/swipe.hook'
import useDrawers from 'hooks/drawers.hook'
import { useWindowDimensions } from 'utils/helpers'

export default function Logo(props) {
  const { isMobile } = useWindowDimensions()
  const { ...rest } = props
  const { activeDrawer, setActiveDrawer } = useDrawers()

  const { pathname } = useLocation()
  const { theme } = useTopNavTheme()

  const swipe = useSwipe({
    onSwipeLeft: () => {
      // onCloseDrawer()
      setActiveDrawer(null)
    }
  })

  useEffect(() => {
    // onCloseDrawer()
    setActiveDrawer(null)
  }, [pathname])

  useEffect(() => {
  }, [])

  return (
    <Container
      onClick={(evt) => {
        evt.stopPropagation()
      }}
      onMouseEnter={() => {
        if (window.screen.width > breakpoints.sm) {
          setActiveDrawer('left')
        }
      }}
      onMouseLeave={() => {
        if (window.screen.width > breakpoints.sm) {
          setActiveDrawer(null)
        }
      }}
    >
      <div className="logo" {...rest} 
        onClick={(evt) => {
          evt.stopPropagation();
          
          if (window.screen.width <= breakpoints.sm) {
            if (activeDrawer !== 'left') {
              setActiveDrawer('left')
            } else
              setActiveDrawer(null)
          }
          if (!isMobile) {
            window.location.href = '/home'
          }
        }}>
        {theme === 'pink' ? <img className="active" src={icons['black-logo-36.png']} alt="" /> : null}
        {theme === 'dark' ? <img className="active
        " src={icons['pink-logo-36.png']} alt="" /> : null}
        <img className="hover" src={icons['white-logo-36.png']} alt="" />
      </div>
      {createPortal(
        <LeftDrawer active={activeDrawer === 'left'} {...swipe} />,
        document.body)}
    </Container>
  )
}