import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Modal from '../components/shared/modal';
import {
  fonts,
  breakpoints,
  colours,
  ButtonReset
} from '../styles/global.styles'
import {
  AccountTypes,
  icons,
  getAccountTypeMeta,
  images
} from '../constants';
import ImageUpload from '../components/form/image-upload';
import { useForm, Controller } from "react-hook-form";
import { CountriesNameCode } from '../utils/countries'

import FormField from '../components/form/form-field';
import InputText from '../components/form/input-text';
import Autocomplete from '../components/form/auto-complete';
import SelectTheme from '../components/form/select-theme';
import InputEditor from '../components/form/input-editor';
import ButtonSave from '../components/form/button-save';

import updateMyProfile from '../hooks/update-user-profile.hook';

import {
  FormActions,
  FormReset,
  Col,
  Row
} from '../components/shared/modal-form/modal-form.styles'
// import { Header, Icon, Title } from 'components/shared/modal-form/centered-form.layout'

const EditContainer = styled.div`  
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:55px;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    max-width: 918px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 45px;
  left:0;
  z-index:10;
  background-color: ${colours.black};
  width:100%;
  max-width:auto;
  height: 45px;
  justify-content: flex-start;
  
  &>.circle {
    margin-bottom: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid ${colours.pink};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 20px;
    
    &>img {
      display: block;
      width: 14px;
      height: 14px;
    }
  }
  &>span {
    color: ${colours.pink};
    font-family: ${fonts.title.bold};
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    position: static;
    top: 0;
    max-width: 918px;
    left: calc((100% - 918px)/2);
    margin-top: 20px;
    background-color: transparent;

    &>.circle {
      width: 36px;
      height: 36px;
      border-width:2px;
    }

    &>span {
      font-size: 14px;
    }
  }
`;

const FormLayout = styled.form`
  display: flex;
  flex-direction: column;
  align-items: initial;
  margin-left: 0px;
  margin-right: 0px;
  padding: 20px 0px 0px 0px;
  height: calc(100vh - 100px);

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    flex-direction: row;
    align-items: center;
    align-items: flex-start;
    padding: 20px 0px 10px 0px;
    margin: 0;
    height: unset;
    width: 100%;
  }
`;

const AvatarField = styled.div`
  width: 130px;
  height: 130px;
  flex: 0 0 130px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  
  &:hover {
    background-color: ${colours.pink_alpha50};
  }


  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    grid-area: avatar;
    align-self: flex-start;
    width: 144px;
    height: 144px;
    flex: 0 0 144px;
  }
`

const BillboardImageUpload = styled.div`
  width: 300px;
  height: 104px;
  flex: 0 0 104px;
  display: flex;
  flex-direction: column;
  align-self: center;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    align-self: center;
    width: 100%;
    max-width: 402px;
    height: 143px;
    flex: 0 0 143px;
    margin-bottom: 0;
    margin-top: 0px;
  }
`;

const LocationIcon = styled.div`
  flex: 0 0 15px;
  width: 15px;
  position: absolute;
  margin-left:10px;
  top: 5px;
`;

const SelectThemeField = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colours.text};

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    grid-area: theme;
    align-self: flex-start;
    justify-self: flex-start;
    padding-top: 12px;
    padding-bottom: 0px;
  }
`;

const FashionStatementField = styled(FormField)`
  margin-top: 40px !important;
  padding-bottom: 30px;
  width:unset;
  margin-left: 12px;
  margin-right: 12px;
  height: 457px;

  .label {
    border-bottom-width: 1px;
  }

  .editor-input {
    font-size: 14px;
    color: ${colours.text};
  }

  &:focus-within {
    .label {
      border-bottom-width: 2px !important;
    }
    .editor-inner {
      border-bottom-width: 2px;
    }
    .editor-input {
      color: ${colours.white};
    }
  }

  .editor-container {
    border-bottom: 1px solid ${colours.text};
  }  

  .input-wrapper {
    height: 320px;
  }
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    grid-area: fashion_statement;
    align-self: flex-start;
    padding-bottom: 0;
    margin-top: 0px !important;
    height: 457px;
    width: 100%;
    max-width: 426px;
    border-width:1px;

    .input-wrapper {
      height: 100%;
    }
  }
`;

export default function EditProfile({
  profile,
  onClose
}) {
  const { update, isLoading, isSuccess, isError, result, error } = updateMyProfile()
  
  const accountTypeMeta = getAccountTypeMeta(profile.accountType);
  const fallBackAvatar = accountTypeMeta.fallBackAvatar;

  const { 
    control,
    register, 
    handleSubmit,
    getValues,
    formState: { 
      errors, 
      isDirty
    }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...profile
    }
  });

  const countriesList = CountriesNameCode();

  const onSubmit = (data) => {
    update({
      ...data
    })
    onClose && onClose();
  }

  return (
    createPortal(
      <Modal 
        onClose={() => {
          onClose && onClose();
        }}
        header={<Header>
          <div className="circle">
            <img src={icons['pink-pencil-14.svg']} alt="edit" />
          </div>
          <span>edit profile</span>
        </Header>}
      >
        <EditContainer>
          <FormLayout onSubmit={handleSubmit(onSubmit)}>
            
            <Col>
              <Row style={{marginTop:'1px'}}>
                <AvatarField>
                  <Controller
                    name="profileImage"
                    control={control}
                    render={({ field }) => 
                      <ImageUpload
                        {...field}
                        placeholderImg={fallBackAvatar}
                        errors={errors} 
                      />
                    }
                  />
                </AvatarField>      
                <Col>
                  {profile.accountType !== AccountTypes.FashionCompany ? 
                  <FormField
                    className="form-field-text"
                    area="name_1">
                    <InputText
                      borderBottom={true}
                      type="text"
                      placeholder="First name"
                      {...register("firstName", {
                        required: true
                      })}
                    />
                  </FormField>
                  : null}

                  {profile.accountType !== AccountTypes.FashionCompany ? 
                  <FormField
                    className="form-field-text"
                    area="name_2">
                    <InputText
                      borderBottom={true}
                      type="text"
                      placeholder="Last name"
                      {...register("lastName", {
                        required: true
                      })}
                    />
                  </FormField>
                  : null}

                  {profile.accountType === AccountTypes.FashionCompany ? 
                  <FormField 
                    className="form-field-text"
                    area="name_1">
                    <InputText 
                      borderBottom={true}
                      {...register("companyName", {
                        required: true
                      })}
                      type="text"
                      placeholder="Company name"
                    />
                  </FormField>
                  : null}

                  <FormField
                    className="form-field-text"
                    area="location">
                      <Controller
                        name="countryCode"
                        control={control}
                        render={({ field }) => 
                          <Autocomplete
                            {...field}
                            prefix={
                              <LocationIcon>
                                <img src={icons['location-pink.svg']} alt="location" />
                              </LocationIcon>
                            }
                            options={countriesList}
                          />
                        }
                      />
                  </FormField>
                </Col>
              </Row>
              <Row>
                <Col
                    addStyles={`
                      margin-top: 78px;

                      @media (max-width: ${breakpoints.sm}px) {
                        margin-top: 10px;
                      }
                    `}>
                  <FormField
                    className="form-field-billboard"
                    name="billboardImage"
                    control={control}
                    area="billboard"
                    label={"Billboard"}
                  >
                    <BillboardImageUpload>
                      <Controller
                        name="billboardImage"
                        control={control}
                        render={({ field }) => 
                          <ImageUpload 
                            {...field}
                            placeholderImg={images['default_billboard.png']}
                            errors={errors} 
                          />
                        }
                      />
                    </BillboardImageUpload>
                  </FormField>

                  <SelectThemeField>
                    <Controller
                      name="colourTheme"
                      control={control}
                      render={({ field }) => 
                        <SelectTheme
                          {...field}
                          defaultValue={getValues('colourTheme')}
                        />
                      }
                    />
                  </SelectThemeField>
                </Col>
              </Row>
            </Col>
            
            <Col>
              <FashionStatementField
                area="fashion_statement"
                label="Fashion Statement"
              >
                <Controller
                  name="fashionStatement"
                  control={control}
                  render={({ field }) => 
                    <InputEditor {...field} maxLength={4000} />
                  }
                />
              </FashionStatementField>
            </Col>
            
            <FormActions>
              {false && <FormReset disabled={!isDirty} type="reset">Reset</FormReset>}
              <ButtonSave active={isDirty} loading={isLoading} type="submit">Save</ButtonSave>
            </FormActions>

          </FormLayout>
        </EditContainer>
      </Modal>
    ,document.body)
  );
}