import React, { useState, useEffect, useMemo, forwardRef } from 'react'

import useGetComments from 'hooks/get-comments.hook'
import CommentThreadItem from './comment-thread-item'

/*
  a series of comments
*/

import {
  Container,
  ViewMore
} from './comment-thread.styles'

export default function CommentThread(props) {
  const { id, contentType, ...rest } = props

  const { data, isSuccess, isLoading, hasNextPage, fetchNextPage } = useGetComments(contentType, id)

  const handleViewMore = () => {
    fetchNextPage()
  }

  const flattend = isSuccess && data ? (data.pages ?? []).reduce((acc, page) => {
    return [...acc,...(page.data ?? [])]
  }, []) : []

  return (
    <Container {...rest}>
      {flattend.map((comment, i) => (
        <CommentThreadItem
          className="comment-thread-item"
          key={`comment-${comment._id}`} 
          id={comment._id}
          relatedId={id}
          contentType={contentType}
          {...comment}
        />
      ))}
      {hasNextPage ? <ViewMore onClick={handleViewMore}>View more comments</ViewMore> : null}
    </Container>
  )
}