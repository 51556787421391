import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import useAuth from 'hooks/auth.hook';

export const TopNavThemeContext = React.createContext(null);

export const TopNavThemeProvider = ({ children }) => {
  const { fetching, session } = useAuth()
  const { pathname } = useLocation()

  const [ theme, setTheme ] = useState(null)
  const [ publicMenuFlipped, setPublicMenuFlip ] = useState(false)

  useEffect(() => {
    if (!fetching && session) {
      if (
        pathname === '/' ||
        pathname.indexOf('/zine') === 0 ||
        pathname.indexOf('/zine/') === 0 ||
        pathname.indexOf('/articles/') === 0 ||
        pathname.indexOf('/itch-articles/') === 0 ||
        pathname.indexOf('/archives/itch-articles') === 0
      ) {
        setTheme('dark')
      } else {
        setTheme('pink')
      }
    }
  }, [pathname, session, fetching])
  
  return (
    <TopNavThemeContext.Provider value={[theme, setTheme, publicMenuFlipped, setPublicMenuFlip]}>
      {children}
    </TopNavThemeContext.Provider>
  )
}

export const useTopNavTheme = () => {
  const [
    theme, setTheme, 
    publicMenuFlipped, setPublicMenuFlip
  ] = useContext(TopNavThemeContext)
  return {
    theme,
    setTheme,
    publicMenuFlipped,
    setPublicMenuFlip
  }
}