import { useQuery } from '@tanstack/react-query';
import { getOne } from '../apis/account-artwork.api';

export default function useGetArtwork(id) {
  const { isLoading, error, data, isSuccess, isFetching } = useQuery(
    ['artworks', id], 
    () => getOne(id), 
    {
      enabled: id !== undefined
    }
  )

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    artwork: {
      ...data?.data
    }
  }
}