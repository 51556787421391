import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  icons
} from '../constants'

import {
  Container,
  Content,
  Icon,
  Title,
  Body,
  OkAction
} from './unsubscribe.styles'

import useUnsubscribe from '../hooks/unsubscribe.hook'

const Unsubscribe = () => {
  const { push } = useHistory()
  const { code } = useParams()
  const { unsubscribeMe } = useUnsubscribe()

  const handleOk = () => {
    push('/signup')
  }

  useEffect(() => {
    unsubscribeMe(code)
  }, [])

  return (
    <Container>
      <Content>
        <Icon>
          <img src={icons['unsubscribe.svg']} alt="" />
        </Icon>
        <Title>Unsubscribe Successful</Title>
        <Body>
          If you ever change your mind about <b>itch</b>, feel free to sign up straight on <a href="https://www.itch.me">www.<b>itch</b>.me!</a>
        </Body>
        <OkAction onClick={handleOk}>Ok, got it!</OkAction>
      </Content>
    </Container>
  )
}

export default Unsubscribe