import React, { useRef } from 'react';
import { createPortal } from 'react-dom'
import Modal from '../shared/modal';
import ActivityListItem from './activity-list-item';

import {
  Title,
  Center,
  Container,
  List,
  ListItem,
} from './activity-list.style'

export default function ActivityList({
  title,
  activities,
  onClose,
  onBottom,
  showActions = false
}) {
  return createPortal(
    <Modal 
      onClose={() => {
        onClose && onClose()
      }}
      onBottom={() => {
        onBottom && onBottom()
      }}
    >
      <Center className="activity-list">
        <Container>
          <Title>
            <span>{title}</span>
          </Title>
          <List>
            {activities.map((activity, i) => (
              <ListItem key={i}>
                <ActivityListItem activity={activity} showAction={showActions}/>
              </ListItem>
            ))}
          </List>
        </Container>
      </Center>
    </Modal>
  ,document.body);
}