import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components'
import Modal from '../components/shared/modal';
import CenteredFormLayout from '../components/shared/modal-form/centered-form.layout';

import {
  EditContainer,
  Error
} from '../components/shared/modal-form/modal-form.styles'
import {
  FormStyled
} from './edit-artwork.styles'
import {
  icons, ReviewStatus
} from '../constants'
import {
  breakpoints
} from 'styles/global.styles'

import FormatType from '../components/artwork-form/format-type.section'
import ArtworkDetails from '../components/artwork-form/artwork-details.section'

import { useForm, Controller } from "react-hook-form";

import useActionAlert from '../hooks/action-alert.hook';

import useCreateArtwork from '../hooks/create-artwork.hook';
import useUpdateMyArtwork from '../hooks/update-artwork.hooks'
import useGetArtwork from '../hooks/get-my-one-artwork.hook';

import useModal from '../hooks/modal.hook';
import useAuth from '../hooks/auth.hook'

import { Header, Icon, Title } from 'components/shared/modal-form/centered-form.layout'
import { useWindowDimensions } from 'utils/helpers'

const Container = styled(EditContainer)`
  min-height:inherit;
  max-width: 100%;
  height: fit-content;
  // height: inherit;
  margin-left:auto;
  margin-right:auto;

  @media (max-width:${breakpoints.sm}px) {
    max-width: 100%;
    min-height:100%;
  }
`

export default function EditArtwork({
  id
}) {
  const { isMobile } = useWindowDimensions()
  const { session } = useAuth()
  const { setModal } = useModal()
  const [ submitting, setSubmitting ] = useState(false)
  const { setActionAlert, closeAlert } = useActionAlert()
  
  const { create, isSuccess, isLoading } = useCreateArtwork(session?.profile?.id)
  const { update, isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate } = useUpdateMyArtwork()
  const { artwork, success: getArtworkSuccess, fetching: getArtworkFetching} = useGetArtwork(id)

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: {
      errors,
      isDirty,
      dirtyFields
    }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      mediaType: null
    }
  })
  
  // to add resolver for form validations
  const isEditing = (id !== undefined);

  const watchMediaType = watch('mediaType')
  
  const onSubmit = (data) => {
    if (!isEditing) {
      create(data)
      setSubmitting(true)
    } else {
      update({
        id, data
      })
      setModal(null)
    }
  }

  const isAnyRequiredFail = Object.keys(errors).reduce((acc, curr) => {
    return acc + (errors[curr].type === 'required' ? 1 : 0)
  }, 0) > 0

  useEffect(() => {
    if (getArtworkSuccess) {

      window.gtag('event', 'modal_view', {
        page_title: 'edit-artwork',
        article_id: id,
        article_title:  artwork.title
      });

      reset({
        ...artwork
      })
    }
  }, [reset, getArtworkSuccess, id])

  useEffect(() => {
    if (!id) {
      window.gtag('event', 'modal_view', {
        page_title: 'reveal-artwork',
        action: 'open-form'
      });
    }
  }, [])

  const disabled = ReviewStatus.Rejected === getValues('reviewStatus')

  const fieldsExcludeMediaType = Object.keys(dirtyFields)
  const isArtworkFormDirty = fieldsExcludeMediaType.filter(p => p !== 'mediaType').length > 0

  function onClose(force){
    if (!disabled) {
        if (force) {
          setModal(null)
        } else
        if (isArtworkFormDirty && !isSuccess && !isEditing) {
          setActionAlert({
            noClose: true,
            theme: 'dark',
            icon: icons['alert.svg'],
            active: true,
            title: 'Ooops',
            body: `You haven't completed your submission<br/><br/>
              <b>Close anyway?</b>`,
            yesLabel: 'Yes',
            noLabel: 'No',
            onYes: () => {
              closeAlert()
              setModal(null)
            }
          })
        } else {
          setModal(null)
        }
      } else {
        setModal(null)
      }
  }

  return <>{!isEditing || (isEditing && getArtworkSuccess && watch('id')) ? createPortal(
    <Modal
      isSubmitting={submitting}
      onSubmitted={() => {
        setModal(null)
      }}
      header={
        isMobile ? <Header
          className="centered-form-kayout__header"
          disabled={disabled}
        >
          <Icon>
            <img src={icons['gallery.svg']} alt="" />
          </Icon>
          <Title>{isEditing ? 'edit my artwork' : 'reveal an artwork'}</Title>
        </Header> : null
      }
      submitting={{
        title: `Thank you, we've got it!`,
        body: `Let us proceed to review and confirm your artwork`
      }}
      isDirty={isDirty}
      onClose={onClose}
    >
      <Container className="edit-artwork-container">
        <CenteredFormLayout
          className="edit-artwork-container__centered-form-layout"
          width={{
            default: !isEditing && watchMediaType === null ? '260px' : '781px',
            [breakpoints.sm]: '100%'
          }}
          title={!isMobile ? (isEditing ? 'edit my artwork' : 'reveal an artwork' ):null}
          icon={icons['gallery.svg']}
          disabled={disabled}
        >
          {isAnyRequiredFail ? <Error>Oops! The highlighted fields are required</Error> : null} 
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            {!isEditing && watchMediaType === null ? <FormatType register={register} /> : null }
            {isEditing || watchMediaType ? 
              <ArtworkDetails 
                id={id}
                isEditing={isEditing}
                register={register} 
                Controller={Controller}
                control={control}
                errors={errors}
                isDirty={isArtworkFormDirty}
                disabled={disabled}
                isSubmitting={isLoading}
                mediaType={watchMediaType || getValues('mediaType')}
                onDelete={() => {
                  setModal(null)
                }}
                onReset={() => {
                if (id) {
                  reset({
                    ...artwork
                  })
                } else {
                  reset()
                }
              }}
              /> : null}
          </FormStyled>  
        </CenteredFormLayout>
      </Container>
    </Modal>, document.body
  ) : null}
  </>
}