import React, { useState, useEffect } from 'react'

export const DrawersContext = React.createContext(null);

export const DrawersProvider = ({ children }) => {
  const [activeDrawer, setActiveDrawer] = useState(null);

  return (
    <DrawersContext.Provider value={{
      activeDrawer, 
      setActiveDrawer
    }}>
      {children}
    </DrawersContext.Provider>
  );
}