import React, { forwardRef } from 'react'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import {
  CommentContainer,
  FirstCol,
  SecondCol,
  UserInfo,
  Name,
  AccountTypeContainer,
  FlagContainer,
  AvatarContainer,
  Body,
  Footer,
  DateTime,
  RespondLink,
  UserInfoRow,
  ContentRow
} from './comment-item.styles'

import { getAccountTypeMeta } from '../../constants'
import { getName } from '../../utils/utils'
import UserAvatar from 'components/avatar/user-avatar'
import Flag from '../shared/flag'

import { imageUrl, ensureLinks } from 'utils/utils'
import useAuth from 'hooks/auth.hook'
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'

const CommentItem = forwardRef(({ id, user, body, createdAt, handleRespond }, ref) => {
  const { handleAction, linkProps, link } = useLoggedOutHandleRoute()
  const { fetching, session } = useAuth()

  return (
    <CommentContainer ref={ref} className="comment-container">
      <UserInfoRow>
        <AccountTypeContainer>
          <img src={getAccountTypeMeta(user?.accountType).iconDark} alt="" />
        </AccountTypeContainer>
        <Name {...linkProps(session?.profile?.id === user._id ? '/my-profile' : `/${user.itchUrl}`)}>
          {getName(user)}
        </Name>
        <AvatarContainer>
          <UserAvatar 
            profileUrl={link(session?.profile?.id === user._id ? '/my-profile' : `/${user.itchUrl}`)}
            url={imageUrl(user.profileImageVariants?.thumb ?? user.profileImage)}
            position={user.profileImage?.position}
            accountType={user.accountType}
          />
        </AvatarContainer>
      </UserInfoRow>
      <ContentRow>
        <FlagContainer>
          <Flag content={'comment'} id={id} />
        </FlagContainer>
        <SecondCol>
          <Body dangerouslySetInnerHTML={{__html: ensureLinks(body)}} />
          <Footer>
            <DateTime>
              {format(new Date(createdAt), 'MMMM d yyyy')} at {format(new Date(createdAt), 'h:mm b')}
            </DateTime>
            {!fetching && session && handleRespond ? 
            <RespondLink 
              onClick={() => {
                handleAction(() => {
                  handleRespond()
                })
              }}>
              Respond
            </RespondLink>
            : null}
          </Footer>
        </SecondCol>
      </ContentRow>
    </CommentContainer>
  )
})

export default CommentItem