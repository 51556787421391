import styled from 'styled-components';
import {
  fonts,
  breakpoints,
  colours,
  ButtonReset
} from '../styles/global.styles'

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
