import styled from 'styled-components'
import { colours, breakpoints } from 'styles/global.styles'

export const Container = styled.div`
  width: 45px;
  height: 100%;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    width: 65px;
  }
`

export const AvatarWrapper = styled.div`
  cursor: pointer;
  position: relative;
  background: white;
  width: 100%;
  height: 100%;

  &>img {
    object-fit:cover;
    height: 100%;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  
  &:hover {
    &:after {
      background-color: ${colours.pink_alpha20};
    }
  }

`