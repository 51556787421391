import axiosInstance from './api';

const base = '/conversations'

export const getAll = async ({
  page,
  size = 10
}) => {
  const response = await axiosInstance.get(`${base}/summary?page=${page}&size=${size}`)
  return response.data
}

export const startConversation = async (userId) => {
  const response = await axiosInstance.post(`${base}/users/${userId}`)
  return response.data
}

export const sendMessage = async (conversationId, body) => {
  const response = await axiosInstance.post(`${base}/${conversationId}/messages`, {
    body
  })
  return response.data
}

export const getMessages = async (conversationId, page) => {
  const response = await axiosInstance.get(`${base}/${conversationId}/messages?page=${page}`)
  return response.data
}

export const countAllConversationsUnread = async () => {
  // todo
  const response = await axiosInstance.get(`${base}/unread/count`)
  return response.data
}

export const countAllMessagesUnread = async () => {
  // todo
  const response = await axiosInstance.get(`${base}/messages/unread/count`)
  return response.data
}

export const setMessagesRead = async (ids) => {
  const response = await axiosInstance.put(`${base}/messages/read`, {
    ids
  })
  return response.data
}

export const setConversationRead = async (id) => {
  const response = await axiosInstance.put(`${base}/${id}/read`)
  return response.data
}

export const getOne = async (id) => {
  const response = await axiosInstance.get(`${base}/${id}`)
  return response.data
}

export const setMute = async (id, mute) => {
  const response = await axiosInstance.put(`${base}/${id}/mute`, {
    mute
  })
  return response
}

export const deleteOne = async (id) => {
  const response = await axiosInstance.delete(`${base}/${id}`)
  return response.data
}