import axiosInstance from './api';

export const getCurrentFeaturedArtwork = async () => {
  const response = await axiosInstance.get('/zine/featured-artwork')
  return response.data
}

export const getFeaturedArtwork = async (chapter) => {
  const response = await axiosInstance.get('/zine/featured-artwork/' + chapter)
  return response.data
}

export const getArchivedFeaturedArtwork = async (page, size) => {
  const response = await axiosInstance.get(`/zine/featured-artwork/archived?page=${page}&size=${size}`)
  return response.data
}

export const getLatestItchArticle = async () => {
  const response = await axiosInstance.get('/zine/itch-article')
  return response.data
}

export const getItchArticle = async (slug) => {
  const response = await axiosInstance.get('/zine/itch-article/' + slug)
  return response.data
}

export const getTrendingUserLatestArticles = async ({
  articleOffset,
  articleSize,
  userOffset,
  userSize
}) => {
  const queryString = `?article_offset=${articleOffset}&article_size=${articleSize}&user_offset=${userOffset}&user_size=${userSize}`
  const response = await axiosInstance.get(`/zine/trending-users-latest-articles${queryString}`)

  return response.data
}

export const getPastItchArticle = async (page, size) => {
  const response = await axiosInstance.get(`/zine/itch-articles/archived?page=${page}&size=${size}`)
  return response.data
}