import styled from 'styled-components';
import { images } from '../../constants';
import { imageUrl } from 'utils/utils';

const Cover = styled.div`
  height: inherit;
  width: 100%;
`;

const Image = styled.img`
  display:block;
  width: 100%;
  height: 100%;
  object-fit: ${props => props.fit};
  object-position: ${ ({position}) => position};
`;

export default function BillBoard({
  image
}) {

  return (
    <Cover>
      <Image 
        src={imageUrl(image) ?? images['default_billboard.jpg']}
        fit={image ? 'cover' : 'fill'}
        position={image?.position ?? 'center center'}
      />
    </Cover>
  );
}