import { useInfiniteQuery } from '@tanstack/react-query';
import { searchUserByKeywords } from '../apis/user.api';

export default function useSearchUserByKeywords({
  page = 0,
  size = 10,
  query
}) {
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: ['search-users-by-keywords'],
    queryFn: ({ pageParam = page }) => searchUserByKeywords({
      query,
      size,
      page: pageParam
    }),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    }
  })

  const flattenedData = isSuccess ? (data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])) : []

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    users: flattenedData,
    hasNext: hasNextPage,
    nextPage: fetchNextPage,
    refresh: refetch
  }
}