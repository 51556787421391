import styled from 'styled-components'
import { 
  fonts,
  colours
} from 'styles/global.styles'
import ButtonSave from 'components/form/button-save'

export const Container = styled.div`
  display:flex;
  flex-direction: row;
  padding: 12px;
  border: 1px solid ${colours.darkerlight4};
`

export const AvatarContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 6px;
  flex: 0 0 34px;
  padding-top: 2px;

  .user-avatar {
  }
`

export const InputContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .rich-editor-container {
    width: 100%;
    border:none;

    .editor-container {
      border: 1px solid ${colours.darkerlight4};
      background: ${colours.darkerlight5};
      height: auto;

      .editor-inner {
        padding: 7px 10px;
        .editor-input {
          color: ${colours.black};
          font-size: 13px;
          line-height: 17px;
          font-weight: 400;
          font-family: ${fonts.content.regular};
          
          a {
            color: ${colours.pink};
          }
        } 
        .editor-placeholder {
          top: 6px;
        }
      }      
    }
    .editor-bottom-bar {
      border:none; 
      margin-left:0px;
      margin-right:0px;
      padding-left:0px;
      padding-right:0px;
      .character-count {
        display: block;
        opacity: 0;
      }
    }
  }

  .submit-comment {
    display:block;
    color: ${colours.lightgrey3};
    border-color: ${colours.darkerlight4};
  }

  &:focus-within {
    .rich-editor-container {
      margin-bottom:0px;
      border: none !important;
      border: 1px solid ${colours.pink};
      .editor-container {
        border: 1px solid ${colours.pink};
      }
      .editor-bottom-bar {
        .character-count {
          display: block;
          opacity: 1;
        }
      }
    }
    
    .submit-comment {
      display:block;
      border-color: ${colours.pink};
      color: ${colours.pink};
    }
  }

`

export const ButtonContainer = styled.div`
`

export const SubmitCommentButton = styled(ButtonSave)`
  font-size: 13px;
  padding: 14px 10px;
  height: auto;
  width: 128px;
`

export const TextContainer = styled.div`
`
