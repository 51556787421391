import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  fonts,
  colours
} from '../../styles/global.styles'
import {
  getAccountTypeMeta,
  AccountTypes,
  Actions
} from '../../constants';
import {
  nameConcat
} from '../../utils/helpers';
import TextLine from '../shared/text-line'
import {
  imageUrl,
  getRelativeDateText
} from 'utils/utils'
import {
  useHistory
} from 'react-router-dom'
import useAuth from 'hooks/auth.hook';

const UserProfileStyled = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    order: 2;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding-right: 15px;
    padding-left: 48px;
}
`;

const UserAccountType = styled.div`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;

  img {
    display:block;
    width: 100%;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
  }
`;

const Body = styled.div`
  flex: 1 1 auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 4px;
  width: 100%;
  max-width: calc(100% - 40px);

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    max-width: 370px;
    width:1px;
  }
`;

// TODO - make a component multi line trancated with ellipsis

const ProfileImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 40px;
  margin-left: 4px;

  img {
    display:block;
    width: 100%;
    object-fit:cover;
    object-position: center center;
  }
`;

const Text = styled.div`
  color: ${colours.text};
  font-family: ${fonts.content.bold};
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SupText = styled(Text)`
  margin-top:4px;
  color: ${colours.lightgrey2};
`

const ActionText = styled(Text)`
  margin-bottom:4px;
  color: ${colours.lightgrey2};
`

export default function ActivityListItem({
  activity,
  style,
  showAction = false
}) {
  const { accountType, itchUrl, firstName, lastName, companyName, profileImage, action, createdAt, id } = activity;
  const accountTypeMeta = getAccountTypeMeta(accountType);
  const { push } = useHistory()
  const { session } = useAuth()

  return (
    <UserProfileStyled onClick={() => {
      if (accountType !== AccountTypes.Itch) {
        push(session?.profile?.id === id ? '/my-profile' : '/' + itchUrl)
      }
    }}>
      <ProfileImage>
        <img src={imageUrl(profileImage)?? accountTypeMeta.fallBackAvatar} alt=""/>
      </ProfileImage>
      <Body>
        <TextLine>
          {showAction ? <ActionText>{Actions[action]}</ActionText> : null}
          <Text>
          {accountType === AccountTypes.FashionCompany ? 
          companyName
          : 
          nameConcat(firstName, lastName)}
          </Text>
          <SupText>{getRelativeDateText(new Date(createdAt))}</SupText>
        </TextLine>
      </Body>
    </UserProfileStyled>
  );
}