import { useInfiniteQuery } from '@tanstack/react-query';
import { getArchivedFeaturedArtwork } from '../apis/zine.api';

export default function useGetArchivedFeaturedArtworks({enabled = true, size = 30}) {
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [`featured-artworks`],
    queryFn: ({ pageParam = 0 }) => getArchivedFeaturedArtwork(pageParam, size),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    },
    enabled: enabled
  })

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    artworks: flattenedData,
    hasNextPage,
    nextPage: fetchNextPage,
    getArchives: refetch,
    totalItems: data?.pages[0]?.totalRecords
  }
}