import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import Modal from '../shared/modal';
import {
  Container,
  Title,
  Header,
  Body,
  Inner,
  ContinueButton
} from './vote-prompt.styles'
import useAcknowledgedVoting from 'hooks/acknowledge-voting.hook';

export default function VotePrompt() {
  const timeoutRef = useRef()
  const { loading, ready, isAcknowledged, acknowledge } = useAcknowledgedVoting()
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (ready && !isAcknowledged) {
      timeoutRef.current = setTimeout(() => {
        setActive(true);
      }, 2500)
    } else {
      clearTimeout(timeoutRef.current)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [ready, isAcknowledged])

  return active ? createPortal(
    <Modal 
      hideClose={true}
      fullTakeOver={true}
      onClose={() => {
        setActive(false)
      }}
    >
      <Container>
        <Inner>
          <Title>let's vote</Title>
          <Header>itch news</Header>
          <Body>
          And we’re ditching the "like" culture. Yes! We’ve now changed "<b>wow</b>" to "<b>vote</b>". This update means, artworks trending with the most <b>votes</b> on <b>itch</b> will be featured next on our <b>zine</b>. This way, trends have meaning as it’s not determined by a select few but transparently by all of you.

          </Body>
          <Header>So why vote?</Header>
          <Body>
          Because active voters will also be selected and featured in our <b>articles</b> and social media for keeping <b>itch</b> a decentralised fashion community. Finally, you have a say to what’s trending and the opportunity to be heard. So let the voting begin!
          </Body>

          <ContinueButton active={true} onClick={() => {
            acknowledge()
            setActive(false)
          }}>Ok. let's vote!</ContinueButton>

        </Inner>
      </Container>
    </Modal>
  ,document.body) : null;
}