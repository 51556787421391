import { useContext } from "react";
import { NotifyAlertContext } from "../contexts/notify-alert.context";

const useNotifyAlert = () => {
  const [notifyAlert, setNotifyAlert] = useContext(NotifyAlertContext);
  return {
    notifyAlert,
    setNotifyAlert,
  }
}

export default useNotifyAlert;