import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { 
  getUserAcknowledgedVoting, 
  setUserAcknowledgedVoting
} from 'apis/user.api'

const useAcknowledgedVoting = () => {
  const cacheKey = 'acknowledged-voting'
  const queryClient = useQueryClient()

  const query = useQuery({
    queryFn: getUserAcknowledgedVoting,
    queryKey: [cacheKey]
  })

  const mutation = useMutation({
    mutationFn: async () => {
      await setUserAcknowledgedVoting()
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: [cacheKey] 
      })
    }
  });

  return {
    loading: query.isLoading || query.isFetching,
    ready: query.isSuccess,
    isAcknowledged: query.isSuccess ? query.data.data ?? false : false,
    acknowledge: mutation.mutate
  }
}

export default useAcknowledgedVoting