import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  colours,
  fonts,
  ButtonReset,
  BaseImage,
  breakpoints
} from '../../styles/global.styles';
import {
  icons
} from '../../constants';
import { ensureLinks } from 'utils/utils'

const FashionStatementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 30px 0 15px 0;
  height: 100%;
  background-color: ${colours.black_alpha85};
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
  }
`;

const FashionStatementContent = styled.div`
  columns: 300px auto;
  column-fill: auto;
  height: 310px;
  display: block;
  
  color: ${colours.grey};
  font-family: ${fonts.content.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  a {
    color: ${colours.pink};
  }

  p.editor-paragraph {
    position: unset;

    span {
      columns: unset !important;
      height: unset;
    }
  }

  ul {
    padding-left: 2px;
    li {
      margin-left: 0;
      &:before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: ${colours.grey};
        margin-right:5px;
        position: relative;
        top:-2px;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
  }
`;

const Carousel = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
  max-width: 308px;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {  
    max-width: 1134px;
  }
`;

const CarouselContent = styled.div`
  overflow-x: hidden;
  width: 100%;
  flex: 1 1 auto;
  margin-left: 15px;
  margin-right: 15px;
  @media screen and (min-width: ${breakpoints.sm + 1}px) {  
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const CarouselNav = styled(ButtonReset)`
  width:14px;
  flex: 0 0 14px;
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;
  
  &:before {
    content: url(${props => props.direction === 'left' ? icons['pink-arrow-left.svg'] : icons['pink-arrow-right.svg']});
  }
  &:after {
    content: url(${props => props.direction === 'left' ? icons['grey-arrow-left.svg'] : icons['grey-arrow-right.svg']});
    position: absolute;
    opacity: 0;
  }

  
  &:hover {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }

`;

const Title = styled.div`
  font-family: ${fonts.content.bold};
  color: ${colours.pink};
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colours.pink};
  margin-bottom: 15px;
  width: 100%;
  max-width: 250px;
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    max-width: 1050px;
  }
`;

const MinimiseButton = styled(ButtonReset)`
  display: block;
  width: 14px;
  height: 14px;
  margin-top: auto;
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: url(${icons['grey-expand-collapse.svg']});
  }
  &:after {
    content: url(${icons['pink-expand-collapse.svg']});
    position: absolute;
    opacity: 0;
  }


  &:hover {
    &:before {
      opacity: 0;
    }
    
    &:after {
      opacity: 1;
    }
  }

`;


export default function FashionStatementExpanded({
  body,
  onMinimise
}) {
  const carouselContent = useRef(null);
  const carouselLeft = useRef(null);
  const carouselRight = useRef(null);

  // TODO - track scroll position and disable left right nav

  return (
    <FashionStatementWrapper>
      <Title>Fashion Statement</Title>
      <Carousel>
        <CarouselNav 
          ref={carouselLeft}
          type="button" 
          direction="left" 
          onClick={() => {
            carouselContent.current.scrollTo({
              left: carouselContent.current.scrollLeft - (carouselContent.current.clientWidth + 12.6),
              behavior: 'smooth'
            })
          }}
        ></CarouselNav>
        <CarouselContent ref={carouselContent}>
          <FashionStatementContent dangerouslySetInnerHTML={{__html: ensureLinks(body)}}>
          </FashionStatementContent>
        </CarouselContent>
        <CarouselNav 
          ref={carouselRight}
          type="button"
          direction="right"
          onClick={() => {
            carouselContent.current.scrollTo({
              left: carouselContent.current.scrollLeft + (carouselContent.current.clientWidth + 12.6),
              behavior: 'smooth'
            })
          }}
        ></CarouselNav>  
      </Carousel>
      <MinimiseButton type="button" onClick={() => {
        onMinimise();
      }}>
      </MinimiseButton>
    </FashionStatementWrapper>
  );
}