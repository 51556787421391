import styled from 'styled-components'

const Container = styled.div`
  
  &>img {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};
  }
`

export default function ArticleCover({ src, position }) {
  return (
      <Container className="article-cover" position={position}>
          <img src={src} alt="article cover" />
      </Container>
  )
}