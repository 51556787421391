import ButtonSave from 'components/form/button-save'
import styled from 'styled-components'

import {
  breakpoints,
  fonts,
  colours
} from 'styles/global.styles'

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.sm}px) {
    height: auto;
    min-height: 100vh;
    padding-top: 40px;
  }
`

export const Inner = styled.div`
  width: 100%;
  max-width: 577px;
  display:flex;
  flex-direction:column;
  align-items:center;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const Title = styled.div`
  font-size: 133px;
  font-family: ${fonts.title.xbold};
  line-height: 133px;
  color: ${colours.pink};
  margin-bottom: 0px;
  margin-left: -3px;
  margin-right: -3px;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 19.2vw;
    line-height: 72px;
    letter-spacing: -1px;
    margin-left: -10px;
    margin-right: -10px;
  }
`

export const Header = styled.div`
  font-size: 21px;
  font-family: ${fonts.content.bold};
  line-height: 24px;
  color: ${colours.pink};
  margin-bottom: 2px;
  text-align: left;
  width: 100%;
  margin-top: 24px;
`

export const Body = styled.div`
  font-size: 18px;
  font-family: ${fonts.content.regular};
  line-height: 24px;
  color: ${colours.darkerlight3};
  text-align: left;
`

export const ContinueButton = styled(ButtonSave)`
  margin-top: 40px;
  font-size: 17px;
  width: 100%;
  height: 48px;
  font-family: ${fonts.content.bold};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
`
