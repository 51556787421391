import React from 'react';
import styled from 'styled-components';
import {
  fonts,
  colours
} from '../../styles/global.styles';

const FieldErrorWrapper = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.content.regular};
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

export default function FieldError({
  error,
  ...rest
}) {
  return (
    <FieldErrorWrapper className="error">
      {error ? error.message : null}
    </FieldErrorWrapper>
  );
}