import styled from 'styled-components';

const TextStyledWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width:100%;
  max-width:100%;
`;

const Inner = styled.div`
  width:100%;
  max-width:100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function TextLine(
  props
) {
  return (
    <TextStyledWrapper>
      <Inner>
        {props.children}
      </Inner>
    </TextStyledWrapper>
  );
}