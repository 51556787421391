import React, { useEffect, useState, useMemo } from 'react';
import { 
  Container, 
  Toast,
  TilesContainer,
  TilesInnerPositioned
} from './gallery.styles';

import useGetMyFeed from 'hooks/get-my-feed.hook';

import ArtworkTile from '../components/gallery/artwork-tile'
import useModal from '../hooks/modal.hook';
import useMasonry from '../hooks/masonry.hook';
import { MediaType, ActivityActions } from '../constants';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function Feed() {
  const { setModal } = useModal()
  const { success, fetching, artworks, nextPage, hasNextPage } = useGetMyFeed()
  
  
  useBottomScrollListener(() => {
    if (hasNextPage) {
      nextPage()
    }
  })
  
  const [expanded, setExpanded] = useState(null)

  const { grid, expand, init, reset } = useMasonry()

  const lastCount = useMemo((() => success ? artworks?.length : 0), [artworks, success])

  const artworksRenderItems = (success && grid.length > 0) ? grid.map((gridItem, i) => {
    const artwork = artworks[i]
    if (!artwork) 
      return null
    else
      return (
        <ArtworkTile
          disableOwnerActions={true}
          disableViewAnalytics={true}
          index={i}
          draggable={false}
          position={gridItem}
          expanded={expanded === (i)}
          key={`feed-artwork-${artwork.id}}`}
          {...artwork}
          id={artwork.id}
          onExpand={() => {
            const offset_i = i
            if (expanded === offset_i) {
              setExpanded(null)
              reset()
            } else {
              setExpanded(offset_i)
              if (artwork.mediaType === MediaType.Image) {
                if (artwork.image.width > artwork.image.height) {
                  expand(offset_i, 3)
                } else {
                  expand(offset_i, 2)
                }
              } else {
                expand(offset_i, 3)
              }
            }
          }}
          onExit={() => {
            setExpanded(null)
            reset()
          }}
          onVotesClick={() => {
            setModal({
              name: 'activity-history',
              payload: {
                title: 'collectives who voted this',
                schemaName: 'artworks',
                action: ActivityActions.Voted,
                relatedId: artwork.id
              }
            })
          }}
          onViewsClick={() => {
            setModal({
              name: 'activity-history',
              payload: {
                title: 'collectives who viewed this',
                schemaName: 'artworks',
                action: ActivityActions.Viewed,
                relatedId: artwork.id
              }
            })
          }}
          onItchbooksClick={() => {
            setModal({
              name: 'activity-history',
              payload: {
                title: 'collectives who itchbooked this',
                schemaName: 'artworks',
                action: ActivityActions.Itchbooked,
                relatedId: artwork.id
              }
            })
          }}
        />
      )
  }) : null

  useEffect(() => {
    if (success) {
      init(artworks?.length, 3)
    }
  }, [success, lastCount])

  const maxRows = grid.length > 0 ? (grid[grid.length - 1].top + 1) + (expanded !== null ? 1 : 0) : 0

  return (
    <Container>
      {(artworks?? []).length === 0 ? <Toast>
        <div>Follow your favourite <b>itch</b> accounts to see their latest artworks here so you won’t miss a thing!</div>
      </Toast> : null }
      <TilesContainer>
        <TilesInnerPositioned rows={maxRows}>
          {artworksRenderItems}
        </TilesInnerPositioned>
        {fetching && success ? <ProgressLoader/> : null}
      </TilesContainer>
    </Container>
  )
}