import React from 'react'
import HoverIcon from '../shared/hover-icon'
import Tile from '../tile/tile'
import styled from 'styled-components'
import {
  icons
} from '../../constants'
import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'

const HoverIconStyled = styled(HoverIcon)`
  z-index: 2;
`

const TileInner = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  top: 7.5px;
  left: 7.5px;

  font-family: ${fonts.title.xbold};
  color: ${colours.grey};
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  .icon {
    width: 100%;
    height: 100%;
  }

  @media (hover: hover) {
    &:hover {
      .text {
        color: ${colours.pink};
      }
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
`

const Text = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:1;
  
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;

  @media (max-width:${breakpoints.sm}px) {
    
  }
`;

export default function RevealArtworkTile(props) {

  const { onClick, ...rest } = props
  return (
    <Tile {...rest}>
      <TileInner>
        <HoverIconStyled
          className="icon"
          active={icons['gallery-icon.svg']}
          hover={icons['gallery-icon-hover.svg']}
          onClick={onClick}
        >
        </HoverIconStyled>
        <Text className="text">reveal an artwork</Text>
      </TileInner>
    </Tile>
  )
}