import React, { useEffect } from 'react'
import { format } from 'date-fns'
import Page from './page'
import useGetPastItchArticles from 'hooks/get-past-itch-articles.hook'
import {
  Title,
  Container,
  Inner,
  Grid,
  ArticleCard
} from './previous-itch-articles.styles.js'
import {
	imageUrl
} from '../utils/utils'
import SignUpPrompt from 'components/sign-up-prompt/sign-up-prompt'
import { usePageReady } from 'contexts/page-ready.context'
import { SignUpPromptProvider } from 'contexts/sign-up-prompt.context'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

export default function PreviousItchArticles() {
  const { 
    success, 
    fetching, 
    articles, 
    nextPage, 
    hasNextPage
  } = useGetPastItchArticles()
  const { setReady } = usePageReady()
  
  useBottomScrollListener(() => {
    if (hasNextPage) {
      nextPage()
    }
  })

  useEffect(() => {
    if (success && articles) {
      setReady(true)
    }
  }, [success, articles])

  return (
    <SignUpPromptProvider>
      <Page>
        <Container>
          <Inner>
            <Title><span>previously on itch</span></Title>
            {success && articles ? 
            <>
            <SignUpPrompt/>
            <Grid>
            {articles.map(({
              _id,
              title,
              slug,
              subHead,
              category,
              coverImageVariants,
              name,
              itchUrl,
              createdAt
            }) => 
              <ArticleCard to={`/itch-articles/${slug}`}>
                <div className="image-container">
                  <img src={imageUrl(coverImageVariants.small)} alt={title} />
                </div>
                <div className="content">
                  {category ? <div className="category">{category}</div> : null}
                  <div className="title">{title}</div>
                  <div className="sub-head">{subHead}</div>
                  <div className="author-date">
                    By <b>itch</b> / <a className="name" href={'/' + itchUrl}>{name}</a>, {format(new Date(createdAt), 'd MMMM yyyy')}
                  </div>
                </div>
              </ArticleCard>
            )}
            </Grid> 
            </>: null}
          </Inner>
        </Container>
      </Page>
    </SignUpPromptProvider>
  )
}