import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useQuery, useMutation, useQueryClient  } from '@tanstack/react-query';
import { getPsstLastReadAt, setPsstLastReadAt } from 'apis/psst-read.api'
import useAuth from 'hooks/auth.hook';

const initialState = {
  readAt: new Date()
}

export const PsstContext = React.createContext(initialState);

export const PsstProvider = ({ children }) => {
  const { fetching, session } = useAuth()
  const queryClient = useQueryClient()
  const [ hasRead, setHasRead ] = useState(null) // date last read

  const { isLoading, error, isSuccess, data, isFetching, refetch } = useQuery({
    queryFn: getPsstLastReadAt,
    queryKey: [`psst-last-readat-${session?.profile?.id}`],
    enabled: true
  })
  
  const mutation = useMutation({
    mutationFn: (date) => {
      setPsstLastReadAt(date)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`activities-counter-${session?.profile?.id}`])
      refetch()
      setHasRead(new Date().now)
    }
  });

  const readAt = isSuccess ? data?.data?.readAt ?? new Date(2000, 1, 1, 0, 0, 0) : new Date(2000, 1, 1, 0, 0, 0)
  
  const setLastReadAtNow = () => {
    mutation.mutate(new Date())
  }

  const state = { ready: isSuccess, readAt, setLastReadAtNow, hasRead }

  useEffect(() => {
    if (!fetching && session) {
      // refetch()
    }
  }, [fetching, session])

  return (
    <PsstContext.Provider value={state}>
      {children}
    </PsstContext.Provider>
  );
}