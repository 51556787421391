import SocialMedia from "./social-media";
import Facebook from "components/social-share/facebook";
import Twitter from "components/social-share/twitter";
import Pinterest from "components/social-share/pinterest";
import CopyLink from "components/social-share/copy-link";

export default function AdditionalContent({ 
    className, noMedia = false, 
    occupation, profileLink, 
    author, date, states, 
    itch = true, children,
    shareUrl,
    theme
}) {
    const { normal, hovered } = states;
    
    const themeMaps = {
        'dark': 'black',
        'white': 'black',
        'pink': 'black'
    }
    
    return (
        <div className={"additional-content pn-bold position-relative " + (className ? className : '')}>
            <div className="info-div d-inline-block">
                <div className="info-1">
                    {itch ?
                        <>
                            <span className="pn">By </span>{occupation && occupation + " /"} <a href={profileLink} className="url-underline">{author}</a>,
                        </>
                        :
                        <>
                            <span className="pn">By </span><a href={profileLink} className="url-underline">{author}</a>
                            <span className="pn">, {occupation},</span>
                        </>
                    }
                </div>
                <div className="info-2">
                    <span className="pn">{date} {children}</span>
                </div>
            </div>
            {!noMedia &&
                <div className="media-buttons media-sprite-16 d-inline-flex justify-content-between">
                    <Facebook className="share-btn" theme={themeMaps[theme]} url={shareUrl} />
                    <Twitter className="share-btn" theme={themeMaps[theme]} url={shareUrl} />
                    <Pinterest className="share-btn" theme={themeMaps[theme]} url={shareUrl} />
                    <CopyLink className="share-btn" theme={themeMaps[theme]} url={shareUrl} />
                </div>
            }
        </div>
    )
}
