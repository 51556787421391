import { useInfiniteQuery, QueryClient, useQueryClient } from '@tanstack/react-query';
import { getAll, getShuffledArtworks } from '../apis/artwork.api';

export default function useFindArtworks({
  userTags = []
}, isShuffle = 0) {
  const queryClient = useQueryClient()

  const size = 12

  const { 
    isLoading, error, 
    data, isSuccess, 
    status,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched,
    refetch
  } = useInfiniteQuery({
    queryKey: isShuffle > 0 ? [ 'shuffle-artworks-pages', isShuffle] : ['find-artworks-pages', { userTags } ],
    queryFn: ({ pageParam = 0 }) => {
      if (isShuffle > 0) {
        return getShuffledArtworks({
          size,
          page: pageParam
        })
      } else {
        return getAll({
          userTags,
          size,
          page: pageParam
        })
      }
    },
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    },
    enabled: true
  })

  const flattenedData = isSuccess ? (data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])) : []

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching || isFetchingNextPage,
    error,
    artworks: flattenedData,
    hasNext: hasNextPage,
    nextPage: fetchNextPage,
    isFetched
  }
}