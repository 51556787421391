import axiosInstance from './api';

export const create = (artwork) => {
  return axiosInstance.post('/account/artworks', artwork)
}

export const getAll = async (page = 0, size = 10, sortBy = null) => {
  return axiosInstance.get(`/account/artworks?page=${page}&size=${size}${sortBy ? '&sortBy=' + sortBy : ''}`)
}

export const getByUserId = async (userid, page = 0, size = 10, sortBy = null) => {
  return axiosInstance.get(`/users/${userid}/artworks?page=${page}&size=${size}${sortBy ? '&sortBy=' + sortBy : ''}`)
}

export const getOne = (id) => {
  return axiosInstance.get('/account/artworks/' + id)
}

export const deleteOne = (id) => {
  return axiosInstance.delete('/account/artworks/' + id)
}

export const updateOne = (id, artwork) => {
  return axiosInstance.put('/account/artworks/' + id, artwork)
}

export const setReviewRespond = (id) => {
  return axiosInstance.put(`/account/artworks/${id}/review-respond`)
}

export const countReviewNotResponded = () => {
  return axiosInstance.get(`/account/artworks/not-responded`)
}

export const countUnseen = () => {
  return axiosInstance.get(`/account/artworks/unseen-count`)
}

export const setSeen = () => {
  return axiosInstance.put(`/account/artworks/unseen-count`)
}