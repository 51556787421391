import React, { useState, forwardRef, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { format, parse } from 'date-fns'
import { stripHtml } from '../../utils/utils'

import Tile from '../tile/tile'

import { useHistory, Link } from 'react-router-dom'

import {
  Container,
  Header,
  HeaderInner,
  BackButton,
  AccountType,
  HeaderNameMessage,
  Name,
  AccountCategories,
  LastMessage,
  Counter,
  Datetime,
  Banner,
  Location,
  Stub,
  EditButton,
  EditButtonContainer,
  Status,
  ProfileImage,
  ItchSenderIcon
} from './conversation-tile.styles'
import HoverIcon from 'components/shared/hover-icon'

import Messaging from './messaging'

import { getName } from '../../utils/utils'
import useModal from 'hooks/modal.hook'
import useTheme from 'hooks/user-theme.hook'

import {
  breakpoints
} from 'styles/global.styles'

import { 
  icons, getAccountTypeMeta, Orientation, 
  getUserAccountCategoriesString, AccountTypes 
} from '../../constants'
import countries from 'i18n-iso-countries';
import UserAvatar from 'components/avatar/user-avatar'

import { imageUrl } from 'utils/utils'
import useActionAlert from 'hooks/action-alert.hook'
import useDeleteConversation from 'hooks/delete-conversation.hook'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const ConversationTile = forwardRef((props, ref) => {
  const { userTheme } = useTheme()
  const { setModal } = useModal()
  const { setActionAlert, closeAlert } = useActionAlert()
  const containerRef = useRef(null)
  const { 
    deleteConversation
  } = useDeleteConversation()

  const {
    index,
    expanded,
    row,
    col,
    expandedCol,
    parentRowExpanded,
    onExpand,
    onExit,
    width,
    ...otherProps
  } = props

  const {
    _id,
    muted,
    itch,
    user,
    lastMessage,
    unreadCount,
    ...rest
  } = otherProps

  const accountTypeMeta = getAccountTypeMeta(user.accountType, 28)
  const hasAlert = unreadCount > 0
  const isMobile = window.innerWidth <= breakpoints.sm
  const isItchUser = user.accountType === AccountTypes.Itch

  const handleDelete = (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    setActionAlert({
      noClose: true,
      theme: 'dark',
      icon: icons['pink-alert-trash-triangle.svg'],
      active: true,
      title: 'Delete this conversation?',
      body: `Both your chat history will be lost.<br/><br/>
        <b>Continue anyway?</b>`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        deleteConversation(_id)
        closeAlert()
        setModal(null)
      }
    })
  }

  const rendered = <Container className="conversation-tile" ref={containerRef} muted={muted} expanded={expanded} 
    onClick={() => {
      if (!expanded)
        onExpand()
    }}>
    <Header alert={hasAlert} userTheme={userTheme}>
      <BackButton onClick={()=> {
        onExit()
      }}>
        <div>
          <img src={icons['dark-gery-arrow-left.svg']} alt="back" />
          <span>Back</span>
        </div>
      </BackButton>
      <HeaderInner onClick={() => {
        onExit()
      }}>
        {isItchUser && expanded ?
        <ItchSenderIcon>
          <img src={icons['itch-icon-36.svg']} alt="" />
        </ItchSenderIcon>
        : <>
        <AccountType className={`account-type ${accountTypeMeta.slug}`} alert={hasAlert} userTheme={userTheme} muted={muted} >
          <img className="icon-dark" src={accountTypeMeta.iconDark} alt={accountTypeMeta.name} />
          <img className="icon-flare" src={accountTypeMeta.iconFlare} alt={accountTypeMeta.name} />
          <img className="icon-metro" src={accountTypeMeta.iconMetro} alt={accountTypeMeta.name} />
          <img className="icon-hover" src={accountTypeMeta.iconMetro} alt={accountTypeMeta.name} />
        </AccountType>
        <HeaderNameMessage className="header-name-message">
          <Name expanded={expanded} alert={hasAlert} userTheme={userTheme} muted={muted} >
            {user.itchUrl ?
            <Link className="name" to={`/${user.itchUrl}`} >{getName(user)}</Link> : <>{getName(user)}</>
            }
            {!expanded ? <Status>
              {muted ? <img src={icons['muted.svg']} alt="" /> : null}
              {!muted && unreadCount > 0 ? <Counter>{unreadCount > 99 ? '99+': unreadCount}</Counter> : null}
            </Status> : null}
          </Name>
          {!isItchUser ? 
          <>
            <AccountCategories>
              {getUserAccountCategoriesString(user)}
            </AccountCategories>
            {expanded && user.countryCode ? <Location alert={hasAlert} userTheme={userTheme}>
              <img src={icons['location-size=8px-colour-efefef.svg']} alt=""/>
              <span>{countries.getName(user.countryCode, "en", { select: "official" })}</span>
            </Location> : null}
          </> : null}
          {lastMessage !== null && !expanded ? 
            <LastMessage muted={muted} 
              alert={hasAlert} 
              userTheme={userTheme}>
                {stripHtml(lastMessage.body).substring(0,120)}
              </LastMessage> : null}
          {!expanded && lastMessage !== null ? 
          <Datetime muted={muted}>
            {format(new Date(lastMessage.createdAt), 'MMMM d, yyyy')} at {format(new Date(lastMessage.createdAt), 'h:mm b')}
          </Datetime> : null}
        </HeaderNameMessage>
        {expanded ? 
        <ProfileImage>
          <UserAvatar
            profileUrl={`/${user.itchUrl}`}
            muted={muted}
            accountType={user.accountType}
            url={imageUrl(user.profileImageVariants?.large ?? user.profileImage)} 
            position={user.profileImage?.position} 
          />
        </ProfileImage>
        : null}
        </>}
      </HeaderInner>
      <Stub userTheme={userTheme} alert={hasAlert} />
    </Header>
    {expanded ? <Messaging
      hasUnread={unreadCount > 0} 
      id={_id} 
      disableEdit={true}
      disableReply={isItchUser || muted}
      onClose={() => {
        onExit()
      }}/> : null }
    {!expanded ? 
    <Banner className="banner" muted={muted} alert={hasAlert} userTheme={userTheme}>
      {false && !isItchUser ? <EditButtonContainer
        onClick={() => {
          setModal({
            name: 'edit-conversation',
            id: _id
          })
        }}
      >
        <EditButton
          active={icons['pencil-efefef.svg']}
          hover={icons['pencil-pink.svg']}
        />
      </EditButtonContainer> : null }
      {!isItchUser ? <EditButtonContainer
        onClick={handleDelete}
      >
        <EditButton
          active={icons['efefef-dustbin-18px.svg']}
          hover={icons['pink-dustbin-18px.svg']}
        />
      </EditButtonContainer> : null }
      <div 
        onClick={() => {
          if (!expanded)
            onExpand()
        }}
        className="banner-overlay"></div>
      <div className="image-wrapper">
        <img src={imageUrl(user.profileImageVariants?.large ?? user.profileImage) ?? accountTypeMeta.fallBackAvatar} alt="" />
      </div>
    </Banner> : null}
  </Container>

useEffect(() => {
  if (isMobile && expanded) {
    // body to no scroll
    document.body.classList.add('no-scroll')
  } else 
  if (!expanded) {
    document.body.classList.remove('no-scroll')
  }
}, [isMobile, expanded])

useEffect(() => {
  if (expanded && containerRef) {
    
  }
}, [expanded])

  return (
    <Tile
      onTransitionEnd={(evt) => {
        if (evt.propertyName === 'height' && expanded && containerRef) {
          const tileContainer = document.getElementById('profile-sub-content')
          const tileOffset = containerRef.current.parentElement.parentElement
          const header = document.getElementById('header')
          const topnav = document.getElementById('top-nav')
          const usernav = document.getElementById('user-nav')
          const scrollToTop = tileContainer.offsetTop + tileOffset.offsetTop - (topnav.offsetHeight + usernav.offsetHeight)
          window.scrollTo({
            top: scrollToTop
          })
        }
      }}
      ref={ref}
      width={`16.666666667%`}
      height={'21vw'}
      responsiveHeight={{
        [breakpoints.sm]: '140px'
      }}
      row={row}
      col={col}
      expandedCol={expandedCol}
      parentRowExpanded={parentRowExpanded} 
      expanded={expanded} 
      index={index}
      orientation={Orientation.Portrait}
      {...rest}
    >
      {isMobile && expanded ? createPortal(
        rendered,
        document.body
      ) : rendered}
    </Tile>
  )
})

export default ConversationTile