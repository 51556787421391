import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  colours,
  fonts,
  BaseInputText
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'

const InputPasswordStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const EyeIcon = styled.div`
  position: absolute;
  right:8px;
  top:50%;
  transform: translate(0, -50%);
  flex: 0 0 20px;
  margin-left: 8px;
  cursor: pointer;
  img {
    display:block;
    width: 20px;
    height: 20px;
  }
  img.action-show {
    visibility: ${props => props.visible ? 'hidden':'visible'};
  }
  img.action-hide {
    position: absolute;
    top: 0;
    left: 0;
    visibility: ${props => props.visible ? 'visible':'hidden'};
  }
`;

export default forwardRef(function InputPassword({
  className,
  ...rest
}, ref) {
  const [ passwordVisible, setPasswordVisible ] = useState(false);

  return (
    <InputPasswordStyled className={className}>
      <BaseInputText {...rest} ref={ref}
        type={passwordVisible ? 'text':'password'} 
      />
      <EyeIcon 
        visible={passwordVisible}
        onClick={() => {
          setPasswordVisible(!passwordVisible)
        }}
      >
        <img className="action-show" src={icons['eye-password.svg']} alt='' />
        <img className="action-hide" src={icons['eye-password-see.svg']} alt='' />
      </EyeIcon>
    </InputPasswordStyled>
  );
});