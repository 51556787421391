import { countAllConversationsUnread } from 'apis/conversation.api'
import { useQuery } from '@tanstack/react-query'
import useAuth from './auth.hook'

const useGetConversationUnreadCount = () => {
  const { session } = useAuth()
  const {
    isSuccess,
    isLoading,
    data
  } = useQuery({
    queryFn: countAllConversationsUnread,
    queryKey: [`conversation-unread-count-${session?.profile?.id}`],
    refetchOnReconnect: true,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchInterval: 60000
  })

  return {
    value: isSuccess ? data.count : 0,
    isSuccess,
    isLoading
  }
}

export default useGetConversationUnreadCount