import React, { useEffect, useRef } from 'react'

import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'

import Comment from '../comment/comment'

import ViewTag from '../shared/view-tag'

import Share from '../shared/share'
import useGetOneArticle from 'hooks/get-one-article.hook';

import countries from 'i18n-iso-countries';
import Image from 'components/shared/image'

import {
  ContentContainer,
  CoverImage,
  ContentArea,
  Caption,
  ArticleHeading,
  ArticleSubHeading,
  Content,
  TagsContainer,
  PublishedDate,
  OwnerProfile,
  ShareBottom
} from './article.styles'

import TileOwnerPanel from '../gallery/owner-panel/tile.owner-panel'

import useAuth from 'hooks/auth.hook'

import ArticleOwner from './article-owner'

import { forceLinksBlankTarget } from 'utils/utils'
import SignUpWidget from 'components/shared/sign-up-widget/sign-up-widget'
import { ReviewStatus } from '../../constants'

import { usePageReady } from 'contexts/page-ready.context'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function Article({
  slug,
  onClose,
  hideScroll,
  expanded = false,
}) {
  const { setReady } = usePageReady()
  const { push } = useHistory()
  const containerElem = useRef()

  const { session } = useAuth()
  const { success, article } = useGetOneArticle(slug)

  const shareUrl = `${window.location.protocol}//${process.env.REACT_APP_HOST}/articles/${slug}`

  useEffect(() => {
    if (success) {
      setReady(true)
    }
  }, [success])
  
  return (
    <>
      {success && article && article.reviewStatus === ReviewStatus.Approved ? <ContentContainer hideScroll={hideScroll} ref={containerElem}>
        <CoverImage className="image-area" 
          onClick={() => {
            onClose && onClose()
          }}
        >
          <Image {...article?.coverImageVariants?.large ?? article?.coverImage} alt={article.caption}/>
        </CoverImage>
        <ContentArea className="content-area">
          <Caption>{article.caption}</Caption>
          <ArticleHeading to={`/articles/${article.slug}`}>{article.title}</ArticleHeading>
          <ArticleSubHeading>{article.subHead}</ArticleSubHeading>
          <ArticleOwner 
            shareUrl={shareUrl}
            user={article.user}
            itchArticleMentionUser={article.itchArticleMentionUser}
            createdAt={article.createdAt}
          />

          <Content dangerouslySetInnerHTML={{__html: forceLinksBlankTarget(article.content)}} />
          
          <TagsContainer>
            {article.tags.map((tag, i) => 
              <ViewTag key={`tag-${i}`} text={tag} onClick={() => {
                push('/search/articles?q=' + tag)
              }}/>
            )}
          </TagsContainer>
          
          <PublishedDate className="published-date">
            {format(new Date(article.createdAt), 'MMMM d yyyy')} at {format(new Date(article.createdAt), 'h:mma')}
          </PublishedDate>
          
          <OwnerProfile className="author-profile" expanded={expanded}>
            <TileOwnerPanel
              avatarFormat="circle" 
              disableFlagging={true}
              isOwner={session?.profile?.id === article.user?._id}
              expanded={true}
              locationIcon={'location-12px-dark.svg'}
              isItchArticle={article.isItchArticle}
              user={article.isItchArticle ? article.itchArticleMentionUser : article.user}
            />
          </OwnerProfile>

          <ShareBottom>
            <Share url={shareUrl} size={20} />
          </ShareBottom>

          <SignUpWidget/>

          <Comment contentType="articles" id={article._id} />
          
        </ContentArea>
      </ContentContainer> : null}
    </>
  )
}