import React, { useState, useEffect } from 'react'
import useGetFollowedByMe from 'hooks/get-followed-by-me.hook'

const initialState = []

export const FollowedByMeContext = React.createContext(initialState);

export const FollowedByMeProvider = ({ children }) => {
  const [followedByMe, setFollowedByMe] = useState(initialState);

  const {
    loading,
    fetching,
    success,
    userIds
  } = useGetFollowedByMe()

  useEffect(() => {
    if (success && userIds?.length > 0) {
      setFollowedByMe(userIds)
    }
  }, [success, userIds])

  return (
    <FollowedByMeContext.Provider value={{followedByMe}}>
      {children}
    </FollowedByMeContext.Provider>
  );
}