import React from 'react'
import styled from 'styled-components'
import AccountTypeCategories from 'components/shared/account-type-categories'

const Container = styled.div`
  .account-type-categories {
    margin-bottom:2px;
  }
`

export default function OwnerAccountTypes(props) {
  
  return (
    <Container>
      <AccountTypeCategories {...props} />
    </Container>
  )
}