import React from 'react';
import SocialShareButton from './social-share-button'

export default function Facebook({
  url,
  ...rest
}) {
  return (
    <SocialShareButton
      socialType={"facebook"}
      onClick={() => {
        
        /*
        window.open(`https://www.facebook.com/dialog/share?
        app_id=145634995501895
        &display=popup
        &href=URL_TO_SHARE
        &redirect_uri=RETURN_URL')
        */
        // eslint-disable-next-line no-undef
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url ?? window.location.href)}`)
        /*
        window.FB.ui({
          method: 'share',
          href: url ?? window.location.href,
        }, function(response){});
        */
      }}
      {...rest}
    />
  )
}