import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'

import {
  Container,
  Cell
} from './grid.styles'

import User from './user'
import Article from './article'

import useGrid from './grid.hook'
import useGetTrendingUserLatestArticles from 'hooks/get-trending-users-latest-articles'
import { isMobile, useWindowDimensions, useUserStatus, useArtworkArticleStatus, ScrollToArticle, WaitForClose } from 'utils/helpers';
import Emitter from 'utils/emitter';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useZine, Sections } from '../zine.context';

export default function Grid() {
  const gridElem = useRef(null)
  
  // const [ startOpen, setStartOpen ] = useState(false)
  const { 
    isSuccess, 
    isLoading, 
    data, 
    fetchNextPage, 
    hasNextPage
  } = useGetTrendingUserLatestArticles()
  const { cells, fill, expand, expanded, rowCount } = useGrid()

  const { 
    setLoadedComponent
  } = useZine()

  useBottomScrollListener(() => {
    // console.log('try get next page')
    if (hasNextPage)
      fetchNextPage()
  }, {
    // offset: gridElem.current?.getBoundingClientRect().bottom ?? window.innerHeight
    offset: 340
  });
  
  const lastCount = useMemo((() => isSuccess ? data.length : 0), [isSuccess, data])

  useEffect(() => {
    if (isSuccess) {
      const gridItems = data.map(item => {
        if (item.type === 'user') {
          return {
            width: 1,
            height: 1,
            size: 1,
            data: item
          }
        } else
        if (item.type === 'article') {
          return {
            width: 2,
            height: 2,
            size: 2,
            data: item
          }
        } else {
          return null
        }
      })
      fill(gridItems)
      // watchElement(window)

      setLoadedComponent(Sections.UserArticle)
    }
  }, [lastCount])

  

  /*
  useEffect(() => {
    console.log('sectionsClosed', sectionsClosed)
    if (startOpen && (sectionsClosed[Sections.ItchArticle]
      || sectionsClosed[Sections.FeaturedArtwork])) {
        console.log('other section closed')
        scrollToUserArticleTop()
        setStartOpen(false)
    }
  }, [sectionsClosed])
  */

  /*
  useEffect(() => {
    if (openedSectionName !== Sections.UserArticle && openedSectionName !== null && expanded) {
      expand(expanded.index)
    }
  }, [openedSectionName])
  */

  const { userArticleAllowed } = useArtworkArticleStatus();
  const { windowWidth, isMobile } = useWindowDimensions();
  const [requestedElement, setRequestedElement] = useState(null);
  const [allowedElement, setAllowedElement] = useState(null);
  const [openedElement, setOpenedElement] = useState(null);

  const handleOpenRequest = (index) => {
      // console.log('emit open user article');
      // disallow all user articles when new request is being handled
      setAllowedElement(null);
      setRequestedElement(index);
      Emitter.emit('openUserArticle');
  }
  
  const handleOpenedUserArticle = (index) => {
      setOpenedElement(index);
  }

  // clear opened user article
  const clearElement = () => {
      // make sure to disallow and close opened article
      setAllowedElement(null);
      setOpenedElement(null);
  }

  const closeUserArticle = (closeWithin) => {
    console.log('emit user article is closed');
    Emitter.emit('itchArticleIsClosed');
    if (closeWithin) {
      Emitter.emit('closeWithin');
    }
  }
  // console.log(requestedElement, userArticleAllowed)
  useEffect(() => {
      // allow an article to open when general state is allowed
      if (userArticleAllowed) {
        console.log(`user article index ${requestedElement} allowed to open`)
        if (!isMobile)
          expand(requestedElement)
        setAllowedElement(requestedElement);
      } else {
        setAllowedElement(null);
        if (!isMobile) {
          if (expanded && requestedElement) {
            expand(expanded?.index) // closed the opened tile
            clearElement()
          }
        } else {
          if (requestedElement) {
            clearElement()
          }
        }
      }
  }, [requestedElement, userArticleAllowed])

  const getMobileSortedCells = (items) => {
    let sorted = []
    let lastUserIndex = null
    let usersCount = 0

    let counter = 0
    for (const item of items) {
      if (usersCount === 1 && item.size === 1) {
        const nextIndex = lastUserIndex + 1
        sorted.splice(nextIndex, 0, item)
        usersCount = 0
        lastUserIndex = null
      } else {
        sorted.push(item)

        if (item.size === 1) {
          usersCount++
          lastUserIndex = counter
        }
      }
      counter++
    }

    return sorted
  }

  return (
    <>
      {isSuccess ? 
      <>
      <Container rowCount={rowCount - 1} id="grid-container" ref={gridElem}>
        {(isMobile ? getMobileSortedCells(cells) : cells).map((item, i) => (
          <Cell
            id={`cell-${i}`} key={i} {...item} 
            expanded={isMobile ? allowedElement === i : expanded?.index === i} 
            onClick={() => {
              if (!isMobile) {
                if (expanded?.index !== i) {
                  handleOpenRequest(i)
                }
              } else {
                if (allowedElement !== i) {
                  handleOpenRequest(i)
                }
              }
            }}
          >
          {item.data.type === 'user' ? <User user={item.data.user} /> : null}
          {item.data.type === 'article' ? <Article 
            parentGridElem={gridElem} 
            onClose={() => {
              clearElement()
              closeUserArticle(true)
              if (!isMobile) {
                if (expanded) {
                  expand(i)
                  
                  const thisCell = document.getElementById(`cell-${i}`)
                  const topNav = document.getElementById('top-nav') || document.getElementById('public-menu')
                  if (thisCell && topNav)
                    window.scrollTo({
                      top: thisCell.offsetTop + thisCell.parentElement.offsetTop - topNav.clientHeight,
                    })

                }
              } else {
                setTimeout(() => {
                  const thisCell = document.getElementById(`cell-${i}`)
                  const topNav = document.getElementById('top-nav') || document.getElementById('public-menu')
                  if (thisCell && topNav)
                    window.scrollTo({
                      top: thisCell.offsetTop - topNav.clientHeight,
                      behavior:'auto'
                    })
                }, 500)
              }
            }}
            setElement={() => handleOpenedUserArticle(i)}
            open={openedElement === i}
            allowed={allowedElement === i}
            expanded={isMobile ? allowedElement === i : expanded?.index === i} 
            article={item.data.article} /> : null}
          </Cell>
        ))}
      </Container>
      </>
      : null}
    </>
  )
}