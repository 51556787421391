import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import TileActions from './tile-actions'

import {
  colours,
  breakpoints
} from '../../styles/global.styles'

import useFullScreen from '../../hooks/fullscreen.hook'
import useArtworkActions from 'hooks/artwork-actions.hook'
import useAuth from 'hooks/auth.hook'
import useActivityViews from 'hooks/activity-views.hook'

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${colours.black_alpha85};
  display:flex;
  justify-content: center;
  align-items: center;

  .image-container {
    position: relative;

    .actions {
      position: absolute;
      z-index:2;
      top: 8px;
      left: 8px;
    }
    
    img.image {
      visibility: hidden;
      display:block;
      object-fit: contain;
      object-position: center center;
      width: unset;
      height: unset;

      &.show {
        visibility: visible;
      }
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    .image-container {
      .actions {
        position: fixed;
        top: unset;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`

export default function FullScreen() {
  const { setFullScreen, fullScreen } = useFullScreen();
  const { createActivity } = useArtworkActions()
  const elem = useRef(null)
  const session = useAuth()
  const { isViewed, pushViewed } = useActivityViews()
  const fullScreenRef = useRef(null)
  const imageContainerRef = useRef(null)

  // set the dimensions based on browser size
  const getImageShape = ({width, height}) => {
    if (fullScreen.active) {
      const shape = {
        width: 0,
        height: 0
      }
      const imageRatio = width / height
      const screenRatio = window.innerWidth / window.innerHeight
      if (imageRatio < screenRatio) {
        shape.width = imageRatio * window.innerHeight
        shape.height = window.innerHeight
      } else {
        shape.height = height / width * window.innerWidth
        shape.width = window.innerWidth
      }
      elem.current.classList.add('show')
      return shape
    }
  }

  const setImageShape = () => {
    if (fullScreen.active) {
      const { width, height } = getImageShape(fullScreen)
      if (elem.current) {
        elem.current.style.height = `${height}px`
        elem.current.style.width = `${width}px`
      }
    }
  }

  useEffect(() => {
    if (fullScreen.active) {
      if (elem.current)
        elem.current.classList.add('show')

      document.body.classList.add('no-scroll');
      window.onresize = () => {
        setImageShape()
      }

      elem.current.addEventListener('click', (e) => {
        e.stopPropagation()
      })

      fullScreenRef.current.addEventListener('click', (e) => {
        setFullScreen({
          active: false
        })
      })
  
      setImageShape()

      if (session) {
        if (!isViewed({
          name: 'artworks',
          id: fullScreen.id
        })) {
          createActivity({
            id: fullScreen.id, 
            action: 'viewed'
          })
          pushViewed({
            name: 'artworks',
            id: fullScreen.id
          })
        }
      }
      
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [fullScreen])
  
  return (
    <>
      {fullScreen.active ? 
        <Container className="fullscreen" ref={fullScreenRef}>
          <div className="image-container">
            <div className="actions">
              <TileActions 
                showFullScreen={false}
                showMinimise={true}
                expanded={true}
                artworkId={fullScreen.id}
                onExit={() => {
                  setFullScreen({
                    active: false
                  })
                }}
                votededByUser={fullScreen.votedByUser}
                viewedByUser={fullScreen.viewedByUser}
                itchbookedByUser={fullScreen.itchbookedByUser}
                mediaType={fullScreen.mediaType} 
              />
            </div>
            <img className="image" 
              ref={elem}
              src={fullScreen.url}
              alt="" />
          </div>
        </Container>
      : null}
    </>
  )
}