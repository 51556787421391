import React from 'react'
import styled from 'styled-components'

import {
  fonts,
  colours,
  breakpoints
} from '../../../styles/global.styles'

import { Link } from 'react-router-dom'

const Container = styled.div`  
  margin-bottom: 4px;
  display:flex;
  align-items: center;
  cursor: pointer;
  
  @media (max-width:${breakpoints.sm}px) {
    flex: 0 0 auto;
  }
`

const Text = styled(Link)`
  font-family: ${fonts.content.bold};
  color: ${colours.darkergrey2};
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0em;
  padding-right: 3px;
  text-align: left;
  word-break: break-all;
  
  height:auto;
  box-sizing: content-box;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */

  
  &:hover {
    color: ${colours.pink};
  }


  &.expanded {
    font-size: 13px;
    line-height: 13px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 11px;
    line-height: 11px;
  }
`

export default function TileOwnerName({
  name,
  url,
  expanded,
  ...rest
}) {
  return (
    <Container {...rest}>
      <Text className={`${expanded ? 'expanded':''}`} to={url}>
        {name}
      </Text>
    </Container>
  )
}