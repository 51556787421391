import React from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours,
  ButtonReset,
  breakpoints
} from '../../styles/global.styles'
import {
  preloaders
} from '../../constants'
import DotProgress from 'components/shared/dot-progress'

export const ButtonSaveStyled = styled(ButtonReset)`
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 14px;
  line-height: 0;
  letter-spacing: 0em;
  width: 270px;
  height: 48px;
  padding: 17px 10px 16px 10px;
  position: relative;
  border:1px solid ${colours.pink};
  opacity: ${props => props.active ? '1': '0.6'};
  pointer-events: ${props => props.active ? 'all':'none'};
  cursor: ${props => props.active ? 'pointer':'none'};
  display:flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: auto !important;
  justify-self:flex-end;

  
  &:hover {
    background-color: ${colours.pink_alpha50};
  }


  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 160px;
  }
`;

export const Loader = styled.span`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background:transparent;

  &>div {
    width: 100%;
  }
`;

export const ButtonText = styled.span`
  
`;

export default function ButtonSave({
  children,
  loading = false,
  active = false,
  loadingVariant = 'light',
  ...rest  
}) {
  return (
    <ButtonSaveStyled active={active} {...rest}>
      {loading ? <Loader className="loader">
        <DotProgress variant={loadingVariant} />
      </Loader> : null}
      {!loading ? <ButtonText>
      {children}
      </ButtonText> : null}
    </ButtonSaveStyled>
  );
}