import React, { useState, useEffect } from 'react';
import Modal from '../shared/modal';
import CenteredFormLayout from '../shared/modal-form/centered-form.layout';

import ButtonSave from '../form/button-save';
import {
  EditContainer,
  FormReset,
  FormActions,
  Error,
  Row,
  Col
} from '../shared/modal-form/modal-form.styles'
import {
  FormStyled,
  Container,
  FieldStyled,
  ReasonField,
  TextAreaStyled,
  RadioButtonsStyled,
  ArtworkImage,
  PreviewStyled,
  ButtonIsIntellectualPropStyled
} from './flag-artwork-form.styles'
import {
  icons,
  FlagReason
} from '../../constants'

import { useForm, Controller } from "react-hook-form";
import useModal from '../../hooks/modal.hook';

import useFlag from '../../hooks/flag.hook'
import useActionAlert from '../../hooks/action-alert.hook'

const FlagArtworkForm = ({
  id,
  imageUrl,
  title,
  content
}) => {
  const { flagContent, isLoading, isSuccess } = useFlag(content, id)
  const { setModal } = useModal()
  const { setActionAlert } = useActionAlert();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: {
      errors,
      isDirty
    }
  } = useForm({
    mode: 'onSubmit'
  })

  const onSubmit = (data) => {
    confirmFlag({
      ...data
    })
  }

  useEffect(() => {
    window.gtag('event', 'modal_view', {
      page_title: 'flag-artwork',
      id: id,
      title: title
    });
  }, [])

  const confirmFlag = ({
    reason, comments
  }) => {
    setActionAlert({
      icon: icons['flag-confirm-icon.svg'],
      active: true,
      theme: 'dark',
      title: 'Proceed to flag this artwork?',
      body: `We'll review this privately to help make <b>itch</b> a creative<br/> yet respectful community for all`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        setActionAlert({
          active: false
        })
        flagContent({
          reason: reason,
          comments: comments
        })
        setModal(null)
      }
    })
  }

  const handleIsIntellectualPropClick = () => {
    confirmFlag({
      reason: FlagReason.INTELLECTUAL_VIOLATION,
      comments: getValues('comments')
    })
  }

  return (
    <Modal
      isSubmitting={false}
      onClose={() => {
        setModal(null)
      }}
    >
      <EditContainer>
        <CenteredFormLayout
          icon={icons['flag-outline.svg']}
          title="flag artwork"
        >
          <FormStyled onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Col style={{alignSelf:'stretch', height:'unset'}}>
                <Row>
                  <FieldStyled
                    label="Specify why your're reporting this artwork"
                  >
                    <Controller
                      name="reason"
                      control={control}
                      rules={{
                        required: false
                      }}
                      render={({ field }) => 
                        <RadioButtonsStyled
                          {...field}
                          className="options"
                          options={[
                            { 
                              value: FlagReason.PORNOGRAPHIC,
                              name: 'Contains pornographic content'
                            },
                            { 
                              value: FlagReason.OFFENSIVE_IRRELEVANT,
                              name: 'Contains other offensive or irrelevant content'
                            }
                          ]}
                        />
                      } />
                  </FieldStyled>
                </Row>
                <Row style={{flex:'1 1 auto'}}>
                  <ReasonField
                    label="Additional Comments"
                  >
                    <TextAreaStyled
                      {...register('comments')}
                    />
                  </ReasonField>
                </Row>
                <Row style={{paddingTop: '20px'}}>
                  <ButtonIsIntellectualPropStyled
                    onClick={handleIsIntellectualPropClick}>
                    This artwork is my intellectual property
                  </ButtonIsIntellectualPropStyled>
                </Row>
              </Col>
              <Col>
                <PreviewStyled
                  label={title}
                >
                  <ArtworkImage>
                    <img src={imageUrl} alt=""/>
                  </ArtworkImage>
                </PreviewStyled>
              </Col>
              <FormActions>
                <FormReset disabled={!isDirty} type="reset">Reset</FormReset>
                <ButtonSave active={isDirty} loading={isLoading} type="submit">Submit</ButtonSave>
              </FormActions>
            </Container>
          </FormStyled>
        </CenteredFormLayout>
      </EditContainer>

    </Modal>
  )
}

export default FlagArtworkForm