import styled from 'styled-components'
import {
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'
import {
  Link
} from 'react-router-dom'

export const Container = styled(Link)`
  width: 100%;  
  display: flex;
  flex-direction: row;
  background-color: ${colours.light};
  max-width:100%;
  margin-bottom:10px;
  
  *.mobile {
    display: block;
  }
  
  *.desktop {
    display: none;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    width: 100%;
    max-width: 930px;
    margin-bottom: 40px;
    height: 178px;
    
    *.mobile {
      display: none;
    }
    *.desktop {
      display: block;
    }
  }
  
`

export const Left = styled.div`
  display:flex;
  flex-direction: column;
  padding: 10px 0px 3px 10px;
  justify-content:flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  width: calc(100% - 142px);

  @media screen and (min-width:${breakpoints.sm}px) {
    padding: 15px;
    width: calc(100% - 90px);
    padding: 10px 9px 3px 10px;
  }
`

export const Row = styled.div`
  display:flex;
  flex-direction: row;
  flex:1 1 auto; 
  justify-content:flex-start;
  align-items: flex-start;
  width:100%;
  
  @media screen and (min-width:${breakpoints.sm}px) {
    padding: 15px 0 15px 15px;
  }
`

export const AccountTypeContainer = styled.div`
  flex: 0 0 37px;
  padding-right: 10px;

  img {
    width:100%;
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    flex: 0 0 65px;
    padding-left: 5px;
    padding-right: 20px;   
  }
`
export const Info = styled.div`
  flex: 1 1 auto;
  width: calc(100% - 37px);
  max-width: calc(100% - 37px);

  &>.inner {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    width: calc(100% - 65px);
    max-width:100%;
    &>.inner {
    }
  }
`

export const Name = styled(Link)`
  font-family: ${fonts.content.bold};
  color: ${colours.darkergrey2};
  font-size: 13px; 
  text-align: left;
  width:100%;
  max-width:99%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    
  @media screen and (min-width:${breakpoints.sm}px) {
  }


  &:hover {
    color: ${colours.pink};
  }

`

export const Categories = styled.div`
  font-family: ${fonts.content.regular};
  font-weight: 400;
  color: ${colours.lightgrey3};
  font-size: 11px;
  line-height: 12px;
  text-align: left;

  @media screen and (min-width:${breakpoints.sm}px) {
    font-size: 13px;
    line-height: 16px;
  }
`
export const Location = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  font-family: ${fonts.content.regular};
  font-weight: 400;
  color: ${colours.lightgrey3};
  font-size: 12px;
  line-height: 14px;
  position: relative;

  img {
    width: 8px;
    margin-right:5px;

    &:first-child {
      visibility:visible;
    }

    &:last-child {
      position:absolute;
      top: 2px;
      left: 0;
      visibility:hidden;
    }
  }
  

  &:hover {
    color: ${colours.pink};
    img:first-child {
      visibility:hidden;
    }
    img:last-child {
      visibility:visible;
    }
  }


  @media screen and (min-width:${breakpoints.sm}px) {
  }
`

export const FashionStatement = styled.div`
  font-family: ${fonts.content.regular};
  font-weight: 400;
  color: ${colours.lightgrey3};
  text-align: left;
  width: 100%;
  
  span, div, p {
    text-align:left !important;
    font-family: ${fonts.content.regular};
    font-weight: 400;
    color: ${colours.lightgrey3};
    padding:0;
    margin:0;
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    border-top:1px solid ${colours.text};
    margin-top: 10px;
    padding-top: 10px;
    font-size: 12px;
    line-height: 14px;
    height: 80px;
    overflow: hidden;
    word-break: break-all;
    max-width: 450px;
  }
`

export const ActionsFollowings = styled.div`
  display:flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: flex-end;
  
  @media screen and (min-width:${breakpoints.sm}px) {
    padding: 15px;
  }
`

export const Actions = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  
  align-items: flex-start;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 6px;

  .follow-widget {
    position: relative;
    top: 4px;

    .unfollow {
      top: -4px;
      .label {
        display:none;
      }
    }
    .follow {
      .label {
        visibility: visible;
        margin-right:0;
      }
    }
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    height: 40px;
    padding-top: 5px;
    padding-bottom: 0px;
    border-right: 1px solid ${colours.text};

    .follow-widget {
      top: 0px;

      .unfollow {
        top: 0px;
        .label {
          display:none;
        }
      }
      .follow {
        .label {
          visibility: hidden;
          margin-right: 8px;
        }
      }

      
      &:hover {
        .follow {
          .label {
            visibility: visible;
          }
        }
      }
    
    }
  }
`

export const ActionItem = styled.div`
  cursor: pointer;
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  align-items: center;
  margin-top:5px;

  img {
    width:100%;
  }

  span {
    font-family: ${fonts.content.regular};
    font-weight: 400;
    color: ${colours.lightgrey2};
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    width: 18px;
    height: 14px;
  }
`

export const Avatar = styled(Link)`
  flex: 0 0 90px;
  overflow: hidden;
  position:relative;

  &:after {
    content: '';
    top:0;
    left:0;
    position:absolute;
    z-index:2;
    width: 100%;
    height: 100%;
    display:block;
    background-color: ${colours.pink_alpha40};
    opacity: 0;
    transition: all .3s;
  }

  img {
    width:100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    flex: 0 0 178px;
  }

  
  &:hover {
    &:after {
      opacity: 1;
    }
  }

`