import styled from 'styled-components';
import {
  fonts,
  breakpoints,
  colours,
  ButtonReset
} from '../../../styles/global.styles'

export const EditContainer = styled.div`  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-height:inherit;
  min-height:inherit;
  justify-content: center;
  align-items: center;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    max-width: 100%;
    
  }
`;

export const FormActions = styled.div`
  display:flex;
  margin-top: auto;
  flex-direction: row;
  border-top: 1px solid rgba(236, 15, 105, 1);
  padding-top: 8px;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 8px;
  background-color: ${colours.black_alpha50};
  margin-left:-22px;
  margin-right:-22px;
  margin-bottom:0px;
  

  &>button[type="submit"] {
    margin-left:auto;
  }
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    position: fixed;
    bottom:0;
    left:0;
    margin-left:unset;
    margin-right:unset;
    width: calc(100% - 15px);
    height: 75px;
    padding-bottom: 5px;
    padding-top: 12px;

    &>button:first-child {
      margin-left: -10px;
    }
  }
`;

export const ButtonBase = styled(ButtonReset)`
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  opacity: ${props => props.disabled ? 0.6 : 1};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  transition-property: all;
  transition-duration: .15s;

  cursor: ${props => props.disabled ? 'unset': 'pointer'};

  
  &:hover {
    background-color: ${colours.pink_alpha50};
  }

`;

export const FormReset = styled(ButtonBase)`
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
  
  border-right:1px solid ${colours.pink};
  pointer-events: ${props => props.disabled ? 'none':'all'};
  background-color: transparent;

  
  &:hover {
    color: ${props => props.theme.main ==='dark' ? colours.text : colours.pink };
    background-color: transparent;
  }

  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    height: 100%;
    padding: 0 30px;
  }
`;    

export const FormButtonGeneric = styled(ButtonBase)`
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
  
  border-right:1px solid ${colours.pink};

  
  &:hover {
    background-color: transparent;
    color: ${colours.text};
  }

  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    padding: 0 30px;
    height: 100%;
  }
`;    

export const Col = styled.div`
  display:flex;
  flex-direction: column;
  padding-left:12px;
  padding-right:12px;
  width: 100%;

  &>.form-field-text {
    
  }

  &>.form-field-billboard {
    margin-top: 30px;
    &>.label {
      margin-bottom:0;
    }
  }
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {  
    padding-left:12px;
    padding-right:12px;
    flex: 1 1 1px;
    justify-content: flex-start;
    height: 100%;
    
    &>.form-field-text {
      padding-right:0px;
    }
    
    &>.form-field-billboard {
      margin-top: 0px;
    }
  }

  ${props => props.addStyles ?? ''}
`;

export const Row = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    flex-direction: row;
    align-items: flex-start;
    flex: 1 1 1px;
    min-height: fit-content;
  }
`;

export const Error = styled.div`
  color: ${colours.pink};
  border: 1px solid ${colours.pink};
  background-color: ${props => (props.theme ?? 'light') === 'light' ? colours.pink_alpha10 : colours.pink_alpha20};
  padding: 11px;
  width: 100%;
  margin-bottom: 10px;
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

export const ClickArea = styled.div`
    width:50%;
    height:100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
`;