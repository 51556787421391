import styled from 'styled-components';
import FormField from '../form/form-field'
import {
  fonts,
  breakpoints,
  colours,
  ButtonPrimary
} from '../../styles/global.styles'
import RadioButtons from '../form/radio-buttons'

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const FieldStyled = styled(FormField)`
  width: 435px;

  &>.label {
    border-bottom:1px solid ${colours.text};
  }
  
  &:hover {
    &>.label {
      background-color: transparent;
    }
    .input-wrapper {
      background-color: transparent;
    }
  }

`;

export const PreviewStyled = styled(FieldStyled)`
  width: 316px;
  
  
  &:hover {
    &>.label {
      background-color: transparent;
    }
    .input-wrapper {
      background-color: transparent;
    }
  }


  &>.label {
    margin-bottom: 25px;
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`

export const Container = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left:-24px;
  margin-right:-24px;
`


export const RadioButtonsStyled = styled(RadioButtons)`
  flex-direction: column !important;
  margin-top:12px;

  .radio-button-item {
    margin-bottom: 2px;
    .label {
      color: ${colours.text};
      font-weight: 700;
    }
  }
`;

export const ReasonField = styled(FieldStyled)`
  margin-top: 12px;
  margin-bottom: 24px;
  height: 100%;

  .label {
    border-bottom:1px solid ${colours.pink};
  }

  &:focus-within {
    background-color: ${colours.pink_alpha40};
    .label {
      border-bottom-width: 2px;
    }
    .input-wrapper {
      
    }
  }

`;

export const TextAreaStyled = styled.textarea`
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.text};
  background-color:transparent;
  border:none;
  outline:none;
  padding: 10px;
  height: 108px;
  margin-left: 4px;
  margin-right:4px;

  &::-webkit-scrollbar {
    width:3px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }

  &::-webkit-resizer {
    display:none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colours.pink};
  }
`;

export const ButtonIsIntellectualPropStyled = styled(ButtonPrimary)`
  width: 100%;
  min-width: auto;
  max-width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const ArtworkImage = styled.div`
  display: block;
  width: 294px;

  img {
    display: block;
    object-fit: contain;
    object-position: center center;
    height: 100%;
    width: 100%;
  }
`