import React, { useState, useEffect } from 'react'
import useGetTasks from 'hooks/get-my-tasks.hook';
import useCancelTask from 'hooks/cancel-task.hook';

import { TaskTypes } from '../constants'

const initialState = {
  tasks: []
}

export const TasksContext = React.createContext(initialState);

export const TasksProvider = ({ children }) => {
  const { success, tasks } = useGetTasks()
  const { cancelTask } = useCancelTask()

  const filteredTasks = (tasks ?? []).filter(t => t.taskType === TaskTypes.ConfirmEmailChange)
  
  const cancel = (id) => {
    cancelTask(id)
  }

  return (
    <TasksContext.Provider value={{tasks: filteredTasks, cancel}}>
      {children}
    </TasksContext.Provider>
  );
}