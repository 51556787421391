
import { setSeen } from 'apis/account-artwork.api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import useAuth from './auth.hook'

const useSetArtworkSeen = () => {
  const queryClient = useQueryClient()
  const { session } = useAuth()
  const {
    mutate,
    isSuccess,
    isLoading
  } = useMutation({
    mutationFn: setSeen,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: [`artwork-unseen-count-${session?.profile?.id}`] })
    }
  })

  return {
    setSeen: mutate,
    isSuccess,
    isLoading
  }
}

export default useSetArtworkSeen