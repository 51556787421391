import styled from 'styled-components'
import {
  colours,
  fonts,
  ScrollStyledBox,
  ButtonPrimary,
  breakpoints,
  icons
} from 'styles/global.styles'
import {
  Link
} from 'react-router-dom'

export const ContentContainer = styled(ScrollStyledBox)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding-bottom: 50px;
  background-color: ${colours.lightgrey7};
  position: relative;

  ${props => props.hideScroll ? `
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  `:''}

  @media (max-width:${breakpoints.sm}px) {
    overflow-y: visible;
  }
`

export const CoverImage = styled.div`
  width: 100%;
  height: 40vw;
  cursor: pointer;

  &>img {
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};
    width: 100%;
    height: 100%;
  }
`

export const CoverImageExpanded = styled.div`
  width: 100%;
  height: 700px;

  &>img {
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};
    width: 100%;
    height: 100%;
  }
`

export const ContentArea = styled.div`
  width: 100%;
  max-width: 63%;
  padding-left:0px;
  padding-right:0px;
  height: auto;
  margin-left:auto;
  margin-right:auto;

  .comment-input-container {
    border-color: ${colours.lightgrey6};
    
    .comment-input-container__inner {

      .rich-editor-container {
        border: 1px solid ${colours.lightgrey6};
      }

      .submit-comment {
        border-color: ${colours.lightgrey6};
        color: ${colours.pink};
             
        &:hover {
          color: ${colours.pink};
        }
      
      }
  
      &:focus-within {
        .submit-comment {
          border-color: ${colours.pink};
          color: ${colours.pink};
        }
      }
    }
  }

  .comment-container {
    border-color: ${colours.lightgrey6} !important;
  } 

  @media (max-width: ${breakpoints.sm}px) {
    padding-left:32px;
    padding-right:32px;
    max-width:100%;
  }
`

export const Caption = styled.div`
  color: ${colours.darkergrey};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
`

export const Heading = styled.div`
  margin-top: 50px;
  text-align: left;
`

export const ArticleHeading = styled(Link)`
  color: ${colours.black};
  font-family: ${fonts.content.bold};
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  display:block;
`
export const ArticleSubHeading = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
`

export const AuthorSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  width: 100%;

  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  border-top:1px solid ${colours.text};
  padding-top:12px;

  @media (max-width:${breakpoints.sm}px) {
    flex-direction:row;
    justify-content: space-between;
  }

`

export const AuthorInfoArticleDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .name {
    font-family: ${fonts.content.bold};
    text-decoration:underline;
  }
`

export const AuthorLink = styled(Link)`
  text-decoration: underline;
  font-family: ${fonts.content.bold};
`

export const AuthorCategories = styled.span`
  padding-left:6px;

  .account-type-categories {
    display: inline;
    color: ${colours.black};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`

export const Sharing = styled.div`
  margin-left: auto;
  
  .share-btn {
    width:16px;
    height:16px;
    margin-left: 6px;
    margin-right: 6px;
  }

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
    margin-left:0;
    margin-top: 12px;

    .share {
      justify-content: flex-end;
    }
  }
`

export const ButtonToPastItchArticles = styled(Link)`
  width: auto;
  justify-self:flex-start;
  font-family: ${fonts.content.regular};
  font-size: 15px;
  line-height: 16px;
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-top:auto;
  .icon {
    margin-right:10px;
    width: 20px;
    height: 6px;
    position: relative;
    img {
      position: absolute;
      top:0;
      left:0;
    }
    img:first-child {
      opacity:1;
    }
    img:last-child {
      opacity:0;
    }
  }
  b {
    font-family: ${fonts.content.bold};
  }
  
  &:hover {
    color: ${colours.pink};
    b {
      color: ${colours.pink};
    }
    img:first-child {
      opacity:0;
    }
    img:last-child {
      opacity:1;
    }
  }

  @media (max-width:${breakpoints.sm}px) {
  }
`

export const ButtonBottomToPastItchArticles = styled(Link)`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top:100px;
  margin-bottom:100px;
  margin-left:auto;
  margin-right:auto;
  &>a {
    justify-content:center;
    width: 300px;
    height:54px;
    border: 1px solid ${colours.grey};
    margin-top:unset;
    
    &:hover {
      border-color: ${colours.pink};
      background-color: ${colours.pink_alpha20};
    }
  
  }
`

export const Content = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;

  img {
    display: block;
    width: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    aspect-ratio: 16/9;
  }

  a {
    color: ${colours.pink};
    text-decoration: none;
  }
`

export const TagsContainer = styled.div`
  margin-bottom: 50px;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  &>* {
    margin:5px;
    text-transform: capitalize;
  }
`

export const CreatedDate = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
`

export const PublishedDate = styled.div`
  border-top: 1px solid ${colours.text};
  padding-top: 10px;
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
`

export const OwnerProfile = styled.div`
  min-height: 100px;
  margin-bottom: 40px;
  .first {
    &>* {
      padding-left:0;
      img {
        width: 40px;
      }
    }
  }
  .info {
    .name>* {
      color: ${colours.black};
      font-family: ${fonts.content.bold};
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    .info-engage {
      .acctypes-location > *{
        color: ${colours.black};
        font-family: ${fonts.content.regular};
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

        .account-type-categories {
          font-size: 14px;
          color: ${colours.black};
        }
        
      }
      .engage {
        padding-bottom: 0;
        .engage-button:last-child {
          margin-bottom: 0;
        }
      }
    }
    
  }

  @media (max-width:${breakpoints.sm}px) {
    .avatar {
      flex: 0 0 84px;
      width: 84px;
    }
  }
`

export const ShareBottom = styled.div`
  margin-top: 63px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
`
