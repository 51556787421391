import React from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours,
  ButtonReset
} from '../../../styles/global.styles'
import {
  icons
} from '../../../constants'

const Button = styled(ButtonReset)`
  &>img {
    transform-origin: center center;
    transform: rotate(${props => props.inverted ? 180:0 }deg);
  }
`

export default function ExpandButton({
  inverted,
  ...rest
}) {
  return (
    <Button type="button" inverted={inverted} {...rest} >
      <img src={icons['pink-down-arrow.svg']} className="expand" alt="expand" />
    </Button>
  )
}