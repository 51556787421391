import axiosInstance from './api';

export const getPsstLastReadAt = () => {
  if (axiosInstance.defaults.headers.common['Authorization'] === null) {
    return false
  }
  return axiosInstance.get(`/account/alert-preferences/psst-read`)
}

export const setPsstLastReadAt = (readAt) => {
  return axiosInstance.put(`/account/alert-preferences/psst-read`, {
    readAt
  })
}
