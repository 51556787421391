import styled from 'styled-components'

import {
  colours,
  breakpoints
} from 'styles/global.styles'


export const Container = styled.div`
  display:block;
  &>.logo {
    display:block;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 56px;
    height:100%;

    &>img {
      position:absolute;
      transition-property: all;
      transition-duration: .3s;
      width:27px;
    }

    &>img.active {
      opacity: 1;
    }

    &>img.hover {
      opacity: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    &>.logo {
      width: 88px;

      &>img {
        width:36px;
      }

      &>img.active {

      }

      &>img.hover {

      }

    
      &:hover {
        background-color: ${colours.black_alpha80};

        &>img {
          transform: rotate(180deg);
        }

        &>img.active {
          opacity: 0;
        }
        
        &>img.hover {
          opacity: 1;
        }
      }
    
    }
  }
`