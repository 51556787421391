import axiosInstance from './api';

export const getOne = (id) => {
  return axiosInstance.get('/articles/' + id)
}

export const getOneBySlug = (slug) => {
  return axiosInstance.get('/articles/slugs/' + slug)
}

export const searchByKeywords = async ({
  query,
  page = 0,
  size = 100
}) => {
  const response = await axiosInstance.get(`/articles/search?query=${query}&page=${page}&size=${size}`)
  return response.data
}
