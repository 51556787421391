import { useEffect, useState, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getActivitiesCountOnCurrentUser } from 'apis/user.api'
import useAuth from './auth.hook';

export default function useGetActivityCount(from) {
  const { fetching, session } = useAuth()
  const { 
    isLoading, 
    error, 
    data, 
    isSuccess, 
    isFetching,
    refetch
  } = useQuery({
    queryKey: [`activities-counter-${session?.profile?.id}`],
    queryFn: () => getActivitiesCountOnCurrentUser(from),
    enabled: true
  })

  useEffect(() => {
    if (from && session && !fetching) {
      refetch(from)
    }
  }, [from, session, fetching])

  const getUpdate = (from) => {
    refetch(from)
  }

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    activityCount: data?.data?.count ?? 0,
    getUpdate
  }
}