import { useContext } from "react";
import { ActivityViewsContext } from "../contexts/activity.views.context";

const useActivityViews = () => {
  const { 
    viewed, 
    pushViewed, 
    pushViewedExpanded, 
    clearViewed, 
    clearViewedExpanded 
  } = useContext(ActivityViewsContext);

  const isViewed = ({
    name,
    id
  }) => {
    return viewed.viewed?.[name]?.[id] ?? false
  }

  const isViewedExpanded = ({
    name,
    id
  }) => viewed.expanded?.[name]?.[id] ?? false

  return {
    viewed,
    isViewed,
    pushViewed,
    isViewedExpanded,
    pushViewedExpanded,
    clearViewed,
    clearViewedExpanded
  }
}

export default useActivityViews;