import React, { useEffect } from 'react';
import ActivityList from '../activities/activity-list';

import useModal from '../../hooks/modal.hook';
import useGetActivityHistory from 'hooks/get-activity-history.hook'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function ActivityHistory({
  title,
  action,
  schemaName,
  relatedId,
  onClose
}) {
  const { setModal } = useModal()
  
  const {
    success,
    loading,
    fetching,
    error,
    activities,
    nextPage,
    hasNextPage
  } = useGetActivityHistory(schemaName, relatedId, action)

  useEffect(() => {
    window.gtag('event', 'modal_view', {
      page_title: 'view-activity-history',
      entity_type: schemaName,
      entity_id: relatedId,
      actions: action
    });
  }, [])

  return (
    <>
      {fetching && success ? <ProgressLoader/> : null}
      {success ? <ActivityList 
        title={title}
        showActions={action === 'all'}
        activities={activities}
        onClose={() => {
          setModal(null)
        }}
        onBottom={() => {
          if (hasNextPage)
            nextPage()
        }}
      /> : null}
    </>
  )
}