import { useInfiniteQuery } from '@tanstack/react-query';
import { getTrendingUserLatestArticles } from 'apis/zine.api'

const flatten = (pages) => {
  return pages.reduce((acc, curr) => {
    return acc.concat(curr.data)
  }, [])
}

const test_no_articles = [
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' }
]

const test_article_last_in_row = [
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'article' }
]

const test_articles_normal = [
  { type: 'user', id:'1' },
  { type: 'user', id:'2' },
  { type: 'article', id:'3' },
  { type: 'article', id:'4' },
  { type: 'article', id:'5' },
  { type: 'article', id:'6' },
  { type: 'user', id:'7' },
  { type: 'user', id:'8' },
  { type: 'article', id:'9' },
  { type: 'article', id:'10' },
  { type: 'user', id:'11' },
  { type: 'user', id:'12' },
  { type: 'user', id:'13' },
  { type: 'user', id:'14' },
  { type: 'article', id:'15' },
  { type: 'article', id:'16' },
]

const test_articles_1 = [
  { type: 'article', createdAt: "2023-04-08T17:44:24.571Z" },
  { type: 'article', createdAt: "2023-04-07T17:44:24.571Z" },
  { type: 'article', createdAt: "2023-04-06T17:44:24.571Z" },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'article', createdAt: "2023-04-05T17:44:24.571Z" },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
  { type: 'user' },
]

const appendOrder = (items) => items.map((item, i) => ({
  ...item,
  order: i
}))

export default function useGetTrendingUserLatestArticles() {
  const { 
    isLoading, 
    error, 
    data, 
    isSuccess, 
    isFetching, 
    isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['trending-users-latest-articles'],
    queryFn: ({pageParam}) => {
      let articleOffset = 0
      let userOffset = 0
      let articleSize = 12
      let userSize = 12

      if (pageParam) {
        articleOffset = pageParam.articleOffset
        userOffset = pageParam.userOffset
      }
      
      return getTrendingUserLatestArticles({
        articleOffset,
        articleSize,
        userOffset,
        userSize
      })
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.hasNext) {
        const {
          nextArticleOffset,
          nextUserOffset,
        } = lastPage
        return {
          articleOffset: nextArticleOffset,
          userOffset: nextUserOffset,
        }
      } else {
        return undefined
      }
    }
  })
  
  return {
    isLoading,
    isSuccess,
    totalRecords: isSuccess ? data?.pages[0]?.totalRecords : 0,
    data : isSuccess ? appendOrder(flatten(data.pages)) : [],
    // data: appendOrder(test_no_articles),
    hasNextPage,
    fetchNextPage
  }
}
