import React, { forwardRef } from 'react'
import styled from 'styled-components'

import {
  AccountTypes,
  FashionArtistProfessions,
  CompanyTypesList,
  CompanyTypesNameSlug,
  getAccountTypeMeta
} from '../../constants'

import {
  fonts,
  colours
} from '../../styles/global.styles'

import {
  Link
} from 'react-router-dom'

const Container = styled.div`
  color: ${colours.lightgrey6};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  
  
  &:hover {
    .account-type__main {
      color: ${colours.pink};
    }
  } 

`

const AccountTypeLink = styled(Link)`
  
  &:hover {
    color: ${colours.pink};
  }

`

export default function AccountTypeCategories({
  accountType,
  primaryProfession,
  secondaryProfessions, 
  companyType,
}) {
  const { name, slug } = getAccountTypeMeta(accountType)
  
  const faProfessions = () => {
    let out = []
    if (primaryProfession) {
      const faPrimary = FashionArtistProfessions.find(p => p.value === primaryProfession)
      if (faPrimary) {
        // out.push(<AccountTypeLink to={`/search/collectives?q=${faPrimary.label}`}>{faPrimary.label}</AccountTypeLink>)
        out.push(faPrimary)
      }
      if (secondaryProfessions) {
        out = out.concat(secondaryProfessions.map(sp => {
          const faSecondary = FashionArtistProfessions.find(p => p.value === sp)
          // return <AccountTypeLink key={`fa-sp-${sp}`} to={`/search/collectives?q=${faSecondary.label}`}>{faSecondary.label}</AccountTypeLink>
          return faSecondary
        }))
      }
    }

    return <> / {out.map((item, i) => (
      <span key={item.value}>
        <AccountTypeLink 
          className="account-type-category__link" 
          to={`/search/collectives?q=${item.value}`}>{item.label}
        </AccountTypeLink>{(i < out.length - 1) ? <>, </> : null}
      </span>))}</>
  }

  const fcCompanyType = () => {
    const findCompanyType = CompanyTypesList.find(c => c.value === companyType)
    if (findCompanyType)
      return <> / <AccountTypeLink 
        className="account-type-category__link" 
        to={`/search/collectives?q=${CompanyTypesNameSlug[findCompanyType.value]}`}>{findCompanyType.label}</AccountTypeLink></>
    else
      return null
  }

  return (
    <Container className="account-type-categories">
      <AccountTypeLink className="account-type__main" to={`/search/collectives?q=${name.toLowerCase()}`}>{name}</AccountTypeLink>
      {accountType === AccountTypes.FashionCompany ? <>{fcCompanyType()}</> : null}
      {accountType === AccountTypes.FashionArtist ? <>{faProfessions()}</> : null}
    </Container>
  )
};
