import React, { useRef, useEffect, useState } from 'react'
import { format, isToday } from 'date-fns'
import {
  Container, 
  MessagesContainer,
  MessagesScrolling,
  MessageInputContainer,
  MyAvatar,
  InputArea,
  TextArea,
  ActionArea,
  Minimise,
  Edit,
  SmallAvatar,
  MessageRow,
  UnreadMessagesCount,
  MessageBubble,
  DateDivider,
  ScrollWrapper
} from './messaging.styles'

import {
  icons,
  AccountTypes
} from '../../constants'

import UserAvatar from 'components/avatar/user-avatar'

import useGetProfile from 'hooks/get-profile.hook'

import useSendMessage from 'hooks/send-message.hook'
import useGetMessages from 'hooks/get-messages.hook'

import useAuth from 'hooks/auth.hook'
import useModal from 'hooks/modal.hook'
import useTheme from 'hooks/user-theme.hook'

import useSetMessagesRead from 'hooks/set-messages-read.hook'
import useSetConversationRead from 'hooks/set-conversation-read.hook'

import { imageUrl, ensureLinks } from 'utils/utils'

const Messaging = ({
  id,
  onClose,
  hasUnread = false,
  disableReply = false,
  disableEdit = false
}) => { 
  const onceRef = useRef(null)
  const { userTheme } = useTheme()
  const { setModal } = useModal()
  const [markRead, setMarkRead] = useState([])
  const inputRef = useRef(null)
  const messageScrollingRef = useRef(null)
  const { session } = useAuth()
  const { success, loading, profile } = useGetProfile()
  const {
    send, 
    isLoading: isSending, isSuccess: isSendSuccess
  } = useSendMessage(id)
  const { setRead } = useSetMessagesRead()
  const { setRead : setConversationRead } = useSetConversationRead()
  const {
    isLoading,
    isSuccess,
    data,
    hasNextPage,
    fetchNextPage,
    isFetched
  } = useGetMessages(id)

  const sendMessage = (text) => {
    send(text)
    inputRef.current.value = ''
  }

  const isOthers = (userId) => (userId !== session?.profile?.id ?? null)

  useEffect(() => {
    if (isSuccess && data?.length > 0 && messageScrollingRef && messageScrollingRef.current && !onceRef.current) {
      const lastMessage = data[data.length - 1]
      const lastMessageElem = document.getElementById(`message-${lastMessage.id}`)
      messageScrollingRef.current.scroll({
        top: lastMessageElem.offsetTop + lastMessageElem.offsetHeight
      })
      onceRef.current = true
    }
  }, [isSuccess, data, messageScrollingRef])

  return (
    <Container className="messaging">
      <MessagesContainer
        onVisible={() => {
          if (hasUnread) {
            setConversationRead(id)
          }
        }}
      >
        <MessagesScrolling>
          <ScrollWrapper ref={messageScrollingRef}>
            {isSuccess ? data.map((message, i) => (
              <>
                {message.unreadDivide > 0 ? <UnreadMessagesCount>{message.unreadDivide} unread messages</UnreadMessagesCount> : null}
                <MessageRow key={`message-${message.id}`}
                  id={`message-${message.id}`} 
                  data={message.id} 
                  theme={userTheme}
                  visibleAlready={message.read}
                  visibleTime={1000}
                  onVisible={() => {
                    // more detailed message by message unread check
                    /*
                    if (message.from._id !== session?.profile?.id && !message.read) {
                      setRead([message.id])
                    }
                    */
                  }} 
                  className={isOthers(message.from._id) ? 'is-others':'is-you'}>
                  <SmallAvatar isItch={message.from.accountType === AccountTypes.Itch} className="message-avatar">
                    <UserAvatar 
                      url={imageUrl(message.from?.profileImageVariants?.thumb ?? message.from?.profileImage)} 
                      accountType={message.from?.accountType}
                      />
                  </SmallAvatar>
                  <MessageBubble className="message-bubble" dangerouslySetInnerHTML={{__html: ensureLinks(message.body) }}>
                  </MessageBubble>
                </MessageRow>
                {message.dateDivide ? <DateDivider>{isToday(message.dateDivide) ? 'Today' : format(message.dateDivide, "d MMMM yyyy")}</DateDivider> : null}
              </>
            )) : null}
          </ScrollWrapper>
        </MessagesScrolling>
      </MessagesContainer>
      {success ? <MessageInputContainer>
        <MyAvatar>
          <UserAvatar
            accountType={profile.accountType}
            url={imageUrl(profile.profileImageVariants?.thumb ?? profile.profileImage)} 
            position={profile.profileImage?.position} 
          />
        </MyAvatar>
        <InputArea>
          <TextArea 
            disabled={disableReply}
            ref={inputRef}
            placeholder={!disableReply ? "Type here and hit ‘Enter’ to send" : "" }
            onKeyUp={(evt) => {
              if (evt.keyCode === 13) {
                sendMessage(evt.target.value)
              }
            }}
          />
        </InputArea>
        <ActionArea>
          {!disableEdit ? <Edit onClick={() => {
            // edit conversation options
            setModal({
              name: 'edit-conversation',
              id: id
            })
          }}>
            <img src={icons['pencil-828282.svg']} alt="" />
          </Edit> : null}
          <Minimise onClick={() => {
            onClose && onClose()
          }}>
            <img src={icons['collapse-828282.svg']} alt="" />
          </Minimise>
        </ActionArea>
      </MessageInputContainer> : null}
    </Container>
  )
}

export default Messaging