import { useQuery } from '@tanstack/react-query';
import { getCurrentFeaturedArtwork, getFeaturedArtwork } from 'apis/zine.api'

export default function useGetCurrentFeaturedArtwork(chapter = null) {
  const { isLoading, error, data, isSuccess, isFetching } = useQuery({
    queryKey: [`zine-featured-artwork-${chapter}`], 
    queryFn: () => {
      if (chapter) {
        return getFeaturedArtwork(chapter)
      } else {
        return getCurrentFeaturedArtwork()
      }
    }
  })

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    featuredArtwork: {
      ...data
    }
  }
}