import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  fonts,
  colours
} from '../../styles/global.styles'
import {
  getAccountTypeMeta,
  AccountTypes
} from '../../constants';
import {
  nameConcat
} from '../../utils/helpers';
import TextLine from '../shared/text-line'
import {
  imageUrl
} from 'utils/utils'
import {
  useHistory
} from 'react-router-dom'
import useAuth from 'hooks/auth.hook';

const UserProfileStyled = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    order: 2;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding-right: 15px;
    padding-left: 48px;
}
`;

const UserAccountType = styled.div`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;

  img {
    display:block;
    width: 100%;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
  }
`;

const ProfileName = styled.div`
  color: ${colours.text};
  font-family: ${fonts.title.xbold};
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  flex: 1 1 auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 4px;
  max-width: calc(100% - 80px);

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    max-width: 370px;
    width:1px;
  }
`;

// TODO - make a component multi line trancated with ellipsis

const ProfileImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 40px;
  margin-left: 4px;

  img {
    display:block;
    width: 100%;
    object-fit:cover;
    object-position: center center;
  }
`;

export default function ProfileListItem({
  profile,
  style
}) {
  const { accountType, itchUrl, firstName, lastName, companyName, profileImage, id } = profile;
  const accountTypeMeta = getAccountTypeMeta(accountType);
  const { push } = useHistory()
  const { session } = useAuth()

  return (
    <UserProfileStyled onClick={() => {
      push(session?.profile?.id === id ? '/my-profile' : '/' + itchUrl)
    }}>
      <UserAccountType>
        <img src={accountTypeMeta.iconFlare} alt={accountTypeMeta.name}/>
      </UserAccountType>
      <ProfileName>
        <TextLine>
          {accountType === AccountTypes.FashionCompany ? 
          companyName
          : 
          nameConcat(firstName, lastName)}
        </TextLine>
      </ProfileName>
      <ProfileImage>
        <img src={imageUrl(profileImage)?? accountTypeMeta.fallBackAvatar} alt=""/>
      </ProfileImage>
    </UserProfileStyled>
  );
}