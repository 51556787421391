import { useMutation, useQueryClient } from '@tanstack/react-query';
import { shuffleArtworks } from 'apis/artwork.api'

export default function useShuffleArtworks() {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (size = 12) => {
      return shuffleArtworks(size)
    },
    onSuccess: (data, variables) => {
      // this isnt working
      
      // queryClient.invalidateQueries({queryKey:['artwork-shuffle-pages'], exact: true})
      
      /*
      queryClient.resetQueries({
        queryKey: ['artwork-shuffle-pages']
      })
      queryClient.setQueryData('artwork-shuffle-pages', (data) => ({
        data: [],
        pageParams: 0
      }))
      console.log(queryClient.getQueriesData('artwork-shuffle-pages'))
      */
    },
  });

  return {
    shuffle: mutate,
    isLoading,
    isSuccess,
    isError,
    artworks: data,
    error
  }
}