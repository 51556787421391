import { useQuery } from '@tanstack/react-query';
import { getLatestItchArticle, getItchArticle } from 'apis/zine.api'

export default function useGetItchArticle(slug = null) {
  const { isLoading, error, data, isSuccess, isFetching } = useQuery({
    queryKey: slug ? ['itch-article', slug] : ['itch-latest-article'], 
    queryFn: slug ? () => getItchArticle(slug) : getLatestItchArticle
  })

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    itchArticle: {
      ...data
    }
  }
}