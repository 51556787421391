import { useContext } from "react";
import { TasksContext } from "../contexts/tasks.context";

const useTasks = () => {
  const { tasks, cancel } = useContext(TasksContext);

  return {
    tasks,
    cancel
  }
}

export default useTasks;