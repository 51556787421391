import { useMutation } from '@tanstack/react-query';
import { flagContent } from 'apis/flag.api'

const useFlag = (contentType, id) => {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: ({reason, comments}) => {
      return flagContent(contentType, id, reason, comments)
    }
  });

  return {
    flagContent: mutate,
    isLoading, 
    isSuccess,
    isError
  }
}

export default useFlag