import React from 'react';
import HoverIcon from 'components/shared/hover-icon';
import {
  icons
} from '../../constants'

export default function SocialShareButton({
  className,
  socialType,
  theme = 'grey',
  ...rest
}) {
  return (
    <HoverIcon
      {...rest}
      className={className}
      active={icons[`${socialType}${theme ? '-' + theme : ''}.svg`]}
      hover={icons[`${socialType}-hover.svg`]}
    />
  )
}