import { useInfiniteQuery } from '@tanstack/react-query';
import { getAllByUser, getAll } from '../apis/account-article.api';

export default function useGetArticles(
  userId,
  isOwner,
  sortBy,
  size = 12
) {
  
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [`articles-${userId}`],
    queryFn: ({ pageParam = 0 }) => !isOwner ? getAllByUser(userId, pageParam, size, sortBy) : getAll(pageParam, size, sortBy),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.data.page) + 1 < Number(lastPage.data.totalPages))
        return Number(lastPage.data.page) + 1
    }
  })
  

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data?.data)
  }), [])

  return {
    success: isSuccess,
    fetched: isFetched,
    loading: isLoading,
    fetching: isFetching || isFetchingNextPage,
    error,
    articles: {
      data: flattenedData
    },
    nextPage: fetchNextPage,
    hasNextPage
  }
}
