import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setReviewRespond } from '../apis/account-article.api';
import useAuth from './auth.hook'

export default function useSetMyArticleReviewRespond() {
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (id) => {
      return setReviewRespond(id)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: [`articles-${session?.profile?.id}`], data: data?.data})
      queryClient.invalidateQueries({queryKey: [`article-unresponded-count-${session?.profile?.id}`]})
    },
  });
  
  return {
    setRespond: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}