import React, { useRef } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/youtube'

import {
  colours
} from '../../styles/global.styles'

const Container = styled.iframe`
  outline:none;
  border:none;
  padding:0;
  margin:0;
  background-color: ${colours.black};
  z-index: -1;
`

const PlayerContainer = styled.div`
  background-color: ${colours.black};
`

/*
  try the youtube iframe api component to manually trigger a play
*/

/*
<Container 
      {...rest}
      allow="autoplay" 
      src={`https://www.youtube.com/embed/${ytId}?origin=${window.location.protocol}//${window.location.host}&rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1`} 
    />
*/

export default function YouTubeEmbed({
  url,
  onPlay,
  ...rest
}) {
  let ytId = ''
  if (url.indexOf('https://youtu.be/') === 0) {
    ytId = url.split('/')[3]
  } else {
    ytId = url.split('?')[1].split('&')[0].split('=')[1]
  }

  return (
    <PlayerContainer {...rest}>
      <ReactPlayer 
        width={'100%'}
        height={'100%'}
        playing={true}
        controls={true}
        onStart={() => {
          onPlay && onPlay()
        }} 
        url={`https://www.youtube.com/watch?v=${ytId}`} 
      />
    </PlayerContainer>
  )
}