import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  breakpoints,
  colours,
  fonts
} from '../styles/global.styles'

import FooterIcon from 'components/shared/footer-icon'
import useAuth from 'hooks/auth.hook'
import { usePageReady } from 'contexts/page-ready.context'

const PageContent = styled.div`
  position: relative;
  padding-top: 65px;
  min-height: calc(100vh - ${props => props.isLoggedIn ? 45:0}px);
  background-color: ${colours.darkerlight2};

  @media screen and (max-width: ${breakpoints.sm}px) {
    padding-top: ${props => props.isLoggedIn ? 45:45}px;
  }
`

export const FooterContainer = styled.div`

  @keyframes delayIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  animation-name: delayIn;
  animation-delay: 1s;
  animation-duration: .1s; // specify the duration
  animation-fill-mode: forwards; 

  opacity: 0;
  background-color: ${colours.darkerlight2};
  padding-bottom: 25px;
  .footer-links {
    font-size: 12px;
    font-family: ${fonts.content.regular};
    line-height: 12px;

    &>span:first-child {
      margin-right:8px;
    }

    a {
      padding-left:6px;
      padding-right:6px;
    }
    br {
      display:none;
    }
  }

  @media (max-width: 768px) {
    .footer-links {
      br {
        display:block;
      }
    }
  }
`


export default function Page({
  children,
  className,
  ...rest
}) {
  const currentYear = new Date().getFullYear()
  const { fetching, session } = useAuth()
  const { ready } = usePageReady()

  return (
    <PageContent className={className} {...rest}>
      {children}
      {(!fetching && !session) || ready ? <FooterContainer>
        <FooterIcon/>
        
        <div className="footer-links">
          <span>Copyright © {currentYear} Groundcrew Pte. Ltd. All Rights Reserved.</span>
          <br/>
          <Link target="_blank" to="/here/privacy">Privacy Policy</Link>
          <span>|</span>
          <Link target="_blank" to="/here/terms">Terms of Use</Link>
          <span>|</span>
          <Link target="_blank" to="/here/contact-us">Contact Us</Link>
        </div>
      </FooterContainer> : null}
    </PageContent>
  )
}