import React, { forwardRef, useRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  colours,
  fonts,
  BaseInputText
} from '../../styles/global.styles'

const InputContainer = styled.div`
  width: 100%;
  position: relative;

  &:focus-within {
    .character-count {
      opacity: 1
    }
  }
`;

const InputPre = styled.span`
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: ${props => props.theme === 'light' ? 400 : 700};
  line-height: 14px;
  letter-spacing: 0em;
  color: ${colours.pink};
  padding-top: 11px;
  padding-right: 0px; 
  padding-bottom: 9px;
  padding-left: 10px;
  white-space: nowrap;
  position:absolute;
`;

const InputRow = styled.div`
  display:flex;
  flex-direction:row;
`;

const CountersRow = styled.div`
  font-family: ${fonts.content.bold};
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: ${colours.lightgrey3};
  transition-property: all;
  transition-duration: .3s;
  
  opacity:0;
`

const InputStyled = styled(BaseInputText)`
  color: ${props => {
    if (props.error === 'true')
      return colours.pink;

    return props.theme === 'light' ? colours.darkergrey2 : colours.text;
  }};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: ${props => props.theme === 'light' ? 400 : 700};
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid ${props => props.theme === 'light' ? colours.pink : colours.text};
  border-bottom: ${props => props.borderBottom ? '':'none'};
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding-top: 11px;
  padding-right: 10px; 
  padding-bottom: 10px;
  padding-left: ${props => props.prePad ? '0px': '10px'};

  &:focus {
    color: ${props => {
      return props.theme === 'light' ? colours.black : colours.white;
    }};
    border-bottom-width: 2px;
  }

  &::placeholder {
    color: ${colours.text};
    font-family: ${fonts.content.bold};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    height:35px;
  }
`;

export default forwardRef(function InputText(
  props,
  ref
) {
  const [charCount, setCharCount] = useState(0)
  const preElem = useRef(null)
  const inputElem = useRef(null)
  useImperativeHandle(ref, () => inputElem.current);
  const { 
    maxLength, prePadText, onChange, ...rest 
  } = props

  useEffect(() => {
    if (prePadText) {
      inputElem.current.style.paddingLeft = preElem.current.getBoundingClientRect().width + 'px'
    }
  }, [])

  return (
    <InputContainer>
      <InputRow>
        {prePadText ? 
          <InputPre 
            ref={preElem}
            className="input-pre"
            theme={rest.theme}>
            {prePadText}
          </InputPre>: null}
        <InputStyled
          className="input-text"
          prePad={prePadText !== ''} 
          ref={inputElem}
          maxlength={maxLength ?? 'unset'}
          onChange={(evt) => {
            if (evt.target && evt.target.value) {
              if (maxLength) {
                setCharCount(evt.target.value.length)
              }
              onChange && onChange(evt)
            }
          }}
          {...rest}
        >
        </InputStyled>
      </InputRow>
      {maxLength ? <CountersRow className="character-count">{maxLength - charCount} characters remaining</CountersRow> : null}
    </InputContainer>
  )
});