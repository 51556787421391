import { useContext } from "react";
import { DrawersContext } from "contexts/drawers.context";

const useDrawers = () => {
  const {activeDrawer, setActiveDrawer} = useContext(DrawersContext)

  return {
    activeDrawer,
    setActiveDrawer
  }
}

export default useDrawers