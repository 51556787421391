import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import { MenuFooter } from "../../../Footer";
import SubNavigation from './sub-nav'

import {
    breakpoints
} from 'styles/global.styles'

import useModal from 'hooks/modal.hook'
import useDrawers from 'hooks/drawers.hook'
import { useWindowDimensions } from 'utils/helpers'

const Menu = styled.ul`
    position: fixed;
    z-index: 1085;
    top: 65px;
    left: 0;
    width: 100%;
    max-width: 480px;
    height: calc(100vh - 65px);
    transition: transform 0.3s;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.8);
    color: #A5A5A5;
    text-align: left;
    transform: translateX(${props => props.active ? 0 : -100}vw);
    overscroll-behavior: contain;

    @media screen and (max-width: ${breakpoints.sm + 1}px) {
        top: 45px;
        height: calc(100% - 45px);
        -webkit-overflow-scrolling: touch;
    }
`

export default function LeftDrawer({
    active,
    ...rest
}) {
    const { setModal } = useModal()
    const { activeDrawer, setActiveDrawer } = useDrawers()
    const menuRef = useRef(null)
    const { isMobile } = useWindowDimensions()

    const linkClicked = (evt) => {
        evt.stopPropagation()
        setModal(null)
    }

    const handleScroll = (event) => {
        if (menuRef.current && menuRef.current.contains(event.target)) {
            event.stopPropagation()
        }
    }

    const closeLeftDrawer = (event) => {
        if (menuRef.current && menuRef.current.contains(event.target) && activeDrawer === 'left') {
            setActiveDrawer(null)
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeLeftDrawer)
        const menuRefElem = menuRef.current

        menuRefElem.addEventListener('touchmove', handleScroll, { passive: false })
        menuRefElem.addEventListener('wheel', handleScroll, { passive: false })

        if (activeDrawer === 'left') {
            // document.body.style.overflow = 'hidden'
        }
        
        return () => {
            document.body.removeEventListener('click', closeLeftDrawer)
            menuRefElem.removeEventListener('touchmove', handleScroll)
            menuRefElem.removeEventListener('wheel', handleScroll)
            // document.body.style.overflow = 'auto'
        }
    }, [activeDrawer])

    return (
        <Menu active={active} ref={menuRef} {...rest} className="left-drawer nexa-x-bold custom-scroller" onClick={linkClicked}>
            <li className="item-div find">
                <div className="item-heading">
                    {isMobile ? <div onClick={() => {
                        window.location.href = '/home'
                    }}>home</div> : <Link to="/home">home</Link>}
                </div>
                <table className="submenu">
                    <tbody>
                        <tr className="small-height">
                            <td rowSpan={2} className="submenu-item-div pr-3">
                                <div className="submenu-heading">
                                    <Link to="/home/fashion-artist" className="bottom-underline">fa artworks</Link>
                                </div>
                                <ul className="submenu-item pn-bold">
                                    <Link to="/home/fashion-artist/designer">Designers</Link>
                                <Link to="/home/fashion-artist/model">Models</Link>
                                    <Link to="/home/fashion-artist/photographer">Photographers</Link>
                                    <Link to="/home/fashion-artist/videographer">Videographers</Link>
                                    <Link to="/home/fashion-artist/makeupartist">Makeup Artists</Link>
                                    <Link to="/home/fashion-artist/hairstylist">Hair Stylists</Link>
                                    <Link to="/home/fashion-artist/stylist">Stylists</Link>
                                    <Link to="/home/fashion-artist/nailartist">Nail Artists</Link>
                                    <Link to="/home/fashion-artist/creativedirector">Creative Directors</Link>
                                    <Link to="/home/fashion-artist/retoucher">Retouchers</Link>
                                </ul>
                            </td>
                            <td className="submenu-item-div">
                                <div className="submenu-heading">
                                    <Link to="/home/fashion-company" className="bottom-underline">fc artworks</Link>
                                </div>
                                <ul className="submenu-item pn-bold">
                                    <Link to="/home/fashion-company/brand">Brands</Link>
                                    <Link to="/home/fashion-company/agency">Agencies</Link>
                                    <Link to="/home/fashion-company/service">Services</Link>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="submenu-item-div">
                                <div className="submenu-heading">
                                    <Link to="/home/fashion-lover" className="bottom-underline">fl artworks</Link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
            <li className="item-div zine">
                <div className="item-heading">
                    <Link to="/">zine</Link>
                </div>
                <div className="submenu-item">
                    <div onClick={(evt) => {
                        evt.stopPropagation()
                        setModal({
                            name: 'zine-archives'
                        })
                    }}>archive</div>
                    <Link to="/archives/itch-articles">previously on itch</Link>
                    <Link to="/faq#how-we-feature-you">how we feature you</Link>
                </div>
            </li>
            <li className="item-div collective">
                <div className="item-heading">
                    <Link to="/collectives">collectives</Link>
                </div>
                <SubNavigation />
            </li>
            <MenuFooter />
        </Menu>
    )
}