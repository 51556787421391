import React from 'react'

import {
  deriveUserName
} from '../../utils/helpers'
import AccountTypeCategories from 'components/shared/account-type-categories'
import {
  AuthorSection,
  AuthorInfoArticleDate,
  Sharing,
  AuthorLink,
  AuthorCategories
} from './article.styles'
import OwnerAccountTypes from '../gallery/owner-panel/tile-owner-types'

import {
  icons,
  getUserAccountCategoriesString
} from '../../constants'
import useAuth from 'hooks/auth.hook'
import Share from '../shared/share'
import { format } from 'date-fns'

const ArticleOwner = (props) => {
  const { shareUrl, user, itchArticleMentionUser, createdAt } = props
  const { session } = useAuth()

  const articleUser = user === null ? itchArticleMentionUser : user

  return (
    <AuthorSection className="author-section-share">
      <AuthorInfoArticleDate>
        <div>
          By {user === null ? 
          <><b>Itch</b> / </>: null }
          <AuthorLink 
            to={session?.profile?.id !== articleUser._id ? `/${articleUser.itchUrl}` : '/my-profile'}>
            {deriveUserName({
              accountType: articleUser.accountType,
              firstName: articleUser.firstName,
              lastName: articleUser.lastName,
              companyName: articleUser.companyName
              })}
          </AuthorLink>
          <AuthorCategories>
            <AccountTypeCategories {...articleUser} />
          </AuthorCategories>
        </div>
        <div>{format(new Date(createdAt), 'MMMM d yyyy')} at {format(new Date(createdAt), 'h:mma')}</div>
      </AuthorInfoArticleDate>
      <Sharing className="sharing-top">
        <Share url={shareUrl}/>
      </Sharing>
    </AuthorSection>
  )
}

export default ArticleOwner