import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

const initialState = false

export const SignUpPromptContext = React.createContext(null);

export const SignUpPromptProvider = ({ children }) => {
  const [isAck, setIsAck] = useState(initialState);
  
  useEffect(() => {
    const cookie = Cookies.get('signUpPromptAcknowledged');
    console.log(cookie)
    setIsAck(cookie === 'true');
  }, []);

  const acknowledge = () => {
    setIsAck(true);
    Cookies.set('signUpPromptAcknowledged', 'true', { expires: 1 });
  }

  return (
    <SignUpPromptContext.Provider value={{isAck, acknowledge}}>
      {children}
    </SignUpPromptContext.Provider>
  );
}

export const useSignUpPrompt = () => React.useContext(SignUpPromptContext)