export const toQueryParams = (obj) => {
  if (obj === undefined) 
    return ''

  const keys = Object.keys(obj)
  let query = []
  for (const key of keys) {
    query.push(`${key}=${obj[key]}`)
  }
  return '?' + query.join('&')
}