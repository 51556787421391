import React, { useEffect, useRef } from 'react'
import useModal from '../hooks/modal.hook';

import Settings from '../pages/settings';
import Deactivate from '../pages/deactivate';
import EditArtwork from '../pages/edit-artwork';
import EditArticle from '../pages/edit-article';
import EditConversation from 'pages/edit-conversation';
import FlagArtworkForm from './flag/flag-artwork-form';

import ActivityUsers from './gallery/activity-users';
import ActivityHistory from './gallery/activity-history';
import { ArchiveModal } from '../pages/zine'

import { useLocation } from 'react-router-dom';

export default function ModalCollections() {
  const prevLocation = useRef()
  const location = useLocation()
  const { modal, setModal } = useModal()

  useEffect(() => {
    if (prevLocation.current !== location.pathname && modal !== null) {
      setModal(null)  
    }
    prevLocation.current = location.pathname;
  }, [location, modal])

  return (
    <>
      {modal?.name === 'settings' ? 
        <Settings /> : null}
      {modal?.name === 'deactivate' ? 
        <Deactivate /> : null}
      {modal?.name === 'reveal-artwork' ? 
        <EditArtwork id={modal?.id}/>: null}
      {modal?.name === 'reveal-article' ? 
        <EditArticle id={modal?.id}/>: null}
      {modal?.name === 'activity-users' ? 
        <ActivityUsers {...modal?.payload}/>: null}
      {modal?.name === 'activity-history' ? 
        <ActivityHistory {...modal?.payload}/>: null}
      {modal?.name === 'edit-conversation' ? 
        <EditConversation id={modal?.id} /> : null}
      {modal?.name === 'flag-artwork' ? 
        <FlagArtworkForm {...modal?.payload} /> : null}
      {modal?.name === 'zine-archives' ? 
        <ArchiveModal show={true} /> : null }
    </>
  )
}