import React from 'react'
import styled from 'styled-components'

import {
  fonts,
  colours
} from '../../../styles/global.styles'

import Avatar from '../../avatar/user-avatar'
import { getName, stripHtml, imageUrl } from '../../../utils/utils'

import { useHistory } from 'react-router-dom'
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'

const Container = styled.div`
  display:flex;
  flex-direction: column;
`

const CommentItem = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 2px;


  &:hover {
    .image {
      &:after {
        visibility: visible;
      }
    }
    .name {
      color: ${colours.pink};
    }
  }

`

const AvatarContainer = styled.div`
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 2px;
`

const Image = styled.div`
  width:26px;
  height:26px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    content:'';
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: ${colours.pink_alpha50};
    z-index: 1;
    top:0;
    left:0;
  }
  
  img {
    display:block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};  
  }
`

const Info = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5px;
  overflow:hidden;
  flex: 1 1 auto;
  width:100%;
`

const Name = styled.div`
  color: ${colours.darkergrey2};
  font-family: ${fonts.content.bold};
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width:100%;
  cursor: pointer;

  
  &:hover {
    color: ${colours.pink};
  }

`

const Body = styled.div`
  color: ${colours.darkergrey2};
  font-family: ${fonts.content.regular};
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

`

export default function Comments({
  comments,
  expanded
}) {
  const { handleAction, linkProps, link } = useLoggedOutHandleRoute()
  const { push } = useHistory()

  return (
    <Container>
      {comments.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1:1)
        .filter(u => u.user !== null).splice(0,2).map(({
        user,
        body
      }, i) => (
        <CommentItem key={`comments-${i }`} >
          <AvatarContainer>
            <Avatar 
              profileUrl={`/${user.itchUrl}`}
              onClick={() => {
                handleAction(() => {
                  push(`/${user.itchUrl}`)
                })
              }}
              accountType={user?.accountType} 
              url={imageUrl(user?.profileImage)}
              position={user?.profileImage?.position}
              id={user?._id}
            />
          </AvatarContainer>
          <Info>
            <Name className="name"
              onClick={() => {
                handleAction(() => {
                  push(`/${user.itchUrl}`)
                })
              }}>
            {getName({
              ...user
            })}</Name>
            <Body dangerouslySetInnerHTML={{__html: stripHtml(body)}}></Body>
          </Info>
        </CommentItem>
      ))}
    </Container>
  )
}