import axiosInstance from './api';

export const isUserFollowedByMe = async (userId) => {
  const response = await axiosInstance.get(`/users/${userId}/followed-by-me`)
  return response.data
}

export const followUser = async (userId) => {
  const response = await axiosInstance.post(`/users/${userId}/followers`)
  return response.data
}

export const unFollowUser = async (userId) => {
  const response = await axiosInstance.delete(`/users/${userId}/followers`)
  return response.data
}