import React from 'react';
import {
  Container,
  Left,
  Row,
  AccountTypeContainer,
  Info,
  Name,
  Categories,
  Location,
  FashionStatement,
  ActionsFollowings,
  Actions,
  ActionItem,
  Avatar
} from './collective-item.styles'
import Following from './following';
import {
  icons,
  AccountTypes,
  getAccountTypeMeta,
  getCompanyTypeName,
  getProfessionLabels
} from '../../constants'
import {
  nameConcat
} from '../../utils/helpers';
import countries from 'i18n-iso-countries';
import FollowWidget from 'components/gallery/owner-panel/follow-widget'
import ChatWidget from 'components/gallery/owner-panel/chat-widget'
import { ensureLinks, imageUrl, trimHtml } from 'utils/utils'
import useAuth from 'hooks/auth.hook';
import AccountTypeCategories from 'components/shared/account-type-categories';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const accountTypeIcons = {
  [AccountTypes.FashionArtist]: 'fa-40px-3f3e3c.svg',
  [AccountTypes.FashionCompany]: 'fc-40px-3f3e3c.svg',
  [AccountTypes.FashionLover]: 'fl-40px-3f3e3c.svg',
}

export default function CollectiveItem({
  id,
  itchUrl,
  accountType,
  firstName,
  lastName,
  companyName,
  countryCode,
  companyType,
  primaryProfession = null,
  secondaryProfessions = [],
  fashionStatement,
  profileImage,
  profileImageVariants,
  followersCount,
  followingCount,
  isFollowedByUser
}) {
  const { session } = useAuth()
  const accountTypeMeta = getAccountTypeMeta(accountType)
  const userAccTypeIcon = accountTypeIcons[accountType]
  const fallBackAvatar = accountTypeMeta.fallBackAvatar;

  const name = accountType === AccountTypes.FashionCompany ? 
    companyName : nameConcat(firstName, lastName);

  const countryName = countries.getName(countryCode, "en", { select: "official" })
  const isMe = session?.profile?.id === id 
  
  return (
    <Container to={isMe ? '/my-profile' : `/${itchUrl}`}> 
      <Left>
        <Row>
          <AccountTypeContainer>
            <img src={icons[userAccTypeIcon]} alt=""/>
          </AccountTypeContainer>
          <Info>
            <div className="inner">
              <Name to={isMe ? '/my-profile' : `/${itchUrl}`}>{name}</Name>
              <AccountTypeCategories 
                accountType={accountType} 
                companyType={companyType} 
                primaryProfession={primaryProfession} 
                secondaryProfessions={secondaryProfessions} />
              {countryName ? <Location to={`/search/collectives?q=${countryName}`}>
                <img src={icons['pin-8x10px-bdbdbd.svg']} alt=""/>
                <img src={icons['pin-8x10px-pink.svg']} alt=""/>
                {countryName}
              </Location> : null}
              <FashionStatement 
                className="desktop"
                dangerouslySetInnerHTML={{
                  __html: trimHtml(ensureLinks(fashionStatement ?? ''), 400)
                }}
              ></FashionStatement>
            </div>
          </Info>
        </Row>
        <Following 
          userId={id}
          name={name}
          followersCount={followersCount} 
          followingCount={followingCount} 
          className="mobile"/>
      </Left>
      <ActionsFollowings>
        <Actions>
          <ActionItem>
            <FollowWidget userId={id} isFollower={isFollowedByUser} />
          </ActionItem>
          {!isMe ? <ActionItem>
            <ChatWidget userId={id} />
          </ActionItem> : null}
        </Actions>
        <Following 
          userId={id}
          name={name}
          followersCount={followersCount} 
          followingCount={followingCount} 
          className="desktop"/>
      </ActionsFollowings>
      <Avatar to={session?.profile?.id === id ? '/my-profile' : `/${itchUrl}`}>
        <img src={imageUrl(profileImageVariants?.medium ?? profileImage) ?? fallBackAvatar} alt=""/>
      </Avatar>
    </Container>
  );
}