import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOne } from '../apis/account-article.api';
import useAuth from './auth.hook'

export default function useDeleteMyArticle() {
  const queryClient = useQueryClient()
  const { session } = useAuth()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: id => {
      return deleteOne(id)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [`articles-${session?.profile?.id}`]})
    }
  });

  return {
    deleteOne: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}