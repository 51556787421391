import { useContext } from "react";
import { ErrorAlertContext } from "../contexts/error-alert.context";

const useErrorAlert = () => {
  const [errorAlert, setErrorAlert] = useContext(ErrorAlertContext);
  return {
    errorAlert,
    setErrorAlert
  }
}

export default useErrorAlert;