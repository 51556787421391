import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 20px;
`

export const ResponsesContainer = styled.div`
  padding-left: 50px;

  .comment-container {
    border-top: 0;
  }

  .comment-input-container {
    border-top:none !important;
  }
`