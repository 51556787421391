import { useInfiniteQuery } from '@tanstack/react-query';
import { getItchBook } from '../apis/itchbook.api';

export default function useGetitchbook(userId = null) {
  
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: userId ? ['itchbook', userId] : ['my-itchbook'],
    queryFn: ({ pageParam = 0 }) => getItchBook({
      userId,
      page: pageParam
    }),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    }
  })
  
  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    artworks: {
      data: flattenedData
    },
    hasNextPage,
    nextPage: fetchNextPage
  }
}