import styled from 'styled-components'

import {
  icons
} from 'constants'

import {
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1099;
  height: 45px;
  background-color: ${props => props.theme.color === 'dark' ? colours.dark : colours.pink};
  box-shadow: 0 2px 3px 0 rgba(51, 51, 51, 0.2);
  transition-property: top;
  transition-duration: .3s;
  transition-timing-function: linear;
  display: flex;
  flex-direction:row;
  justify-content: space-between;

  &.modal-active {
    transition-property: none;
    width: 100%
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    height: 65px;
    &.modal-active {
      transition-property: none;
      width: calc(100% - 15px);
    }
  }

  #avatarMenu {
    right:0;
  }

  .avatar:hover + #avatarMenu,
  #avatarMenu:hover {
    transform: translateX(0);
  }
`
