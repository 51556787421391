import React, { useEffect } from 'react'
import styled from 'styled-components'
import HoverIconLabel from '../../shared/hover-icon-label'
import { useHistory } from 'react-router-dom'
import {
  icons,
} from '../../../constants'
import useStartConversation from 'hooks/start-conversation.hook'
import {
  breakpoints,
  themes,
  fonts
} from 'styles/global.styles'
import useAuth from 'hooks/auth.hook'
import { EventBusy } from '@material-ui/icons'

const Container = styled.div`
  .label {
    font-weight: 400;
    font-family: ${fonts.content.regular};
  }

  @media (max-width:${breakpoints.sm}px) {
    .label {
      display: none;
    }
  }
`

export default function ChatWidget({
  theme,
  userId
}) {
  const { push } = useHistory()
  const { success, conversationId, startConversation } = useStartConversation()
  const { session } = useAuth()

  useEffect(() => {
    if (success && conversationId) {
      push(`/my-profile/conversations?conversation-id=${conversationId}`)
    }
  }, [success, conversationId])

  let followIconActive = null
  if (theme === themes.Flare) 
    followIconActive = "white-chat.svg"
  else
  if (theme === themes.Metro)
    followIconActive = "chat-18x12-grey.svg"
  else
    followIconActive = "chat-darkgrey.svg"

  return (
    session ? <Container className="chat-widget">
      <HoverIconLabel
        label="chat"
        active={icons[followIconActive]}
        hover={icons["chat-pink.svg"]}
        onClick={(evt) => {
          evt.preventDefault()
          evt.stopPropagation()
          startConversation(userId)
        }}
      />
    </Container> : null
  )
}