import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom';

export default function SubNavigation() {
    const [hoveredTab, setHoveredTab] = useState(0);
    let tabs = [
        {
            link: '',
            label: 'select a collective',
            type: ''
        },
        {
            link: `/collectives/fa`,
            label: 'fashion artists',
            type: 'fa'
        },
        {
            link: `/collectives/fc`,
            label: 'fashion companies',
            type: 'fc'
        },
        {
            link: `/collectives/fl`,
            label: 'fashion lovers',
            type: 'fl'
        }
    ];

    return (
        <div className="sub-nav">
            <div id="circles" className="nav-circles" onMouseOut={() => setHoveredTab(0)}>
                {tabs.slice(1).map(({ link, type }, i) => (
                    <Link key={i + 1} to={link}>
                        <div
                            className={"nav-circle text-center " + type}
                            onMouseOver={() => setHoveredTab(i + 1)}
                        >{type}
                        </div>
                    </Link>
                ))}
            </div>
            <div id="diagonals">
                <div id="rightDiagonal" className="nav-diagonal-line"></div>
                <div id="leftDiagonal" className="nav-diagonal-line"></div>
            </div>
            <span className={"subnav-label " + (hoveredTab !== 0 ? 'pink-text' : '')}>{tabs[hoveredTab].label}</span>
        </div>
    )
}
