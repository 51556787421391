import React, { useEffect, useState, useMemo } from 'react';
import {
  useLocation
} from 'react-router-dom'
import {
  Container, 
  Toast,
  TilesContainer,
  TilesInnerPositioned
} from './artworks.styles'

import useSearchArtworksByKeywords from 'hooks/search-artworks-bykeywords.hook';
import ArtworkTile from '../../components/gallery/artwork-tile'
import useMasonry from '../../hooks/masonry.hook';
import { MediaType } from '../../constants';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import useModal from '../../hooks/modal.hook';
import {
  ActivityActions
} from '../../constants'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

const Artworks = () => {
  const { search } = useLocation()
  
  const queryParams = new URLSearchParams(search)
  
  const { setModal } = useModal()
  const {
    success,
    artworks,
    nextPage,
    loading,
    hasNext,
    refresh
  } = useSearchArtworksByKeywords({
    page: 0,
    size: 10,
    query: queryParams.get('q')
  })
  
  const [expanded, setExpanded] = useState(null)

  const { grid, expand, init, reset } = useMasonry()

  const lastCount = useMemo((() => success ? artworks?.length : 0), [success, artworks])

  const artworksRenderItems = (success && grid.length > 0) ? (artworks?? []).map((artwork, i) => (
    <ArtworkTile
      disableOwnerActions={true}
      index={i}
      draggable={false}
      position={grid[i]}
      expanded={expanded === (i)}
      key={`artwork-${artwork.id}}`}
      {...artwork}
      onExpand={() => {
        const offset_i = i
        if (expanded === offset_i) {
          setExpanded(null)
          reset()
        } else {
          setExpanded(offset_i)
          if (artwork.mediaType === MediaType.Image) {
            if (artwork.image.width > artwork.image.height) {
              expand(offset_i, 3)
            } else {
              expand(offset_i, 2)
            }
          } else {
            expand(offset_i, 3)
          }
        }
      }}
      onExit={() => {
        setExpanded(null)
        reset()
      }}
      onVotesClick={() => {
        setModal({
          name: 'activity-history',
          payload: {
            title: 'collectives who voted this',
            action: ActivityActions.Voted,
            schemaName: 'artworks',
            relatedId: artwork.id
          }
        })
      }}
      onViewsClick={() => {
        setModal({
          name: 'activity-history',
          payload: {
            title: 'collectives who viewed this',
            action: ActivityActions.Viewed,
            schemaName: 'artworks',
            relatedId: artwork.id
          }
        })
      }}
      onItchbooksClick={() => {
        setModal({
          name: 'activity-history',
          payload: {
            title: 'collectives who itchbooked this',
            action: ActivityActions.Itchbooked,
            schemaName: 'artworks',
            relatedId: artwork.id
          }
        })
      }}
    />
  )) : null

  useEffect(() => {
    if (success) {
      init(artworks?.length, 3)
    }
  }, [success, lastCount])

  useEffect(() => {
    refresh()
  }, [search])

  useBottomScrollListener(() => {
    if (hasNext) {
      setExpanded(null)
      nextPage()
    }
  })

  const maxRows = grid.length > 0 ? (grid[grid.length - 1].top + 1) + (expanded !== null ? 1 : 0) : 0

  return (
    <Container>
      <TilesContainer>
        <TilesInnerPositioned rows={maxRows}>
          {artworksRenderItems}
        </TilesInnerPositioned>
        {loading && !success ? <ProgressLoader/> : null}
      </TilesContainer>
    </Container>
  )
}

export default Artworks
