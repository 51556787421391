import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { 
  ContainerList,
  User,
  AccountType,
  Name,
  AvatarContainer
} from './select-users.styles'

import UserAvatar from 'components/avatar/user-avatar'

import {
  getAccountTypeMeta
} from '../../constants'

import useSearchUsers from 'hooks/search-users.hook'

import { imageUrl } from 'utils/utils'

const SelectUsers = (props) => {
  const { search, onSelect, excludeUsers } = props

  const { 
    isSuccess,
    data,
    hasNextPage,
    fetchNextPage,
    searchUsers
  } = useSearchUsers(search, excludeUsers)

  return (
    <ContainerList>
      {isSuccess ? data.map((user => (
        <User key={`search-user=${user.id}`} onClick={() => {
          onSelect(user.id)
        }}>
          <AccountType className="account-type">
            <img src={getAccountTypeMeta(user.accountType).iconDark} alt={getAccountTypeMeta(user.accountType).name} />
            <img src={getAccountTypeMeta(user.accountType).iconMetro} alt={getAccountTypeMeta(user.accountType).name} />
          </AccountType>
          <Name className="name">
            {user.name}
          </Name>
          <AvatarContainer>
            <UserAvatar 
              url={imageUrl(user.avatar)}
              accountType={user.accountType}
            />
          </AvatarContainer>
        </User>
      )))
      : null}
    </ContainerList>
  )
}

export default SelectUsers