import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom';
import styled from 'styled-components'
import {
  breakpoints,
  colours,
  fonts,
} from '../../styles/global.styles';
import {
  ButtonResetForm
} from '../../pages/settings.styles'
import ButtonSave from '../form/button-save';
import {
  SubModalWrapper,
  Header,
  Close,
  Body,
  Footer
} from './shared.styles'
import {
  icons
} from '../../constants'
import FormField from '../form/form-field'
import InputPassword from '../form/input-password'
import ChangePasswordEmail from './settings.change-password-email'

import useTriggerPasswordChange from '../../hooks/trigger-change-password.hook'
import useLoggedinTriggerPasswordReset from '../../hooks/loggedin-trigger-password-reset.hook'
import useNotifyAlert from '../../hooks/notify-alert.hook'

import { useForm } from "react-hook-form";
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

import { onSubModalLoadElevate } from '../shared/modal';

import { checkCurrentPassword } from '../../apis/user.api';

const InnerFormHeader = styled.div`
  display: none;
  width: 100%;
  position: relative;
  left:-35px;

  .header-wrapper {
    padding: 10px 11px 9px 19px;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-right: 5px;
      img {
        display: block;
        width: 100%;
      }
    }

    .text {
      color: ${colours.pink};
      font-family: ${fonts.title.xbold};
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    display: block;

    .header-wrapper {
      .icon {
        margin-right:10px;
        width: 36px;
        heighT: 36px;
      }
      .text {
        font-size: 14px;
        line-height: 16.8px;
      }
    }
  }
`;

const LinkForgetPassword = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.content.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  align-self: flex-end;
  margin-bottom: 12px;
  margin-top: 0px;
  cursor: pointer;
`;

const PasswordField = styled(FormField)`
  margin-bottom:10px;

  .label {
    margin-bottom: 0px !important;
    border-bottom-color: ${colours.pink};
  }

  
  &:hover {
    .label {
      background-color: ${colours.pink_alpha40};
    }
  }


  &:focus-within {
    .label {
      background-color: transparent;
    }
  }

  input {
    font-size: 14px;
  }
`;

const FormError = styled.div`
  color: ${colours.pink};
  border: 1px solid ${colours.pink};
  background-color: ${colours.pink_alpha10};
  padding: 11px;
  width: 100%;
  margin-bottom: 10px;
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const HideDesktopFormHeader = styled.div`
  flex: 1 1 auto;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    display: none;
  }
`;

const ChangePasswordFormBody = styled(Body)`
  padding-top:30px;
  height: calc(100% - 44px);
  min-height:auto;

  &> form {
    height:100%;
    min-height:auto;
    display:flex;
    flex-direction: column;
    
    &>.wrapper {
      min-height: auto;
      height: calc(100% - 75px);
      flex: 1 1 auto;
    }
  }
  
  @media and (min-width:${breakpoints.sm + 1}px) {
    height: auto;
    min-height:auto;
    
    &> form {
      height:auto;
      min-height:auto;
      justify-content:center;
      align-items:center;
      
      &>.wrapper {
        max-width: 435px;
        padding:0;
        overflow: visible;
        margin-top:auto;
        margin-bottom:auto;
        min-height: calc(100vh - 150px);
        height: calc(100% - 175px);
      }
    }
  }
`;

Yup.addMethod(Yup.mixed, 'validCurrentPassword', function(args) {
  const message = args
  return this.test('valid-current-password', message, function(value) {
    if (value && value.length >= 6 ) {
      const { path, createError } = this;
      return new Promise((resolve, reject) => {
        checkCurrentPassword(value).then(res => {
          if (res.data.result)
            resolve(true)
          else
            reject(createError({ path, message }))
        })
      })
    }
  })
})

Yup.addMethod(Yup.MixedSchema, 'oneOfSchemas', function({
  schemas,
  message
}) {
  return this.test(
    "one-of-schemas",
    message,
    (item) => schemas.some((schema) => schema.isValidSync(item, { strict : true } ))
  )
})

export default function ChangePassword(
  props
) {
  const { trigger, isLoading, isSuccess, isError } = useTriggerPasswordChange()
  const triggerPasswordReset = useLoggedinTriggerPasswordReset()
  const { notifyAlert, setNotifyAlert } = useNotifyAlert()
  const modalElem = useRef(null)

  const formSchema = Yup.object().shape({
    current: Yup.string()
      .required("Current password is required")
      .validCurrentPassword("Oops! Your current password is incorrect"),
    newPassword: Yup.string()
      .required("Current password is required")
      .oneOfSchemas({
        message: "Oops! We hate this too. But for security’s sake, your password should be a minimum of 7 characters, with at least 1 alphabet and 1 numerical digit.",
        schemas: [
          Yup.string().min(7),
          Yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/)
        ]
      }),
    confirmPassword: Yup.string()
      .required("Current password is required")
      .oneOf([Yup.ref('newPassword')], "Passwords do not match")
  })

  const {
    register, 
    setError,
    handleSubmit,
    formState: { 
      errors, 
      isDirty
    }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema)
  });

  const [ submitted, setSubmitted ] = useState(false)

  const onSubmit = (data) => {
    trigger({
      currentPassword: data.current,
      newPassword: data.newPassword
    })
  }

  const isRequiredAll = Object.keys(errors).filter(curr => {
    if (errors[curr].type === 'required') {
      return true;
    } else
      return false;
  }).length === 3

  useEffect(onSubModalLoadElevate, [])

  useEffect(() => {
    if (isSuccess && !isError) {
      setSubmitted(true)
    } else
    if (isError) {
      setError('current', {
        message: 'Oops! Something went wrong.'
      })
    }
  }, [isSuccess, isError])
  
  const FormHeader = () => (
    <div className="header-wrapper">
      <div className="icon">
        <img src={icons['change-password.svg']} alt=""/>
      </div>
      <div className="text">
        change password
      </div>
    </div>
  )

  const handleClose = () => {
    modalElem.current.classList.add('closing')
    setTimeout(() => {
      props.onClose && props.onClose();
    }, 300)
  }
  
  return createPortal(
    <SubModalWrapper ref={modalElem} className="modal-account-change-password">
      {!submitted ? <>
        <Header>
          <HideDesktopFormHeader>
            <FormHeader/>
          </HideDesktopFormHeader>
          <Close className="sub-modal-close" 
            onClick={() => {
              handleClose()
            }}></Close>
        </Header>
        <ChangePasswordFormBody>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrapper">
              <InnerFormHeader>
                <FormHeader/>
              </InnerFormHeader>
              {isRequiredAll ? <FormError>Oops! All fields are required!</FormError> : null}
              <PasswordField
                style={{marginBottom:'0px'}}
                label="Current password"
                error={!isRequiredAll ? errors['current'] : null}
              >
                <InputPassword 
                  theme="light"
                  {...register('current')}
                />
              </PasswordField>
              <LinkForgetPassword 
                onClick={() => {
                  triggerPasswordReset.trigger()
                  // todo show info prompt
                  setNotifyAlert({
                    title: 'Reset Password',
                    body:'An email has been sent to your email address, please check to reset your password'
                  })
                }}
              >Forgot password?</LinkForgetPassword>
              <PasswordField
                label="New Password"
                error={!isRequiredAll ? errors['newPassword'] : null}
              >
                <InputPassword 
                  {...register('newPassword')}
                />
              </PasswordField>
              <PasswordField
                label="Confirm Password"
                error={!isRequiredAll ? errors['confirmPassword'] : null}
              >
                <InputPassword 
                  {...register('confirmPassword')}
                />
              </PasswordField>
            </div>
            <Footer>
              <ButtonResetForm active={isDirty} type="reset">Reset</ButtonResetForm>
              <ButtonSave
                type="submit" 
                loading={isLoading}
                active={isDirty}
              >Confirm</ButtonSave>
            </Footer>
          </form>
        </ChangePasswordFormBody>
      </>
      : <>
          <Header></Header>
          <Body style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}>
            <ChangePasswordEmail
              email={props.email}
              onClose={props.onClose}
            />
          </Body>
        </>
      }
    </SubModalWrapper>, document.body
  )
}