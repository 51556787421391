import { useInfiniteQuery } from '@tanstack/react-query'
import { getAll } from 'apis/conversation.api'
import useAuth from './auth.hook'

export default function useGetMyConversations() {
  const { session } = useAuth()
  const {
    isLoading,
    isSuccess,
    data,
    isFetching,
    isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: [`conversations-${session?.profile?.id}`],
    queryFn: ({ pageParam = 0}) => getAll({
      page: pageParam,
      size: 1000 // this should be a hard limit at the backend in the future
    }),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true
  })

  return {
    isLoading: isLoading || isFetching,
    isSuccess,
    isFetched,
    data: isSuccess ? (data.pages ?? []).reduce((acc, curr) => {
      return acc.concat(curr.data)
    }, []) : [],
    hasNextPage,
    fetchNextPage
  }
}