import React, { useState } from 'react'

import { useWindowDimensions } from '../../utils/helpers';

import UserAvatar from 'components/avatar/user-avatar';
import LocationIconWrapper from './location-wrapper'

export default function ArtworkAuthorDetails({ user, author, authorStates, occupation, occupationState, location }) {
  const [hoveredAuthor, setHoveredAuthor] = useState(false);
  const { windowWidth } = useWindowDimensions();
  return (
    <div className={"author-details text-center " + (windowWidth > 1030 ? "middle-y" : "middle-x")}>
      <div className="d-inline-block" 
        style={{
        borderRadius: '50%',
        overflow: 'hidden',
        width: '72px',
        height: '72px'
      }}>
        <UserAvatar profileUrl={`/${user.itchUrl}`} {...user} />
      </div>
      <div className="body-text">
        <div
            className={(hoveredAuthor ? authorStates.hovered : authorStates.normal) + " author nexa-x-bold"}
            onMouseOver={() => setHoveredAuthor(true)}
            onMouseOut={() => setHoveredAuthor(false)}
        >
            <a href={`/${user.itchUrl}`}>{author}</a>
        </div>
        <div className={occupationState + " occupation"}>{occupation}</div>
        <LocationIconWrapper textColor={occupationState} location={location} />
      </div>
    </div>
  )
}
