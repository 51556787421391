import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import {
  BaseTransition,
  breakpoints, colours
} from '../../styles/global.styles';
import {
  icons
} from '../../constants'
import useModal from '../../hooks/modal.hook';
import useToast from '../../hooks/toast.hook';
import HoverIcon from './hover-icon';

import ModalProgress from './modal-form/modal-progress';
import { useBottomScrollListener }from 'react-bottom-scroll-listener';

import { ThemeProvider } from 'styled-components'

export const onSubModalLoadElevate = () => {
  const modal = document.getElementById('modal')
  if (modal)
    modal.classList.add('elevated')

  return () => {
    const modal = document.getElementById('modal')
    if (modal)
      modal.classList.remove('elevated')
  }
}

const getModalPositionTop = (height, props) => {
  let top = 0;
  if (!props.fullTakeOver) {
    top = height
  }
  if (props.toastActive) {
    top += 45
  }
  return top
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  padding-top: ${props => getModalPositionTop(45, props) }px;
  display:flex;
  flex-direction: column;
  background-color: ${props => props.theme === 'light' ? colours.darkerlight2_alpha95 : colours.black_alpha92 };
  z-index: ${props => props.fullTakeOver ? 1100 : 100};
  width: 100%;
  height: 100%;
  min-height:100dvh;
  padding-bottom: 15px;
  overflow-y: scroll;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;

  &.elevated {
    z-index:101;
  }

  &>div {
    padding: ${props => props.noPadding ? '0' : '8px 10px 0px 10px'};
    height: 100%;
    width: 100%;
  }

  @keyframes animateIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes animateOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: animateIn;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: foward;
  animation-iteration-count:1;

  .fade-when-mouse-off {
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    
    &.modal-body-fade {
      opacity: .5;
    }
  }

  &.closing {
    animation-name: animateOut;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    padding-top: ${props => props.toastActive ? 101 : (props.fullTakeOver ? 0 : 65 ) }px;
    padding-left: ${props => props.noPadding ? '0' : '19px'};
    padding-bottom: ${props => props.noPadding ? '0' : '19px'};
    padding-right: ${props => props.noPadding ? '0' : '19px'};

    @media screen and (min-width: ${breakpoints.sm + 1}px) {
      min-height: 100vh
    }
  }
`;

const ModalBody = styled.div`
  z-index: 1;
  min-height: inherit;
  padding-bottom: 20px;
  height: 100%;
  flex: 1 1 auto;

  &>div {
    width:100%;
    height: 100%;
    min-height: inherit;
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    
    &.modal-body-fade {
      filter: opacity(0.5);
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    
  }
`;

const IsSubmitting = styled.div`
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ModalHeader = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  left: auto;
  right: ${props => props.noPadding ? 0: 10}px;
  z-index: 21;
  flex: 0 0 50px;
  margin-left:0;
  margin-right:0;

  @media screen and (max-width: ${breakpoints.sm}px) {
    background-color: ${props => props.haveContent ? colours.black : 'transparent'};
    left:${props => props.noPadding ? '0': 'auto'};
    height:45px;
    position:fixed;
    top:45px;
    margin-left:-10px;
    margin-right:-10px;
  }
`;

const Header = styled.div`
  width: 100%;

  &>div {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    width: 100%;
    justify-content: center;
    
    &.modal-header-fade {
      opacity: 0.5
    }
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    
  }
`;

const CloseVertical = styled.div`
  position:fixed;
  z-index:101;
  top: ${props => props.fullTakeOver ? 15 : 53 }px;
  right: ${props => props.noPadding ? '5px' : '5px'};
  width: 43px;
  height: 20px;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  cursor:pointer;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {  
    top: ${props => props.fullTakeOver ? 15 : props.noPadding ? 65 : 75 }px;
    height: 90vh;
    right: ${props => props.noPadding ? '5px' : '15px'};
  }
`

const CloseClickArea = styled.div`
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    width: 64px;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
  }
`;

const Close = styled(BaseTransition)`
  position: relative;
  top: 4px;
  right: 0px;
  cursor: pointer;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-left:auto;  
  opacity: 0.6;

  &>span {
    width:100%;
    height:100%;
    img {
      width:24px;
      height:24px;
    }
  }

  &.modal-close-bright {
    opacity: 1;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    top: ${props => props.positionTop !== null ? 
                    props.positionTop : (props.noPadding ? 20 : 10)}px;
    right: 30px;
    margin-left: auto;
    flex: 0 0 47px;
    align-self: flex-start;
    width:auto;
    
    &>span {
      width:100%;
      height:100%;
      img {
        width:30px;
        height:30px;
      }
    }
  }
`;

export default function Modal(
  props
) {
  // closeButtonTop is used to override the close button position.
  // This is only used for the Settings page, where the close button
  // has to be position at 17px.
  const { onBottom, closeButtonTop = null} = props

  const closeElem = useRef(null)
  // const modalElem = useRef(null)
  // const modalElem = useRef(null)
  
  const modalElem = useBottomScrollListener(() => {
    onBottom && onBottom()
  },{
    offset: 340
  })
  
  const modalBodyElem = useRef(null)
  const modalHeaderElem = useRef(null)
  
  const { toast } = useToast()

  const theme = {
    main: props.theme ?? 'dark'
  }

  const handleSubmitted = () => {
    modalElem.current.classList.add('closing')
    setTimeout(() => {
      props.onSubmitted && props.onSubmitted();
    }, 300)
  }

  const handleClose = () => {
    if (props.isDirty) {
      props.onClose && props.onClose();
    } else {
      modalElem.current.classList.add('closing')
      setTimeout(() => {
        props.onClose && props.onClose();
      }, 300)
    }
  }
  
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.body.style.overflow = 'hidden';
    const topNavElem = document.getElementById('top-nav')
    if (topNavElem)
      topNavElem.classList.add('modal-active');
    // document.getElementById('app').classList.add('no-scroll');

    modalElem.current.onmousemove = (evt) => {
      if (closeElem.current) {
        const { left } = closeElem.current.getBoundingClientRect()
        const target = modalBodyElem.current.querySelector('.fade-when-mouse-off')
        if (evt.clientX > (left)) {
          closeElem.current.classList.add('modal-close-bright')
          if (target) {
            target.classList.add('modal-body-fade')
          }
        } else {
          closeElem.current.classList.remove('modal-close-bright')
          if (target) {
            target.classList.remove('modal-body-fade')
          }
        }
      }
    }

    // window.scrollTo({top: 0, behavior: 'instant'});

    const handleAndroidResizeEvents = () => {
      // console.log(document.activeElement)
      if (document.activeElement.tagName === "CONTENT") {
        window.setTimeout(function () {
          try {
            //@ts-ignore
            document?.activeElement?.scrollIntoViewIfNeeded(true)
          } catch (e) {
            //dev-null any errors here
          }
        }, 100)
      }
    }
    const isAndroidDevice = () => {
      // console.log('checking for android')
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.indexOf("android") > -1;
    }
    
    return () => {
      document.body.classList.remove('no-scroll');
      document.body.style.overflow = 'auto';
      const topNavElem = document.getElementById('top-nav')
      if (topNavElem)
        topNavElem.classList.remove('modal-active');
      // document.getElementById('app').classList.remove('no-scroll');

      // fix for android devices where scrolling and softKeyboards MAY be
      // acting weird when the modal contains an input field
      if (isAndroidDevice()) {
        window.addEventListener("resize", handleAndroidResizeEvents)
      }
      
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ModalContainer 
        aria-modal="true"
        fullTakeOver={props.fullTakeOver ?? false}
        toastActive={toast?.active ?? false}
        ref={modalElem}
        id="modal"
        className={`modal`}
        noPadding={props.noPadding ?? false}
        theme={props.theme ?? 'dark'}
      >
        <div className="modal-container-inner">
          <ModalHeader className="modal__header" noPadding={props.noPadding} 
            haveContent={props.header !== undefined && props.header !== null}>
            {props.header ? <Header>
              <div ref={modalHeaderElem}>
              {props.header}
              </div>
            </Header> : null}
            {!props.isSubmitting && !props.hideClose ? 
            <CloseVertical noPadding={props.noPadding} fullTakeOver={props.fullTakeOver ?? false} >
              <Close 
                ref={closeElem}
                theme={props.theme ?? 'dark'}
                noPadding={props.noPadding}
                positionTop={closeButtonTop}
                onClick={handleClose}
              >
                <HoverIcon
                  active={props.theme === 'light' ? icons['close-30px-828282.svg'] : icons['close-30px-ffffff.svg']}
                  hover={props.theme === 'light' ? icons['close-30px-pink.svg'] : icons['close-30px-pink.svg']}
                />
              </Close>
              <CloseClickArea
                  onClick={handleClose}
              ></CloseClickArea>
            </CloseVertical> : null}
          </ModalHeader>
          <ModalBody className="modal__body">
            <div ref={modalBodyElem}>
            {!props.isSubmitting ? props.children : null}
            {props.isSubmitting ? 
            <IsSubmitting>
              <ModalProgress 
                theme="dark" 
                title={props?.submitting?.title}
                body={props?.submitting?.body}
                onOk={handleSubmitted} />
            </IsSubmitting>: null}
            </div>
          </ModalBody>
        </div>
      </ModalContainer>
    </ThemeProvider>
  );
}