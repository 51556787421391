import { useMutation, useQueryClient } from '@tanstack/react-query';
import { triggerChangePassword } from '../apis/user.api';

export default function useTriggerPasswordChange() {
  const queryClient = useQueryClient()
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return triggerChangePassword(data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`my-tasks`]
      })
    },
  });
  

  return {
    trigger: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}