import React from 'react'
import styled from 'styled-components'

import {
  icons,
  AccountTypes
} from '../../../constants'
import {
  breakpoints
} from 'styles/global.styles'

const Container = styled.div`
  width: 100%;
  padding-left: ${props => props.expanded ? 15:8}px;
  padding-top: ${props => props.expanded ? 15:8}px;
  img {
    display:block;
    width: ${props => props.expanded ? 32:28}px;
  }

  @media (max-width:${breakpoints.sm}px) {
    padding-left:6px;
    padding-top:6px;
  }
`

export default function TileOwnerAccountType({
  accType,
  expanded
}) {
  let icon
  switch (accType) {
    case AccountTypes.FashionArtist:
      icon = icons['fa-icon-28-dark.svg'];
      break;
    case AccountTypes.FashionCompany:
      icon = icons['fc-icon-28-dark.svg'];
      break;
    case AccountTypes.FashionLover:
      icon = icons['fl-icon-28-dark.svg'];
      break;
    default:
      icon = icons['fa-icon-28-dark.svg'];
      break;
  }
  return (
    <Container expanded={expanded}>
      <img src={icon} alt="" />
    </Container>
  )
}
