import React, { useState, useEffect, useMemo, useCallback } from 'react'
// styled components
import { 
  Container, 
  Toast,
  TilesContainer,
  TilesInnerPositioned
} from './gallery.styles';
import {
  AccountTypes,
} from '../constants'
import { useLocation } from 'react-router-dom'

import useGetMyConversations from 'hooks/get-conversations.hook';

import useMasonry from '../hooks/masonry.hook'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import RevealConversationTile from 'components/conversations/reveal-conversation-tile';

import ConversationTile from 'components/conversations/conversation-tile';
import ProgressLoader from 'components/shared/progress-loader/progress-loader';
import useSetConversationRead from 'hooks/set-conversation-read.hook'

export default function Conversations() {
  const { search } = useLocation()
  const {
    data, isSuccess, isLoading, isFetched, hasNextPage, fetchNextPage
  } = useGetMyConversations()
  const { setRead } = useSetConversationRead()
  
  useBottomScrollListener(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  })

  const urlQuery = new URLSearchParams(search)

  const [startedConversationId, setStartedConversationId] = useState(urlQuery.get('conversation-id'))
  const [expanded, setExpanded] = useState(null)

  const lastCount = useMemo(() => isSuccess ? data.length : 0, [data, isSuccess])

  const { grid, expand, init, reset } = useMasonry({
    startOffset: 0
  })

  // const columns = Math.floor(window.innerWidth / 224)
  
  useEffect(() => {
    if (isSuccess || isFetched) {
      init(data.length + 2, 6)
      setExpanded(null)

      // set all read
      for (const conversation of data) {
        setRead(conversation._id)
      }
    }
  }, [isSuccess, isFetched, lastCount])

  const maxRows = grid.length > 0 ? (grid[grid.length - 1].top + 1) + (expanded !== null ? 1 : 0) : 0
  const noConversationsWithUsers = (data ?? []).filter(c => c.user.accountType !== AccountTypes.Itch).length === 0 
  const existingConversationUsers = data.map(d => d.user._id)

  useEffect(() => {
    if (startedConversationId && expanded === null && grid.length > 0) {
      const findTileIndex = data.findIndex(c => c._id === startedConversationId)
      if (findTileIndex >= 0) {
        setExpanded(findTileIndex + 2)
        expand(findTileIndex + 2, 2)
      }
    }
  }, [grid])

  const sortConversations = (a, b) => {
    if (startedConversationId) {
      return a._id === startedConversationId ? -1 : 1
    } else {
      return a.updatedAt > b.updatedAt ? -1 : 1
    }
  }

  return (
    <Container>
      {noConversationsWithUsers ? <Toast>
        <div>
        Be it an inquiry, potential collaboration; marketing yourself directly to your followers 
        or just showing your love and support, start fashioning a connection here with anyone on <b>itch</b>!
        </div>
      </Toast> : null }
      <TilesContainer>
        {isLoading && !isSuccess ? <ProgressLoader/> : null}
        <TilesInnerPositioned rows={maxRows}>
          <RevealConversationTile
            index={0}
            position={{
              top: 0,
              left: 0
            }}
            key='reveal'
            existingConversationUsers={existingConversationUsers}
            onStartConversation={(params) => {
              setExpanded(null)
              reset()
              if (params.conversationId) {
                // new conversation
                setStartedConversationId(params.conversationId)
              } else
              if (params.userId) {
                // existing user
                const conversation = data.find(d => d.user._id === params.userId);
                if (conversation) {
                  setStartedConversationId(conversation._id)
                }
              }
            }}
          />
          {(isSuccess && data && grid.length > 0) ? (data ??[])
            .sort(sortConversations).map((conversation, i) => {
              return (
                <ConversationTile
                  index={i + 2}
                  position={grid[i + 2]}
                  expanded={expanded === (i + 2)}
                  key={`conversation-${conversation._id}`}
                  {...conversation}
                  onExpand={() => {
                    const offset_i = i + 2
                    if (expanded === offset_i) {
                      setExpanded(null)
                      reset()
                    } else {
                      setExpanded(offset_i)
                      expand(offset_i, 2)
                    }
                  }}
                  onExit={() => {
                    setStartedConversationId(null)
                    setExpanded(null)
                    reset()
                  }}
                />
              )
            }): null}
        </TilesInnerPositioned>
      </TilesContainer>
    </Container>
  )
}