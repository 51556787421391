import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProfile } from '../apis/user.api';
import useAuth from './auth.hook'

export default function useUpdateMyProfile() {
  const queryClient = useQueryClient()
  const { session } = useAuth()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: profile => {
      return updateProfile(profile)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [`my-profile-${session?.profile?.id}`]}, data?.data)
      queryClient.invalidateQueries({ queryKey: [`articles-${session?.profile?.id}`]})
      queryClient.invalidateQueries({ queryKey: [`artworks-${session?.profile?.id}`]})
      queryClient.invalidateQueries({ queryKey: [`trending-users-latest-articles`]})
      queryClient.invalidateQueries({ queryKey: [`find-artworks-pages`, { page : 1}]})
      queryClient.invalidateQueries({ queryKey: [`trending-users-latest-articles`]})
      queryClient.invalidateQueries({ queryKey: [`users`]})
    },
  });

  return {
    update: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}