import React from 'react'

import Page from './page'

import {
  Container,
  Inner
} from './solo-article.styles'

export default function Ping2() {
  return (
    <Page>
      <Container>
        <Inner>
          ping2 feature
        </Inner>
      </Container>
    </Page>
  )
}