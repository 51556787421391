import styled from 'styled-components'

import {
  fonts,
  colours,
  ScrollStyledBox,
  breakpoints
} from '../../styles/global.styles'

export const ContainerList = styled(ScrollStyledBox)`
  display:flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colours.darkerlight};

  @media (max-width: ${breakpoints.sm}px) {
    
  }
`

export const User = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 15px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${colours.darklight};
  border-bottom: 1px solid ${colours.darkerlight};


  &:hover {
    background-color: ${colours.pink_alpha50};

    .account-type {
      img:first-child {
        display: none;
      }
      img:last-child {
        display: block;
      }
    }

    .name {
      color: ${colours.pink};
    }
  }

`

export const AccountType = styled.div`
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  display: block;
  position: relative;

  img:first-child {
    display: block;
    position: absolute;
  }
  img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: none;
  }
`

export const Name = styled.div`
  flex: 1 1 auto;
  padding-left: 10px;
  padding-right: 10px;
  color: ${colours.darkergrey2};
  font-family: ${fonts.content.bold};
  font-size: 14px;
  line-height: 14px;
  text-align: left;
`

export const AvatarContainer = styled.div`
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`
