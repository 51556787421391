import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  colours,
  fonts,
  ButtonPrimary,
  BaseTransition,
  breakpoints
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'
import useActionAlert from '../../hooks/action-alert.hook'

const Wrapper = styled.div`
  position:fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme === 'light' ? colours.darklight_alpha90 : colours.black_alpha85};
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.visible ? 'all': 'none'};
  visibility: ${props => props.visible ? 'visible':'hidden'};
  opacity: ${props => props.visible? 1: 0};
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
`;

const AlertContent = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`

const Icon = styled.div`
  display:flex;
  justify-content:center;
  margin-bottom:50px;
`

const Header = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
`;

const Body = styled.div`
  color: ${props => props.theme ==='light' ? colours.dark : colours.grey };
  font-family: ${fonts.content.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 50px;
`;

const Actions = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  
  &>button {
    margin-left: 4px;
    margin-right: 4px;
    white-space: nowrap;
    width: 100%;
    max-width: 236px;
    min-width: 160px;;
  }
`

const Close = styled(BaseTransition)`
  position:absolute;
  top: ${props => props.noPadding ? '10px' : '0px'};
  right: ${props => props.noPadding ? '10px' : '0px'};
  cursor: pointer;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-left:auto;
  background-image: url(${props => props.theme === 'light' ? icons['close-30px-3f3e3c.svg'] : icons['close-30px-ffffff.svg']});
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: contain;

  &.modal-close-bright {
    opacity: 1;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    top: ${props => props.noPadding ? '17px' : '0px'};
    right: ${props => props.noPadding ? '18px' : '0px'};
    margin-left: auto;
    flex: 0 0 30px;
    align-self: flex-start;
    width: 30px;
    height: 30px;
  }
`;

export default function ActionAlert() {
  const { actionAlert, closeAlert } = useActionAlert()
  
  return (
    <>
      {actionAlert.active ? <Wrapper 
        visible={actionAlert.active} 
        theme={actionAlert.theme ?? 'light'}>
        {!actionAlert.noClose ? <Close 
          theme={actionAlert.theme ?? 'light'}
          noPadding={true}
          onClick={() => {
            closeAlert()
          }}
        /> : null}
        <AlertContent>
          <Icon>
            <img src={actionAlert.icon ?? icons['alert.svg']} alt="alert" />
          </Icon>
          <Header>{actionAlert?.title ?? ''}</Header>
          <Body dangerouslySetInnerHTML={{__html: actionAlert?.body ?? ''}}></Body>
          <Actions>
            <ButtonPrimary onClick={() => {
              actionAlert.onYes && actionAlert.onYes()
            }}>{actionAlert?.yesLabel ?? ''}</ButtonPrimary>
            <ButtonPrimary onClick={() => {
              closeAlert()
            }}>{actionAlert?.noLabel ?? ''}</ButtonPrimary>
          </Actions>
        </AlertContent>
      </Wrapper> : null}
    </>
  );
}