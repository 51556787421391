import React from "react";
import {
  Container,
  HLink
} from './footer.styles'

export default function Footer() {

  const year = new Date().getFullYear()

  return (
    <Container>
      <span>Copyright &copy; {year} Groundcrew Pte. Ltd. All Rights Reserved.</span>
      <div className="break"></div>
      <HLink to="/here/privacy">Privacy Policy</HLink> | 
      <HLink to="/here/terms">Terms of Use</HLink> |
      <HLink to="/here/contact-us">Contact Us</HLink>
    </Container>
  )
}