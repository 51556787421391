import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cancelTask } from '../apis/user.api';

export default function useCancelTask() {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: data => {
      return cancelTask(data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`my-tasks`]
      })
    },
  });

  return {
    cancelTask: mutate,
    isLoading,
    isSuccess,
    isError,
    error
  }
}