import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'

const RadioButtonsWrapper = styled.div`
  display:flex;
  flex-direction:column;
  align-items:flex-start;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    flex-direction:row;
    justify-content: space-between;
  }
`;

const RadioButtonItem = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;

  input[type="radio"] {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  span.indicator-wrapper {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      visibility: hidden;
      background-color: ${colours.pink_alpha40};
      height: 36px;
      width: 36px;
      border-radius: 50%;
    }

    span.indicator {
      height: 24px;
      width: 24px;
      left: 6px;
      top: 6px;
      border-radius: 50%;
      border:2px solid ${colours.grey2};
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: '';
        display: inline-block;
        background-color: ${colours.pink};
        height: 14px;
        width: 14px;
        left: 11px;
        top: 11px;
        border-radius: 50%;
        visibility: hidden;
      }
    }
  }

  input[type="radio"]:checked + span.indicator-wrapper > span.indicator {
    border-color: ${colours.pink};
    &:before {
      visibility: visible;
      background-color: ${colours.pink};
    }
  }

  
  &:hover {
    span.indicator-wrapper {
      &:after {
        visibility: visible;
      }
    }
  }


  span.label {
    color: ${colours.darkergrey2};
    font-family: ${fonts.content.regular};
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

    b {
      font-weight: 700;
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    margin-right:18px;
  }
`;

export default forwardRef(function RadioButtons({
  name,
  value = null,
  options,
  onChange,
  ...rest
}, ref) {
  return (
    <RadioButtonsWrapper className="radio-button-list" ref={ref} {...rest}>
      {options.map((option, i) => (
        <RadioButtonItem key={i} className="radio-button-item">
          <input 
            name={name}
            type="radio" 
            value={option.value} 
            defaultChecked={value ? (value.toString() === option.value.toString()) : false} 
            onChange={() => {
              onChange && onChange(option.value)
            }}
          />
          <span className="indicator-wrapper">
            <span className="indicator"></span>
          </span>
          <span className="label" dangerouslySetInnerHTML={{
            __html: option.name
          }}>
          </span>
        </RadioButtonItem>
      ))}
    </RadioButtonsWrapper>
  )
});