import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserStatus } from '../../utils/helpers';
import { JoinUsModal } from 'pages/user';

export default function Tags({ className, tags }) {
    const [display, setDisplay] = useState(false);
    const loggedIn = !!useUserStatus();
    const history = useHistory();
    const handleClick = () => {
        if (loggedIn) {
            setDisplay(false);
            return false;
            // history.push('/#'); // url to be changed... or it can be a prop
        }
        else setDisplay(true);
    }

    const toggleDisplay = () => setDisplay(false); // make sure JoinUsModal displays every time tag is clicked

    return (
        <>
            {display && <JoinUsModal timeout={0} continuousToggling={true} passToggle={toggleDisplay} /> /* timeout zero to display immediately */}
            <div className={"tags-div " + (className ? className : '')}>
                {tags.map((val, i) =>
                    <div key={i} onClick={handleClick} className="tag d-inline-block">{val}</div>
                )}
            </div>
        </>
    )
}