import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  fonts,
  colours
} from '../../styles/global.styles'
import {
  getAccountTypeMeta,
  AccountTypes
} from '../../constants';
import {
  nameConcat
} from '../../utils/helpers';
import TextLine from '../shared/text-line'
import {
  imageUrl
} from 'utils/utils'

const accountTypeSizes = {
  default: '25px',
  'md': '32px'
}

const nameSizes = {
  default: '12px',
  'md': '14px'
}

const imageSizes = {
  default: '25px',
  'md': '40px'
}

const UserProfileStyled = styled.div`
  display:flex;
  flex-direction:row;
  justify-self:flex-end;
  align-items:center;
  margin-left:auto;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    order: 2;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding-right: 15px;
    padding-left: 48px;
}
`;

const AccountTypeAndName = styled.div`
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-self:flex-start;
  justify-content:flex-end;
  
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    
  }
`

const UserAccountType = styled.div`
  width: ${props => accountTypeSizes[props.size] ?? accountTypeSizes.default};
  height: ${props => accountTypeSizes[props.size] ?? accountTypeSizes.default};
  flex: 0 0 ${props => accountTypeSizes[props.size] ?? accountTypeSizes.default};

  img {
    display:block;
    width: 100%;
  }
`;

const ProfileName = styled.div`
  color: ${colours.text};
  font-family: ${fonts.title.xbold};
  font-size: ${props => nameSizes[props.size] ?? nameSizes.default};
  font-weight: 800;
  line-height: ${props => nameSizes[props.size] ?? nameSizes.default};
  letter-spacing: 0em;
  text-align: left;
  flex: 1 1 auto;
  margin-left: 4px;
  
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    max-width: 450px;
    width:auto;
    padding-right:4px;
  }
`;

// TODO - make a component multi line trancated with ellipsis

const ProfileImage = styled.div`
  width: ${props => imageSizes[props?.size] ?? imageSizes.default};
  height: ${props => imageSizes[props?.size] ?? imageSizes.default};
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 ${props => imageSizes[props?.size] ?? imageSizes.default};
  margin-left: 4px;

  img {
    display:block;
    width: 100%;
    object-fit:cover;
    object-position: center center;
  }
`;

export default function UserProfileLine({
  profile,
  style
}) {
  const { accountType, firstName, lastName, companyName, profileImage } = profile;
  const accountTypeMeta = getAccountTypeMeta(accountType);

  return (
    <UserProfileStyled>
      <AccountTypeAndName>
        <UserAccountType size={style?.size}>
          <img src={accountTypeMeta.iconFlare} alt={accountTypeMeta.name}/>
        </UserAccountType>
        <ProfileName size={style?.size}>
          <TextLine>
            {accountType === AccountTypes.FashionCompany ? 
            companyName
            : 
            nameConcat(firstName, lastName)}
          </TextLine>
        </ProfileName>
      </AccountTypeAndName>
      <ProfileImage size={style?.size}>
        <img src={imageUrl(profileImage)?? accountTypeMeta.fallBackAvatar} alt=""/>
      </ProfileImage>
    </UserProfileStyled>
  );
}