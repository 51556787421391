import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendArtworkAction } from 'apis/activity.api'

export default function useArtworkActions() {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: ({id, action}) => {
      return sendArtworkAction(id, action)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: [`activities-artworks-${variables.id}-${variables.action}`]})
      // replace the artwork item in the query cache
      // console.log('cache', queryClient.getQueriesData)
      // console.log('artwork returned', data)
      // queryClient.invalidateQueries({ queryKey: ['find-artworks'] })
      // queryClient.setQueryData(['find-artworks'], data)
      // const { id } = variables
      // queryClient.invalidateQueries({ queryKey: ['artworks', id] })
    }
  });

  return {
    createActivity: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}