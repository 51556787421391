import React, { useEffect } from 'react'
import {
  preloaders
} from '../constants'
import {
  fonts,
  colours
} from '../styles/global.styles'
import { useParams, Redirect } from 'react-router-dom'
import useConfirmPasswordChange from '../hooks/confirm-change-password.hook'
import { PageContainer, BodyContent } from './shared/page-layout.styles'
import { usePageReady } from 'contexts/page-ready.context'

export default function ConfirmChangePassword() {
  const { code } = useParams()
  const { confirm, isLoading, isSuccess } = useConfirmPasswordChange()
  const { setReady } = usePageReady()

  useEffect(() => {
    confirm(code)
  }, [code])

  useEffect(() => {
    setReady(true)
  }, [])

  return (
    <>
      <PageContainer>
        <BodyContent>
          {isLoading ? <img src={preloaders['05-preloader-6px-d7d7d7.gif']} alt="loading"/> : null}
          {isSuccess ? <Redirect to="/login" /> : null}
        </BodyContent>
      </PageContainer>
    </>
  );
}