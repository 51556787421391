import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import {
  preloaders,
  icons,
  ActivityActions,
  ActivityTrackedSchemas,
  getAccountTypeMeta,
  MediaType
} from '../../../constants'
import {
  makeNotificationNote,
  getThumbnailLink,
  getName,
  imageUrl,
} from '../../../utils/utils'
import { getRelativeDateText } from 'utils/utils'
import { usePsstDrawer } from "./psst.drawer.context";
import { useActivities } from '../../../contexts/activities.context';
import usePsst from "hooks/psst.hook";

import {
  colours,
  fonts
} from 'styles/global.styles'
import styled from 'styled-components'
import {
  AccountTypes
} from '../../../constants'

const AllCaughtUp = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 14px;
  line-height: 13px;
  color: ${colours.grey};
  text-align:center;
  width: 100%;
  height: calc(100vh - 85px);
  padding-top:20px;
`

const Note = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height :13px;
  color: ${colours.grey};

  b {
    font-family: ${fonts.content.bold};
  }
`

const TimeStamp = styled.div`
  font-family: ${fonts.content.bold};
  font-size: 12px;
  line-height :13px;
  color: ${colours.lightgrey3};
`

export default function PsstDrawer() {
  const { opened, close } = usePsstDrawer()
  const { setLastReadAtNow } = usePsst()
  const notificationElemRef = useRef(null)

  const {
    isLoading,
    isSuccess,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useActivities()

  useEffect(() => {
    if (isSuccess && opened && data.length > 0) {
      setLastReadAtNow()
    }
  }, [isSuccess, data, opened])

  const getTitle = ({
    schemaName,
    artwork,
    article
  }) => {
    if (schemaName === ActivityTrackedSchemas.Artworks && artwork) {
      return artwork.title
    } else
    if (schemaName === ActivityTrackedSchemas.Articles && article) {
      return article.title
    }
  }

  const getLink = ({
    schemaName,
    artwork,
    article
  }) => {
    if (schemaName === ActivityTrackedSchemas.Artworks && artwork) {
      return `/artworks/${artwork._id}`
    } else
    if (schemaName === ActivityTrackedSchemas.Articles && article) {
      return `/articles/${article._id}`
    } else {
      return ''
    }
  }

  const getAvatarImage = ({
    profileImage,
    profileImageVariants,
    accountType
  }) => {
    if (accountType === AccountTypes.Itch) {
      return icons['pink-logo-24.png']
    } else {
      const accountTypeMeta = getAccountTypeMeta(Number(accountType));
      const { fallBackAvatar } = accountTypeMeta;
      return imageUrl(profileImageVariants?.thumb ?? profileImage) ?? fallBackAvatar
    }
  }

  const getArtworkImage = ({mediaType, video, imageVariants}) => {
    if (mediaType === MediaType.Image)
      return `${process.env.REACT_APP_UPLOADS_URL}/${imageVariants?.small?.filename}`
    else
      return `${video.posterUrl}`
  }

  const psstClickOutsideHandler = (evt) => {
    close()
  }

  useEffect(() => {
    document.body.addEventListener('click', psstClickOutsideHandler)
    if (notificationElemRef)
      notificationElemRef.current.onclick = (evt) => {
        evt.stopPropagation()
      } 

    return () => {
      document.body.removeEventListener('click', psstClickOutsideHandler)
    }
  }, [])

  return (
    <div id="notificationList" 
      ref={notificationElemRef}
      className={opened ? 'show' : ''} 
      style={{ maxHeight: `calc(100vh - ${document.getElementById('top-nav').clientHeight}px)`, overflow: "auto" }}>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={
          isFetchingNextPage || isLoading ?
            <div className="preloader-div text-center">
              <img src={preloaders['03-preloader-6px-000000.gif']} alt="preloader" />
            </div>
            : null
        }
        scrollableTarget="notificationList"
      >
        {data.map(({metadata, createdAt, action}, i) => (
          <div key={i} className="notification-item">
            <div className="notification-div">
              <div className={`user-profile ${metadata?.user?.accountType !== AccountTypes.Itch ? 'is-user':'is-itch'}`}>
                {metadata?.user 
                  ? <Link to={`/${metadata.user?.itchUrl}`}>
                      <img src={getAvatarImage(metadata.user)} alt={getName({
                        ...metadata.user
                      })} />
                    </Link> 
                  : <img className="itch-logo" src={icons['pink-logo-24.png']} alt="itch" />}
              </div>
              <div className="notification pn">
                <Note className="notification-message" dangerouslySetInnerHTML={{__html: makeNotificationNote({
                  action,
                  schemaName: metadata.schemaName,
                  title: getTitle(metadata),
                  name: getName({
                    ...metadata.user
                  }),
                  slug: metadata.article?.slug,
                  relatedId: metadata.relatedId,
                  userItchUrl: metadata.user?.itchUrl ?? null
                })}}></Note>
                <TimeStamp>{getRelativeDateText(new Date(createdAt))}</TimeStamp>
              </div>
              {metadata?.artwork ?
                <Link className="user-artwork" to={getThumbnailLink({
                  action,
                  schemaName: metadata.schemaName,
                  slug: metadata.article?.slug,
                  relatedId: metadata.relatedId,
                  userItchUrl: metadata.user?.itchUrl ?? null
                })}>
                  <img src={getArtworkImage(metadata.artwork)} alt="" />
                </Link>
                : null}
              {metadata?.article ?
                <Link className="user-artwork" to={getThumbnailLink({
                  action,
                  schemaName: metadata.schemaName,
                  slug: metadata.article?.slug,
                  relatedId: metadata.relatedId,
                  userItchUrl: metadata.user?.itchUrl ?? null
                })}>
                  <img src={imageUrl(metadata.article.coverImageVariants.small)} alt="" />
                </Link>
              : null}
            </div>
          </div>
        ))}
        {data.length === 0 ? <AllCaughtUp>
          You're all caught up!
        </AllCaughtUp>: null}
      </InfiniteScroll>
    </div>
  )
}