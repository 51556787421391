import React, { useState } from 'react'

const initialState = {
  viewed: {
    artworks: {},
    articles: {}
  },
  expanded: {
    artworks: {},
    articles: {}
  }
}

export const ActivityViewsContext = React.createContext(null);

export const ActivityViewsProvider = ({ children }) => {
  const [viewed, setViewed] = useState({...initialState});

  const pushViewed = ({
    name,
    id
  }) => {

    const viewedStateUpdate = {
      ...viewed
    }
    viewedStateUpdate.viewed[name][id] = true
    setViewed(viewedStateUpdate)
  }

  const pushViewedExpanded = ({
    name,
    id
  }) => {
    const viewedStateUpdate = {
      ...viewed
    }
    viewedStateUpdate.expanded[name][id] = true
    setViewed(viewedStateUpdate)
  }

  const clearViewed = () => {
    const updateState = {...viewed}
    updateState.viewed = {
      artworks: {},
      articles: {}
    }
    setViewed(updateState)
  }

  const clearViewedExpanded = () => {
    const updateState = {...viewed}
    updateState.expanded = {
      artworks: {},
      articles: {}
    }
    setViewed(updateState)
  }

  return (
    <ActivityViewsContext.Provider 
      value={{viewed, pushViewed, pushViewedExpanded, clearViewed, clearViewedExpanded}}>
      {children}
    </ActivityViewsContext.Provider>
  );
}