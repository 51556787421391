import React from 'react'
import styled from 'styled-components'
import { imageUrl } from 'utils/utils'

const Wrapper = styled.div`
  width: inherit;
  height:inherit;
  overflow: hidden;
`

const Img = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: ${props => props.position};
`

export default function Image({
  filename,
  placeholder = '',
  alt = '',
  className = '',
  position = 'center center',
  ...rest
}) {
  return (
    <Wrapper className={`image ${className}`} {...rest}>
      <Img src={imageUrl({filename}) ?? placeholder} alt={alt} position={position} />
    </Wrapper>
  );
}