import React, { forwardRef } from 'react';
import styled from 'styled-components';
import RichTextEditor from 'components/editor/editor'
import {
  colours
} from 'styles/global.styles'

const EditorContainer = styled.div`
  .rich-editor-container {
    .editor-container {
      .editor-bottom-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .editor-input:focus {
        color: ${colours.white};
      }
      .editor-input {
        color: ${colours.text};
      }
    }
  }
`

export default forwardRef(function InputEditor(
  props,
  ref
) {
  const { 
    height, onChange, onBlur,
    renderBackElement,
    value, placeholder, 
    options, maxLength,
    disabled, ...rest } = props;

  return (
    <EditorContainer style={{height: height ?? '100%' }}>
      <RichTextEditor 
        {...{
          value, placeholder, 
          onChange, onBlur, 
          disabled, options,
          renderBackElement,
          maxLength
        }} 
      />
    </EditorContainer>
  )
});