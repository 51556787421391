import React from 'react'
import styled from 'styled-components'

import {
  colours,
  fonts,
  breakpoints
} from '../../styles/global.styles'

const Container = styled.span`
  position: relative;

  .icon {
    position: relative;
    display: block;
    cursor: pointer;
    &>img {
      display:block;
      width: 100%;
      transition-property: all;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
    }
    &>img:first-child {
      opacity: 1;
    }
    &>img:last-child {
      top:0;
      left:0;
      position: absolute;
      opacity: 0;
    }
  }

  .label {
    color: ${colours.pink};
    font-family: ${fonts.title.xbold};
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: right;
    visibility: hidden;
    position: absolute;
    right:100%;
    padding-right:10px;
    margin-right: 8px;
    height: 100%;
    top: 2px;
  }
  
  @media (hover: hover) {
    &:hover {
      .icon {
        &>img:first-child {
          opacity: 0;
        }
        &>img:last-child {
          opacity: 1;
        }
      }
      .label {
        visibility: visible;
      } 
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    display:flex;
    flex-direction: column;
    align-items: center;

    .icon {
      margin-top:3px;
    }
    
    .label {
      padding:0;
      line-height:9px;
      order:2;
      margin-top:3px;
      position: static;
      font-size: 9px;
      color: ${colours.lightgrey2};
    }

    &:active {
      .icon {
        &>img:first-child {
          opacity: 1;
        }
        &>img:last-child {
          top:0;
          left:0;
          position: absolute;
          opacity: 0;
        }
      }
    }
  }
`;

export default function HoverIconLabel({
  active,
  hover,
  className,
  label = '',
  alt = '',
  ...rest
}) {
  return (
    <Container className={className} {...rest}>
      <div className="label">{label}</div>
      <div className="icon">
        <img src={active} alt={alt}/>
        <img src={hover} alt={alt}/>
      </div>
    </Container>
  )
}