import React, {useState, useEffect, useRef} from 'react'

import Emitter from '../../utils/emitter';
import parse from 'html-react-parser';
import countries from 'i18n-iso-countries';
import {format} from 'date-fns'

import {featuredTextColor} from './commons'
import {
    useWindowDimensions,
    useUserStatus,
    useArtworkArticleStatus,
    ScrollToArticle,
    WaitForClose,
    chunk
} from '../../utils/helpers';
import CloseFeatureButton from './close-feature-button';
import ExpandFeatureButton from './expand-feature-button';
import ArtworkAuthorDetails from './artwork-author-details'
import Tags from './tags'
import ImagePopup from './image-popup'

import AdditionalContent from './additional-content'

import {MediaType} from '../../constants'
import {
    getName,
    imageUrl,
    ensureLinks,
    getImageByWidth
} from '../../utils/utils'

import { getOccupation } from './utils'
import SocialMedia from './social-media'
import useGetArtwork from 'hooks/get-one-artwork.hook';
import { useFeaturedArtworkTheme } from './featured-artwork.context'
import { useParams, useLocation } from 'react-router-dom'
import useAuth from 'hooks/auth.hook'
import useToastPop from 'hooks/toast-pop.hook'
import { copyToClipboard } from 'utils/utils'
import { useTopNavTheme } from 'components/shared/top-nav/top-nav-theme-context'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function FeaturedArtworkPreview(props) {
    const { show } = useToastPop()
    const { ready, session } = useAuth()
    const { artworkId } = useParams()
    const { search} = useLocation()
    const { success, loading, artwork } = useGetArtwork(artworkId)
    const topNavTheme = useTopNavTheme()

    const urlSearch = new URLSearchParams(search)
    const colourTheme = urlSearch.get('colour-theme')
    const chapter = parseInt(urlSearch.get('chapter'))
    const qualityImageFilename = urlSearch.get('image')
    
    const { 
        setTheme, 
        open: expandArtwork, 
        close: minimiseArtwork,  
    } = useFeaturedArtworkTheme()

    const [initialHeight, setInitialHeight] = useState(0);
    const [open, setOpen] = useState(false);
    const [hasScroll, setScroll] = useState(false);

    const featuredRef = useRef();
    const coverRef = useRef();
    const scrollableRef = useRef();
    const scrollableInnerRef = useRef();
    const expandImageRef = useRef();

    const {windowWidth, windowHeight} = useWindowDimensions();
    const {artworkAllowed} = useArtworkArticleStatus();

    let currentYear = new Date().getFullYear();
    let f = featuredTextColor[success ? colourTheme : 'pink'];

    const openArtwork = () => {
        if (!open) {
            console.log('emit open artwork');
            Emitter.emit('openArtwork');
            expandArtwork()
            setOpen(true);
        }
    }

    const waitForScroll = async () => {
        if (!open) { // scroll to position
        console.log('scrolling to artwork');
        const finishScrolling = await ScrollToArticle(featuredRef);
        // check if scroll is finished
        if (finishScrolling) {
                setOpen(true);
                // passChangeNav();
                Emitter.emit('artworkIsOpened');
            }
        }
    }

    const closeArtwork = async (closeWithinArtwork) => {
        if (open) { // restart text scroll when closed
            scrollableRef.current.scrollTop = 0;
            setOpen(false);
            minimiseArtwork()
            // re-adjust window scroll position
            document.documentElement.scrollTop -= featuredRef.current.clientHeight - initialHeight;
            // passCheckScrollPosition();

            console.log('waiting to close featured');
            // wait until finish closing
            const finishClosing = await WaitForClose(initialHeight, featuredRef);
            // if artwork is closed by opening of article
            if (finishClosing) {
                console.log('emit artwork is closed');
                Emitter.emit('artworkIsClosed');
                if (closeWithinArtwork) 
                Emitter.emit('closeWithin');
                

            }
        }
    }

    const triggerExpandModal = () => expandImageRef.current.triggerModal();

    useEffect(() => { // scroll when allowed, close when disallowed
        if (artworkAllowed) 
            waitForScroll();
        else 
            closeArtwork();
        // eslint-disable-next-line
    }, [artworkAllowed])

    useEffect(() => { // compare heights when opened
        if (open) {
            setScroll(scrollableInnerRef.current.clientHeight > scrollableRef.current.clientHeight);
        } else { // set height whenever there is change in dimensions when closed
        if (featuredRef.current) 
            setInitialHeight(featuredRef.current.clientHeight);
        }
    }, [windowWidth, windowHeight, open])

    useEffect(() => {
        // passState(open);
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        setTheme(colourTheme)
            if (ready && session === null)
                topNavTheme.setTheme(colourTheme === 'white' ? 'dark' : 'transparent')
    }, [colourTheme])

    let artworkImageUrl = null

    if (success && artwork) {
        if (qualityImageFilename) {
            artworkImageUrl = imageUrl({filename: qualityImageFilename})
        } else
        if (artwork.mediaType === MediaType.Image) {
            artworkImageUrl = (artwork.imageVariants ? 
                imageUrl(getImageByWidth({...artwork.imageVariants, 'original': artwork.image}, artwork.image)) : null)
        } else
        if (artwork.mediaType === MediaType.VideoLink) {
            artworkImageUrl = artwork.poster?.url
        }
    }

    const zineUrl = `${process.env.REACT_APP_API}/api/share/preview-featured-artwork/${chapter}`

    return (
        <div ref={featuredRef}
            className={"featured-space " + (open ? 'open' : '')}> 
            { success && artwork ? 
            <>
            <CloseFeatureButton 
                src={f.closeIcon}
                addClass={f.class}
                passCloseSection={() => closeArtwork(true)}
                hide={!open}/>
            <ExpandFeatureButton 
                src={f.expandIcon}
                addClass={f.class}
                passTriggerModal={triggerExpandModal}
                hide={!open}/>
            <div ref={coverRef}
                className="featured-cover">
                <img src={artworkImageUrl}
                    className="featured-cover-img"
                    alt="featured cover"/>
                <div className={f.class + " sidebar"}
                    onClick={openArtwork}>
                    <ArtworkAuthorDetails 
                        user={artwork?.user}
                        author={getName(artwork?.user)}
                        authorStates={
                            {
                                normal: f.author,
                                hovered: f.mouseover.author
                            }}
                        occupation={getOccupation(artwork?.user)}
                        occupationState={f.occupation}
                        location={countries.getName(artwork?.user?.countryCode, "en", {select: "official"})}/>

                    <div className="sidebar-inner">
                        <div className={"media-buttons media-sprite-20 d-flex justify-content-between align-items-center"}>
                        <SocialMedia 
                            onClick={() => {
                                window.FB.ui({
                                    method: 'share',
                                    href: zineUrl,
                                }, function(response){});
                            }}
                            type='facebook'
                            states={
                            {
                                normal: f.socialMedia,
                                hovered: f.mouseover.socialMedia
                            }
                            }/>
                        <SocialMedia
                            onClick={() => {
                                window.open(`https://twitter.com/intent/tweet?url=${zineUrl}`)
                            }}
                            type='twitter'
                            states={
                            {
                                normal: f.socialMedia,
                                hovered: f.mouseover.socialMedia
                            }
                            }/>
                        <SocialMedia
                            onClick={() => {
                                window.open(`http://pinterest.com/pin/create/link/?url=${zineUrl}`)
                            }}
                            type="pinterest"
                            states={
                            {
                                normal: f.socialMedia,
                                hovered: f.mouseover.socialMedia
                            }
                            }/>
                        <SocialMedia
                            onClick={() => {
                                copyToClipboard(zineUrl)
                                show('Link copied!')
                            }}
                            type="google"
                            states={
                            {
                                normal: f.socialMedia,
                                hovered: f.mouseover.socialMedia
                            }
                            }/>
                        </div>
                        <div ref={scrollableRef} className="sidebar-scrollable no-scroller overflow-y-auto text-left">
                            <div ref={scrollableInnerRef}
                                className="sidebar-scrollable-inner">
                                <div className={f.title + " statement text-uppercase pn-bold mb-1"}>fashion statement</div>
                                {artwork.user?.fashionStatement ? <div className={f.text + " mb-5"}> {parse(artwork.user?.fashionStatement ?? '')}</div> : null}
                                <div className={f.title + " statement text-uppercase pn-bold mb-1"}>artwork title</div>
                                <div className={f.text + " mb-5"}> {artwork.title}</div>
                                <div className={f.title + " statement text-uppercase pn-bold mb-1"}>artwork description</div>
                                <div className={f.text + " mb-5"}
                                dangerouslySetInnerHTML={{__html: ensureLinks(artwork.description)}}></div>
                            </div>
                            <div className={"additional-author-content pb-4 w-100 position-absolute left-0 " + (!hasScroll ? 'bottom-0' : '')}>
                                <Tags className={f.text}
                                    tags={artwork.tags}/>
                                <AdditionalContent className={f.socialMedia}
                                    noMedia={windowWidth >= 1030 ? false : true}
                                    occupation=""
                                    profileLink={session?.profile?.id === artwork.user._id ? '/my-profile' : '/' + artwork.user.itchUrl }
                                    author={getName(artwork.user)}
                                    date={format(new Date(artwork.createdAt), 'MMMM d, yyyy')}
                                    states={
                                        {
                                            normal: f.socialMedia,
                                            hovered: f.mouseover.socialMedia
                                        }
                                    }>at {format(new Date(artwork.createdAt), 'h:mm b')} </AdditionalContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={f.class + " tint"}
                onClick={openArtwork}>
                <div className="featured-details middle-xy">
                    <div className="body-text pn-bold">
                        <img src={f.logo}
                            className="artwork-logo"
                            alt="logo"/>
                        <div className={f.chapter + " chapter text-uppercase pink-text mt-3"}>zine chapter #{chapter}</div>
                        <div className={f.year + " year"}> {currentYear}</div>
                    </div>
                </div>
            </div>
            {open && artwork.mediaType === MediaType.VideoLink && <figure className="play-button-div middle-xy">
                <div onClick={triggerExpandModal}>
                    <button name="play"></button>
                </div>
            </figure>}
            <ImagePopup ref={expandImageRef}
                img={imageUrl(artwork.image)}
                video={artwork.video}/>
            </> : null } 
        </div>
    )
}
