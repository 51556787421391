import React, { useState, useEffect, useMemo, useRef } from 'react'

// styled components
import { 
  Container, 
  Toast,
  TilesContainer,
  TilesInnerPositioned,
} from './gallery.styles';
import {
  icons, ReviewStatus
} from '../constants'
import {
  breakpoints
} from '../styles/global.styles'

import RevealArticleTile from '../components/articles/reveal-article-tile'
import ArticleTile from '../components/articles/article-tile';

// hooks
import useModal from '../hooks/modal.hook'
import useMasonry from '../hooks/masonry.hook'
import useGetArticles from '../hooks/get-articles.hook'
import useDeleteMyArticle from '../hooks/delete-my-article.hook'
import useActionAlert from '../hooks/action-alert.hook';
import { useLocation, useParams } from 'react-router-dom'

import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import useGetProfile from 'hooks/get-profile.hook';
import { getName } from 'utils/utils'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';
import useSetArticleSeen from 'hooks/set-article-seen.hook'

export default function Articles({
  userId
}) {
  const { itchUrl } = useParams()
  const getProfile = useGetProfile(itchUrl)
  const prevLastCountRef = useRef();
  const location = useLocation()
  const { pathname } = location
  const isOwner = pathname.indexOf('/my-profile') === 0;
  const { setModal } = useModal()
  const { success, fetching, articles, fetched, nextPage, hasNextPage } = useGetArticles(userId, isOwner, 12, isOwner ? 'reviewStatusPending,createdAt' : 'createdAt')
  const { setSeen } = useSetArticleSeen()
  
  useBottomScrollListener(() => {
    if (hasNextPage) {
      nextPage()
    }
  })

  const { deleteOne } = useDeleteMyArticle()
  const { setActionAlert, closeAlert } = useActionAlert()

  const [expanded, setExpanded] = useState(null)

  const lastCount = useMemo((() => success ? articles.data?.length : 0), [articles, success])

  const { grid, expand, init, reset } = useMasonry()

  const articlesRenderItems = (success && grid.length > 0) ? (articles?.data?? []).map((article, i) => (
    <ArticleTile
      index={i + (isOwner ? 1 : 0)}
      draggable={true}
      disableOwnerActions={!isOwner}
      position={grid[i + (isOwner ? 1 : 0)]}
      expanded={expanded === (i + (isOwner ? 1 : 0))}
      key={`article-${article.id}}`}
      {...article}
      onClose={() => {
        setExpanded(null)
        reset()
      }}
      onUpdate={() => {
        setExpanded(null)
        reset()
      }}
      onExpand={() => {
        if (expanded === (i + (isOwner ? 1 : 0))) {
          setExpanded(null)
          reset()
        } else {
          setExpanded(i + (isOwner ? 1 : 0))
          expand((i + (isOwner ? 1 : 0)), 2)
        }
      }}
    />
  )) : null
  
  useEffect(() => {
    // todo - fix the arrangement for new articles added
    if (success || fetched || lastCount !== prevLastCountRef.current) {
      setExpanded(null)
      init(articles?.data?.length + (isOwner ? 1 : 0), 3)
      setSeen()
    }
    prevLastCountRef.current = lastCount
  }, [success, fetched, lastCount])

  const draftArticle = articles?.data?.find(a => a.reviewStatus === ReviewStatus.Draft)
  // disable draft
  
  const maxRows = grid.length > 0 ? (Math.ceil(grid.length/ 3) + (expanded !== null ? 1 : 0)): 0

  return (
    <Container>
      {isOwner && (articles?.data?? []).length === 0 ? <Toast>
        <div>
        Submit your fashion articles here. Upon approval, they’ll appear chronologically on <b>itch</b>’s zine page!
        </div>
      </Toast> : null }
      {!isOwner && getProfile.profile && (articles?.data?? []).length === 0 ? <Toast>
        <div>
        {getName(getProfile.profile)} hasn't uploaded any articles.
        </div>
      </Toast> : null }
      <TilesContainer>
        <TilesInnerPositioned rows={maxRows}>
          {isOwner ? <RevealArticleTile
            responsiveHeight={{
              [breakpoints.sm]: '240px'
            }}
            onContinueDraft={() => {
              setModal({
                name:'reveal-article',
                id: draftArticle.id
              })
            }}
            onRestart={() => {
              // show prompt
              setActionAlert({
                noClose: true,
                theme: 'dark',
                icon: icons['pink-alert-trash-triangle.svg'],
                active: true,
                title: 'Delete my saved article draft?',
                body: `
                  You're about to permanently delete this article draft.
                  <br/><br/>
                  <b>Are you sure you want to continue?</b>`,
                yesLabel: 'Yes',
                noLabel: 'No',
                onYes: () => {
                  closeAlert()
                  // delete draft article
                  deleteOne(draftArticle.id)
                  setModal({
                    name: 'reveal-article'
                  })
                }
              })
            }}
            haveDraft={false}
            index={0}
            position={{
              top: 0,
              left: 0
            }}
            key={'reveal'}
            onNewArticle={() => {
              setModal({
                name: 'reveal-article'
              })
            }}
          /> : null}
          {articlesRenderItems}
        </TilesInnerPositioned>
        {fetching && !success ? <ProgressLoader/> : null}
      </TilesContainer>
    </Container>
  )
}