import { useMutation, useQueryClient } from '@tanstack/react-query';
import { triggerDeactivateMe } from '../apis/user.api';

export default function useTriggerDeactivate() {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (data) => {
      console.log('data', data)
      return triggerDeactivateMe(data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`my-tasks`]
      })
    }
  });

  return {
    trigger: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}