import React, { useState } from 'react'

export const FullScreenContext = React.createContext(null);

export const FullScreenProvider = ({ children }) => {
  const [fullScreen, setFullScreen] = useState({
    active: false
  });
  return (
    <FullScreenContext.Provider value={[fullScreen, setFullScreen]}>
      {children}
    </FullScreenContext.Provider>
  );
}