import React, { useState, useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query';
import { getMessages } from 'apis/conversation.api'
import { isBefore, parse, isToday } from 'date-fns'
import useAuth from './auth.hook';

const useGetMessages = (id) => {
  const { session } = useAuth()
  const { isLoading, error, 
    data, isSuccess, isFetching, isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: [`messages`, id], 
    queryFn: ({ pageParam = 0 }) => getMessages(id, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page + 1 < lastPage.totalPages)
        return Number(lastPage.page) + 1
    },
    refetchOnReconnect: true,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchInterval: 10000
  })

  // append a dateDivide prop to a message object
  // flatten the list
  const toMessages = (pages) => {
    let lastDate = null
    let unreadCount = null
    let lastReadStatus = null
    const messages = pages.reduce((acc, curr) => {
      const pageMessages = curr.data.map((message, i) => {
        let messageDate = parse(message.createdAt.split('T')[0], 'yyyy-MM-dd', new Date())

        if (message.from._id !== session?.profile?.id) {
          if (!message.read) {
            if (lastReadStatus === null)
              lastReadStatus = false

            if (unreadCount === null)
              unreadCount = 1;
            else
              unreadCount++;
          }

          if ((lastReadStatus !== null && lastReadStatus !== message.read && message.read) 
            || (i + 1 === curr.data.length)) {
            message.unreadDivide = unreadCount
            lastReadStatus = null
            unreadCount = 0
          }
        } else {
          message.unreadDivide = unreadCount
          lastReadStatus = null
          unreadCount = 0
        }

        if (lastDate === null) {
          lastDate = messageDate
        }
      
        if (isBefore(messageDate, lastDate)) {
          message.dateDivide = lastDate
          lastDate = messageDate
        } else
        if (i + 1 === curr.data.length) {
          message.dateDivide = lastDate
        }

        return message
      })
      return acc.concat(pageMessages)
    }, [])
    return messages
  }

  return {
    isLoading,
    isSuccess,
    isFetched,
    data: isSuccess ? toMessages(data.pages ?? []) : [],
    hasNextPage,
    fetchNextPage
  }
}

export default useGetMessages