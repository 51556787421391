import React, { useEffect } from 'react';
import {
  icons
} from '../../constants';
import AccountTypeSelect from './account-type-select';
import {
  ButtonResetForm
} from '../../pages/settings.styles'
import ButtonSave from '../form/button-save';
import {
  SubModalWrapper,
  Header,
  Close,
  Body,
  Footer
} from './shared.styles'

import useUpdateMyAccountType from '../../hooks/update-user-account-type.hook';

import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

import { onSubModalLoadElevate } from '../shared/modal';

export default function ChangeAccountType({
  accountType,
  primaryProfession,
  secondaryProfessions,
  companyType,
  onClose
}) {
  const { update, isLoading, isSuccess, isError } = useUpdateMyAccountType()

  const {
    reset, 
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: {
      isDirty
    }
  } = useForm({
    defaultValues: {
      accountType,
      primaryProfession,
      secondaryProfessions,
      companyType
    }
  })

  const onSubmit = (data) => {
    update({
      ...data.accountSettings
    })
    onClose && onClose()
  }

  useEffect(onSubModalLoadElevate, [])

  return (
    <SubModalWrapper className="modal-account-change-type">
      <Header>
        <div className="header-wrapper">
          <div className="icon">
            <img src={icons['fcfafl.svg']} alt=""/>
          </div>
          <div className="text">
            change account type
          </div>
        </div>
        <Close 
          onClick={() => {
            onClose && onClose();
          }}
        />
      </Header>
      <Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="wrapper">
            <Controller
              name="accountSettings"
              control={control}
              render={({ field }) => 
                <AccountTypeSelect
                  accountType={accountType}
                  primaryProfession={primaryProfession}
                  secondaryProfession={secondaryProfessions}
                  companyType={companyType} 
                  onChange={(accountSettingsUpdate) => {
                    field.onChange({
                      ...accountSettingsUpdate
                    })
                  }}
                />
              }
            />
          </div>
          <Footer>
            <ButtonResetForm active={isDirty} type="button">Reset</ButtonResetForm>
            <ButtonSave loading={isLoading} active={isDirty} >Save</ButtonSave>
          </Footer>
        </form>
      </Body>
    </SubModalWrapper>
  );
}