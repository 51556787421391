import React, { useState, useRef, useEffect } from 'react'
import {
  Container,
  Input
} from './search.styles'
import {
  icons
} from '../../../constants'
import {
  useHistory
} from 'react-router-dom'

export default function Search() {
  const inputRef = useRef()
  const [active, setActive] = useState(false)
  const { push } = useHistory()

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === 'Escape') {
      setActive(false)
      push(`/search?q=${event.target.value}`)
    }
  }

  const handleClick = () => {
    setActive(true)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.focus()
    }
  }, [active])

  return (
    <Container showInput={active}>
      <div className="search">
        <img className="icon" src={icons['search.svg']} alt=""/>
        <Input ref={inputRef} placeholder="type here" type="text" onKeyDown={handleKeyDown} /> 
      </div>
      <div className="label" onClick={handleClick}>search</div>
    </Container>
  )
}