import React from 'react'
import styled from 'styled-components'
import {
  colours,
  breakpoints,
  fonts,
  ButtonPrimary
} from 'styles/global.styles'
import {
  icons,
  images,
  mobileImages
} from '../constants'
import useAuth from 'hooks/auth.hook'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  background-image:url(${images['error404-desktop.jpg']});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`

const Alert = styled.div`
  background-image:url(${icons['triangle.svg']});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 137px;
  height: 120px;
  margin-bottom: 50px;
`

const Title = styled.div`
  text-align:center;
  font-size: 21px;
  line-height: 11px;
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  margin-bottom: 8px;
`

const Body = styled.div`
  width: 100%;
  max-width: 337px;
  text-align:center;
  font-size: 16px;
  color: ${colours.grey};
  font-family: ${fonts.content.regular};
  margin-bottom: 54px;

  b {
    font-family: ${fonts.content.bold};
  }
`

const RefreshButton = styled(ButtonPrimary)`
  height: 52px;
  width: 240px;
  max-width: unset;
`

export default function Snap() {
  const history = useHistory()
  const { fetching, session } = useAuth()
  const isLoggedIn = !fetching && session

  return (
    <Container id="snap">
      <Alert></Alert>
      <Title>Snap!</Title>
      <Body>
        <p>Even to the best of us, some things, sometimes, 
        somewhere can go awfully wrong...</p>

        <b>But we know this wouldn't really stop you.</b>
      </Body>
      <RefreshButton onClick={() => {
        if (isLoggedIn) {
          window.location.href = '/home'
        } else {
          window.location.href = '/'
        }
      }}>Refresh!</RefreshButton>
    </Container>
  )
}
