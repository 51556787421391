import styled from 'styled-components'
import {
  fonts,
  colours
} from 'styles/global.styles'
import { Link } from 'react-router-dom'

export const CommentContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  margin-bottom: 0px;
  border:1px solid ${colours.darkerlight4};
`

export const UserInfoRow = styled.div`
  display:flex;
  flex-direction: row;
  align-items:center;
`

export const ContentRow = styled.div`
  display:flex;
  flex-direction: row;
  align-items:flex-start;
`

export const FirstCol = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 0 0 32px;
  
`

export const SecondCol = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  padding-left:8px;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const AccountTypeContainer = styled.div`
  flex: 0 0 32px;
`

export const FlagContainer = styled.div`
  justify-self:flex-end;
  height:100%;
  margin-top: auto;
  flex: 0 0 32px;
  display:flex;
  justify-content:flex-start;
  &>* {
    width: 9px;
  }
`

export const Name = styled(Link)`
  padding-left: 6px;
  flex: 1 1 auto;
  font-family: ${fonts.content.bold};
  font-size: 13px;
  line-height: 17px;
  color: ${colours.darkergrey2};
  text-align: left;

  
  &:hover {
    color: ${colours.pink};
  }

`

export const AvatarContainer = styled.div`
  flex: 0 0 32px;
  margin-left: 6px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;
`

export const Body = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 13px;
  line-height: 17px;
  color: ${colours.darkergrey2};
  text-align:left;
  
  a {
    color: ${colours.pink};
    text-decoration: none;
  }
`

export const Footer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`

export const DateTime = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${colours.grey};
  font-family: ${fonts.regular};
`

export const RespondLink = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${colours.darkergrey2};
  font-family: ${fonts.regular};
  margin-left:auto;
  text-decoration: underline;
  cursor: pointer;
`
