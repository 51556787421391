import { useState, useEffect } from 'react';

function useElementInViewport(ref) {
  const [isInViewport, setIsInViewport] = useState(false);

  const checkViewport = () => {
    if (!ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const isInView = rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
    if (isInView !== isInViewport)
      setIsInViewport(isInView);
  };

  useEffect(() => {
    checkViewport();
    window.addEventListener('scroll', checkViewport);
    return () => {
      window.removeEventListener('scroll', checkViewport);
    };
  }, []);

  return isInViewport;
}

export default useElementInViewport;