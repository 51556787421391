import React, { useState, useEffect, useRef } from 'react'
import {
  Container
} from '../gallery/artwork-tile.styles'

import Tile from '../tile/tile'

import ViewTag from '../shared/view-tag'
import TileAlert from '../gallery/tile.alert'
import TileStatus from '../gallery/tile-status'
import TileMessage from '../gallery/tile-message'
import UserAvatar from '../avatar/user-avatar'
import TileOwnerPanel from '../gallery/owner-panel/tile.owner-panel'
import Share from '../shared/share'
import HoverIcon from '../shared/hover-icon'
import Image from 'components/shared/image'
import OwnerAccountTypes from './owner-account-types'

import Comment from 'components/comment/comment'

import { imageUrl, forceLinksBlankTarget } from 'utils/utils'

import {
  TileStatusContainer,
  Status,
  StatusMessage,
  CoverImage,
  AuthorContainer,
  AuthorInfo,
  SubHead,
  Author,
  Avatar,
  Tint,
  Title,
  ContentContainer,
  StickWrapper,
  CloseButton,
  ContentArea,
  Caption,
  AuthorSection,
  ArticleHeading,
  ArticleSubHeading,
  AuthorInfoArticleDate,
  AuthorCategories,
  Sharing,
  Content,
  TagsContainer,
  PublishedDate,
  OwnerProfile,
  ShareBottom,
  EditButtonContainer,
  EditButton,
  OwnerActions,
  OwnerButton,
  CommentsContainer
} from './article-tile.styles'

// hooks
import useModal from '../../hooks/modal.hook'
import useSetMyArticleReviewRespond from '../../hooks/set-article-review-respond.hook'
import useDeleteMyArticle from '../../hooks/delete-my-article.hook'

import { 
  icons,
  Orientation,
  ReviewStatus,
  getAccountTypeMeta,
  getUserAccountCategoriesString,
  RejectReason,
  RejectReasonLinks,
  Links
} from '../../constants'

import {
  colours,
  breakpoints
} from '../../styles/global.styles'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import useAuth from '../../hooks/auth.hook'

import {
  deriveUserName
} from '../../utils/helpers'
import { format } from 'date-fns'
import useActionAlert from 'hooks/action-alert.hook';

import { useWindowDimensions } from '../../utils/helpers'
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'

export default function ArticleTile({
  onClick,
  id,
  title,
  subHead,
  content,
  coverImage,
  coverImageVariants,
  caption,
  tags,
  createdAt,
  theme,
  slug,
  
  user = null,
  reviewRespondAt = null,
  reviewStatus = ReviewStatus.Pending,
  rejectedReason,

  index = 0,
  expanded = false,
  row,
  col,
  expandedCol,
  parentRowExpanded,

  onExpand,
  onClose,
  onUpdate,
  
  disableOwnerActions = false,
  ...rest
}) {
  const { handleRoute, link, linkProps } = useLoggedOutHandleRoute()
  const { isMobile } = useWindowDimensions()
  const [showFull, setShowFull] = useState(false)

  const { deleteOne } = useDeleteMyArticle()
  const { setRespond } = useSetMyArticleReviewRespond()
  const { setModal } = useModal()
  const [ alertActive, setAlertActive ] = useState(reviewStatus !== ReviewStatus.Pending)
  const { setActionAlert, closeAlert } = useActionAlert()

  const containerRef = useRef(null)
  const contentRef = useRef(null)
  const [expandComplete, setExpandComplete] = useState(false)

  const { session, fetching } = useAuth()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const match = useRouteMatch()

  const stickyWrapperRef = useRef(null)

  const tagsRendered = tags.map((tag, i) => 
    <ViewTag key={`tag-${i}`} text={tag} onClick={() => {
      push('/search/articles?q=' + tag)
    }}/>
  )

  useEffect(() => {
    if (!expanded) {
      setShowFull(false)
      setExpandComplete(false)
    } else {
      window.addEventListener('scroll', setCloseButton)
      if (contentRef.current) {
        contentRef.current.addEventListener('scroll', setCloseButton)
      }

      setCloseButton()
  
      return () => {
        window.removeEventListener('scroll', setCloseButton)
        if (contentRef.current) {
          contentRef.current.addEventListener('scroll', setCloseButton)
        }
      }
    }
  }, [expanded])

  const setCloseButton = () => {
    if (containerRef.current && contentRef.current) {
      let containerBox = isMobile ? containerRef.current.getBoundingClientRect() : contentRef.current.getBoundingClientRect();
      const containerBoxRef = isMobile ? containerRef.current : contentRef.current;
      const topnavElem = document.getElementById('top-nav') ?? document.getElementById('public-menu')
      const userNavElem = document.getElementById('user-nav')
      
      if (topnavElem && containerBox && containerBoxRef && stickyWrapperRef.current) {
        if (isMobile) {
          const top = containerBox.top - topnavElem.clientHeight - (userNavElem?.clientHeight ?? 0)
          const bottom = top + containerBoxRef.scrollHeight - 110
          if (top <= 0 && bottom >= 0) {
            stickyWrapperRef.current.style.transform = `translateY(${-1 * top}px)`;
          }  
        } else {
          const scrollDiff = (topnavElem.clientHeight + userNavElem.clientHeight) - containerBox.top
          const top = containerBoxRef.scrollTop + (containerBox.top <= (topnavElem.clientHeight + userNavElem.clientHeight) ? scrollDiff : 0)
          // console.log(top, containerBoxRef.scrollHeight - 65, topnavElem.clientHeight, userNavElem.clientHeight)
          const bottom = containerBoxRef.scrollHeight - 65
          if (top <= bottom)
            stickyWrapperRef.current.style.transform = `translateY(${top}px)`;
        }
      }
    }
  }

  const getRejectDescription = (rejectedReason) => {
    if (rejectedReason === RejectReason.Explicit) {
      return `This article is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} content</a>. <br/>Please kindly refer to our <a href="${Links.MisUse}" target="_blank">Terms & Conditions</a>. Thank you.`
    } else
    if (rejectedReason === RejectReason.Legal) {
      return `This article is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} reasons</a>. <br/>Please kindly refer to our <a href="${Links.ProtectingRights}" target="_blank">Terms & Conditions</a>. Thank you.`
    } else
    if (rejectedReason === RejectReason.Unprofessional) {
      return `This article is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} content</a>. <br/>Thank you.`
    } else
    if (rejectedReason === RejectReason.Irrelevant) {
      return  `This article is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} content</a>. Kindly upload articles that are fashion related, within your field of expertise, and with proper descriptions. Thank you.`
    } else {
      return null
    }
  }

  const alertRejectedRender = (rejectReason) =>
    <TileAlert 
      icon={icons['rejected.svg']}
      title="Oops!"
      description={getRejectDescription(rejectReason)}
      onClose={() => {
        setRespond(id)
        setAlertActive(false)
      }}
    />

  const alertApprovedRender = 
    <TileAlert 
      icon={icons['approved-artwork-logo.svg']}
      title="Wow, you’ve done it!"
      description={`This article is now live on itch!`}
      onClose={() => {
        setRespond(id)
        setAlertActive(false)
      }}
    />

  // a user may be null - so thats an itch article
  const isOwner = (user?._id === session?.profile?.id ?? null)
  
  const isEditable = (reviewStatus === ReviewStatus.Rejected || reviewStatus === ReviewStatus.Approved) && isOwner && !disableOwnerActions

  let borderColor = colours.pink
  if (reviewStatus === ReviewStatus.Approved) 
    borderColor = colours.transparent
  if (reviewStatus === ReviewStatus.Rejected) 
    borderColor = colours.darkergrey2

  const imageSmall = coverImageVariants ? {
    ...coverImage,
    url: process.env.REACT_APP_UPLOADS_URL + '/' + coverImageVariants.small.filename
  } : coverImage

  const imageExpanded = coverImageVariants ? {
    ...coverImage,
    url: process.env.REACT_APP_UPLOADS_URL + '/' + coverImageVariants.large.filename
  } : coverImage

  const image = expanded ? imageExpanded : imageSmall

  const shareUrl = `${window.location.protocol}//${process.env.REACT_APP_HOST}/articles/${slug}`

  const handleDelete = () => {
    setActionAlert({
      noClose: true,
      theme: 'dark',
      icon: icons['pink-alert-trash-triangle.svg'],
      active: true,
      title: 'Delete my article from articles?',
      body: `You can’t undo this action.<br/><br/>
        <b>Are you sure you want to continue?</b>`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        deleteOne(id)
        closeAlert()
      }
    })
  }

  return (
    <Tile
      disabled={reviewStatus === ReviewStatus.Rejected}
      addStyle={isMobile ? `
        box-shadow: 0px 0px 5px 3px rgba(51, 51, 51, 0.25);
      `: null}
      row={row}
      col={col}
      expandedCol={expandedCol}
      parentRowExpanded={parentRowExpanded} 
      expanded={expanded} 
      onExpandCompleted={() => {
        if (containerRef?.current) {
          const tile = containerRef.current.parentElement.parentElement
  
          let viewPortTopOffset = 0
          let scrollTop = 0
          if (match.path === '/:itchUrl' ||
            pathname.indexOf('/articles') > 0 ||
            pathname.indexOf('/my-profile') === 0) {
            
            viewPortTopOffset = document.getElementById('header').clientHeight - document.getElementById('user-nav').clientHeight
            scrollTop = tile.offsetTop + viewPortTopOffset
          } else
          if (pathname.indexOf('/search/articles') === 0) {
            const bounds = document.getElementById('search-header').getBoundingClientRect()
            viewPortTopOffset = bounds.top + bounds.height
            scrollTop = tile.offsetTop > 0 ? (tile.offsetTop + 30) : 0
          }

          window.scrollTo({
            top: scrollTop
          })

          if (contentRef.current) {
            contentRef.current.scrollTop = 0
          }
          
          setExpandComplete(true)
        }
      }}
      index={index}
      orientation={Orientation.Portrait}
      responsiveHeight={{
        [breakpoints.sm]: '64vw'
      }}
      {...rest}
    >
      <Container 
        ref={containerRef}
        borderColor={borderColor}
        expanded={expanded}>
        
        {!disableOwnerActions && reviewStatus === ReviewStatus.Rejected && !reviewRespondAt ? alertRejectedRender(rejectedReason) : null}
        {!disableOwnerActions && reviewStatus === ReviewStatus.Approved && !reviewRespondAt ? alertApprovedRender : null}
        
        {reviewStatus !== ReviewStatus.Approved ?
        <TileStatusContainer>
          <Status>
            <TileStatus reviewStatus={reviewStatus} />
          </Status>
          <StatusMessage>
            <TileMessage contentType='article' reviewStatus={reviewStatus} rejectedReason={rejectedReason} />
          </StatusMessage>
        </TileStatusContainer>
        : null}

        {isEditable && reviewRespondAt ? 
        <EditButtonContainer>
          {false && <EditButton onClick={(evt) => {
            onUpdate()
            setModal({
              name: 'reveal-article',
              id: id
            })
            evt.stopPropagation()
          }}>
            <HoverIcon
              active={icons['efefef-pencil-18px.svg']}
              hover={icons['pink-pencil-18px.svg']}
            />
          </EditButton>}
          <EditButton onClick={(evt) => {
            handleDelete()
            evt.stopPropagation()
          }}>
            <HoverIcon
              active={icons['dustbin-off.svg']}
              hover={icons['dustbin-on.svg']}
            />
          </EditButton>
        </EditButtonContainer>
        : null}

        {!expanded ? <AuthorContainer {...linkProps(isOwner ? `/my-profile`:`/${user?.itchUrl}`)} >
          <Avatar>
            <UserAvatar 
              profileUrl={link(isOwner ? `/my-profile`:`/${user?.itchUrl}`)}
              accountType={user?.accountType}
              url={imageUrl(user?.profileImageVariants?.medium ?? user?.profileImage)}
              position={user?.profileImage?.position}
            />
          </Avatar>
          <AuthorInfo>
            <Title theme={theme}>{title}</Title>
            {subHead ? <SubHead theme={theme}>{subHead}</SubHead> : null}
            <Author theme={theme}>
              {deriveUserName({...user})}
            </Author>
          </AuthorInfo>
        </AuthorContainer> : null}

        {!expanded ? 
        <Tint 
          theme={theme}
          onClick={() => {
            onExpand()
          }} 
        /> 
        : null}

        <ContentContainer
          className="content"
          ref={contentRef} 
          expanded={expanded}>
          {expanded ? 
          <StickWrapper ref={stickyWrapperRef}>
            <CloseButton 
              theme={theme}
              onClick={() => {
                onClose()
              }}
            >
              <img src={icons[theme === 'white' ? 'close-grey.svg' : 'close-white.svg']} alt="close" />
            </CloseButton>
          </StickWrapper>: null}

          <CoverImage 
            position={image.position}
            expanded={expanded} onClick={() => {
            onClose();
          }}>
            <Image {...image} alt="" />
          </CoverImage>

          {expanded ? 
          <ContentArea>
            <Caption>{caption}</Caption>
            <ArticleHeading to={`/articles/${slug}`}>{title}</ArticleHeading>
            <ArticleSubHeading>{subHead}</ArticleSubHeading>

            <AuthorSection>
              <AuthorInfoArticleDate>
                {user ? <div>By <a className='name' href={link(`/${user.itchUrl}`)}>{deriveUserName({
                  accountType: user.accountType,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  companyName: user.companyName
                })}</a>, &nbsp;
                <AuthorCategories>
                  <OwnerAccountTypes
                    {...user}
                  />
                </AuthorCategories></div> : null}
                <div>{format(new Date(createdAt), 'MMMM d yyyy')} at {format(new Date(createdAt), 'h:mma')}</div>
              </AuthorInfoArticleDate>
              
              <Sharing>
                <Share url={shareUrl} />
              </Sharing>
            </AuthorSection>

            <Content dangerouslySetInnerHTML={{__html: forceLinksBlankTarget(content)}}>
            </Content>

            <TagsContainer>
              {tagsRendered}
            </TagsContainer>
            
            <PublishedDate>
              {format(new Date(createdAt), 'd MMMM yyyy')} at {format(new Date(createdAt), 'h:mma')}
            </PublishedDate>
            
            <OwnerProfile expanded={expanded}>
              <TileOwnerPanel
                avatarFormat="circle" 
                disableFlagging={true}
                isOwner={isOwner}
                expanded={expanded}
                locationIcon={'location-12px-dark.svg'}
                user={user}
              />
            </OwnerProfile>

            <ShareBottom>
              <Share url={shareUrl} size={20} />
            </ShareBottom>
            
            <CommentsContainer>
              <Comment contentType="articles" id={id} />
            </CommentsContainer>

          </ContentArea>
          : null}
        </ContentContainer>

      </Container>
    </Tile>
  )
}