import { 
  AccountTypes, 
  ActivityActions, 
  ActivityTrackedSchemas
} from '../constants';
import React from 'react'
import { nameConcat } from './helpers';
import {
  differenceInMinutes, 
  differenceInDays, differenceInMonths, 
  differenceInHours, differenceInYears,
  parseISO
} from 'date-fns'

export const getName = (profile) => {
  if (!profile) return ''
  
  const {
    accountType,
    firstName,
    lastName,
    companyName
  } = profile
  return accountType === AccountTypes.FashionCompany ? 
    companyName : nameConcat(firstName, lastName);
}

/*
  standard account type name string
*/
export const getAccountTypeNamesString = ({
  accountType,
  companyType,
  primaryProfession,
  secondaryProfession
}) => {
  if (accountType === AccountTypes.FashionCompany) {
    return 'Fashion Company'
  } else
  if (accountType === AccountTypes.FashionArtist) {
    return 'Fashion Artist'
  } else
  if (accountType === AccountTypes.FashionLover) {
    return 'Fashion Lover'
  }
}

export const imageUrl = (params) => {
  if (!params) return undefined
  const { filename, url } = params
  if (!filename && !url) 
    return undefined
  return url ?? process.env.REACT_APP_UPLOADS_URL + '/' + filename
}

export const stripHtml = (str) => (str??'').replace(/(<([^>]+)>)/gi, "").replace(/\r?\n/g, ' ').replace(/[' ']+/g, ' ');

export const ensureLinks = (htmlContent) => {
  if (htmlContent) {
    return forceLinksBlankTarget(htmlContent)
  } else {
    return ''
  }
}

export const forceLinksBlankTarget = (html) => {
  // Create a temporary element to parse the HTML text
  var tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Regular expression to match URLs in text (including those starting with www)
  var urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+))/gi;

  // Define an array of domains to exclude
  var excludedDomains = ['www.youtube.com', 'www.vimeo.com'];

  // Function to process text content and wrap URLs in anchor tags
  function processTextContent(element) {
      for (var i = 0; i < element.childNodes.length; i++) {
          var node = element.childNodes[i];
          if (node.nodeType === 3) { // Text node
              var textContent = node.textContent;
              var replacedContent = textContent.replace(urlRegex, function(url) {
                  // Check if the URL starts with "www" and add "http://" if missing
                  if (url.startsWith("www.")) {
                      url = "http://" + url;
                  }

                  // Check if the URL's domain is in the excludedDomains array
                  if (excludedDomains.some(function(domain) { return url.indexOf(domain) !== -1; })) {
                      return url; // Return the URL as is without modification
                  }

                  // Wrap the matched URL in an anchor tag
                  return '<a href="' + url + '" target="_blank">' + url + '</a>';
              });
              // Replace the text content with the modified content
              var newNode = document.createElement('span');
              newNode.innerHTML = replacedContent;
              element.replaceChild(newNode, node);
          } else if (node.nodeType === 1) { // Element node
              processTextContent(node); // Recursively process child elements
          }
      }
  }

  // Start processing text content within the root element
  processTextContent(tempElement);

  // Return the modified HTML
  return tempElement.innerHTML;
}

export const isURL = (text) => {
  const urlRegex = /((http[s]?|www)\:\/\/[\w\.-/]+)|(\bwww\.[\da-z.-]+\.[a-z.]{2,6}(\/[\w .-]*)*\/?\b)/gi;
  return urlRegex.test(text)
}

export const trimHtml = (htmlContent, maxChars) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const rootNode = doc.body;

  let charCount = 0;
  let trimmedContent = '';

  // Helper function to recursively traverse the DOM tree
  function traverse(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      // Count characters in text nodes
      const text = node.textContent;
      const remainingChars = maxChars - charCount;

      if (text.length <= remainingChars) {
        trimmedContent += text;
        charCount += text.length;
      } else {
        trimmedContent += text.slice(0, remainingChars);
        charCount += remainingChars;
        return; // Stop further traversal if character limit is reached
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Recursively traverse child nodes
      for (const childNode of node.childNodes) {
        traverse(childNode);
      }
    }
  }

  // Start traversing from the root node
  traverse(rootNode);

  return trimmedContent;
}

export const getThumbnailLink = ({
  action, schemaName, relatedId, userItchUrl, slug
}) => {
  if (action === ActivityActions.Approved && schemaName === ActivityTrackedSchemas.Artworks) {
    return `/my-profile/gallery`
  } else
  if (action === ActivityActions.Approved && schemaName === ActivityTrackedSchemas.Articles) {
    return `/my-profile/articles`
  } else
  if (action === ActivityActions.Followed) {
    return `/${userItchUrl}`
  } else
  if (action === ActivityActions.Unfollowed) {
    return `/${userItchUrl}`
  } else
  if (action === ActivityActions.Wowed) {
    return `/artworks/${relatedId}`
  } else
  if (action === ActivityActions.Voted) {
    return `/artworks/${relatedId}`
  } else
  if (action === ActivityActions.SharedConversation) {
    return `/my-profile/conversations`  
  } else 
  if (action === ActivityActions.StartedConversation) {
    return `/my-profile/conversations`  
  } else 
  if (action === ActivityActions.Itchbooked) {
    return `/artworks/${relatedId}`
  } else 
  if (action === ActivityActions.CommentedArtwork) {
    return `/artworks/${relatedId}`
  } else 
  if (action === ActivityActions.CommentedArticle) {
    return `/articles/${slug}`
  } else 
  if (action === ActivityActions.TaggedFashionStatement) {
    return ``
  } else
  if (action === ActivityActions.TaggedArtwork) {
    return `/artworks/${relatedId}`
  } else
  if (action === ActivityActions.TaggedArticle) {
    return `/articles/${slug}`
  } else
  if (action === ActivityActions.TaggedArtworkComment) {
    return `/artworks/${relatedId}`
  } else
  if (action === ActivityActions.TaggedArticleComment) {
    return `/articles/${slug}`
  } else
  if (action === ActivityActions.Rejected) {
    if (schemaName === ActivityTrackedSchemas.Artworks) {
      return `my-profile/gallery`
    } else
    if (schemaName === ActivityTrackedSchemas.Articles) {
      return `/my-profile/articles`
    } else {
      return null
    }
  } else
  if (action === ActivityActions.RejectedArtwork) {
    return `/my-profile/gallery`
  } else
  if (action === ActivityActions.RejectedArticle) {
    return `/my-profile/articles`
  } else
  if (action === ActivityActions.Featured) {
    return `/zine`
  }
}

export const makeNotificationNote = ({
  action, name, title, schemaName, relatedId, userItchUrl, slug
}) => {
  if (action === ActivityActions.Approved && schemaName === ActivityTrackedSchemas.Artworks) {
    return `Your artwork <b><a href="/my-profile/gallery">${title}</a></b> is now live on <a href="/home"><b>home</b></a> page!`
  } else
  if (action === ActivityActions.Approved && schemaName === ActivityTrackedSchemas.Articles) {
    return `Your article <b><a href="/my-profile/articles">${title}</a></b> is now live on <a href="/zine"><b>zine</b></a>!`
  } else
  if (action === ActivityActions.Followed) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> just followed you!`
  } else
  if (action === ActivityActions.Unfollowed) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> just unfollowed you!`
  } else
  if (action === ActivityActions.Wowed) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> wowed your artwork <b><a href="/artworks/${relatedId}">${title}</a></b>`
  } else
  if (action === ActivityActions.Voted) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> voted your artwork <b><a href="/artworks/${relatedId}">${title}</a></b>`
  } else
  if (action === ActivityActions.SharedConversation) {
    return `<b>itch</b> shared a <a href="/my-profile/conversations?conversation-id=${relatedId}"><b>conversation</b></a> with you.`  
  } else 
  if (action === ActivityActions.StartedConversation) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> started a <a href="/my-profile/conversations?conversation-id=${relatedId}"><b>conversation</b></a> with you.`  
  } else 
  if (action === ActivityActions.Itchbooked) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> itchbooked your artwork <b><a href="/artworks/${relatedId}">${title}</a></b>`
  } else 
  if (action === ActivityActions.CommentedArtwork) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> commented on your artwork <b><a href="/artworks/${relatedId}">${title}</a></b>`
  } else 
  if (action === ActivityActions.CommentedArticle) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> commented on your article <b><a href="/articles/${slug}">${title}</a></b>`
  } else 
  if (action === ActivityActions.TaggedFashionStatement) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> tagged you in their fashion statement`
  } else
  if (action === ActivityActions.TaggedArtwork) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> tagged you in <b><a href="/artworks/${relatedId}">${title}</a></b>`
  } else
  if (action === ActivityActions.TaggedArticle) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> tagged you in <b><a href="/articles/${slug}">${title}</a></b>`
  } else
  if (action === ActivityActions.TaggedArtworkComment) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> tagged you in a comment on <b><a href="/artworks/${relatedId}">${title}</a></b>`
  } else
  if (action === ActivityActions.TaggedArticleComment) {
    return `<b><a href="/${userItchUrl}">${name}</a></b> tagged you in a comment on <b><a href="/articles/${slug}">${title}</a></b>`
  } else
  if (action === ActivityActions.Rejected) {
    if (schemaName === ActivityTrackedSchemas.Artworks) {
      return `Your artwork <b><a href="/my-profile/gallery">${title}</a></b> has been disapproved`
    } else
    if (schemaName === ActivityTrackedSchemas.Articles) {
      return `Your article <b><a href="/my-profile/articles">${title}</a></b> has been disapproved`
    } else {
      return null
    }
  } else
  if (action === ActivityActions.RejectedArtwork) {
    return `Your artwork <b><a href="/my-profile/gallery">${title}</a></b> has been disapproved`
  } else
  if (action === ActivityActions.RejectedArticle) {
    return `Your article <b><a href="/my-profile/articles">${title}</a></b> has been disapproved`
  } else
  if (action === ActivityActions.Featured) {
    return `Everyone has decided! Your artwork <b><a href="/zine">${title}</a></b> is now featured on zine!`
  }
}

/*
  date texts
    - just now
    - x min ago
    - x hour ago
    - x day ago
    - x month ago
    - x year ago
*/
export const getRelativeDateText = (date) => {
  const now = new Date()
  const diffYear = differenceInYears(now, date, {
    roundingMethod: 'floor'
  })
  if (diffYear >= 1) return `${diffYear} ${diffYear > 1 ? 'years' : 'year'} ago`
  
  const diffMonth = differenceInMonths(now, date, {
    roundingMethod: 'floor'
  })
  if (diffMonth >= 1) return `${diffMonth} ${diffMonth > 1 ? 'months' : 'month'} ago`

  const diffDay = differenceInDays(now, date, {
    roundingMethod: 'floor'
  })
  if (diffDay >= 1) return `${diffDay} ${diffDay > 1 ? 'days' : 'day'} ago`

  const diffHour = differenceInHours(now, date, {
    roundingMethod: 'floor'
  })
  if (diffHour >= 1) return `${diffHour} ${diffHour > 1 ? 'hours' : 'hour' } ago`

  const diffMin = differenceInMinutes(now, date, {
    roundingMethod: 'floor'
  })
  if (diffMin >= 1) return `${diffMin} ${diffMin > 1 ? 'mins':'min'} ago`
  else return 'just now'
}

export const preventEnterKeySubmission = (e) => {
  const target = e.target;
  if (e.key === "Enter" && !["TEXTAREA"].includes(target.tagName)) {
    e.preventDefault();
  }
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const getImageByWidth = (variants, fallback, targetWidth = window.innerWidth) => {
  const images = Object.entries(variants).map(([a,b]) => b)
  images.sort((a, b) => {
      return a.width < b.width ? -1 : 1
  })
  
  for (const img of images) {
      if (img.width >= targetWidth) return img
  }
  return fallback
}
