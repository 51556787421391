import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  FollowingItem
} from './following.styles'

const defaultPopUpState = {
  which: null,
  userId: null,
  name: null
}

export const FollowingFollowersPopUpContext = React.createContext(defaultPopUpState);

export const FollowingFollowersPopUpProvider = ({ children }) => {
  const [popup, setPopup] = useState(defaultPopUpState);

  useEffect(() => {
    setPopup(defaultPopUpState)
  }, [])

  return (
    <FollowingFollowersPopUpContext.Provider value={[popup, setPopup]}>
      {children}
    </FollowingFollowersPopUpContext.Provider>
  );
}

export const useFollowingFollowersPopup = () => {
  const [popup, setPopup] = useContext(FollowingFollowersPopUpContext);

  return {
    popup,
    setPopup    
  }
}

export default function Following(props) {
  const { setPopup } = useFollowingFollowersPopup()
  const { 
    followersCount, 
    followingCount,
    userId,
    name,
    ...rest } = props;

  return (
    <div {...rest}>
      <Container>
        <FollowingItem 
          onClick={(evt) => {
            evt.preventDefault();
            // console.log('open following', userId, name)
            setPopup({
              which: 'followers',
              userId,
              name
            })
          }}
        >
          <label>{followersCount ?? 0}</label>
          <span>Followers</span>
        </FollowingItem>
        <FollowingItem
          onClick={(evt) => {
            evt.preventDefault();
            setPopup({
              which: 'following',
              userId,
              name,
            })
          }}
        >
          <label>{followingCount ?? 0}</label>
          <span>Following</span>
        </FollowingItem>
      </Container>
    </div>
  )
}