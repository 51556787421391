import { useContext } from "react";
import { FollowedByMeContext } from "../contexts/followed-by-me.context";

const useFollowedByMe = () => {
  const { followedByMe } = useContext(FollowedByMeContext);

  const isFollowed = (id) => followedByMe.includes(id)

  return {
    isFollowed
  }
}

export default useFollowedByMe;