import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  colours,
  fonts,
  ButtonPrimary,
  themes
} from '../../styles/global.styles'
import useTheme from '../../hooks/user-theme.hook'

const exitAnimation = keyframes`
  0% {
    top: 0;
  }
  100% {
    top: -101%;
  }
`

const Container = styled.div`
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme === themes.Flare ? 
    colours.darkpink_alpha90 : colours.black_alpha85 };
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px;
  animation-name: ${props => props.close ? exitAnimation : 'unset'};
  animation-duration: .12s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
    
  
  &:hover {
    background-color: ${props => props.theme === themes.Flare ?
      colours.darkpink2_alpha90 : colours.black_alpha95 };
  }

`

const Icon = styled.div`
  width: 36px;
  height: 36px;
  margin-bottom: 20px;
  img {
    display:block;
    width: 100%;
  }
`

const Title = styled.div`
  font-family: ${fonts.content.bold};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme === themes.Flare ? colours.white : colours.pink};
  margin-bottom: 6px;

  &>a {
    color: ${colours.pink};
  }
`

const Description = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme === themes.Flare ? colours.light3 : colours.grey2};
  margin-bottom: 30px;
  
  &>a {
    color: ${colours.pink};
    text-decoration: underline;
  }
`

const OkButton = styled(ButtonPrimary)`
  font-family: ${fonts.content.bold};
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;
  border-color: ${props => props.theme === themes.Flare ? colours.light : colours.pink};
  color: ${props => props.theme === themes.Flare ? colours.light : colours.pink};

  
  &:hover {
    background-color: ${props => props.theme === themes.Flare ? colours.darkerpink : colours.pink_alpha50};
  }

`

export default function TileAlert({
  icon,
  title,
  description,
  onClose
}) {
  const  { userTheme } = useTheme()
  const [close, setClose] = useState(false)

  return (
    <Container 
      theme={userTheme}
      close={close}
      onAnimationEnd={() => {
        onClose && onClose()
      }}
    >
      <Icon>
        <img src={icon} alt="title" />
      </Icon>
      <Title theme={userTheme}>{title}</Title>
      <Description 
        theme={userTheme} 
        dangerouslySetInnerHTML={{__html: description}}>
      </Description>
      <OkButton
        theme={userTheme} 
        onClick={() => {
          setClose(true)
        }} 
      >Ok</OkButton>
    </Container>
  )
}