import React from 'react'
import styled from 'styled-components'
import {
  icons,
  ReviewStatus
} from '../../constants'

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 12px;

  img {
    display: block;
    float:left;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`

export default function TileStatus({
  reviewStatus
}) {
  return (
    <Container>
      {reviewStatus === ReviewStatus.Pending ? <img src={icons['pending-6px.svg']} alt="" /> : null}
      {reviewStatus === ReviewStatus.Rejected ? <img src={icons['rejected-22px.svg']} alt="" /> : null}
    </Container>
  )
}