import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getProfile } from '../apis/user.api';
import useAuth from './auth.hook'

export default function useGetMyProfile() {
  const { session } = useAuth();
  const { 
    isLoading, isSuccess, error, data, isFetching, refetch
  } = useQuery({
    queryKey: [`my-profile-${session?.profile?.id}`], 
    queryFn: () => {
      return session ? getProfile() : null
    }
  })

  useEffect(() => {
    if (session) {
      refetch()
    }
  }, [session])

  return {
    loading: isLoading,
    fetching: isFetching,
    success: session ? isSuccess : true,
    error,
    profile: session ? {
      ...data?.data,
      isOwner: true
    } : null
  }
}