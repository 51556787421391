import React, { useState, useEffect } from 'react'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { getAll } from 'apis/comments.api'

const useGetComments = (contentType, id) => {
  const { isLoading, error, 
    data, isSuccess, isFetching, isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: [`comments-${contentType}`, id], 
    queryFn: ({ pageParam = 0 }) => getAll(contentType, id, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page + 1 < lastPage.totalPages)
        return Number(lastPage.page) + 1
    }
  })

  return {
    isLoading,
    isSuccess,
    data,
    hasNextPage,
    fetchNextPage
  }
}

export default useGetComments