import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Container } from './top-nav.styles'

import Logo from './logo'
import Menu from './menu'
import TopNavAvatar from './top-nav-avatar'
import PsstDrawer from '../psst/psst.drawer'

import { useTopNavTheme } from './top-nav-theme-context'
import { ThemeProvider } from 'styled-components'

import useGetMyProfile from 'hooks/get-user-profile.hook'
import { usePsstDrawer } from "../psst/psst.drawer.context";
import PublicMenu from './public-menu'
import useModal from 'hooks/modal.hook'
import { useLocation } from 'react-router-dom'
import Tasks from '../tasks'
import useAuth from 'hooks/auth.hook'
import { useWindowDimensions } from 'utils/helpers';
import useDrawers from 'hooks/drawers.hook'

export default function TopNav() {  
  // const [ activeDrawer, setActiveDrawer] = useState() // left, right or psst
  const { activeDrawer, setActiveDrawer } = useDrawers()
  const { opened, close } = usePsstDrawer()
  const { theme } = useTopNavTheme()
  const { success, profile } = useGetMyProfile()
  const { setModal } = useModal()
  const { pathname } = useLocation()
  const { session, fetching } = useAuth()
  
  const isLoggedIn = session && !fetching
  const atAboutItch = ['/here/manifesto','/here/how-it-works'].includes(pathname)
  
  return (
    <>
      {!fetching ? <ThemeProvider theme={{color: theme}}>
        {success && profile !== null && profile !== undefined ? 
        createPortal(
          <>
            <Container id='top-nav' onClick={(evt) => {
              // evt.stopPropagation()
              setModal(null)
            }}>
              <Logo className="logo"/>
              {opened ? <PsstDrawer/> : null}
              <Menu/>
              <TopNavAvatar className="avatar" 
                {...profile} 
              />
            </Container>
            <Tasks/>
          </>, document.body
        ) : null }
        {atAboutItch || !isLoggedIn ? <PublicMenu theme={theme} isLoggedIn={isLoggedIn} /> : null}
      </ThemeProvider> : null}
    </>
  )
}