import { useEffect } from 'react';
import { isError, useQuery } from '@tanstack/react-query';
import { getUserProfile, getCurrentUserProfile } from '../apis/user.api';
import useAuth from './auth.hook'

export default function useGetProfile(itchUrl) {
  const { session, fetching } = useAuth()
  const { isLoading, error, isSuccess, data, isFetching, refetch } = useQuery(
    itchUrl ? ['user-profile', itchUrl] : [`my-profile-${session?.profile?.id}`], 
    itchUrl ? () => getUserProfile(itchUrl) : getCurrentUserProfile
  )
    
  useEffect(() => {
    if (!fetching) {
      refetch()
    }
  }, [session, fetching])

  const profile = isSuccess && data?.data ? {
    ...data?.data,
    isOwner: itchUrl ? false : true
  } : null

  const success = isSuccess && data?.status === 200
  const loading = isLoading || fetching

  return {
    loading: loading,
    fetching: isFetching,
    success,
    error,
    profile
  }
}


//itchUrl ? 'user-profile-' + itchUrl : 'my-profile'