import { useContext } from "react";
import { ActionsContext } from "../contexts/actions.context";

const useActions = () => {
  const { actions, pushAction, pullAction, clearActions } = useContext(ActionsContext);

  const isActioned = ({
    name,
    action,
    id
  }) => actions[name]?.[action]?.includes(id)

  return {
    actions,
    isActioned,
    pushAction,
    pullAction,
    clearActions
  }
}

export default useActions;