import React from 'react';
import SocialShareButton from './social-share-button'

export default function Pinterest({
  url,
  ...rest
}) {
  return (
    <SocialShareButton
      socialType={"pinterest"}
      onClick={() => {
        window.open(`http://pinterest.com/pin/create/link/?url=${url}`)
      }}
      {...rest}
    />
  )
}