import { StatLabel } from 'components/profile/userinfo.style'
import styled from 'styled-components'

import {
  fonts,
  colours,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;

  @media (max-width:${breakpoints.sm}px) {
    
  }
`

export const Inner = styled.div`
  width: 100%;
`