import styled from 'styled-components'
import {
  fonts,
  colours,
  themes,
  breakpoints,
} from '../../styles/global.styles'
import HoverIcon from 'components/shared/hover-icon'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  top: 7.5px;
  left: 7.5px;
  position: relative;
  background-color: #fff;
  cursor: pointer;


  &:hover {
    ${props => !props.expanded ? `
      .account-type {
        &>img {
          display: none;
        }
        &>img.icon-hover {
          display: block;
        }
      }
      .header-name-message * {
        color: ${colours.pink} !important;
      }
    `:``}

    .banner {
      .banner-overlay {
        background-color: ${props => props.muted ? colours.light_alpha40 : colours.pink_alpha40};
      }
      &>img {
        transform: translateX(-5px);
      }
    }
  }


  ${props => !props.expanded ? `
    
    &:hover {
      
    }
  
  `:``}

  @media (max-width:${breakpoints.sm}px) {
    flex-direction: ${props => props.theme.expanded ? 'column':'row'};
    position: ${props => props.theme.expanded ? 'fixed':'static'};
    top: ${props => props.theme.expanded ? '0':'unset'};
    left: ${props => props.theme.expanded ? 0:'unset'};
    z-index: ${props => props.theme.expanded ? 9999:'unset'};
    width:100%;
    height:${props => props.theme.expanded ? '100%': '100%'};
    
    .tile {
      height: 140px !important;
    }

    .header-name-message {
      .name {
        white-space: nowrap;
      }
    }
  }
`

const getUserThemeColorWithAlert = (hasAlert, theme) => {
  if (hasAlert) {
    return theme === themes.Flare ? colours.darkpink : colours.dark 
  } else
    return colours.light
}

export const Header = styled.div`
  flex: 0 0 85px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  background-color: ${props => getUserThemeColorWithAlert(props.alert, props.userTheme)};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  position: relative;

  @media (max-width:${breakpoints.sm}px) {
    flex: 1 1 auto;
    height: ${props => props.theme.expanded ? '66px':'100%'};
    width: ${props => props.theme.expanded ? '100%':'auto'};
    padding: ${props => props.theme.expanded ? 0:8}px;
  }
`

export const HeaderInner = styled.div`
  display:flex;
  flex-direction:row;
  flex: 1 1 auto;
  padding: ${props => props.theme.expanded ? 8:0}px;
  width:inherit;

  @media (max-width:${breakpoints.sm}px) {
    max-width: ${props => props.theme.expanded ? 'auto':'48vw'};
  }
`

export const BackButton = styled.div`
  display:none;
  @media (max-width:${breakpoints.sm}px) {
    display: ${props => props.theme.expanded ? 'flex':'none'};
    justify-content: center;
    align-items: center;
    background-color: ${colours.darklight};
    flex: 0 0 66px;
    &>div {
      display:flex;
      flex-direction: column;
      align-items: center;
      height:auto;
      &>span {
        color: ${colours.lightgrey2};
        font-family: ${fonts.content.regular};
        font-size: 10px;
        line-height:13px;
        margin-top:4px;
      }
    }
  }
`

export const Stub = styled.div`
  position: absolute;
  z-index: 11;
  bottom:0;
  right:20px;
  transform: translate(0, 100%);
  width: 20px;
  height: 20px;
  overflow:hidden;

  &:before {
    position:absolute;
    top: -50%;
    left: -50%;
    background-color: ${props => getUserThemeColorWithAlert(props.alert, props.userTheme)};
    content: '';
    transform-origin: center center;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  }

  @media (max-width:${breakpoints.sm}px) {
    display: none;
  }
`

export const AccountType = styled.div`
  flex:0 0 25px;
  width: 25px;
  height: 25px;
  position: relative;
  display:flex;
  
  img {
    width: 25px;
    position: absolute;
    top:0;
    left:0;
    opacity: ${props => props.muted ? .6 : 1};
    display: none;

    &.icon-dark {
      display: ${props => !props.alert ? 'block' : 'none'};
    }

    &.icon-flare {
      display: ${props => props.alert ? (props.userTheme === themes.Flare ? 'block': 'none') : 'none'}
    }

    &.icon-metro {
      display: ${props => props.alert ? (props.userTheme === themes.Metro ? 'block': 'none') : 'none'}
    }
  }
  
  &.itch {
    img {
      width: 21px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }


  @media (max-width:${breakpoints.sm}px) {
    img {
      width: 100%;
    }
  }
`

const getThemeTextColor = (alert, theme, muted) => {
  if (muted) {
    return colours.darkergrey2
  } else
  if (alert) {
    return theme === themes.Metro ? colours.text : colours.light
  } else
    return colours.darkergrey2
}

export const HeaderNameMessage = styled.div`
  flex: 1 1 auto;
  padding-top: 8px;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => props.theme.expanded ? '22vw' : 'calc(100% - 10px)'}; ;

  &>div {
    width: inherit;
    text-align:left;
  }

  .name {
    width: inherit;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    text-align:left;
  }
  
  @media (max-width:${breakpoints.sm}px) {
    padding-top: 0;
    padding-left: 6px;
    width: ${props => props.theme.expanded ? '22vw' : 'calc(100% - 30px)'}; ;

    &>div {
      width: 100%;
    }

  }
`

export const ItchSenderIcon = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;

  img {
    width: 36px;
  }
`

export const Name = styled.div`
  font-family: ${fonts.content.bold};
  font-size: 12px;
  line-height: 12px;
  color: ${props => getThemeTextColor(!props.expanded && props.alert, props.userTheme, props.muted)};
  opacity: ${props => props.muted ? .6 : 1 };
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  &:hover {
    text-decoration: underline;
  }


  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;  
  }

  @media (max-width:${breakpoints.sm}px) {
    padding-bottom:4px;
    width: inherit;
  }
`

export const AccountCategories = styled.div`
  display: ${props => props.theme.expanded ? 'block' : 'none'};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: ${colours.lightgrey2};
`

export const Status = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`

export const ProfileImage = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
`

export const LastMessage = styled.div`
  margin-top: 4px;
  font-family: ${fonts.content.regular}; 
  font-size: 12px;
  line-height: 13px;
  color: ${props => getThemeTextColor(props.alert, props.userTheme)};
  opacity: ${props => props.muted ? .6 : 1 };
  height: 26px;
  text-align:left;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  display: -webkit-box;   
  -webkit-line-clamp: 2;   
  -webkit-box-orient: vertical;     
  overflow: hidden; 
`

export const Counter = styled.div`
  font-family: ${fonts.content.bold};
  font-size: 10px;
  line-height: 10px;
  color: ${colours.pink};
  text-align: right;
  width: 14px;
  text-align: center;
`

export const Datetime = styled.div`
  margin-top: 4px;
  font-family: ${fonts.content.regular};
  font-size: 10px;
  line-height: 10px;
  color: ${colours.lightgrey5};
  opacity: ${props => props.muted ? .6 : 1 };
`

export const Banner = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow:hidden;
  width: 100%;
  height: 214px;
  
  .image-wrapper {
    width: 105%;
    height: 105%;
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    transform: translateX(-2.5%);
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .banner-overlay {
    display:block;
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 3;
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    background-color: ${props => props.muted ? colours.light_alpha60 : 'transparent'};
    opacity: ${props => props.muted ? .6 : 1 };
  }


  &:hover {
    .image-wrapper {
      transform: translateX(0%);
    }
  }


  @media screen and (max-width:${breakpoints.sm}px) {
    flex: 0 0 140px;
    height:inherit;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 6px;
      left: -12px;
      border-top: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid ${props => getUserThemeColorWithAlert(props.alert, props.userTheme)};
      border-left: 12px solid transparent;
    }

    .image-wrapper {
      height: inherit;
      img {
        transform: translateX(0);
      }
    }
  }
` 

export const EditButtonContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 5;
`

export const EditButton = styled(HoverIcon)`
  width: 32px;
  height: 32px;
  background-color: ${colours.light5_alpha08};
  border: 1px solid ${colours.white};
  &>img,
  img:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
  }

  
  &:hover {
    border-color: ${colours.pink};
  }

`

export const Location = styled.div`
  margin-top: 4px;
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height: 13px;
  color: ${colours.lightgrey2};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    display: block;
    padding-right: 5px;
  }
`
