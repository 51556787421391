import styled from 'styled-components'
import {
  fonts,
  colours
} from '../../styles/global.styles'

export const HeaderBanner = styled.div`
  background-image: url(${props => props.imageSrc});
  background-position: -3px 40px;
  background-size: cover;
  margin-top:45px;
  height: 330px;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 361px) {
    height: 420px;
    background-position: center center;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.title.xbold};
  font-size: 100px;
  line-height: 120px;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: center;
  color: ${colours.pink};
  width: 100%;
  max-width: 580px;
  text-align:left;
  padding-left:40px;
  padding-right: 40px;
  margin-bottom: 14px;

  @media screen and (min-width: 361px) {
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 120px;
    line-height: 144px;
  }
`;

export const PageContainer = styled.div`
  background-color: ${colours.darkerlight2};
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  
`;

export const BodyContent = styled.div`
  width: 100%;
  max-width: 580px;
  color: ${colours.darkergrey2};
  padding: 40px;
  ol {
    padding:0;
    margin:0;
    li {
      list-style-position: inside;
      text-align: left;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom:0;
      }

      &::marker {
        font-family: ${fonts.content.bold};
        font-size: 20px;
        line-height: 24px;
      }    

      h1 {
        font-family: ${fonts.content.bold};
        font-size: 20px;
        line-height: 24px;
        display:inline;
        padding:0;
      }

      &> div {
        margin-top: 10px;
        font-size: 16px;
        line-height: 19px;
        
        a {
          color: ${colours.pink};
          text-decoration: underline;
          font-weight: 700;
        }

        p {
          font-family: ${fonts.content.regular};
        }

        b {
          font-weight: 500;
        }
      }

      ol {
        margin-left: 20px;
        li {
          list-style: lower-latin;
          margin-bottom: 10px;
          list-style-position:inside;
          &::marker {
            font-family: ${fonts.content.bold};
            font-size: 16px;
          }

          &>b {
            display:inline-block;
            font-weight: 500;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 361px) {
    padding: 40px 0;

    ol {
      li {
        margin-bottom: 40px;
        &::marker {
          font-size: 20px;
        }
        
        h1 {
          font-size: 20px;
          line-height: 25px;
          display:inline;
          padding:0;
        }

        &> div {
          margin-top: 10px;
          font-size: 16px;
          line-height: 20px;
        
          p {
            font-size: 16px;
          }

          a {
            color: ${colours.pink};
          }
          
        }
      }
    }
  }
`;