import React, { useState, useEffect, useRef } from 'react'
import { 
  Container,
  AvatarContainer,
  InputContainer,
  ButtonContainer,
  SubmitCommentButton
} from './comment-input.styles'
import RichTextEditor from 'components/editor/editor'

import useGetProfile from 'hooks/get-profile.hook'

import Avatar from '../avatar/user-avatar'
import { imageUrl, stripHtml } from 'utils/utils'

import { $getRoot } from 'lexical'

const CommentInput = (props) => {
  const [ body, setBody ] = useState('')
  const { onSend, sending } = props
  const { success, loading, profile } = useGetProfile()
  const editorRef = useRef(null)
  
  const onSubmit = () => {
    console.log('submit comment', body)
    if (stripHtml(body).trim().length > 0) {
      onSend({
        body
      })
      
      editorRef.current.update(() => {
        const root = $getRoot();
        root.clear();
      });
    }
  }

  return (
    <Container className="comment-input-container">
      <AvatarContainer>
        {success && profile ? <Avatar 
          format="circle"
          url={imageUrl(profile.profileImageVariants?.thumb ?? profile.profileImage)}
          position={profile.profileImage?.position}
          accountType={profile.accountType}
        /> : null }
      </AvatarContainer>
      <InputContainer className="comment-input-container__inner">
        <RichTextEditor
          className="comment-body"
          placeholder={'Write your comment'} 
          disableToolbar={true} 
          maxLength={500}
          ref={editorRef}
          onChange={(data) => {
            setBody(data)
          }} />
        <ButtonContainer className="button-container">
          <SubmitCommentButton
            type="button"
            className="submit-comment" 
            loading={sending}
            onClick={onSubmit}
            style={{marginTop:'7px'}} active={true} >
            Comment
          </SubmitCommentButton>
        </ButtonContainer>
      </InputContainer>
    </Container>
  )
}

export default CommentInput