import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { 
  $getNodeByKey, 
  $createParagraphNode,
  $isTextNode,
  LexicalEditor,
  $isElementNode
} from 'lexical';
import {
  $isHeadingNode,
  $isQuoteNode,
} from '@lexical/rich-text'
import {
  $isCodeNode,
  $isCodeHighlightNode
} from '@lexical/code'
import {
  $isLinkNode
} from '@lexical/link'
import {$isDecoratorBlockNode} from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $getNearestBlockElementAncestorOrThrow,
} from '@lexical/utils'
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';

const SUPPORTED_ELEMENT_NODE_TYPES = ['heading', 'link', 'quote'];
const SUPPORTED_NODE_TYPES = ['text']

export default function ContentPastePlugin():null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener((listener) => {
      if (!listener.tags.has('paste')) return;
      
      editor.update(() => {
        /*
        listener.dirtyLeaves.forEach(key => {
          const node = $getNodeByKey(key);
          if (!node) return
          
          console.log(node.getType(), node.getTextContent(), $generateHtmlFromNodes(editor, node))
        })
        */
        listener.dirtyElements.forEach((flag, key) => {
          const node = $getNodeByKey(key);
          
          if (!node) return

          if ($isTextNode(node)) {
            if (node.__style !== '') {
              node.setStyle('');
            }
            if (node.__format !== 0) {
              node.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(node).setFormat('');
            }
          } else if ($isHeadingNode(node) || $isQuoteNode(node) 
          || $isCodeNode(node) || $isCodeHighlightNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        })
      
      });
    });
  }, [editor]);

  return null;
}
