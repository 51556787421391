import { useMutation, useQueryClient  } from '@tanstack/react-query';
import { updateEmailAlerts } from 'apis/email-alerts.api'
import useAuth from './auth.hook'

export default function useUpdateMyEmailAlerts() {
  const queryClient = useQueryClient ()
  const { session } = useAuth()
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return updateEmailAlerts(data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`my-email-alerts-${session?.profile?.id}`] })
    }
  })
  
  return {
    saveEmailAlerts: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}
