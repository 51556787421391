import styled from 'styled-components';
import {
  breakpoints,
  colours,
  fonts,
} from '../../styles/global.styles';
import {
  icons
} from '../../constants';

export const SubModalWrapper = styled.div`
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;

  @keyframes animateIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes animateOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: animateIn;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: foward;
  animation-iteration-count:1;

  .fade-when-mouse-off {
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    
    &.modal-body-fade {
      opacity: .5;
    }
  }

  &.closing {
    animation-name: animateOut;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    height:100%;
  }
`;

export const Header = styled.div`
  height: 44px;
  width: 100%;
  display: block;
  background-color: ${colours.darkerlight2};
  box-shadow: 0px 0px 3px 0px rgba(51, 51, 51, 0.2);
  position: relative;
  display:flex;
  justify-content:center;

  .header-wrapper {
    padding: 10px 11px 9px 19px;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .icon {
        margin-right: 5px;
    }

    .text {
      color: ${colours.pink};
      font-family: ${fonts.title.xbold};
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    box-shadow:none;
    height: 65px;
    flex: 0 0 65px;
    background-color: ${colours.darkerlight2_alpha90};
    
    .wrapper {
      max-width: 640px;
      width: 100%;
      .icon {
        margin-right: 10px; 
        img {
          width: 39px;
        }
      }
      .text {
        font-size: 14px;
      }
    }
  }
`;

export const Close = styled.div`
  position: fixed;
  z-index: 1300;
  top: 10px;
  right: 11px;
  cursor: pointer;
  flex: 0 0 24px;
  display:block;
  width: 24px;
  height: 24px;
  margin-left:auto;
  background-image: url(${icons['lightgrey-close.svg']});
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    top: 17px;
    right: 28px;
    margin-left: auto;
    flex: 0 0 30px;
    align-self: flex-start;
    width: 30px;
    height: 30px;
  }
`;

export const Body = styled.div`
  width: 100%;
  flex:1 1 auto;
  position: relative;
  background-color: ${colours.darkerlight2_alpha90};
  min-height: calc(100vh - 44px);
  height:fit-content;
  
  &>form {
    width: 100%;
    min-height: calc(100vh - 44px);
    height:fit-content;
    display:flex;
    flex-direction:column;

    &>.wrapper {
      position: relative;
      padding: 30px 20px;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items:center;
      height:fit-content;
      min-height: calc(100vh - 119px)
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    min-height: calc(100vh - 65px);

    &>form {
      min-height: calc(100vh - 65px);
      
      &>.wrapper {
        padding: 30px 50px;
        width: 100%;
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
        min-height:unset;
      }
    }
  }
`;

export const Footer = styled.div`
  background-color: ${colours.darkerlight2};
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  height: 75px;
  padding: 17px 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex: 0 0 75px;

  button:nth-child(n+1) {
    border-right:1px solid ${colours.lightgrey2};
  }

  button:last-child {
    margin-left: auto;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    margin-top:auto;
    align-self:flex-end;
    button:last-child {
      width: 270px;
    } 
  }
`;