import React, { useEffect, useRef, useState } from 'react'

import {
  ContentContainer,
  CloseButton,
  StickWrapper
} from './article-expanded.style'

import {
  icons
} from '../../../constants'

import Article from 'components/articles/article'
import { useWindowDimensions } from 'utils/helpers'

export default function ArticleExpanded({
  slug,
  onClose,
  theme,
  hideScroll
}) {
  const closeBtnElem = useRef()
  const containerElem = useRef()
  const stickWrapperElem = useRef()
  const { isMobile } = useWindowDimensions()

  const setCloseButton = () => {
    if (containerElem.current) {
      const containerBox = containerElem.current.getBoundingClientRect();
      const topnavElem = document.getElementById('top-nav')
      
      if (topnavElem && containerBox && closeBtnElem.current) {    
        const top = containerBox.top - topnavElem.clientHeight
        const bottom = top + containerElem.current.scrollHeight
        
        if (top <= 0 && bottom >= 0) {
          closeBtnElem.current.style.top = (-1 * top) + 'px'
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', setCloseButton)
    
    setCloseButton()

    return () => {
      window.removeEventListener('scroll', setCloseButton)
    }
  }, [])

  let closeIcon = 'close-grey.svg'
  if (theme === 'pink' || theme === 'dark') {
    closeIcon = 'close-white.svg'
  }

  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (containerElem.current && stickWrapperElem.current && closeBtnElem.current) {
        const topnavElem = document.getElementById('top-nav') || document.getElementById('public-menu');
        // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const parentTop = -1 * containerElem.current.getBoundingClientRect().top;
        const parentHeight = containerElem.current.clientHeight;
        
        const stickWrapperHeight = closeBtnElem.current.offsetHeight;

        const fromTop = (parentTop + topnavElem.clientHeight + stickWrapperHeight + 30) <= parentHeight ? (parentTop + topnavElem.clientHeight) : (parentHeight - stickWrapperHeight - 30);
        const top = fromTop > 0 ? fromTop : 0

        stickWrapperElem.current.style.top = `${top}px`;
      }
    };
    
    setParentHeight(containerElem.current.offsetHeight);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {<ContentContainer ref={containerElem}>
        <StickWrapper ref={stickWrapperElem}>
          <CloseButton 
            theme={theme}
            ref={closeBtnElem}
            onClick={() => {
              onClose()
            }}>
            <img src={icons[closeIcon]} alt="close" />
          </CloseButton>
        </StickWrapper>
        <Article slug={slug} onClose={onClose} expanded={true} hideScroll={hideScroll} />
      </ContentContainer>}
    </>
  )
}