import React, { useState } from 'react';
import styled from 'styled-components';
import { 
  themes,
  colours,
  fonts,
  breakpoints,
  ButtonReset,
  BaseImage
} from '../../styles/global.styles';
import { 
  icons,
  AccountTypes,
  CompanyTypes,
  getAccountTypeMeta
} from '../../constants';
import {
  nameConcat
} from '../../utils/helpers';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 214px;
  display:flex;
  justify-content: center;

  @media screen and (max-width:${breakpoints.sm}px) {
    height: 225px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1160px;
  display:flex;
  flex-direction: row;
  padding: 20px 20px;
  position: absolute;
  z-index: 2;

  @media screen and (max-width:${breakpoints.sm}px) {
    padding: 15px 15px 10px 15px;
    flex-direction:column;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1160px;
  background: ${props => props.theme === themes.Flare ? 'rgba(162, 10, 72, 1)' : 'rgba(63, 62, 60, 1)'};
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
`;

export const AccountInfoAvatarWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  
  @media screen and (max-width:${breakpoints.sm}px) {
    flex-direction:row;
  }
`;

const getIconUrl = (accountType, theme, desktop) => {
  const size = desktop ? '50': '28';
  switch(accountType) {
    case AccountTypes.FashionArtist:
      return theme === themes.Flare ? icons['fa-icon-' + size + '-light.svg'] : icons['fa-icon-' + size + '-pink.svg'];
    case AccountTypes.FashionCompany:
      return theme === themes.Flare ? icons['fc-icon-' + size + '-light.svg'] : icons['fc-icon-' + size + '-pink.svg'];
    case AccountTypes.FashionLover:
      return theme === themes.Flare ? icons['fl-icon-' + size + '-light.svg'] : icons['fl-icon-' + size + '-pink.svg'];
    default:
      return '';
  }
}

export const AccountTypeIcon = styled.div`
  flex: 0 0 50px;
  
  &:before {
    content: url(${({ accountType, theme}) => getIconUrl(accountType, theme, true) });
  }

  @media screen and (max-width:${breakpoints.sm}px) {
    flex: 0 0 28px;
    
    &:before {
      content: url(${({ accountType, theme}) => getIconUrl(accountType, theme, false) });
    }
  }
`;

export const Info = styled.div`
  flex: 0 1 532px;
  display:flex;
  flex-direction:column;
  padding: 15px 15px 0 15px;
  width: 100%;
  
  @media screen and (max-width:${breakpoints.sm}px) {
    flex: 1 1 40%;
    padding-top: 5px;
    padding-left: 15px;
  }
`;

export const BaseThemedText = styled.div`
  color: ${props => props.theme === themes.Flare ? colours.flare.text : colours.metro.text};
`;

export const BaseThemedTitle = styled.div`
  color: ${props => props.theme === themes.Flare ? colours.flare.title : colours.metro.title};
`;

export const Name = styled(BaseThemedTitle)`
  font-family: ${fonts.content.bold};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (max-width:${breakpoints.sm}px) {
    font-size: 15px;
    line-height: 15px; 
    max-height: 31px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AccountTypeProfessions = styled(BaseThemedText)`
  color: ${props => props.theme === themes.Flare ? colours.flare.text : colours.metro.text};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-top:2px;

  
  &:hover {
    &>span:first-child {
      color: ${colours.pink};
    }
  }


  &>.profession {
    text-transform: capitalize;
  }
  
  &>.profession:after {
    content: ', ';
  }

  &>.profession:nth-child(n):after {
    content: ', ';
  }
  
  &>.profession:last-child:after {
    content: '';
  }
  
  &>.profession:hover {
    color: ${colours.pink};
    cursor: pointer;
  }
  
  @media screen and (max-width:${breakpoints.sm}px) {
    line-height: 13px;
    margin-top:5px;
  }
`;

export const CompanyType = styled(BaseThemedText)`
  margin-top:2px;
  color: ${props => props.theme === themes.Flare ? colours.flare.text : colours.metro.text};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (max-width:${breakpoints.sm}px) {
    line-height: 13px;
    margin-top:5px;
  }
`;

export const Location = styled.div`
  margin-top: 3px;
  display:flex;
  justify-content:flex-start;
  cursor:pointer;


  &:hover {
    * {
      color: ${colours.pink};
    }
    .location-icon {
      &>img:first-child {
        opacity:0;
      }  
      &>img:last-child {
        opacity:1;
      }  
    }
  }


  @media screen and (max-width:${breakpoints.sm}px) {
    margin-top: 5px;
  }
`;

export const LocationPinIcon = styled.div`
  width: 8px;
  height: 12px;
  position:relative;
  top:-3px;

  .location-icon {
    width: 8px;
    height: 12px;
    display:inline-block;
  }
`;

export const LocationName = styled(BaseThemedText)`
  margin-left:5px;
  color: ${props => props.theme === themes.Flare ? colours.flare.text : colours.metro.text};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  transition-duration:.3s;
  transition-timing-function: ease-in-out;
`;

export const FashionStatement = styled(BaseThemedText)`
  margin-top:15px;
  padding-top:17px;
  display:flex;
  flex-direction:row;
  border-top: 1px solid ${props => props.theme === themes.Flare ? colours.flare.text : colours.pink};
  overflow:hidden;
  
  @media screen and (max-width:${breakpoints.sm}px) {
    display:none;  
  }
`;

export const FashionStatementContent = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  flex: 1 1 auto;
  padding-right: 15px;

  a {
    color:${colours.pink};
  }
  
  @media screen and (max-width:${breakpoints.sm}px) {
    padding-right: 24px;
    display: flex;
    align-items:center;
    position: relative;
  }
`;

export const TextTruncate = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExpandButton = styled(ButtonReset)`
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  align-self:flex-end;

  @media screen and (max-width:${breakpoints.sm}px) {
    position: absolute;
  }
`;

export const MobileSection = styled.div`
  margin-top: auto;
  margin-left: 43px;
  margin-right: 43px;
  display:none;
  
  @media screen and (max-width:${breakpoints.sm}px) {
    display:flex;
    flex-direction:column;
    flex: 1 1 auto;
    height: 47%;
  }
`;

export const FashionStatementMobile = styled(BaseThemedText)`
  display:none;
  border-top: 1px solid ${props => props.theme === themes.Flare ? colours.flare.text : colours.pink};
  padding-top:10px;
  flex-direction:column;

  @media screen and (max-width:${breakpoints.sm}px) {
    display:flex;
  }
`;

export const FollowingActionsContainer = styled.div`
  display:flex;
  flex-direction: row;
  align-items:flex-end;
  
  @media screen and (max-width:${breakpoints.sm}px) {
    display:none;
  }
`;

export const FollowingActionsContainerMobile = styled(FollowingActionsContainer)`
  display:none;
  @media screen and (max-width:${breakpoints.sm}px) {
    display:flex;
    flex-direction:row;
    margin-top:auto;
    width: 100%;
    justify-content: space-between;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    border-right: 1px solid ${props => props.theme === themes.Flare ? colours.light : colours.pink};
    padding-right: 22px;
    margin-right: 20px;
    align-items: flex-end;
    height: 42px;
    width: 100%;
  }
`;

export const GuestActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: unset;

  &>div:first-child {
    margin-right: 35%;
  }

  .follow-widget,
  .chat-widget {
    &>span {
      &>div.icon {
        position:relative;
        top:-2px;
      }
      &>div.label {
        width: 100%;
        color: ${colours.light};
        font-family: ${fonts.content.regular};
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: center;
        display:block;
        margin-top: 5px;
        margin-left: 0;
        margin-right: 0;
        order: 2;
        visibility: visible;
        text-transform: capitalize;
      }
    }
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 18px;
    height: 100px;

    &>div:first-child {
      margin-right: 0;
    }

    &>div:last-child {
      margin-right: 0;
    }

    .follow-widget,
    .chat-widget {
      
      &>span {
        position:relative;

        &>div.label {    
          position:absolute;
          top: -8px;
          left: -100%;
          width: 80px;
          transform: translateX(-100%);
          font-size: 14px;
          font-family: ${fonts.title.xbold};
          line-height: 17px;
          text-align:right;
          text-transform: lowercase;
          visibility: hidden;
        }
      }

      
      &:hover {
        &>span {
          &>div.label {
            visibility: visible;
            color: ${colours.pink};
          }
        }
      }
    
    }

    .follow-widget {
      width:20px;
      height:20px;
      margin-bottom: 6px;

      &>div.icon {
        width: 18px;
      }
    }

    .chat-widget {
      &>div.icon {
        width: 20px;
      }
    }
  }
`;

export const ActionButton = styled(ButtonReset)`
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;

  &>span.label {
    color: ${colours.light};
    font-family: ${fonts.content.regular};
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    display:block;
    margin-top: auto;
    order: 2;
  }
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    flex-direction: row;

    &>span.label {
      display:none;
      color: ${colours.light};
      font-family: ${fonts.title.xbold};
      text-align: right;
      font-size: 14px;
      font-weight: 800;  
      line-height: 17px;
      margin-right: 7px;
      order: 1;
    }
    
    &:hover {
      &>span.label {
        color: ${colours.pink};
        display: inline-block;
      }
    }
  
  }
`;

export const EditButton = styled(ActionButton)`
  width: 46px;
  height: 37px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  justify-content: center;
  align-items: center;

  &>span.label {
    display:block;
    color: ${colours.light};
    font-family: ${fonts.content.regular};
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    margin:0;
  }
  &>.edit-icon {
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
    margin-left:auto;
    margin-right:auto;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    width: 22px;
    height: 22px;
    margin-right: unset;
    &>span.label {
      display: none;
    }
    &>.edit-icon {
      width: 22px;
      height: 22px;
    }
    
    &:hover {
      &>span.label {
        display: none;
      }
    }
  
  }
`;

export const FollowButton = styled(ActionButton)`
  height: 37px;

  .follow-widget {
    width: 20px;
    height: 16px;
  }
  
  
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    height: 16px;
    margin-bottom: 10px;
    &:after {
      order:2;
    }
  }
`;

export const ChatButton = styled(ActionButton)`
  height: 37px;
  width: 60px;

  .chat-widget {
    width: 20px;
    height: 16px;
  }

  
  &:hover {
    
  }


  @media screen and (min-width:${breakpoints.sm + 1}px) {
    height: 16px;
    width: unset;
    margin-bottom: 2px;
    &:after {
      order:2;
    }
  }
`;

export const TextCounter = styled(BaseThemedText)`
  color: ${colours.text};
  font-family: ${fonts.content.bold};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  
  &:hover {
    color: ${colours.pink};
  }


  @media screen and (max-width:${breakpoints.sm}px) {
    font-size: 13px;
    line-height: 13px;
  }
`;

export const StatLabel = styled(BaseThemedText)`
  border-top: 1px solid ${props => props.theme === themes.Flare ? colours.text : colours.pink};
  margin-top: 4px;
  color: ${props => props.theme === themes.Flare ? colours.text : colours.pink};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-top:6px;

  @media screen and (max-width:${breakpoints.sm}px) {
    font-size: 10px;
    line-height: 13px;
    margin-top: 5px;
    padding-top: 5px;
  }
`;

export const StatBox = styled.div`
  display:flex;
  flex-direction:column;
  padding: 0 10px;
  cursor:pointer;

  @media screen and (max-width:${breakpoints.sm}px) {
    padding-left: 17.5px;
    padding-right: 17.5px;

    &:last-child {
      padding-right: 0;
    }
  }
`;

export const UserAvatarWrapper = styled.div`
  flex: 0 0 174px;
  width: 174px;
  height: 174px;

  @media screen and (max-width:${breakpoints.sm}px) {
    display:none;
  }
`;

export const UserAvatar = styled.img`
  width: 100%;
  height: 100%;
  display:block;
  object-fit: cover;
  object-position: ${props => props.position};
`;

export const UserAvatarMobileWrapper = styled.div`
  flex: 0 0 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
`

export const UserAvatarMobile = styled.img`
  display:none;

  @media screen and (max-width:${breakpoints.sm}px) {
    display:block;
    width:100%;
    object-fit: cover;
    object-position: ${props => props.position};
  }
`;
