import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useWindowDimensions, useUserStatus, useArtworkArticleStatus, ScrollToArticle, WaitForClose } from '../../utils/helpers';
import { itchArticleTextColor, blackText, pinkText } from './commons'
import Emitter from '../../utils/emitter';
import CloseFeatureButton from './close-feature-button'
import ItchArticleCover from './itch-article-cover'
import { ItchArticleDetails } from './itch-article-details';
import AdditionalContent from './additional-content'
import { format } from 'date-fns'
import Tags from './tags'
import Comment from 'components/comment/comment';

import useGetItchArticle from 'hooks/get-itch-article.hook';

import {
    imageUrl,
    ensureLinks,
    getName,
    forceLinksBlankTarget
} from 'utils/utils'

import {
    AuthorSection,
    AuthorInfoArticleDate,
    AuthorLink,
    AuthorCategories,
    Sharing,
    ButtonToPastItchArticles,
    ButtonBottomToPastItchArticles
} from 'components/articles/article.styles'
import AccountTypeCategories from 'components/shared/account-type-categories'
import Share from 'components/shared/share'
import { useZine } from './zine.context';
import SignUpWidget from 'components/shared/sign-up-widget/sign-up-widget'
import {
    icons
} from '../../constants'
import { usePageReady } from 'contexts/page-ready.context';

export function useNavHeight() {
    const loggedIn = !!useUserStatus();
    const { windowWidth } = useWindowDimensions();
    let navHeight = 0;
    if (windowWidth <= 1030) {
        navHeight = 45;
    }
    else {
        navHeight = loggedIn ? 65 : 73;
    }
    return navHeight;
}

export default function ItchArticle({
    slug = null,
    opened = false,
    defaultOpen = false
}) {
    const { setReady } = usePageReady()
    const { success, itchArticle, loading } = useGetItchArticle(slug)
    const { 
        openSection, openedSectionName, 
        Sections, setLoadedComponent, setClosed 
    } = useZine()
    
    // const history = useHistory();
    const { windowWidth, isMobile } = useWindowDimensions();
    const { 
        artworkIsClosed, itchArticleIsClosed, userArticleIsClosed, 
        artworkAllowed, itchArticleAllowed, userArticleAllowed 
    } = useArtworkArticleStatus();
    const maxHeight = 1332;
    let a = itchArticleTextColor[itchArticle?.theme ?? 'pink'];
    let navHeight = useNavHeight();

    const [scrollAreaHeight, setScrollAreaHeight] = useState(0);
    const [open, setOpen] = useState(defaultOpen);

    const itchSpaceRef = useRef();
    const articleRef = useRef();
    const articleCoverRef = useRef();

    const shareUrl = `${window.location.protocol}//${process.env.REACT_APP_HOST}/itch-articles/${itchArticle?.slug}`

    const setConditionalHeight = () => {
        let height = null;
        if (defaultOpen) {
            if (articleCoverRef.current) {
                height = maxHeight - articleCoverRef.current.clientHeight;
            }
        }
        else {
            if (!isMobile) {
                if (open) {
                    height = scrollAreaHeight;
                }
                else height = 0;
            }
        }
        return height;
    }

    const openArticle = () => {
        if (!open) {
            console.log('emit open article');
            Emitter.emit('openItchArticle');            
        }
    }

    const waitForScroll = async () => {
        // scroll to article
        console.log('scrolling to article');
        const finishScrolling = await ScrollToArticle(itchSpaceRef, navHeight);
        console.log('finishScrolling', finishScrolling)
        // check if scroll is finished
        if (finishScrolling) {
            setOpen(true);
            if (articleCoverRef.current) {
                setScrollAreaHeight(maxHeight - articleCoverRef.current.clientHeight);
                Emitter.emit('itchArticleIsOpened');
                openSection(Sections.ItchArticle)
            }
        }
    }

    const closeArticle = async (closeWithinArticle) => {
        if (open) {
            setScrollAreaHeight(0);
            // restart itch article scroller when closed
            if (articleRef.current) {
                articleRef.current.scrollTop = 0;
                setOpen(false);
                console.log('waiting to close itch article');
                const finishClosing = await WaitForClose(articleCoverRef.current.clientHeight, itchSpaceRef);
                if (finishClosing) {
                    console.log('emit itch is closed');
                    Emitter.emit('itchArticleIsClosed');
                    setClosed(Sections.ItchArticle)
                    if (closeWithinArticle) Emitter.emit('closeWithin');
                }
            }
        }
    }

    useEffect(() => {
        if (articleCoverRef.current) {
            console.log('defaultOpen', defaultOpen, itchArticleAllowed)
            if (defaultOpen) setScrollAreaHeight(maxHeight - articleCoverRef.current.clientHeight);
            else {
                if (itchArticleAllowed) waitForScroll();
                else closeArticle();
            }
            // eslint-disable-next-line
        }
    }, [itchArticleAllowed])

    useEffect(() => {
        if (success) {
            setLoadedComponent(Sections.ItchArticle)
            setReady(true)
        }
        if (success && opened && !defaultOpen) {
            setOpen(true)
            openArticle()
        }
    }, [success])

    useEffect(() => {
        if (!defaultOpen && openedSectionName !== Sections.ItchArticle) {
            closeArticle(true)
        }
    }, [openedSectionName])
    

    return (
        <>{(success && itchArticle._id) ?
        <div
            id="itch-article"
            ref={itchSpaceRef}
            className={"article-space itch-space " + (open ? 'open' : '')}
            onClick={openArticle}
        >
            {!defaultOpen &&
                <div className="close-feature-div position-absolute">
                    <CloseFeatureButton
                        src={a.closeIcon}
                        addClass={a.class}
                        passCloseSection={() =>  {
                            closeArticle(true)}
                        }
                        hide={!open}
                    />
                </div>
            }
            <div ref={articleRef} className={a.class + " itch-article " + (isMobile ? 'overflow-y-auto no-scroller' : '')}>
                <div ref={articleCoverRef} className="article-cover-height">
                    <ItchArticleCover open={open} position={itchArticle?.coverImage.position ?? 'center center'} 
                        src={imageUrl(itchArticle?.coverImageVariants?.medium ?? itchArticle?.coverImage) ?? ''} />
                </div>
                <div className="before-tint" />
                <ItchArticleDetails 
                    open={false}
                    theme={itchArticle?.theme}
                    title={itchArticle?.title} 
                    subHead={itchArticle?.subHead}
                    category={itchArticle?.category}
                />
                <div className="scroll-area position-relative" style={{ height: setConditionalHeight() }}>
                    <div className="image-caption color-3c">{itchArticle?.caption}</div>
                    <div className={"scroll-area-inner " + (windowWidth < 1030 && !isMobile ? 'no-scroller overflow-y-auto' : '')}>
                        <div className={"right-text-area text-left " + (windowWidth >= 1030 ? "position-absolute right-0" : "w-100 position-relative")}>
                            <ItchArticleDetails 
                                open={true} 
                                theme={itchArticle?.theme}
                                title={itchArticle?.title}
                                slug={itchArticle?.slug} 
                                subHead={itchArticle?.subHead}
                                category={itchArticle?.category}    
                            />
                            <AuthorSection className="author-section-share">
                                <AuthorInfoArticleDate>
                                    <div>
                                        By <b>Itch</b> 
                                        {itchArticle.itchArticleMentionUser ?
                                        <>
                                        &nbsp; / <AuthorLink to={`/${itchArticle.itchArticleMentionUser.itchUrl}`}>{getName({
                                        accountType: itchArticle.itchArticleMentionUser.accountType,
                                        firstName: itchArticle.itchArticleMentionUser.firstName,
                                        lastName: itchArticle.itchArticleMentionUser.lastName,
                                        companyName: itchArticle.itchArticleMentionUser.companyName
                                        })}</AuthorLink>
                                        <AuthorCategories>
                                        <AccountTypeCategories {...itchArticle.itchArticleMentionUser} />
                                        </AuthorCategories>
                                        </> : null }
                                    </div>
                                    <div>{format(new Date(itchArticle.createdAt), 'MMMM d yyyy')} at {format(new Date(itchArticle.createdAt), 'h:mma')}</div>
                                </AuthorInfoArticleDate>
                                <Sharing className="sharing-top">
                                    <Share url={shareUrl} theme="black" />
                                </Sharing>
                            </AuthorSection>

                            {!isMobile ? <ButtonToPastItchArticles to={'/archives/itch-articles'}>
                                <span className="icon">
                                    <img src={icons['line-arrow-right-black.svg']} alt=""/>
                                    <img src={icons['line-arrow-right-pink.svg']} alt=""/>
                                </span>
                                Previously on&nbsp;<b>itch</b>
                            </ButtonToPastItchArticles> : null}
                            
                            {false && <AdditionalContent
                                occupation={""}
                                profileLink={`/profiles${itchArticle.itchArticleMentionUser.itchUrl}`}
                                author={getName(itchArticle.itchArticleMentionUser)}
                                date={format(new Date(itchArticle?.createdAt), 'd MMMM yyyy')}
                                states={{
                                    normal: blackText,
                                    hovered: pinkText
                                }}
                            />}
                        </div>
                        <div className={"left-text-scroll text-left " + (windowWidth >= 1030 ? "position-absolute left-0 no-scroller overflow-y-auto" : "w-100 position-relative")}>
                            <div className="body-text" dangerouslySetInnerHTML={{__html: ensureLinks(itchArticle?.content)}} ></div>
                            <Tags tags={itchArticle?.tags} />
                            {itchArticle.itchArticleMentionUser ? <AdditionalContent
                                user
                                theme={itchArticle.theme ?? 'dark'}
                                shareUrl={shareUrl}
                                occupation={""}
                                profileLink={`/profiles${itchArticle.itchArticleMentionUser?.itchUrl}`}
                                author={getName(itchArticle.itchArticleMentionUser)}
                                date={format(new Date(itchArticle?.createdAt), 'MMMM d, yyyy')}
                                states={{
                                    normal: blackText,
                                    hovered: pinkText
                                }}
                            >
                                at {format(new Date(itchArticle?.createdAt), 'h:mm b')}
                            </AdditionalContent> : null}
                            
                            <ButtonBottomToPastItchArticles>
                                <ButtonToPastItchArticles to={'/archives/itch-articles'}>
                                    <span className="icon">
                                        <img src={icons['line-arrow-right-black.svg']} alt=""/>
                                        <img src={icons['line-arrow-right-pink.svg']} alt=""/>
                                    </span>
                                    Previously on&nbsp;<b>itch</b>
                                </ButtonToPastItchArticles>
                            </ButtonBottomToPastItchArticles>

                            <SignUpWidget/>
                            
                            <Comment id={itchArticle?._id} contentType={'articles'}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : null}
        </>
    )
}
