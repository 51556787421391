import { forwardRef } from "react"

export default forwardRef(function ExpandFeatureButton({ src, addClass, passTriggerModal, hide }, ref) {
  return (
    <div
      ref={ref} 
      id="expand-feature"
      className={"expand-feature d-flex justify-content-center align-items-center " + (addClass ? addClass : "")}
      onClick={() => passTriggerModal()}
      style={{ visibility: hide ? 'hidden' : 'visible' }}
    >
      <img src={src} className="align-self-center" alt="" />
    </div>
  )
})