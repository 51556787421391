import React, { useRef } from 'react';
import Footer from 'components/shared/footer'
import {
  Container,
  Logo,
  Heading,
  CallToAction,
  ViewArchiveLink
} from './zine-footer.styles'
import {
  icons
} from '../../constants'

export default function ZineFooter({onViewArchive}) {
  
  return (
    <Container id="zine-footer">
      <Logo>
        <img src={icons['logo-size-111px-colour=ec0f69.svg']} alt="" />
      </Logo>
      <Heading>
        <b>www.itch.me</b><br/>
        Your decentralised fashion channel
      </Heading>
      <CallToAction to='/signup'><b>Join us</b> its free!</CallToAction>
      <ViewArchiveLink rel="nofollow"  onClick={() => {
        onViewArchive()
      }}>View<br/>Archive</ViewArchiveLink>
      <Footer/>
    </Container>
  )
}