import React from 'react'
import styled from 'styled-components'
import AccountTypeCategories from 'components/shared/account-type-categories'
import {
  fonts,
  colours
} from 'styles/global.styles'

const Container = styled.div`
  display:inline-block;

  .account-type-categories {
    color: ${colours.black};
    font-family: ${fonts.content.regular};
    font-size: 14px;
    line-height: 18px;
  }
`

export default function OwnerAccountTypes(props) {
  
  return (
    <Container>
      <AccountTypeCategories {...props} />
    </Container>
  )
}