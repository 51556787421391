import React, { useState, useRef, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'
import HoverIcon from '../shared/hover-icon'

const Container = styled.div`
  padding: 22px 22px 22px 22px;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: 3px;
  position: relative;

  &>div.inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding:0;
  }
`

const InputContainer = styled.div`
  padding: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
  display: ${props => props.visible ? 'block':'none'};

  &:focus-within {
    .hint {
      visibility: hidden;
    }
  }
`

const Delete = styled.div`
  position: absolute;
  top: 5px;
  right:5px;
  z-index: 7;
`

const Input = styled.textarea`
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.lightgrey2};
  padding: 10px;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  resize: none;
  box-sizing: border-box;
  overflow: hidden;
  border:none;
  border:2px solid ${colours.text};

  &:focus {
    color: ${colours.white};
  }
`

const Poster = styled.div`
  width: 100%;
  height: 100%;
  visibility: ${props => props.visible ? 'visible':'hidden'};

  img {
    display:block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const ImageEye = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 12px;
  justify-content: center;
  overflow: hidden;
  display: none;

  &.on-drag {
    img {
      cursor: grabbing;
    }
  }

  .eye-drag {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>img {
      cursor: grab;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &:hover{
        background-color: ${colours.pink_alpha50};
        cursor: grab;
      }
      &:active,
      &:focus,
      &:active:hover {
        background-color: ${colours.pink_alpha50};
        cursor:none !important;
      }

      &[src=""] {
        display: none;
      }
    }
    
    &>span {
      position: absolute;
      top:100%;
      transform: translateY(5px);

      padding: 5px 30px;
      color: ${colours.pink};
      font-family: ${fonts.title.xbold};
      font-size: 12px;
      font-weight: 800;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: center;
      background-color: ${colours.dark_alpha70};

      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: .2s;
      white-space: nowrap;

      opacity: ${props => props.touchDrag ? 0:1};

      &:before {
        content: '';
        display:block;
        position: absolute;
        top:-8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent ${colours.dark_alpha70} transparent;
      }
    }
  }

  &:active {
    &>span {
      opacity: 0;
    }
  }
`;

const Hint = styled.div`
  color: ${colours.grey};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  top:0px;
  z-index: 0;
  padding:10px;
  width: 80%;
  visibility: ${props => props.visible ? 'visible':'hidden'};
`

const EyeAxis = {
  Horizontal: 1,
  Vertical: 2
}

const getImageEyeAxis = (previewDimensions, imageDimensions) => {
  let eyeAxis = null
  const imageRatio = imageDimensions[0] / imageDimensions[1];

  // compare the dimensions
  const imageDimensionsScaled = [previewDimensions[0], previewDimensions[0] / imageRatio];
  
  if (imageDimensionsScaled[1] >= previewDimensions[1]) {
    eyeAxis = EyeAxis.Vertical;
  } else {
    eyeAxis = EyeAxis.Horizontal;
  }

  return eyeAxis;
}

export default forwardRef(function InputVideoLinkPreview(
  props,
  ref
) {
  const {
    value,
    disabled,
    onChange,
    onBlur,
    ...rest   
  } = props

  const [touchDrag, setTouchDrag] = useState(false)

  const eyeElem = useRef(null);
  const eyeDraggableElem = useRef(null);
  const previewElem = useRef(null);

  const [url, setUrl] = useState('')
  const [posterUrl, setPosterUrl] = useState('')
  const [uploadCount, setUploadCount] = useState(0)
  const [isDragging, setIsDragging] = useState(false);

  function dragElement(elmnt, followElmnt) {
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let eyeAxis = null;
    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      pos1 = 0;
      pos2 = 0;
      pos3 = 0;
      pos4 = 0;
      // get the image orientation
      eyeAxis = Number(followElmnt.getAttribute('data-eye-axis'));
      
      setTouchDrag(true)
      setIsDragging(true);

      e = e || window.event;
      e.preventDefault();
      e.stopPropagation();
      // get the mouse cursor position at startup:
      if (eyeAxis === EyeAxis.Horizontal) {
        pos3 = e.clientX;
      }
      if (eyeAxis === EyeAxis.Vertical) {
        pos4 = e.clientY
      }

      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;

      eyeElem.current.classList.add('on-drag')
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();

      const dragHintElem = eyeElem.current.querySelector('.drag-hint')
      if (dragHintElem) {
        dragHintElem.style.visibility = 'hidden'
      }

      document.body.style.cursor = 'grabbing'

      // calculate the new cursor position:
      if (eyeAxis === EyeAxis.Horizontal) {
        pos1 = pos3 - e.clientX;
        pos3 = e.clientX;
      }

      if (eyeAxis === EyeAxis.Vertical) {
        pos2 = pos4 - e.clientY;
        pos4 = e.clientY;
      }
      // set the element's new position:
      const newTop = elmnt.offsetTop - pos2;
      const newLeft = elmnt.offsetLeft - pos1;

      // check bounds, cannot exceed parent bounding rect
      const elmntBox = elmnt.getBoundingClientRect()
      const { width, height } = elmnt.parentElement.getBoundingClientRect();
      
      if (newTop >= 0 && newTop <= (height - elmntBox.height)
        && newLeft >= 0 && newLeft <= (width - elmntBox.width)) {
          elmnt.style.top = newTop + "px";
          elmnt.style.left = newLeft + "px";
    
          const pX = 100 * newLeft / width;
          const pY = 100 * newTop / height;
    
          if (followElmnt) {
            const position = `${pX}% ${pY}%`
            followElmnt.style.objectPosition = position;
          }
      }
    }

    function closeDragElement(evt) {
      document.body.style.cursor = 'default'

      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
      // because the event bubbling continues after this
      // causes the file input to trigger the browse file

      if (props.onChange)
        props.onChange({
          url,
          posterUrl,
          position: followElmnt.style.objectPosition
        })

      onBlur && onBlur()

      eyeElem.current.classList.remove('on-drag')
      
      setTimeout(() => {
        setIsDragging(false)
        
      }, 500)
    }
  }

  const setupEye = (url) => {
    const img = new Image()
    let eyeAxis = null

    img.onload = function () {
      previewElem.current.setAttribute('data-width', this.width);
      previewElem.current.setAttribute('data-height', this.height);

      // find the axis for adjusting eye based on cover rule
      const previewDimensions = [previewElem.current.width, previewElem.current.height];
      const imageDimensions = [this.width, this.height];
      eyeAxis = getImageEyeAxis(previewDimensions, imageDimensions)
      
      previewElem.current.setAttribute('data-eye-axis', eyeAxis)
    };

    img.src = url

    return eyeAxis
  }

  const isValidVideoUrl = (url) => {
    if (url.indexOf('https://youtu.be/') ===0) {
      return true
    }
    if (url.indexOf('https://www.youtube.com/') === 0 && url.indexOf('?') > 0) {
      return true
    } else
    if (url.indexOf('https://vimeo.com/') === 0) {
      return true
    } else
      return false
  }

  const getVideoPosterUrl = (url) => {
    let getPosterUrl = null
    // check if its youtube or vimeo based on url schema get poster
    if (url.indexOf('https://youtu.be/') === 0) {
      /*
        https://youtu.be/yycVNcishrE?si=vJB25G9VEu5d5yzs
      */
      let video_id = url.split('?')[0].split('/')[3]
      
      if (video_id !== '') {
        getPosterUrl = 'https://img.youtube.com/vi/' + video_id + '/hqdefault.jpg'
        setPosterUrl(getPosterUrl)
        setupEye(getPosterUrl)
      }
    } else
    if (url.indexOf('https://www.youtube.com/') === 0 && url.indexOf('?') > 0) {
      let video_id = url.split('?')[1].split('v=')[1];
      const ampersandPosition = video_id.indexOf('&');
      if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }

      if (video_id !== '') {
        getPosterUrl = 'https://img.youtube.com/vi/' + video_id + '/hqdefault.jpg'
        setPosterUrl(getPosterUrl)
        setupEye(getPosterUrl)
      }
    } else
    if (url.indexOf('https://vimeo.com/') === 0) {
      const parts = url.split('?')[0].split('/')
      let video_id = parts[parts.length - 1];
      if (video_id !== '') {
        let apiUrl = `https://vimeo.com/api/v2/video/${video_id}.json`

        fetch(apiUrl)
          .then(res => res.json())
          .then(data => {
            getPosterUrl = data[0].thumbnail_large
            setPosterUrl(getPosterUrl)
            setupEye(getPosterUrl)
          })
      }
    }
  }

  const setup = (value) => {
    const { posterUrl, position } = value ?? null
    previewElem.current.src = posterUrl
    // show the eye element if there is a file passed thru props
    if (url) {
      eyeElem.current.style.display = 'flex';
      dragElement(eyeDraggableElem.current, previewElem.current, value)
      
      const eyeAxis = setupEye(posterUrl)

      previewElem.current.style.objectPosition = position ?? '50% 50%';

      if (position) {
        const dimensions = [
          eyeDraggableElem.current.height,
          eyeDraggableElem.current.width
        ];
        const positionsValues = position.split(' ').map((v, i) => Number(v.replace('%', '')) * dimensions[i]);
        if (eyeAxis === EyeAxis.Horizontal) {
          eyeDraggableElem.current.style.left = positionsValues[0];
        }
        if (eyeAxis === EyeAxis.Vertical) {
          eyeDraggableElem.current.style.top = positionsValues[1];
        }
      }
    }
  }

  useEffect(() => {
    if (value) {
      setup(value)
    }
  }, [])

  useEffect(() => {
    if (posterUrl.length > 0) {
      previewElem.current.src = posterUrl
        
      eyeElem.current.style.display = 'flex';
      // reset eye position
      eyeDraggableElem.current.style.top = 'unset';
      eyeDraggableElem.current.style.left = 'unset'; 

      setUploadCount(uploadCount + 1)

      dragElement(eyeDraggableElem.current, previewElem.current)
      
      if (props.onChange)
        props.onChange({
          url,
          posterUrl
        })
    }
  }, [posterUrl, url])

  useEffect(() => {
    if (value && eyeDraggableElem.current) {
      setPosterUrl(value.posterUrl)
      setUrl(value.url)

      setup(value)
    }
  }, [value])

  return (
    <Container>
      <Delete 
        onClick={() => {
          setUrl('')
          setPosterUrl('')
          eyeElem.current.removeAttribute('style')
        }}
      >
        <HoverIcon
          active={icons['pink-delete.svg']}
          hover={icons['white-delete.svg']}
        />
      </Delete>
      <div className="inner">
        {!disabled ? <InputContainer visible={url === ''}>
          <Input
            defaultValue={''}
            onChange={(evt) => {
              const url = evt.target.value
              console.log(evt)
              if (isValidVideoUrl(url)) {
                setUrl(url)
                getVideoPosterUrl(url)
              }
            }}
          />
          {url === '' ? <Hint className="hint">Paste in your video link from either YouTube or Vimeo only.</Hint> : null}
        </InputContainer> : null}
        <ImageEye
          touchedDrag={touchDrag}
          ref={eyeElem}>
          <div
            ref={eyeDraggableElem}
            className="eye-drag"
          >
            <img 
              src={icons['eye-pink.svg']}
              alt="drag eye to adjust"
            />
            {uploadCount === 1 ? <span className="drag-hint">drag eye to adjust</span> : null }
          </div>
        </ImageEye>
        <Poster visible={posterUrl !== ''}>
          <img src={posterUrl} alt="" ref={previewElem} />
        </Poster>
      </div>
    </Container>
  )
});