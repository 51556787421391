import { StatLabel } from 'components/profile/userinfo.style'
import styled from 'styled-components'

import {
  fonts,
  colours,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;

  @media (max-width:${breakpoints.sm}px) {
    
  }
`

export const Inner = styled.div`
  width: 100%;
  max-width: 843px;

  .content-area {
    max-width: 540px;
  }

  .author-section-share {
    border-top:1px solid ${colours.text};
    padding-top:14px;
  }

  .sharing-top {
    .share-action-item {
      width: 25px;
      margin-left:5px;
      margin-right:5px;
    }
  }

  .published-date {
    padding-top:25px;
  }

  .author-profile {
    margin-top: 60px;
  }

  @media (max-width:${breakpoints.sm}px) {
    .image-area {
      height: 300px;
    }
    .content-area {
      width: 100%;
      max-width: 85%;
      padding: 0 20px 0 20px;

      .author-profile {
        .info {
          padding-right:12px;
        }
        .avatar {
          height:auto;
        }
      }
    }
  }
`