import React from 'react'
import styled from 'styled-components'

import {
  icons,
  ActivityActions,
  ActivityCounterText
} from '../../../constants'

import CounterItem from './counter-item'

const Container = styled.div`
  width:100;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top:auto;
`

const getIcons = (action) => {
  switch (action) {
    case ActivityActions.Featured:
      return {
        iconActive: icons['featured-grey.svg'],
        iconHover: icons['featured-pink.svg']
      }
    case ActivityActions.Wowed:
      return {
        iconActive: icons['wow-grey.svg'],
        iconHover: icons['wow-pink.svg']
      }
    case ActivityActions.Voted:
      return {
        iconActive: icons['wow-grey.svg'],
        iconHover: icons['wow-pink.svg']
      }
    case ActivityActions.Itchbooked:
      return {
        iconActive: icons['itchbook-grey.svg'],
        iconHover: icons['itchbook-pink.svg']
      }
    case ActivityActions.Viewed:
      return {
        iconActive: icons['views-grey.svg'],
        iconHover: icons['views-pink.svg']
        }
    case ActivityActions.Commented:
      return {
        iconActive: icons['comment-grey.svg'],
        iconHover: icons['comment-pink.svg']
        }
    default:
      break;
  }
}

export default function Counters({
  activityAggregates = {},
  onVotesClick,
  onItchbooksClick,
  onViewsClick,
  expanded
}) {

  const counterMap = [
    ActivityActions.Featured,
    ActivityActions.Voted,
    ActivityActions.Itchbooked,
    ActivityActions.Viewed,
    ActivityActions.Commented
  ]

  const counterMapOnClick = {
    [ActivityActions.Voted]: () => {
      onVotesClick && onVotesClick()
    },
    [ActivityActions.Featured]: () => {
      // no op
    },
    [ActivityActions.Itchbooked]: () => {
      onItchbooksClick && onItchbooksClick()
    },
    [ActivityActions.Viewed]: () => {
      onViewsClick && onViewsClick()
    },
    [ActivityActions.Commented]: () => {
    }
  }

  const counters = []

  for (const k of counterMap) {
    if (k in activityAggregates && activityAggregates[k] > 0)
      counters.push({
        action: k,
        ...getIcons(k),
        label: ActivityCounterText[k],
        count: activityAggregates[k],
        noClick: k === ActivityActions.Commented
      })
  }

  return (
    <Container>
      {counters.map((item, i) => (
        <CounterItem
          expanded={expanded}
          noClick={item.noClick}
          key={i}
          onClick={counterMapOnClick[item.action]}
          {...item}
        />
      ))}
    </Container>
  )
}