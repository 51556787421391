import React, { useEffect, useState, useRef, useContext } from 'react'
import { createPortal } from 'react-dom'
import Modal from '../shared/modal';
import ButtonSave from 'components/form/button-save';
import { useHistory } from 'react-router-dom'
import {
  Container,
  Logo,
  Title,
  Body,
  LaterLink,
  Inner,
  CreateAccountButton
} from './sign-up-prompt.styles'
import {
  icons
} from '../../constants'
import useAuth from 'hooks/auth.hook'
import { SignUpPromptContext } from 'contexts/sign-up-prompt.context';

export default function SignUpPrompt(
) {
  const timeoutRef = useRef()
  const { session } = useAuth()
  const { push } = useHistory()
  const [active, setActive] = useState();
  const { isAck, acknowledge } = useContext(SignUpPromptContext);

  useEffect(() => {
    if ((session === null || session === undefined) && !isAck) {
      timeoutRef.current = setTimeout(() => {
        setActive(true);
        acknowledge()
      }, 2500)
      
    } else {
      clearTimeout(timeoutRef.current)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [session, isAck])

  return active ? createPortal(
    <Modal 
      fullTakeOver={true}
      onClose={() => {
        setActive(false)
        acknowledge()
      }}
    >
      <Container>
        <Inner>
          <Logo>
            <img src={icons['logo-111px-ec0f69.svg']} />
          </Logo>
          <Title>Join us, its free!</Title>
          <Body>
          In an industry that takes more than giving back, 
          we’re starting by giving creative control back to 
          fashion creatives and lovers. No hooks, no catch, 
          no fashion rulebook—just do you. So sign up and join us now!
          </Body>
          <CreateAccountButton active={true} onClick={() => {
            push('/signup')
            acknowledge()
          }}>Create account</CreateAccountButton>
          <LaterLink onClick={() => {
            setActive(false)
            acknowledge()
          }}>Later</LaterLink>
        </Inner>
      </Container>
    </Modal>
  ,document.body) : null;
}