import axiosInstance from './api';

export const ContentType = {
  Artwork: 'artwork',
  Comment: 'comment'
}

export const flagContent = (contentType, id, reason, comments) => {
  return axiosInstance.post(`/flagging/${contentType}/${id}`, {
    reason,
    comments
  })
}
