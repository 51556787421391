import React from 'react'
import styled from 'styled-components'
import FormField from '../form/form-field'
import {
  breakpoints,
  colours,
  fonts,
  ButtonReset
} from '../../styles/global.styles'
import ButtonSave from '../form/button-save';
import {
  FormActions,
  FormReset,
  FormButtonGeneric,
  Row,
  Col
} from '../shared/modal-form/modal-form.styles'

import InputText from '../form/input-text'
import InputEditor from '../form/input-editor';
import InputTags from '../form/input-tags';
import { Controller } from 'react-hook-form';
import ImageUpload from '../form/image-upload';
import InputVideoLinkPreview from './input-video-link-preview';

import {
  MediaType,
  icons
} from '../../constants'

import useDeleteMyArtwork from '../../hooks/delete-my-artwork.hook';
import useActionAlert from '../../hooks/action-alert.hook';

const Container = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left:-24px;
  margin-right:-24px;
  padding-top: 0px;
  padding-bottom: 75px;
  margin-bottom:75px;

  @media (max-width:${breakpoints.sm}px) {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    align-items:initial;
    padding-bottom: 0;
    padding-top:20px;
    margin-bottom:0;
  }
`

const StyledField = styled(FormField)`
  margin-bottom: 12px;
  opacity: ${props => props.disabled ? .5:1};
  pointer-events: ${props => props.disabled ? 'none':'all'};

  input {
    width: 435px;
    font-weight: 400;
    &:focus {
      font-weight: 400;
    }
  }

  .label {
    border-bottom-width:1px;
  }

  input:hover {
    font-family: ${fonts.content.regular};
    font-weight: 400;
  }

  &:focus-within {
    .label {
      border-bottom-width:2px !important;
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    input {
      width: 100%;
    }
  }
`

const ImageField = styled(FormField)`
  height: 426px;
  width: 316px !important;
  opacity: ${props => props.disabled ? .5:1};
  pointer-events: ${props => props.disabled ? 'none':'all'};

  .label {
    border-bottom-width:1px;
  }
  .input-wrapper {
    padding-top: 10px;
  }

  @media (max-width:${breakpoints.sm}px) {
    height: 450px;
    .input-wrapper {
      height: 390px;
      padding-bottom:10px;
      &>label {
        height: 380px;
      }
    }
  }
`
const VideoField = styled(FormField)`
  height: 426px;
  width: 316px !important;
  opacity: ${props => props.disabled ? .5:1};
  pointer-events: ${props => props.disabled ? 'none':'all'};

  .label {
    border-bottom-width:1px;
  }
`

const DescriptionField = styled(StyledField)`
  padding-bottom: 40px;
  .editor-wrapper-class {
    height: 150px;
  }
  .rich-editor-container {
    &:focus-within {
      .editor-container {
        border-top-width: 2px;
        border-bottom-width: 2px;
      }
    }
    .editor-container {
      border-bottom:1px solid ${colours.text}; 
      .editor-inner {
        height: 100%;
        .editor-input {
          font-size:14px;
        }
      }
    }
  }

  .form-field-text {
    .input-wrapper {
      height: calc(100% - 40px);
    }
    
    &:hover {
      .input-wrapper {
        background-color: transparent;
      }
    }
  
  }
`

const InputTextStyled = styled(InputText)`
  border-bottom: none;
`

const TagsField = styled(StyledField)`
  width: 435px;
  
  @media (max-width:${breakpoints.sm}px) {
    width:100%
  }
`

const InputTagsStyled = styled(InputTags)`
  max-width: 435px;
  width:100%;

  @media (max-width:${breakpoints.sm}px) {
    max-width: unset;
    width:100%
  }
`

export default function ArtworkDetails({
  id,
  isEditing = false,
  register,
  control,
  mediaType,
  errors,
  isDirty,
  isSubmitting,
  disabled,
  onDelete = () => {},
  onReset = () => {},
}) {
  const { setActionAlert, closeAlert } = useActionAlert()
  const { deleteOne, isLoading, isSuccess } = useDeleteMyArtwork()

  const handleDelete = () => {
    setActionAlert({
      noClose: true,
      theme: 'dark',
      icon: icons['pink-alert-trash-triangle.svg'],
      active: true,
      title: 'Delete my artwork from gallery?',
      body: `You can’t undo this action.<br/><br/>
        <b>Are you sure you want to continue?</b>`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        deleteOne(id)
        closeAlert()
        onDelete()
      }
    })
    // 
  }

  return (
    <Container className="edit-artwork-details fade-when-mouse-off">
      <Col>
        <Row>
          <Col>
            <StyledField
              disabled={disabled}
              className="form-field-text"
              label="Title"
              error={errors['title']}
              area="title">
              <InputTextStyled
                disabled={disabled}
                type="text"
                maxLength={55}
                {...register("title", {
                  required: true
                })}
              />

            </StyledField>
          </Col>
        </Row>
        <Row>
          <Col>
            <DescriptionField
              disabled={disabled}
              className="form-field-text"
              label="Description"
              error={errors['description']}
              area="desciption"
            >
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => 
                  <InputEditor
                    {...field}
                    disabled={disabled}
                    maxLength={3000}
                    height={'150px'}
                    placeholder="Don't forget your team credits for this artwork"
                  />
                }
              />
            </DescriptionField>
          </Col>
        </Row>
        <Row>
            <Col>
              <TagsField
                disabled={disabled}
                label="Tags"
              >
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => 
                  <InputTagsStyled
                    disabled={disabled}
                    {...field}
                  />}
                />
              </TagsField>
            </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          {mediaType === MediaType.Image ? 
          <ImageField
            disabled={disabled}
            label="My Image"
            error={errors['image']}
          >
            <Controller
              name="image"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => 
                <ImageUpload
                  noUpload={isEditing}
                  disabled={disabled || isEditing}
                  {...field}
                />
              }
            />
          </ImageField> : null}
          {mediaType === MediaType.VideoLink ?
          <VideoField
            disabled={disabled}
            label="My Video URL"
            error={errors['video']}
          >
            <Controller
              name="video"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => 
                <InputVideoLinkPreview
                  noInput={isEditing}
                  disabled={false}
                  {...field}
                />
              }
            />
          </VideoField>  : null}
        </Row>
      </Col>
      <FormActions>
        {id ? <FormButtonGeneric type="button" onClick={handleDelete}>Delete</FormButtonGeneric> : null}
        {false && <FormReset onClick={onReset} disabled={!isDirty || disabled} type="button">Reset</FormReset>}
        <ButtonSave active={isDirty && !disabled} loading={isSubmitting} type="submit">{isEditing ? 'Save':'Submit'}</ButtonSave>
      </FormActions>
    </Container>    
  )
}