import React, { useState, useEffect, forwardRef, useRef } from 'react'
import InputText from './input-text'
import styled from 'styled-components'
import Tag from '../shared/tag';
import { 
  fonts,
  colours,
  ScrollStyledBox
} from '../../styles/global.styles'

const Container = styled(ScrollStyledBox)`
  display:flex;
  flex-direction: row;
  max-height: 100px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const InputWrapper = styled.div`
  flex:1 1 1px;
  width: 100%;
  min-width: 100px;
  position: relative;
  top:-4px;

  input {
    width: -webkit-fill-available;
  }
`

const StyledInputText = styled(InputText)`
  border:none;
  position: relative;
  z-index: 1;
`

const Hint = styled.div`
  color: ${colours.lightgrey2};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  top:10px;
  z-index: 0;
`

export default forwardRef(function InputTags(props, ref) {
  const [tags, setTags] = useState([])
  const [dirty, setDirty] = useState(false)
  const inputRef = useRef()

  const { value, onChange, onBlur, disabled, ...rest } = props

  useEffect(() => {
    if (tags.length > 0) {
      onChange && onChange(tags)
    }
  }, [tags])

  useEffect(() => {
    if (value) {
      setTags(value)
    }
  }, [value])

  return (
    <Container {...rest} ref={ref}>
      {tags.map((tag, i) => 
      <Tag key={i} 
        onRemove={() => {
          const updatedTags = [...tags]
          updatedTags.splice(i,1)
          setTags(updatedTags)
        }} 
        text={tag}
        disabled={disabled}
      />)}
      <InputWrapper>
        <StyledInputText
          disabled={disabled}
          ref={inputRef}
          onChange={(evt) => {
          }}
          onKeyUp={(evt) => {
            if (inputRef?.current?.value.length > 0) {
              setDirty(true)
            } else {
              setDirty(false)
            }
          }}
          onKeyDown={(evt) => {
            evt.stopPropagation()
            // on enter or space
            if (evt.keyCode === 13) {
              const newTag = evt.target.value
              if (newTag.length > 0) {
                if (!tags.includes(newTag)) {
                  setTags([...tags, newTag])
                  evt.target.value = ''
                }
              }
              evt.preventDefault();
            }
          }} 
          onKeyPress={(evt) => {
            evt.stopPropagation()
            if (evt.keyCode === 13) {
              const newTag = evt.target.value
              if (newTag.length > 0) {
                if (!tags.includes(newTag)) {
                  setTags([...tags, newTag])
                  evt.target.value = ''
                }
              }
              evt.preventDefault();
            }
          }}
          onBlur={onBlur}
        />
        {(tags.length === 0 && !dirty) ? 
        <Hint className="hint">
          Key in a word and hit enter to generate a tag
        </Hint> : null}
      </InputWrapper>
    </Container>
  )
});