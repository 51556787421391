import React from 'react'
import styled from 'styled-components'
import {
  colours
} from 'styles/global.styles'
import {
  preloaders
} from '../../../constants'

const Container = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  img {
    width:50px;
  }
`

export default function ProgressLoader() {
  return (
    <Container>
      <img src={preloaders['02-preloader-8px-d7d7d7.gif']} alt=""/>
    </Container>
  )
}