import React, { useState } from 'react'

export const ErrorAlertContext = React.createContext(null);

export const ErrorAlertProvider = ({ children }) => {
  const [errorAlert, setErrorAlert] = useState({
    active: false
  });
  return (
    <ErrorAlertContext.Provider value={[errorAlert, setErrorAlert]}>
      {children}
    </ErrorAlertContext.Provider>
  );
}