import React, { useState, useEffect } from 'react'
import { refreshToken } from '../apis/login.api'
import { updateAuthorizationHeader } from '../apis/api';
import { getToken } from 'utils/token'
import posthog from 'posthog-js'

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    const refreshAuth = async () => {
      const result = await refreshToken()
      if (result.data) {
        const { profile, accessToken } = result.data
        // console.log('refreshed token', result)
        setSession({
          profile,
          accessToken
        })
        if (profile)
          posthog.identify(
            profile.id,  // Replace 'distinct_id' with your user's unique identifier
            { name: profile.name } // optional: set additional user properties
          );
        updateAuthorizationHeader(accessToken.token)
      }
      
      setFetching(false)
    }

    const user = getToken()
    
    if (user) 
      refreshAuth()
    else
      setFetching(false)    
  }, [])

  return (
    <AuthContext.Provider value={[session, setSession, fetching]}>
      {children}
    </AuthContext.Provider>
  );
}