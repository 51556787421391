import React, { useEffect } from 'react';
import SocialShareButton from './social-share-button'
import useToastPop from 'hooks/toast-pop.hook'
import { copyToClipboard } from 'utils/utils'

export default function CopyLink({
  url,
  ...rest
}) {
  const { show } = useToastPop()

  let positionedTop = window.screen.width > 768 ? 65 : 45
  if (document.getElementById('public-menu')
    && window.scrollY === 0
    && (window.location.pathname === '/' || window.location.pathname === '/zine')) {
      positionedTop = 0;
  }
  return (
    <SocialShareButton
      socialType={"copyurl"}
      onClick={() => {
        copyToClipboard(url || window.location.href)
        show('Link copied!', {
          autoHide: true,
          positionedTop,
          zIndex: 10
        })
      }}
      {...rest}
    />
  )
}