import styled from 'styled-components'

import {
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  transition-property: all;
  transition-duration:.3s;
  transition-timing-function: ease-in-out;
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    flex-direction:column;
    flex-wrap:nowrap;
    position: relative;
    height: calc(100vw / 5 * ${props => props.rowCount ?? 1});
    overflow: hidden;
  }
`

/*
  a cell has 2 types (user, article)
  an cell type=article has 2 state (min,max)
*/

export const Cell = styled.div`
  background-color: ${props => props.data.type === 'user' ? colours.pink : colours.darkpink};
  transition-property: all;
  flex: ${props => props.size === 1 ? '0 0 50%' : '0 0 100%'};
  width: ${props => props.size === 1 ? '50%': '100%'};
  height: ${props => props.size === 1 ? '50vw': props.expanded ? 'auto': `calc(100vh / 2)`};
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-duration:.3s;
  transition-timing-function: ease-in-out;
  cursor: ${props => props.expanded ? 'default' : 'pointer'};
  overflow: hidden;

  .white-text {
    color: #bebebe;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    position: absolute;
    transition-property: all;
    width: calc(100% / 5 * ${(props) => props.size});
    height: calc(100vw / 5 * ${(props) => props.height ?? props.size});
    top: calc(100vw / 5 * ${(props) => props.y});
    left: calc(100% / 5 * ${(props) => props.x});
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      width: 100%;
      height: 100%;
    }
  }
`