import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addToOrRemoveFromItchbook } from 'apis/itchbook.api'

export default function useToggleItchbook() {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: id => {
      return addToOrRemoveFromItchbook(id)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: ['my-itchbook']})
      queryClient.invalidateQueries({queryKey: ['my-itchbook-artwork-ids']})
      queryClient.invalidateQueries({queryKey: ['itchbook']})
      queryClient.invalidateQueries({queryKey: [`activities-artworks-${variables.id}-itchbooked`]})
      queryClient.invalidateQueries({queryKey: [`activities-history-artworks-${variables.id}-itchbooked`]})
    },
  });
  

  return {
    itchbookIt: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}