import React, { useState } from 'react'

const initialState = {
  ui: {
    autoHide: false,
    positionedTop: 65,
    zIndex: 10,
    elementId: null
  },
  active: false,
  message: null
}

export const ToastPopContext = React.createContext(null);

export const ToastPopProvider = ({ children }) => {
  const [toastPopped, setToastPopped] = useState(initialState);
  return (
    <ToastPopContext.Provider value={{toastPopped, setToastPopped}}>
      {children}
    </ToastPopContext.Provider>
  );
}