import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOne } from '../apis/account-artwork.api';
import useAuth from './auth.hook'

export default function useUpdateMyArtwork() {
  const { session } = useAuth();
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: ({id, data}) => {
      return updateOne(id, data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: [`artworks`, variables.id] })
      queryClient.invalidateQueries({queryKey: [`artworks-${session?.profile?.id}`] })
      queryClient.invalidateQueries({queryKey: ['my-artworks'] })
    },
  });
  

  return {
    update: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}