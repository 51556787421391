import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Modal from '../components/shared/modal';
import { 
  SettingsContainer,
  Header,
  HeaderTitleWrapper,
  SubMenu,
  SubMenuItem
} from './settings.styles'
import {
  TitleWithLeftRightLines
} from '../styles/global.styles'
import SettingsEmailAlerts from '../components/settings/settings.email-alerts'
import SettingsAccount from '../components/settings/settings.account';

import useModal from '../hooks/modal.hook';

const Tabs = {
  Account: 'account',
  EmailAlerts: 'email-alerts'
}

export default function Settings({
  onClose
}) {
  const { setModal } = useModal()
  const [tab, setTab] = useState(Tabs.Account)
  const [subModalOpened, setSubModalOpened] = useState(false)

  return (
    createPortal (
      <Modal
        noPadding={true}
        theme="light"
        fullTakeOver={false}
        header={
          <Header className="modal__custom_header">
            <HeaderTitleWrapper>
              <TitleWithLeftRightLines>settings</TitleWithLeftRightLines>
              {false && <SubMenu>
                <SubMenuItem 
                  onClick={() => {
                    setTab(Tabs.Account)
                  }} 
                  active={tab === Tabs.Account}>
                    account</SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    setTab(Tabs.EmailAlerts)
                  }}
                  active={tab === Tabs.EmailAlerts}>
                    email alerts</SubMenuItem>
              </SubMenu>}
            </HeaderTitleWrapper>
          </Header>
        }
        onClose={() => {
          if (subModalOpened) {
            setSubModalOpened(false)
          } else {
            onClose && onClose();
            setModal(null)
          }
        }}
        closeButtonTop={17}
      >
        <SettingsContainer>
          {tab === Tabs.EmailAlerts ? 
            <SettingsEmailAlerts/> : null}
          {tab === Tabs.Account ? 
            <SettingsAccount/> : null}
        </SettingsContainer>
      </Modal>,
    document.body)
  )
}