import React from 'react'
import styled from 'styled-components'
import {
  icons
} from '../../constants'
import {
  fonts,
  colours
} from '../../styles/global.styles'

const Wrapper = styled.div`
  display:flex;
  flex-direction: column;
  
`

const RadioStyled = styled.input`
  position: absolute;
  visibility: hidden;
`

const SelectableItem = styled.label`
  width: 246px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border:1px solid ${colours.pink};
  cursor: pointer;

  &:has(:checked) {
    background-color: ${colours.pink_alpha50};
  }

  
  &:hover {
    background-color: ${colours.pink_alpha50};
  }

`

const ImageText = styled.div`
  font-family: ${fonts.content.bold};
  font-size: 12px;
  color: ${colours.pink};
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>img {
    display: block;
    width: 24px;
    margin-bottom: 12px;
  }
`

export default function SelectFormatType({
  register
}) {
  return (
    <Wrapper>
      <SelectableItem>
        <ImageText>
          <img src={icons['upload-image.svg']} alt="upload image"/>
          <span>Upload Image</span>
        </ImageText>
        <RadioStyled 
          type="radio" 
          {...register('mediaType')} 
          value="image"  
        />
      </SelectableItem>
      <SelectableItem>
        <ImageText>
          <img src={icons['embed-video.svg']} alt="embed video"/>
          <span>Embed Video</span>
        </ImageText>
        <RadioStyled 
          type="radio" 
          {...register('mediaType')} 
          value="videoLink"
        />
      </SelectableItem>
    </Wrapper>
  )
}