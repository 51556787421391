import React, { useEffect, useState } from 'react';
import Modal from '../components/shared/modal';
import styled from 'styled-components'

import {
  ButtonResetForm
} from './settings.styles'
import ButtonSave from '../components/form/button-save';
import {
  icons
} from '../constants'
import {
  fonts,
  colours,
  breakpoints
} from '../styles/global.styles'
import {
  Header,
  Close,
  Body,
  Footer
} from '../components/settings/shared.styles'

import { useForm, Controller } from "react-hook-form";

import RadioButtons from '../components/form/radio-buttons';
import FormField from '../components/form/form-field';

import useActionAlert from '../hooks/action-alert.hook'
import useGetMyAccount from '../hooks/get-user-account.hook';

import useTriggerDeactivate from '../hooks/trigger-deactivate.hook';

import { useHistory } from 'react-router-dom';

import useModal from 'hooks/modal.hook';

const ModalBody = styled(Body)`
  min-height:inherit;
  display:flex;
  flex-direction: column;
  align-items: center;

  form {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top:auto;
  margin-bottom:auto;

  .header-wrapper {
    padding: 10px 11px 9px 19px;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .icon {
        margin-right: 5px;
        img {
          display: block;
          width: 42px;
        }
    }

    .text {
      color: ${colours.pink};
      font-family: ${fonts.title.xbold};
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  p {
    font-family: ${fonts.content.regular};
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  h3 {
    font-family: ${fonts.content.bold};
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    height: 36px;
    border-bottom:1px solid ${colours.pink};
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    max-width: 435px;

    .header-wrapper {
      margin-right: 10px;
      position:relative;
      left: -40px;
      .icon {
        width: 42px;
        img {
          
        }
      }
      .text {
        font-size: 14px;
        line-height: 16.8px;
      }
    }
  }
`;

const FieldStyled = styled(FormField)`
  opacity: ${props => props.active ?? true ? 1 : 0.5};
  pointer-events: ${props => props.active ?? true ? 'all' : 'none'};

  &>.label {
    border-bottom:1px solid ${colours.pink};
  }

  &:hover {
    &>.label {
      background-color: transparent;
    }
    .input-wrapper {
      background-color: transparent;
    }
  }

`;

const RadioButtonsStyled = styled(RadioButtons)`
  flex-direction: column !important;
  margin-top:12px;

  .radio-button-item {
    margin-bottom: 2px;
    .label {
      color: ${colours.darkergrey2};
      font-weight: 700;
    }
  }
`;

const CommentsField = styled(FormField)`
  opacity: ${props => props.active ? 1: 0.5};
  pointer-events: ${props => props.active ? 'all' : 'none'};

  .label {
    border-bottom:1px solid ${colours.pink};
  }

  &:focus-within {
    .label {
      border-bottom-width: 2px;
    }
  }

`;

const TextAreaStyled = styled.textarea`
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.black};
  background-color:transparent;
  border:none;
  outline:none;
  padding: 10px;
  height: 108px;
  margin-left: 4px;
  margin-right:4px;

  &::-webkit-scrollbar {
    width:3px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }

  &::-webkit-resizer {
    display:none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colours.pink};
  }
`;

export default function Deactivate() {
  const { setModal } = useModal()
  const { trigger, isLoading } = useTriggerDeactivate()
  const { setActionAlert } = useActionAlert();
  const userAcc = useGetMyAccount()
  const { push } = useHistory()

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { 
      isDirty
    }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      reason: null,
      comments: '',
    }
  });

  const reasonWatch = watch('reason')

  const onSubmit = (data) => {
    // open the prompter modal
    const { email } = userAcc?.accountSettings
    if (email) {
      setActionAlert({
        active: true,
        title: 'Sorry to see you leave!',
        body: `Deactivating your <b>itch</b> account is an action you can’t undo. 
          If you’re ready to permanently close this account,<br/>
          we’ll send you a confirmation email to <b>${email}</b> for safety reasons.`,
        yesLabel: 'Ok, send the email',
        noLabel: 'No, I change my mind',
        onYes: () => {
          trigger(data)
          setActionAlert({
            active: false
          })
          console.log('clear session')
          localStorage.clear()
          push('/login')
        }
      })
    }
  }

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type)
    })
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Modal
      noPadding={true}
      theme="light"
      onClose={() => {
        setModal(null)
      }}
      >
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <div className="header-wrapper">
              <div className="icon">
                <img src={icons['alert.svg']} alt=""/>
              </div>
              <div className="text">
                deactivate your itch account?
              </div>
            </div>
            <p>We're sad to see you go. But do note that deactivating your account is permanent. Your profile, artworks, social status and everything else will be deleted forever.</p>
            <FieldStyled
              label="If you’ve a moment, help us understand why you’re leaving:">
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => 
                  <RadioButtonsStyled
                    {...field}
                    options={[
                      {
                        name: "There're no interesting fashion artworks",
                        value: "There're no interesting fashion artworks"
                      },
                      {
                        name: "I've another itch account",
                        value: "I've another itch account"
                      },
                      {
                        name: "I'm not getting wide enough reach",
                        value: "I'm not getting wide enough reach"
                      },
                      {
                        name: "Others",
                        value: "Others"
                      }
                    ]}
                  />
                }
              />
            </FieldStyled>
            
            <CommentsField
              active={reasonWatch !== null && reasonWatch !== undefined}
              label="Additional comments">
              <TextAreaStyled
                {...register('comments')}
              />
            </CommentsField>
          </Wrapper>
          <Footer>
            <ButtonResetForm 
              type="reset"
              active={isDirty} 
            >Reset</ButtonResetForm>
            <ButtonSave
              type="submit"
              loading={isLoading}
              active={isDirty}
            >Confirm</ButtonSave>
          </Footer>
        </form>
      </ModalBody>
    </Modal>
  )
}