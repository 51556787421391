import React from 'react'
import styled from 'styled-components'

import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'

import { Link } from 'react-router-dom'

const Container = styled(Link)`
  height:28px;
  padding:8px;
  display: inline-block;
  background-color: ${colours.light};

  &:hover {
    background-color: ${colours.darkerlight};  
  }


  @media (max-width:${breakpoints.sm}px) {
    background-color: ${colours.darklight};
  }
`

const Text = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.lightgrey3};
  word-wrap: nowrap;
`

export default function ViewTag({
  url = null,
  text,
  ...rest
}) {
  return (
    <Container {...rest} className="tag" to={url ?? '#'}>
      <Text>{text}</Text>
    </Container>
  )
}