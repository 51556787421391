import axiosInstance from './api';
import {
  toQueryParams
} from '../utils/query-parameters.js'

export const addToOrRemoveFromItchbook = (id) => {
  return axiosInstance.post(`/account/itchbook`, {
    id
  })
}

export const getMyItchBook = async (query) => {
  const response = await axiosInstance.get(`/account/itchbook/${toQueryParams(query)}`)
  return {
    data: response.data.artworks.data.map(item => ({
      ...item
    })),
    totalRecords: response.data.artworks.data.totalRecords
  }
}

export const getItchBook = async ({userId, ...pageQuery}) => {
  const path = userId !== null ? 
    `/itchbooks/${userId}${toQueryParams(pageQuery)}` 
    : `/account/itchbook${toQueryParams(pageQuery)}`

  const response = await axiosInstance.get(path)
  return {
    data: response.data.artworks.data.map(item => ({
      ...item
    })),
    totalRecords: response.data.artworks.totalRecords
  }
}

export const getItchBookArtworkIds = async () => {
  let response = await axiosInstance.get(`/account/itchbook-ids`)
  return response.data
}