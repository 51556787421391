import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import BillBoard from '../components/profile/billboard';
import UserInfo from '../components/profile/userinfo';
import UserNav from '../components/profile/usernav';
import useGetProfile from '../hooks/get-profile.hook';
import FashionStatementExpanded from '../components/profile/fashion-statement-expanded';
import EditProfile from './edit-profile';
import { useRouteMatch, useLocation, useParams, useHistory, Link } from 'react-router-dom'
import useAuth from 'hooks/auth.hook';
import FooterIcon from 'components/shared/footer-icon'
import { FooterContainer } from './page';

import ProfileRoutes from './profile/profile.routes'
import SignUpPrompt from 'components/sign-up-prompt/sign-up-prompt'
import useModal from 'hooks/modal.hook';
import { usePageReady } from 'contexts/page-ready.context';
import { SignUpPromptProvider } from 'contexts/sign-up-prompt.context'

import {
  breakpoints
} from 'styles/global.styles'

const Header = styled.div`
  height: 488px;
  display:flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  
  @media screen and (max-width:360px) {
    height: 495px;
  }
`;

const FashionStatementContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  padding-top: 65px;
  display: ${props => props.visible ? 'block':'none'};

  @media (max-width: ${breakpoints.sm}px) {
    padding-top: 45px;
  }
`;

const ProfileSubContent = styled.div`
  position: relative;
  z-index:0;
  min-height: 100vh;
`

export default function Profile(props) {
  const { modal } = useModal()
  const { pathname } = useLocation()
  const { itchUrl } = useParams()
  const [ fashionStatementVisible, setFashionStatementVisible ] = useState(false)
  const [ editVisible, setEditVisible ] = useState(false)
  const { loading, fetching, success, profile } = useGetProfile(itchUrl)
  const { push } = useHistory()
  const { fetching: sessionFetched, session } = useAuth()

  const billboardImage = profile ? (profile?.billboardImageVariants?.medium ? {
    ...profile?.billboardImageVariants?.medium,
    position: profile.billboardImage.position
  } : profile.billboardImage) : null
  
  useEffect(() => {
    if (fashionStatementVisible) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [fashionStatementVisible])

  if (success && profile === null) {
    push('/snap')
  }

  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (modal) {
      setEditVisible(false)
    }
  }, [modal])

  useEffect(() => {
    if (pathname !== '/my-profile') {
      setEditVisible(false)
    }
  }, [pathname])

  return (
    <SignUpPromptProvider>
      <SignUpPrompt/>
      <Header id="header">
        {success && profile ?
        <>
          <FashionStatementContainer visible={fashionStatementVisible}>
            <FashionStatementExpanded onMinimise={() => {
              setFashionStatementVisible(false)
            }} body={profile?.fashionStatement ?? ''}></FashionStatementExpanded>
          </FashionStatementContainer>
          <UserInfo
            profile={profile}
            onEdit={() => {
              setEditVisible(true)
            }}
            onExpandFashionStatement={() => {
              setFashionStatementVisible(true)
            }}
          />
          <BillBoard 
            image={billboardImage}
          />
        </>
        : null}
      </Header>
      {profile ? <UserNav
        profile={profile} /> : null}
      {(editVisible && profile) ? 
        <EditProfile
          profile={profile}
          onClose={() => {
            setEditVisible(false)
          }} 
        /> : null}
      {profile && profile.id ? <ProfileSubContent id="profile-sub-content">
        <ProfileRoutes userId={profile.id} /> 
        {(!sessionFetched && !session) ? <FooterContainer>
          <FooterIcon/>
          
          <div className="footer-links">
            <span>Copyright © {currentYear} Groundcrew Pte. Ltd. All Rights Reserved.</span>
            
            <Link target="_blank" to="/here/privacy">Privacy Policy</Link>
            <span>|</span>
            <Link target="_blank" to="/here/terms">Terms of Use</Link>
            <span>|</span>
            <Link target="_blank" to="/here/contact-us">Contact Us</Link>
          </div>
        </FooterContainer> : null}
      </ProfileSubContent> : null}
    </SignUpPromptProvider>
  )
}