import React, { useState, useEffect } from 'react'
import { useInfiniteQuery } from "@tanstack/react-query";
import { findUsersByNameStartWith } from 'apis/user.api';

const useSearchUsers = (name, excludeUsers) => {
  const [ searchName, setSearchName ] = useState()
  const {
    refetch,
    isLoading, error, 
    data, isSuccess, isFetching, isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['users', name],
    queryFn: ({ pageParams = 0 }) => findUsersByNameStartWith({
      name: name,
      excludeUsers,
      page: pageParams
    }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page + 1 < lastPage.totalPages)
          return Number(lastPage.page) + 1
    }
  })

  const searchUsers = (name) => {
    setSearchName(name)
  }

  useEffect(() => {
    setSearchName(name)
    refetch({
      refetchPage: (page, index) => true
    })
  }, [name, refetch])

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])

  return {
    isLoading,
    isSuccess,
    data: flattenedData,
    hasNextPage,
    fetchNextPage,
    searchUsers,
  }
}

export default useSearchUsers