import { useContext } from "react";
import { UserThemeContext } from "../contexts/user-theme.context";

const useTheme = () => {
  const [userTheme, setUserTheme] = useContext(UserThemeContext);
  return {
    userTheme,
    setUserTheme
  }
}

export default useTheme;