import React from 'react'
import styled from 'styled-components'
import {
  colours,
  fonts,
  ButtonPrimary
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'
import useErrorAlert from '../../hooks/error-alert.hook'

const Wrapper = styled.div`
  position:fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colours.black_alpha85};
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.visible ? 'all': 'none'};
  visibility: ${props => props.visible ? 'visible':'hidden'};
  opacity: ${props => props.visible? 1: 0};
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
`;

const AlertContent = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`

const Icon = styled.div`
  display:flex;
  justify-content:center;
  margin-bottom:50px;
`

const Header = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
`;

const Body = styled.div`
  color: ${colours.grey};
  font-family: ${fonts.content.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 50px;
`;

export default function ErrorAlert() {
  const { errorAlert, setErrorAlert } = useErrorAlert()

  return (
    <>
      {errorAlert.active ? <Wrapper visible={errorAlert.active}>
        <AlertContent>
          <Icon>
            <img src={icons['alert.svg']} alt="alert" />
          </Icon>
          <Header>Ooops</Header>
          <Body dangerouslySetInnerHTML={{__html: errorAlert?.body ?? ''}}></Body>
          <ButtonPrimary onClick={() => {
            setErrorAlert({
              ...errorAlert,
              active: false
            })
          }}>{errorAlert?.actionLabel ?? 'Ok!'}</ButtonPrimary>
        </AlertContent>
      </Wrapper> : null}
    </>
  );
}