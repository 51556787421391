import { useMutation } from '@tanstack/react-query';
import { conpletePasswordReset } from '../apis/auth.api';

export default function useCompletePasswordReset() {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return conpletePasswordReset(data)
    },
  });
  

  return {
    complete: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}