import { useQuery } from '@tanstack/react-query';
import { getEmailAlerts } from 'apis/email-alerts.api'

export default function useGetMyEmailAlerts() {
  const { isLoading, error, 
      data, isSuccess, isFetching, isFetched 
  } = useQuery(['my-email-alerts'], getEmailAlerts)

  /*
  const emailAlerts = isSuccess && data ? (data?.data?.alerts?? []).reduce((acc, curr) => {
    acc[curr.topic] = curr.enabled
    return acc
  }, {}) : null
  */
  
  return {
    success: isSuccess,
    fetched: isFetched,
    loading: isLoading,
    fetching: isFetching,
    error,
    emailAlerts: (isSuccess && data) ? (data?.data?.alerts ?? []).filter(o => o.enabled).map(o => o.topic) : []
  }
}
