import React, { useState } from 'react'

export const ActionAlertContext = React.createContext(null);

export const ActionAlertProvider = ({ children }) => {
  const [actionAlert, setActionAlert] = useState({
    active: false
  });
  return (
    <ActionAlertContext.Provider value={[actionAlert, setActionAlert]}>
      {children}
    </ActionAlertContext.Provider>
  );
}