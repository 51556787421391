import { useInfiniteQuery } from '@tanstack/react-query';
import { searchUserByAccountType, searchUsers } from '../apis/user.api';

export default function useGetUsersByAccountType({
  accountType,
  subType = null,
  page = 0,
  size = 10
}) {
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: ['users', `${accountType}-${subType ?? 'all'}`],
    queryFn: ({ pageParam = page }) => {
      if (accountType) {
        return searchUserByAccountType({
          accountType,
          subType,
          size,
          page: pageParam
        })
      } else {
        return searchUsers({
          size,
          page: pageParam
        })
      }
    },
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
      else
        return null
    }
  })

  const flattenedData = isSuccess ? (data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])) : []

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    users: flattenedData,
    hasNext: hasNextPage,
    nextPage: fetchNextPage,
    refresh: refetch
  }
}