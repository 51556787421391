import React, { useState, useEffect } from 'react'
import { ActivityActions } from '../constants'
import useGetitchbookArtworkIds from 'hooks/get-itchbook-artwork-ids'
import useAuth from 'hooks/auth.hook'

const initialState = {
  artworks: {
    [ActivityActions.Itchbooked]: [],
    [ActivityActions.Voted]: []
  }
}

export const ActionsContext = React.createContext(initialState);

export const ActionsProvider = ({ children }) => {
  const { success, artworksIds } = useGetitchbookArtworkIds()
  const [actions, setActions] = useState(initialState);
  const { fetching, session } = useAuth()

  const pushAction = ({
    name,
    action,
    id
  }) => {
    if (actions && actions[name] && actions[name][action]) {
      if (!actions[name][action].includes(id)) {
        const stateUpdate = {
          ...actions
        }
        
        stateUpdate[name][action].push(id)
        setActions(stateUpdate)
      }
    }
  }

  const pullAction = ({
    name,
    action,
    id
  }) => {
    if (actions[name][action].includes(id)) {
      const stateUpdate = {
        ...actions
      }
      
      stateUpdate[name][action] = stateUpdate[name][action].filter(i => i !== id)
      setActions(stateUpdate)
    }
  }

  const clearActions = () => {
    setActions(initialState)
  }

  useEffect(() => {
    if (success) {
      let state = {
        ...actions
      }
      state.artworks[ActivityActions.Itchbooked] = artworksIds
      setActions(state)
    }
  }, [success])

  useEffect(() => {
    if (!fetching && session) {
      clearActions()
    }
  }, [fetching, session])

  return (
    <ActionsContext.Provider value={{actions, pushAction, pullAction, clearActions}}>
      {children}
    </ActionsContext.Provider>
  );
}