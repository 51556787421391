import React, { 
  forwardRef,
  useRef,
  useEffect,
  useState
} from 'react';
import styled from 'styled-components';
import {
  colours,
  fonts
} from '../../styles/global.styles';
import {
  icons
} from '../../constants';
import useUpload from '../../hooks/upload.hook';
import Progress from '../shared/progress';
import useErrorAlert from '../../hooks/error-alert.hook';
import {
  useWindowDimensions
} from 'utils/helpers'

const FormField = styled.label`
  user-select: none;
  display:flex;
  position: relative;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${props => props.disabled ? 'none': 'auto'};
  cursor: pointer;
`;

const ImageContainer = styled.div`
  display: block;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  overflow: hidden;
  position: relative;
  padding: 12px;
`;

const DropZone = styled.div`
  position:absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition-property: all;
  transition-timing-function: ease-in;
  transition-duration: .2s;
  padding: 12px;
  &:before {
    content: '';
    background-color: ${props => props.dragOver ? colours.pink_alpha60 : 'transparent' };
    display: block;
    width: 100%;
    height: 100%;
    border: ${props => props.border ? `1px solid ${colours.text}`:'none'}
  }
`;

const ImagePreview = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;

  visibility: ${props => props.visible ? 'visible':'hidden'};
`;

const ImageEye = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  height: 100%;
  width: 100%;
  padding: 12px;
  justify-content: center;
  overflow: hidden;
  display: none;

  &.on-drag {
    img {
      cursor: grabbing;
    }
  }

  .eye-drag {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>img {
      cursor: grab;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      
      &:hover{
        background-color: ${colours.pink_alpha50};
        cursor: grab;
      }
      &:active,
      &:focus,
      &:active:hover {
        background-color: ${colours.pink_alpha50};
        cursor:none !important;
      }
    }
    
    &>span {
      position: absolute;
      top:100%;
      transform: translateY(5px);

      padding: 5px 30px;
      color: ${colours.pink};
      font-family: ${fonts.title.xbold};
      font-size: 12px;
      font-weight: 800;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: center;
      background-color: ${colours.dark_alpha70};

      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: .2s;
      white-space: nowrap;

      opacity: ${props => props.touchDrag ? 0:1};

      &:before {
        content: '';
        display:block;
        position: absolute;
        top:-8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent ${colours.dark_alpha70} transparent;
      }
    }

  }

  &:active {
    &>span {
      opacity: 0;
    }
  }
`;

const InputFileWrapper = styled.label`
  width: 100%;
  height: 100%;
  position:absolute;
  z-index:1;
  background-color: transparent;
  cursor: pointer;
  
  &>input[type="file"] {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }
`;

const EyeAxis = {
  Horizontal: 1,
  Vertical: 2
}

const ProgressWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colours.black_alpha85};
`;

const getImageEyeAxis = (previewDimensions, imageDimensions) => {
  let eyeAxis = null
  const imageRatio = imageDimensions[0] / imageDimensions[1];

  // compare the dimensions
  const imageDimensionsScaled = [previewDimensions[0], previewDimensions[0] / imageRatio];
  
  if (imageDimensionsScaled[1] >= previewDimensions[1]) {
    eyeAxis = EyeAxis.Vertical;
  } else {
    eyeAxis = EyeAxis.Horizontal;
  }

  return eyeAxis;
}

export default forwardRef(function ImageUpload(
  props,
  ref
) {
  const { setErrorAlert } = useErrorAlert()
  const { 
    uploadFiles,
    isLoading,
    isSuccess,
    isError,
    result,
    error
  } = useUpload()

  const { 
    value,
    width,
    height,
    disabled,
    enableItchEye = true,
    noUpload,
    ...rest
  } = props;

  const [uploadCount, setUploadCount] = useState(0);
  const [touchDrag, setTouchDrag] = useState(false)
  const [isDragging, setIsDragging] = useState(false);
  const previewElem = useRef(null);
  const eyeElem = useRef(null);
  const containerElem = useRef(null);
  const eyeDraggableElem = useRef(null);
  const inputFileRef = useRef(null)
  const [fileDraggedOver, setFileDraggedOver] = useState(false)
  const { isMobile } = useWindowDimensions()
  
  // set up the drag behaviour
  function dragElement(elmnt, followElmnt, fileUploaded) {
    // console.log('setup drag element', elmnt, followElmnt, fileUploaded)
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let eyeAxis = null;

    if (!isMobile) {
      elmnt.onmousedown = dragMouseDown;
    } else {
      // elmnt.onmousedown = dragMouseDown;
      elmnt.ontouchstart = dragMouseDown;
    }
    
    function dragMouseDown(e) {
      console.log('drag mouse down', e)
      pos1 = 0;
      pos2 = 0;
      pos3 = 0;
      pos4 = 0;
      // get the image orientation
      eyeAxis = Number(followElmnt.getAttribute('data-eye-axis'));
      
      setTouchDrag(true)
      setIsDragging(true)

      e = isMobile ? e : e || window.event;
      // console.log(e.touches)
      e.preventDefault();
      e.stopPropagation();
      // get the mouse cursor position at startup:
      if (eyeAxis === EyeAxis.Horizontal) {  
        pos3 = isMobile ? e.touches[0].clientX : e.clientX;
      }
      if (eyeAxis === EyeAxis.Vertical) {
        pos4 = isMobile ? e.touches[0].clientY : e.clientY
      }

      if (!isMobile) {
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      } else {
        // touch up and touch move
        document.ontouchend = closeDragElement;
        document.ontouchmove = elementDrag;
      }

      eyeElem.current.classList.add('on-drag')
    }

    function elementDrag(e) {
      e = isMobile ? e : (e || window.event);
      
      // e.stopPropagation()
      e.preventDefault();
      
      const dragHintElem = eyeElem.current.querySelector('.drag-hint')
      if (dragHintElem) {
        dragHintElem.style.visibility = 'hidden'
      }

      document.body.style.cursor = 'grabbing'


      // calculate the new cursor position:
      if (eyeAxis === EyeAxis.Horizontal) {
        pos1 = pos3 - (isMobile ? e.touches[0].clientX : e.clientX);
        pos3 = isMobile ? e.touches[0].clientX : e.clientX;
      }

      if (eyeAxis === EyeAxis.Vertical) {
        pos2 = pos4 - (isMobile ? e.touches[0].clientY : e.clientY);
        pos4 = isMobile ? e.touches[0].clientY : e.clientY;
      }
      // set the element's new position:
      const newTop = elmnt.offsetTop - pos2;
      const newLeft = elmnt.offsetLeft - pos1;

      // check bounds, cannot exceed parent bounding rect
      const elmntBox = elmnt.getBoundingClientRect()
      const { width, height } = elmnt.parentElement.getBoundingClientRect();

      if (newTop >= 0 && newTop <= (height - elmntBox.height)
        && newLeft >= 0 && newLeft <= (width - elmntBox.width)) {
          elmnt.style.top = newTop + "px";
          elmnt.style.left = newLeft + "px";
    
          const pX = 100 * newLeft / width;
          const pY = 100 * newTop / height;
    
          if (followElmnt) {
            const position = `${pX}% ${pY}%`
            followElmnt.style.objectPosition = position;
          }
      }
    }

    function closeDragElement(evt) {
      // evt.preventDefault()
      // evt.stopPropagation()
      document.body.style.cursor = 'default'

      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchstart = null;
      document.ontouchend = null;
      // because the event bubbling continues after this
      // causes the file input to trigger the browse file

      const { url, mimeType, width, height, filename } = fileUploaded
      if (props.onChange)
        props.onChange({
          url,
          position: followElmnt.style.objectPosition,
          mimeType,
          width,
          height,
          filename
        })
      props.onBlur && props.onBlur()

      eyeElem.current.classList.remove('on-drag')
      
      setTimeout(() => {
        setIsDragging(false)
        
      }, 500)
    }
  }

  function handleFile(files) {
    if (files.length === 1) {
      uploadFiles(files)

      const reader = new FileReader();
      reader.onload = null;

      const img = new Image()
      reader.readAsDataURL(files[0]); // read the data blob from upload
      
      img.onload = function () {
        previewElem.current.setAttribute('data-width', this.width);
        previewElem.current.setAttribute('data-height', this.height);

        // find the axis for adjusting eye based on cover rule
        const previewDimensions = [previewElem.current.width, previewElem.current.height];
        const imageDimensions = [this.width, this.height];
        const eyeAxis = getImageEyeAxis(previewDimensions, imageDimensions)
        
        previewElem.current.setAttribute('data-eye-axis', eyeAxis)
      };

      reader.onload = () => {
        img.src = reader.result;
      };
    }
  }

  useEffect(() => {
    if (value) {
      const { filename, width, height, position } = value ?? null
      const url = process.env.REACT_APP_UPLOADS_URL + '/' + filename
      previewElem.current.src = url
      // show the eye element if there is a file passed thru props
      if (url) {
        eyeElem.current.style.display = 'flex';
        dragElement(eyeDraggableElem.current, previewElem.current, value)

        previewElem.current.setAttribute('data-width', width);
        previewElem.current.setAttribute('data-height', height);

        // find the axis for adjusting eye based on cover rule
        const previewDimensions = [previewElem.current.width, previewElem.current.height];
        const imageDimensions = [width, height];
        const eyeAxis = getImageEyeAxis(previewDimensions, imageDimensions)
        
        previewElem.current.setAttribute('data-eye-axis', eyeAxis)

        previewElem.current.style.objectPosition = position ?? '50% 50%';

        if (position) {
          const dimensions = [
            eyeDraggableElem.current.height,
            eyeDraggableElem.current.width
          ];
          const positionsValues = position.split(' ').map((v, i) => Number(v.replace('%', '')) * dimensions[i]);
          if (eyeAxis === EyeAxis.Horizontal) {
            eyeDraggableElem.current.style.left = positionsValues[0];
          }
          if (eyeAxis === EyeAxis.Vertical) {
            eyeDraggableElem.current.style.top = positionsValues[1];
          }
        }
      }
    }

    return () => {
      // delete eyeDraggableElem.current.onmousedown

    }
  }, [value])

  /*
  useEffect(() => {
    if (value) {
      previewElem.current.src = process.env.REACT_APP_UPLOADS_URL + '/' + value.filename
    }
  }, [value])
  */

  useEffect(() => {
    if (isSuccess) {
      if (result && result.length > 0) {
        const url = process.env.REACT_APP_UPLOADS_URL + '/' + result[0].name;
        previewElem.current.src = url
          
        eyeElem.current.style.display = 'flex';
        // reset eye position
        eyeDraggableElem.current.style.top = 'unset';
        eyeDraggableElem.current.style.left = 'unset'; 

        const fileUploaded = {
          url,
          position: null,
          mimeType: result[0].mimetype,
          width: result[0].width,
          height: result[0].height,
          filename: result[0].name
        }

        props.onChange && props.onChange(fileUploaded)
        props.onBlur && props.onBlur()

        setUploadCount(uploadCount + 1)

        dragElement(eyeDraggableElem.current, previewElem.current, fileUploaded)
      }
    } else
    if (isError) {
      let body = ''
      if (error && Array.isArray(error)) {
        switch(error[0].code) {
          case 'ERR_FILE_TYPES':
            body = 'Please upload a JPEG or PNG with a file size of no more than 20mb.'
            break;
          case 'ERR_FILE_SIZE':
            body = 'Please upload a JPEG or PNG with a file size of no more than 20mb.'
            break;
          default:
            body = 'Please upload a JPEG or PNG with a file size of no more than 20mb.'
        }
        setErrorAlert({
          active: true,
          body,
        })
      }
    }
  }, [isSuccess, isError])

  return (
    <FormField 
      disabled={!enableItchEye && (disabled || isDragging)}
      className={props.className} 
      onDragOver={(evt) => {
        setFileDraggedOver(true)
        evt.preventDefault()
      }}
      onDrop={(evt) => {
        // handle file
        if (evt.dataTransfer.files 
          && evt.dataTransfer.files.length === 1) {
          handleFile(evt.dataTransfer.files)
        }

        setFileDraggedOver(false)
        
        evt.preventDefault()
      }}
    >
      <InputFileWrapper>
        <input 
          ref={inputFileRef}
          disabled={disabled || noUpload}
          type="file"
          onChange={(evt) => {
            if (evt.target.files.length === 1) {
              handleFile(evt.target.files)
            }
        }} />
      </InputFileWrapper>
      {isLoading ? <ProgressWrapper>
        <Progress/>
      </ProgressWrapper> : null}
      
      {!disabled ? <DropZone
        border={props.placeholderImg === undefined && result === undefined}
        width={width} 
        height={height}
        className="dropzone"
        dragOver={fileDraggedOver}
      /> : null}
      <ImageEye
        onClick={(evt) => {
          if (isDragging)
            evt.preventDefault()
        }}
        touchedDrag={touchDrag}
        ref={eyeElem}>
        <div
          ref={eyeDraggableElem}
          className="eye-drag"
        >
          <img
            src={icons['eye-pink.svg']}
            alt="drag eye to adjust"
          />
          {(uploadCount === 1 || enableItchEye) ? <span className="drag-hint">drag eye to adjust</span> : null}
        </div>
      </ImageEye>
      <ImageContainer 
        ref={containerElem}>
        <ImagePreview
          visible={props.placeholderImg !== undefined || isSuccess || props.value}
          ref={previewElem}
          src={(props.placeholderImg) ?? ''}
        ></ImagePreview>
      </ImageContainer>
    </FormField>
  );
});