import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'


import Page from './page'
import Progress from '../components/shared/progress'

import { format } from 'date-fns'

import ItchArticle from 'components/zine/itch-article'
import SignUpPrompt from 'components/sign-up-prompt/sign-up-prompt'

import {
  icons,
  MediaType,
  Orientation,
  ReviewStatus,
  ActivityActions
} from '../constants'

import {
  Container,
  Inner
} from './solo-itch-article.styles'
import ZineProvider from 'components/zine/zine.context'
import { usePageReady } from 'contexts/page-ready.context'
import { SignUpPromptProvider } from 'contexts/sign-up-prompt.context'

export default function SoloItchArticle() {
  const { slug } = useParams()
  const { ready } = usePageReady()
  return (
    <SignUpPromptProvider>
      <ZineProvider>
        <Page>
          <Container>
            <Inner>
              {ready ? <SignUpPrompt/> : null}
              <ItchArticle slug={slug} opened={true} defaultOpen={true} />
            </Inner>
          </Container>
        </Page>
      </ZineProvider>
    </SignUpPromptProvider>
  )
}