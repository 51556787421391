import styled from 'styled-components';
import {
  BaseTransition,
  colours,
  fonts,
  breakpoints
} from '../styles/global.styles'

export const Toast = styled.div`
  background-color: ${colours.darkerlight3};
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  min-height: 78px;

  font-family: ${fonts.content.regular};
  color: ${colours.lightgrey4};
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const Container = styled.div`
  background-color: ${colours.darkerlight2};
  display: flex;
  flex-direction: column;
  min-height: 1000px;
`;

export const TilesContainer = styled.div`
  padding:7.5px;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 20px;
  }
`;

export const TilesInnerGrid = styled.div`
  display:grid;
  grid-template-rows:auto;
  grid-gap: 21px;
  grid-auto-flow: dense;
`;

export const TilesSubGridRow = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 21px;
  grid-auto-flow: dense;
`

/*
  try the abolutes positioning, trick is to also calculate the tile container height from the cards
*/
export const TilesInnerAutoPositioned = styled.div`
`

export const TilesInnerPositioned = styled.div`
  height: calc(${props => props.rows} * 25vw);
  position: relative;
  overflow-x: hidden;

  @media screen and (max-width:${breakpoints.sm}px) {
    height: auto;
  }
`

export const TilesRow = styled(BaseTransition)`
  position: relative;
  height: ${props => props.expandedWithin ? 50:25 }vw;
  transition-duration: .1s;

  @media (max-width:${breakpoints.sm}px) {
    height: auto;
  }
`
