import React, { useState, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import InputText from './input-text';
import DropDown from './dropdown';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputTextWrapper = styled(InputText)`
  position: relative;
  z-index: 2;
  top: 0;
  left:0;
  padding-left: ${props => props.prefix === 'true' ? '30px': '10px'} !important;
`;

const DropDownWrapper = styled.div`
  position: relative;
  top: 10px;
  z-index: 9;
  display: ${props => props.visible ? 'block': 'none'};
`;

export default forwardRef(function Autocomplete(
  props,
  ref
) {
  const {
    prefix,
    options = [],
    onChange,
    onBlur,
    value 
  } = props;
  const inputElem = useRef(null);
  const [matches, setMatches] = useState([]);

  return (
    <Wrapper ref={ref}
      className="auto-complete">
      {prefix ?? null}
      <InputTextWrapper
        prefix={prefix !== undefined ? 'true' : 'false'}
        borderBottom={true}
        className="input-text"
        defaultValue={value ? (options.find(
          item => item?.value?.toUpperCase() === (value ?? '')
        )?.name ?? '') : ''}
        type={props.type ?? 'text'}
        ref={inputElem}
        onChange={(evt) => {
          const found = options.filter((item) => {
            if (evt.target.value.length > 0)
              return item.name.toUpperCase().indexOf(evt.target.value.toUpperCase()) === 0
            else
              return [];
          });
          setMatches(found);
        }}
      />
      <DropDownWrapper visible={matches.length > 0}>
        <DropDown
          visible={matches.length > 0} 
          items={matches}
          onSelect={(selected) => {
            inputElem.current.value = selected.name;
            setMatches([])
            onChange && onChange(selected.value)
            onBlur && onBlur()
          }}
        > 
        </DropDown>
      </DropDownWrapper>
    </Wrapper>
  );
});