import styled from 'styled-components'
import {
  fonts,
  colours,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  min-height: 100vh;
  padding: 55px 10px;
  background-color: ${colours.darkerlight2_alpha95};
  display:flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    padding-top: 95px;
  }
`

export const SubTypesNavigation = styled.div`
  
`

export const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${colours.dark2};
  font-family: ${fonts.title.xbold};
  font-weight: 800;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  line-height: 15px;
  margin-left:auto;
  margin-right:auto;

  &:before,
  &:after {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0em;
    justify-content: center;
    padding-top:10px;
    max-width: 721px;
    
    &:before {
      content: '';
      display: inline-block;
      justify-self: flex-start;
      width: 236px;
      border-bottom: 1px solid ${colours.dark2};
      margin-right: 10px;
      flex: 1 1 auto;
    }
    &:after {
      content: '';
      display: inline-block;
      justify-self: flex-end;
      width: 236px;
      border-bottom: 1px solid ${colours.dark2};
      margin-left: 10px;
      flex: 1 1 auto;
    }
    &>span {
      width: fit-content;
      flex: 0 0 auto;
    }
  }
`;