import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  ImageContainer,
  Image,
  Right,
  Info,
  Title,
  Description,
  OwnerPanelContainer,
  Body,
  TagsContainer,
  TimeStamp,
  ActivityPanelContainer,
  EditButton,
  PlayButton,
  DeleteButton,
  VideoPlayer,
  Actions,
  StatsButton,
  OwnerActions,
  StatsContainer
} from './artwork-tile.styles'
import Tile from '../tile/tile'

import CounterItem from './activity-panel/counter-item'

import TileStatus from './tile-status'
import TileMessage from './tile-message'
import ViewTag from '../shared/view-tag'

import HoverIcon from '../shared/hover-icon'
import YouTubeEmbed from '../youtube/youtube'
import VimeoEmbed from '../vimeo/vimeo'

import { 
  icons,
  MediaType,
  Orientation,
  ReviewStatus,
  ActivityActions,
  RejectReasonLinks,
  Links,
  RejectReason
} from '../../constants'

import {
  breakpoints
} from 'styles/global.styles'

import Expandable from '../shared/expandable/expandable'
import TileAlert from './tile.alert'

import { format } from 'date-fns'
import useModal from '../../hooks/modal.hook'
import useSetMyArtworkReviewRespond from '../../hooks/set-artworks-review-respond.hook'
import useDeleteMyArtwork from 'hooks/delete-my-artwork.hook'
import useArtworkActions from 'hooks/artwork-actions.hook'

import TileOwnerPanel from './owner-panel/tile.owner-panel'

import LastActivityPanel from './activity-panel/last-activity-panel'
import Counters from './activity-panel/counters'
import Comments from './activity-panel/comments'
import TileActions from './tile-actions'

import useAuth from '../../hooks/auth.hook'
import useFullScreen from '../../hooks/fullscreen.hook'

import {
  colours
} from '../../styles/global.styles'
import { useHistory, useLocation, useRouteMatch, Link } from 'react-router-dom'
import Comment from 'components/comment/comment'

import { imageUrl, ensureLinks } from 'utils/utils'

import useActivityViews from 'hooks/activity-views.hook'
import useActionAlert from 'hooks/action-alert.hook';
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'
import { useWindowDimensions } from 'utils/helpers'
import useElementInViewport from 'hooks/element-in-viewport.hook'

export function getPoster({
  image,
  mediaType,
  imageVariants,
  video,
  isMobile
}) {
  if (mediaType === MediaType.Image) {
    const thumbImage = imageVariants ? (isMobile ? imageVariants.small : imageVariants.medium) : image
    const expandedImage = imageVariants ? (imageVariants.expanded || imageVariants.large) : image

    return {
      thumbUrl: process.env.REACT_APP_UPLOADS_URL + '/' + thumbImage.filename,
      expandedUrl: process.env.REACT_APP_UPLOADS_URL + '/' + expandedImage.filename,
      position: image.position ?? 'center center',
      orientation: image.width > image.height ? Orientation.Landscape : Orientation.Portrait
    }
  } else {
    if (video) {
      const { posterUrl, position } = video
      return {
        thumbUrl: posterUrl,
        expandedUrl: posterUrl,
        position,
        orientation: Orientation.Landscape
      }
    } else {
      return {
        thumbUrl: null,
        expandedUrl: null,
        position: null,
        orientation: Orientation.Landscape
      }
    }
  }
} 

export default function ArtworkTile({
  onClick,
  id,
  title,
  description,
  mediaType,
  image,
  imageVariants,
  video,
  tags,
  lastActivity,
  featuredChapter,
  activityAggregates,
  comments,
  createdAt,
  expanded = false,
  index = 0,
  reviewRespondAt = null,
  reviewStatus = ReviewStatus.Pending,
  rejectedReason,
  user = null,
  votedByUser,
  viewedByUser,
  itchbookedByUser,
  row,
  col,
  expandedCol,
  parentRowExpanded,
  onExit,
  onExpand,
  onVotesClick,
  onCommentsClick,
  onViewsClick,
  onItchbooksClick,
  disableOwnerActions = false,
  disableViewAnalytics = false,
  ...rest
}) {
  const { isMobile } = useWindowDimensions()
  const { handleAction, linkProps, link }= useLoggedOutHandleRoute()
  const { setFullScreen } = useFullScreen()
  const { setRespond } = useSetMyArtworkReviewRespond()
  const { deleteOne } = useDeleteMyArtwork()
  const { createActivity } = useArtworkActions()
  const { setModal } = useModal()
  const [ showFull, setShowFull ] = useState(false)
  const [ alertActive, setAlertActive ] = useState(reviewStatus !== ReviewStatus.Pending)
  const { session } = useAuth()
  const { push } = useHistory()
  const match = useRouteMatch()
  
  const { isViewed, pushViewed, isViewedExpanded, pushViewedExpanded } = useActivityViews()
  const { setActionAlert, closeAlert } = useActionAlert()
  const containerRef = useRef(null)
  const [expandComplete, setExpandComplete] = useState(false)
  const { pathname } = useLocation()
  const isInViewport = useElementInViewport(containerRef)

  const poster = getPoster({
    image,
    mediaType,
    imageVariants,
    video,
    isMobile
  })
  const { 
    thumbUrl,
    expandedUrl,
    position,
    orientation
  } = poster

  const tagsRendered = (tags ?? []).map((tag, i) => 
    <ViewTag key={`tag-${i}`} text={tag} url={link('/search/artworks?q=' + tag)} />
  )

  useEffect(() => {
    if (!expanded) {
      setShowFull(false)
      setExpandComplete(false)
    }
  }, [expanded])

  const expandHandler = () => {
    if (reviewStatus === ReviewStatus.Approved) {

      if (!isViewedExpanded({
        name: 'artworks',
        id
      })) {
        createActivity({
          id, 
          action: ActivityActions.Viewed
        })
        pushViewedExpanded({
          name: 'artworks',
          id
        })
      }
    }

    onExpand && onExpand()
  }

  const getRejectDescription = (rejectedReason) => {
    if (rejectedReason === RejectReason.Explicit) {
      return `This artwork is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} content</a>. <br/>Please kindly refer to our <a href="${Links.MisUse}" target="_blank">Terms & Conditions</a>. Thank you.`
    } else
    if (rejectedReason === RejectReason.Legal) {
      return `This artwork is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} reasons</a>. <br/>Please kindly refer to our <a href="${Links.ProtectingRights}" target="_blank">Terms & Conditions</a>. Thank you.`
    } else
    if (rejectedReason === RejectReason.Unprofessional) {
      return `This artwork is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} content</a>. <br/>Thank you.`
    } else
    if (rejectedReason === RejectReason.Irrelevant) {
      return  `This artwork is disapproved for <a href="${RejectReasonLinks[rejectedReason]}" target="_blank">${rejectedReason} content</a> . Kindly upload artworks that are fashion related, within your field of expertise, and with proper descriptions. Thank you.`
    } else {
      return null
    }
  }

  const alertRejectedRender = (rejectedReason) => (
    <TileAlert 
      icon={icons['rejected.svg']}
      title="Oops!"
      description={getRejectDescription(rejectedReason)}
      onClose={() => {
        setRespond(id)
        setAlertActive(false)
      }}
    />
  )

  const alertApprovedRender = 
    <TileAlert 
      icon={icons['approved-artwork-logo.svg']}
      title="Wow, you’ve done it!"
      description={`This artwork is now live on itch!`}
      onClose={() => {
        setRespond(id)
        setAlertActive(false)
      }}
    />

  const handleDelete = () => {
    setActionAlert({
      noClose: true,
      theme: 'dark',
      icon: icons['pink-alert-trash-triangle.svg'],
      active: true,
      title: 'Delete my artwork from gallery?',
      body: `You can’t undo this action.<br/><br/>
        <b>Are you sure you want to continue?</b>`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        deleteOne(id)
        closeAlert()
      }
    })
  }

  const isOwner = (user?._id === session?.profile?.id ?? null)
  
  const isEditable = (reviewStatus === ReviewStatus.Rejected || reviewStatus === ReviewStatus.Approved) && isOwner && !disableOwnerActions
  
  const isLandscape = mediaType === MediaType.VideoLink || (image && (image.width > image.height))
  const displayImage = mediaType === MediaType.Image ? image : {
    url: video.posterUrl
  }

  const isVimeo = video?.url.indexOf('https://vimeo.com/') >= 0
  const isYoutube = video?.url.indexOf('https://www.youtube.com/') >= 0 || video?.url.indexOf('https://youtu.be/') >= 0

  let borderColor = colours.pink
  if (reviewStatus === ReviewStatus.Approved) 
    borderColor = colours.transparent
  if (reviewStatus === ReviewStatus.Rejected) 
    borderColor = colours.darkergrey2
  
  useEffect(() => {
    if (isInViewport && mediaType === MediaType.Image) {
      if (!isViewed({
        name: 'artworks',
        id
      })) {
        createActivity({
          id, 
          action: ActivityActions.Viewed
        })
        pushViewed({
          name: 'artworks',
          id
        })
      }
    }
  }, [isInViewport])

  return (
    <Tile
      disabled={reviewStatus === ReviewStatus.Rejected}
      responsiveHeight={{
        [breakpoints.sm]: '64vw'
      }}
      row={row}
      col={col}
      expandedCol={expandedCol}
      parentRowExpanded={parentRowExpanded} 
      expanded={expanded}
      onExpandCompleted={() => {
        if (containerRef?.current) {
          const tile = containerRef.current.parentElement.parentElement
  
          let viewPortTopOffset = 0
          let scrollTop = 0
          if (match.path === '/:itchUrl' ||
            pathname.indexOf('/gallery') > 0 ||
            pathname.indexOf('/my-profile') === 0) {
            viewPortTopOffset = document.getElementById('header').clientHeight - document.getElementById('user-nav').clientHeight
            // const topNav = document.getElementById('top-nav')
            scrollTop = tile.offsetTop + viewPortTopOffset
            //scrollTop = window.scrollY + containerRef.current.getBoundingClientRect().top - topNav.clientHeight - 45
          } else
          if (pathname.indexOf('/search/artworks') === 0) {
            const bounds = document.getElementById('search-header').getBoundingClientRect()
            viewPortTopOffset = bounds.top + bounds.height
            scrollTop = tile.offsetTop > 0 ? (tile.offsetTop + 10) : 0
          } else
          if (pathname.indexOf('/home') === 0) {
            // viewPortTopOffset = document.getElementById('top-nav').clientHeight
            scrollTop = tile.offsetTop
          }
  
          window.scrollTo({
            top: scrollTop
          })
          setExpandComplete(true)
        }
      }}
      index={index}
      orientation={orientation}
      {...rest}
    >
      <Container 
        ref={containerRef}
        id={`artwork-${id}`}
        borderColor={borderColor}
        expanded={expanded}>
        {!disableOwnerActions && reviewStatus === ReviewStatus.Rejected && !reviewRespondAt ? alertRejectedRender(rejectedReason) : null}
        {!disableOwnerActions && reviewStatus === ReviewStatus.Approved && !reviewRespondAt ? alertApprovedRender : null}
        {expanded ? <OwnerPanelContainer 
          className="mobile-expanded"
          dropShadow={reviewStatus === ReviewStatus.Approved}
          borderColor={borderColor}
          expanded={expanded}
        >
          {reviewStatus !== ReviewStatus.Approved ? 
            <TileStatus reviewStatus={reviewStatus} /> : 
            <TileOwnerPanel
              content={'artwork'}
              artwork={{
                id,
                imageUrl: imageUrl(displayImage),
                title
              }}
              isOwner={isOwner}
              expanded={expanded}
              user={user}
            /> 
          }
        </OwnerPanelContainer> : null}
        <ImageContainer
          borderColor={borderColor}
          expanded={expanded}
          isLandscape={isLandscape}
        >
          {reviewStatus === ReviewStatus.Approved ? 
            <Actions className="actions">
              <TileActions 
                artworkId={id}
                showFullScreen={mediaType === MediaType.Image}
                showMinimise={expanded && mediaType === MediaType.VideoLink}
                onFullScreen={() => {
                  setFullScreen({
                    active: true,
                    id: id,
                    url: imageUrl(displayImage),
                    height: image?.height,
                    width: image?.width,
                    mediaType,
                    votedByUser,
                    viewedByUser,
                    itchbookedByUser
                  })
                }}
                onExit={() => {
                  onExit()
                }}
                votedByUser={votedByUser}
                viewedByUser={viewedByUser}
                itchbookedByUser={itchbookedByUser}
                mediaType={mediaType} 
                expanded={expanded} />
            </Actions> 
          : null}
          {mediaType === MediaType.Image ? 
            <Image 
              className="image"
              position={position} 
              orientation={orientation}
              expanded={expanded}
              onClick={expandHandler}
            >
              <img src={expanded ? expandedUrl : thumbUrl} alt={title} />
            </Image> 
          : null}
          {mediaType === MediaType.VideoLink ? 
          <VideoPlayer
            position={position} 
            orientation={orientation}
            expanded={expanded}
            onClick={expandHandler}
          >
            {reviewStatus === ReviewStatus.Approved ? <PlayButton>
              <HoverIcon 
                active={icons['white-play-10px.svg']}
                hover={icons['white-play-10px-over.svg']}
              />
            </PlayButton> : null}
            <div className="poster">
              <img src={expanded ? expandedUrl : thumbUrl} alt={title} />
            </div>
            {reviewStatus === ReviewStatus.Approved && expanded ? 
            <>
            {isYoutube ? <YouTubeEmbed className="videoplayer" url={video?.url} /> : null}
            {isVimeo ? <VimeoEmbed className="videoplayer" url={video?.url} /> : null}
            </> : null}
          </VideoPlayer> : null}

          {isOwner ? <OwnerActions expanded={expanded}>
            {!disableOwnerActions && reviewRespondAt && reviewStatus === ReviewStatus.Approved ? 
              <StatsButton 
                onClick={() => {
                  setModal({
                    name: 'activity-history',
                    payload: {
                      schemaName: 'artworks',
                      action: 'all',
                      title: 'artwork activity history',
                      relatedId: id
                    }
                  })
                }}
              >
                <HoverIcon
                  active={icons['activity-grey-16px.svg']}
                  hover={icons['activity-pink-16px.svg']}
                />  
              </StatsButton>
            : null}
            {isEditable && reviewRespondAt ? <EditButton onClick={(evt) => {
              setModal({
                name: 'reveal-artwork',
                id: id
              })
              evt.stopPropagation()
              
            }}>
              <HoverIcon
                active={icons['pencil-grey-16px.svg']}
                hover={icons['pencil-pink-16px.svg']}
              />
            </EditButton> : null}
            {isEditable && reviewRespondAt ? 
            <DeleteButton 
              onClick={(evt) => {
                handleDelete()
                evt.stopPropagation()
              }}
            >
              <HoverIcon
                active={icons['dustbin-grey-16px.svg']}
                hover={icons['dustbin-pink-16px.svg']}
              />
            </DeleteButton> : null}
          </OwnerActions> : null}
        </ImageContainer>
        <Right>
          <OwnerPanelContainer 
            className="desktop"
            dropShadow={reviewStatus === ReviewStatus.Approved}
            borderColor={borderColor}
            expanded={expanded}
          >
            {reviewStatus !== ReviewStatus.Approved ? 
              <TileStatus reviewStatus={reviewStatus} /> : 
              <TileOwnerPanel
                content={'artwork'}
                artwork={{
                  id,
                  imageUrl:imageUrl(displayImage),
                  title
                }}
                isOwner={isOwner}
                expanded={expanded}
                user={user}
              /> 
            }
          </OwnerPanelContainer>
          <Info 
            expanded={expanded}
          >
            {isOwner && reviewStatus !== ReviewStatus.Approved ? <TileMessage reviewStatus={reviewStatus} rejectedReason={rejectedReason} /> : null}
            <Title expanded={expanded} className="title">
              <Link {...linkProps(reviewStatus === ReviewStatus.Approved ? `/artworks/${id}`: '')}>{title.substr(0, 55)}
              </Link>
            </Title>
            {expanded ? 
            <Body className="artwork-body">
              <Expandable
                className="expandable"
                disableExpand={isMobile}
                footer={<TimeStamp>{format(new Date(createdAt), 'MMMM d yyyy h:mm a')}</TimeStamp>}
              >
                <Description className="description" dangerouslySetInnerHTML={{__html: ensureLinks(description) }} />
              </Expandable>
              <TagsContainer>
                {tagsRendered}
              </TagsContainer>
            </Body> 
            : null}
            <StatsContainer>
            {featuredChapter > 0 ? 
              <CounterItem
                expanded={expanded}
                iconActive={icons['featured-grey.svg']}
                iconHover={icons['featured-pink.svg']}
                action={ActivityActions.Featured}
                label={`Featured on zine #${featuredChapter}`}
                url={link(`/zine/${featuredChapter}`)}
              /> : null}
            {reviewStatus === ReviewStatus.Approved ? 
              <Counters 
                expanded={expanded}
                activityAggregates={activityAggregates} 
                onVotesClick={() => {
                  handleAction(() => {
                    onVotesClick && onVotesClick()
                  })
                }}
                onViewsClick={() => {
                  handleAction(() => {
                    onViewsClick && onViewsClick()
                  })
                }}
                onItchbooksClick={() => {
                  handleAction(() => {
                    onItchbooksClick && onItchbooksClick()
                  })
                }}
              />
            :null }
            </StatsContainer>
            {reviewStatus === ReviewStatus.Approved ?
              <>
              {expandComplete ? <Comment id={id} contentType={'artworks'} /> : <Comments expanded={expanded} comments={comments} />}
              </>
            :null }
          </Info>
          {reviewStatus === ReviewStatus.Approved && lastActivity ? 
          <ActivityPanelContainer 
            borderColor={borderColor}
            expanded={expanded}>
            <LastActivityPanel
              expanded={expanded}
              {...lastActivity}
            />
          </ActivityPanelContainer>: null}
        </Right>
      </Container>
    </Tile>
  )
}