import React, { useEffect, useMemo } from 'react';
import {
  useLocation
} from 'react-router-dom'
import {
  Container,
  NoResult
} from './collectives.styles'
import CollectiveItem from 'components/collective-item/collective-item';
import useSearchUserByKeywords from 'hooks/search-users-bykeywords.hook'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function Collectives() {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const { 
    success,
    users,
    nextPage,
    loading,
    hasNext,
    refresh
  } = useSearchUserByKeywords({
    page: 0,
    size: 10,
    query: queryParams.get('q')
  })
  
  useBottomScrollListener(() => {
    if (hasNext) {
      nextPage()
    }
  })

  useEffect(() => {
    refresh()
  }, [search])

  return (
    <Container>
      {loading && !success ? <ProgressLoader/> : null}
      {success ? (users ?? []).map(user => (
        <CollectiveItem {...user} />
      )) : null}
      {success && (users ?? []).length === 0 ? 
      <NoResult>There are no results for {queryParams.get('q')}</NoResult>: null}
    </Container>
  )
}