import { rgbToHex } from '@material-ui/core';
import { SportsRugbySharp } from '@material-ui/icons';
import { createContext } from 'react';
import styled from 'styled-components';

export const themes = {
  Flare: 1,
  Metro: 2
}

export const fonts = {
  content: {
    bold: 'ProximaNovaBold',
    regular: 'ProximaNovaRegular',
    light: ''
  },
  title: {
    xbold: 'NexaXBold',
    bold: 'NexaBold',
    book: 'NexaBook',
    light: 'NexaLight',
    regular: 'NexaRegular',
  }
}

export const breakpoints = {
  xs: 360,
  sm: 768
}

export const sizes = [

]

export const colours = {
  white: 'rgb(255,255,255)',
  white_alpha90: 'rgba(255,255,255, .9)',
  black: 'rgb(0,0,0)',
  darkpink: 'rgb(107,7,48)',
  darkerpink: 'rgb(125, 8, 56)',
  darkpink_alpha90: 'rgba(107,7,48, .9)',
  darkpink_alpha95: 'rgba(107,7,48, .95)',
  darkpink2_alpha90: 'rgba(162, 10, 72, 0.9)',
  pink: 'rgba(236, 15, 105, 1)',
  tint: {
    flare: 'rgba(179,11,80,0.7)',
    light: 'rgba(239,239,239,0.75)',
    metro: 'rgba(35,31,32, .75)',
  },
  lightpink2: 'rgba(230,203,202,1)',
  lightgreypink: 'rgb(217,199,206)',
  lightgreypink_alpha92: 'rgba(217,199,206, 0.92)',
  lightpink: 'rgba(255, 119, 130 ,1)',
  pink_alpha90: 'rgba(236, 15, 105, .9)',
  pink_alpha70: 'rgba(236, 15, 105, .7)',
  pink_alpha60: 'rgba(236, 15, 105, .6)',
  pink_alpha50: 'rgba(236, 15, 105, .5)',
  pink_alpha40: 'rgba(236, 15, 105, .4)',
  pink_alpha20: 'rgba(236, 15, 105, .2)',
  pink_alpha15: 'rgba(236, 15, 105, .15)',
  pink_alpha10: 'rgba(236, 15, 105, .1)',
  darker: 'rgb(36, 36, 36)',
  darker_alpha70: 'rgba(36, 36, 36, .7)',
  darker_alpha90: 'rgba(36, 36, 36, .9)',
  dark: 'rgba(43, 42, 41, 1)',
  dark2: 'rgba(43, 43, 43, 1)',
  dark_alpha70: 'rgba(43, 42, 41, .7)',
  light2: 'rgb(235,235,235)',
  light2_alpha85: 'rgb(235,235,235, .85)',
  light: 'rgba(239,239,239,1)',
  light_alpha60: 'rgba(239,239,239,.6)',
  light_alpha40: 'rgba(239,239,239,.4)',
  light_alpha70: 'rgba(239,239,239,.7)',
  light3: 'rgba(240,240,240,1)',
  light4: 'rgba(242,242,242,1)',
  light5_alpha08: 'rgba(248,248,248,.8)',
  darklight: 'rgba(229,228,228, 1)',
  darklight_alpha90: 'rgba(229,228,228, 0.9)',
  darkerlight3: 'rgba(190,190,190, 1)',
  darkerlight: 'rgba(205,205,205, 1)',
  darkerlight4: 'rgba(204,204,204, 1)',
  darkerlight2: 'rgba(214,214,214, 1)',
  darkerlight5: 'rgba(214,212,208, 1)',
  darkerlight6: 'rgba(195,195,195, 1)',
  darkerlight7: 'rgba(210,210,210, 1)',
  darkerlight8: 'rgba(212,211,208, 1)',
  darkerlight2_alpha95: 'rgba(214,214,214, .95)',
  darkerlight2_alpha90: 'rgba(214,214,214, .9)',
  darkest1_alpha90: 'rgba(36,36,36, 0.9)',
  darkergrey2: 'rgba(63, 62, 60, 1)',
  darkergrey: 'rgba(60, 60, 60, 1)',
  darkgrey: 'rgba(79,79,79,1)',
  darkgrey2: 'rgba(84,83,82,0)',
  lightgrey: 'rgba(128,128,128,1)',
  lightgrey2: 'rgba(130,130,130,1)',
  lightgrey5: 'rgba(140,140,130,1)',
  lightgrey6: 'rgba(160,160,160,1)',
  lightgrey8: 'rgba(168,168,168,1)',
  lightgrey3: 'rgba(112,112,112, 1)',
  lightgrey4: 'rgb(100,100,100)',
  lightgrey7: 'rgb(202, 201, 198)',
  lightgrey9: 'rgb(196,190,190)',
  grey: 'rgba(165, 165, 165, 1)',
  grey2: 'rgba(170, 170, 170, 1)',
  text: 'rgba(189, 189, 189, 1)',
  black_alpha95: 'rgba(0,0,0, .95)',
  black_alpha92: 'rgba(0,0,0, .92)',
  black_alpha85: 'rgba(0,0,0, .85)',
  black_alpha80: 'rgba(0,0,0, .8)',
  black_alpha50: 'rgba(0,0,0, .5)',
  transparent: 'transparent',
  metro: {
    title: 'rgba(236, 15, 105, 1)',
    text: 'rgba(189, 189, 189, 1)',
  },
  flare: {
    title: 'rgba(239, 239, 239, 1)',
    text: 'rgba(239, 239, 239, 1)',
  }
}

export const BaseTransition = styled.div`
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
`

export const BaseContainer = styled.div`

`;

export const BaseText = styled.div`
  color: ${colours.text};
`;

export const ButtonReset = styled.button`
  outline:none;
  border:none;
  background:transparent;
  padding:0;
  margin:0;
  text-align:center;
`;

export const ButtonPrimary = styled(ButtonReset)`
  color: ${props => props.theme === themes.Flare ? colours.white : colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 16px;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  border:1px solid ${props => props.theme === themes.Flare ? colours.white : colours.pink};
  padding: 20px;
  width: 100%;
  max-width: 180px;

  
  &:hover {
    background-color: ${colours.pink_alpha50};
  }

`;

export const BaseImage = styled.img`
  display:block;
  width: 100%;
`;

export const BaseInputText = styled.input`
  background-color: transparent;
  border:none;
  outline: none;
  padding:6px;
  margin:0;
  width: 100%;
  color: ${props => props.error === 'true' ? colours.pink : colours.darkergrey2};
`;

export const TitleWithLeftRightLines = styled.div`
  color: ${colours.dark};
  font-family: ${fonts.title.xbold};
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;

  &:before {
    content: '';
    width:1px;
    flex: 1 1 auto;
    border-bottom: 1px solid ${colours.dark};
    margin-right: 10px;
  }

  &:after {
    content: '';
    width:1px;
    flex: 1 1 auto;
    border-bottom: 1px solid ${colours.dark};
    margin-left: 10px;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const ScrollStyledBox = styled.div`
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(236, 15, 105, 1);
  }
`

export const ScrollInvisibleStyledBox = styled.div`
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0,0,0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(236, 15, 105, 1);
  }
`