import styled from 'styled-components'
import {
  fonts,
  breakpoints,
  colours
} from '../styles/global.styles'
import { Link } from 'react-router-dom'
export const Title = styled.div`
  color: ${colours.dark};
  font-family: ${fonts.title.xbold};
  font-weight: 800;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  max-width: 100%;
  position: static;
  top: 55px;
  margin-top:20px;
  margin-bottom:10px;
  
  &:before {
    content: '';
    display: inline-block;
    justify-self: flex-start;
    width: 236px;
    border-bottom: 1px solid ${colours.dark};
    margin-right: 10px;
    flex: 1 1 auto;
  }
  &:after {
    content: '';
    display: inline-block;
    justify-self: flex-end;
    width: 236px;
    border-bottom: 1px solid ${colours.dark};
    margin-left: 10px;
    flex: 1 1 auto;
  }
  &>span {
    width: fit-content;
    flex: 0 0 auto;
    white-space:nowrap;
  }
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0em;
    justify-content: center;
    padding-top:10px;
    max-width: 721px;
    margin-top:20px;
    margin-bottom:20px;
  }
`;
export const Container = styled.div`
  width: 100%;
  min-height:calc(100vh - 65px);
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  padding-bottom: 20px;
  background-color: ${colours.lightgrey7};
  @media (max-width:${breakpoints.sm}px) {
    padding:0 20px;
  }
`
export const Inner = styled.div`
  width: 100%;
  max-width: 1120px;
  display:flex;
  flex-direction:column;
  align-items:center;
`
export const Grid = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  @media (max-width:${breakpoints.sm}px) {
    display:block;
  }
`
export const ArticleCard = styled(Link)`
  width: 100%;
  height: 590px;
  display:flex;
  flex-direction: column;
  justify-content:flex-start;
  background-color: ${colours.darkerlight8};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  
  .image-container {
    height: 300px;
    flex: 0 0 300px;
    width: 100%;
    overflow:hidden;
    img {
      object-fit:cover;
      width:110%;
      height:100%;
      transition: all .15s;
      transform: translateX(-10px);
    }
  }
  .content {
    flex: 1 1 auto;
    padding: 10px 22px 10px 22px;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    .category {
      color: ${colours.dark};
      font-family: ${fonts.content.bold};
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.1em;
      padding-top:3px;
      padding-bottom: 10px;
      border-bottom: 1px solid ${colours.text};
      margin-bottom: 8px;
      width:100%;
      text-align:left;
      text-transform: uppercase;
    }
    .title {
      text-align:left;
      color: ${colours.dark};
      font-family: ${fonts.content.bold};
      font-size: 24px;
      line-height: 26px;
    }
    .sub-head {
      text-align:left;
      color: ${colours.dark};
      font-family: ${fonts.content.regular};
      font-size: 14px;
      line-height: 16px;
    }
    .author-date {
      text-align:left;
      margin-top: auto;
      font-size: 11px;
      color: ${colours.dark};
      font-family: ${fonts.content.regular};
      b {
        font-family: ${fonts.content.bold};
      }
      .name {
        font-family: ${fonts.content.bold};
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: elipsis;
        width:100px;
        
        &:hover {
          color: ${colours.pink};
        }
      
      }
    }
  }
  
  &:hover {
    .image-container>img {
      transform: translateX(0px);
    }
    .content {
      .category {
        color: ${colours.pink};
        border-bottom-color: ${colours.pink};
      }
      .title {
        color: ${colours.pink};
      }
      .sub-head {
        color: ${colours.pink};
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom:20px;
    height: 550px;
    .image-container {
      height: 260px;
      flex: 0 0 260px;
      width: 100%;
      overflow:hidden;
    }
    .title {
      font-size: 22px;
      line-height: 24px;
    }
    .sub-head {
      font-size: 13px;
      line-height: 15px;
    }
  }  
`