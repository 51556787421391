import styled from 'styled-components'
import {
  fonts,
  colours,
  BaseTransition,
  ScrollStyledBox,
  ButtonReset,
  breakpoints
} from '../styles/global.styles'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  padding: 10px;
`

export const Container = styled.div`
  margin-left:auto;
  margin-right:auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${colours.transparent};
  height: calc(100vh - 85px);
  width: ${props => props.isLandscape ? '100%': '60vw'};
  min-width: ${props => props.isLandscape ? '100%': '800px'};;
  position: relative;
  background-color: ${colours.light2};

  .image>img {
  }
  .poster>img {
  }

  .mobile {
    display:none;
  }
  .desktop {
    display: block;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    min-width: 100%;
    height: 100%;
    flex-direction: column;
    border:none;

    .mobile {
      display:block;
    }
    .desktop {
      display:none;
    }
  }
`

export const ImageContainer = styled(BaseTransition)`
  flex: 0 0 ${props => props.isLandscape ? 75:60}%;
  border-right:1px solid ${colours.transparent};
  height: 100%;
  cursor: pointer;
  transition-duration: .1s;

  
  &:hover {
    .actions {
        visibility: visible;
      }
  }


  @media (max-width: ${breakpoints.sm}px) {
    flex:1 1 auto;
    width: 100%;
    border-right: none;
    padding-bottom: 58px;
    position: relative;
  }
`

export const Image = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &>img {
    display:block;
    object-fit: cover;
    object-position: ${props => props.position};
    width: 100%;
    height: 100%;
    transition-property: all;
  }
`

export const VideoPlayer = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .poster {
    width: 100%;
    height: 100%;
    &>img {
      display:block;
      object-fit: cover;
      object-position: ${props => props.position};
      width: 100%;
      height: 100%;
      transition-property: all;
    }
  }

  .videoplayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display:block;
  }
`

export const PlayButton = styled(ButtonReset)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index:2;
  transform: translate(-50%, -50%);
`

export const Title = styled(Link)`
  font-family: ${fonts.content.bold};
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.darkergrey2};
  margin-bottom: 10px;
  cursor: pointer;
  
  &:hover {
    color: ${colours.pink};
  }


  @media (max-width:${breakpoints.sm}px) {
    width:100%;
    font-size: 12px;
    line-height: 12px;
  }
`

export const Body = styled.div`
  flex: 0 1 auto;
  width: 100%;
  display:flex;
  flex-direction:column;
  overflow: ${props => props.scroll ? 'visible':'hidden'};
`

export const StatsContainer = styled.div`
  width:100;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top:auto;
`

export const Description = styled.div`
  text-align: left;
  font-family: ${fonts.content.regular};
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`

export const TagsContainer = styled.div`
  padding-top: 30px;
  margin-left: -4px;
  margin-right: -4px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &>.tag {
    background-color: ${colours.darklight};
    color: ${colours.lightgrey3};
    margin: 4px;
    cursor: pointer;
  }
`

export const TimeStamp = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.grey};
  margin-top: 4px;
`

export const Right = styled.div`
  flex: 1 1 auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow:hidden;

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
  }
`

export const Info = styled(ScrollStyledBox)`
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${colours.light2};

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
    padding: 14px;
  }
`

export const UserPanelContainer = styled.div`
  border-bottom:1px solid ${colours.transparent};
  height: 98px;
  flex: 0 0 98px;
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${colours.light4};
  ${props => props.dropShadow ? `
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  `: ``}

  .user-avatar {
    width: 98px;
  }

  .engage {
    display: block;
    flex: 0 0 25px;
    margin-bottom: 10px;

    .engage-button {
      width: 18px;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
    width:100%;
    display:flex;
    height: 66px;
    flex: 0 0 66px;
  }
`

export const ActivityPanelContainer = styled.div`
  border-top:1px solid ${colours.transparent};
  flex: 0 0 60px;
  width: 100%;
  background-color: ${colours.light4};
`

export const Actions = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 3;
  visibility: hidden;

  @media (max-width:${breakpoints.sm}px) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left:-6px;
    margin-right:-6px;
    top: auto;
    visibility: visible;
    padding-left:14px;
    padding-right:14px;

    .label {
      .mobile-text {
        display: block;
      }
    }
  }
`
