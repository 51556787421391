import React, { forwardRef, useState, useEffect, useRef } from 'react'
import './style.scss'

import adminTheme from "./themes/adminTheme"

import { $getRoot, $getSelection, $setSelection, $insertNodes, $createParagraphNode } from 'lexical';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";

import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
// import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
// import { ListItemNode, ListNode } from "@lexical/list";
// import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
// import { ListPlugin } from "@lexical/react/LexicalListPlugin";
// import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
// import { TRANSFORMERS } from "@lexical/markdown";
import { $restoreEditorState } from '@lexical/utils'

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
// import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ImagePlugin from "./plugins/ImagePlugin";
import { ImageNode } from "./nodes/ImageNode";
import MentionsPlugin from './plugins/MentionsPlugin';
import { MentionNode } from './nodes/MentionNode';
import EmbedVideoPlugin from './plugins/EmbedVideoPlugin';
import { VideoNode } from './nodes/VideoNode';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import ContentPastePlugin from './plugins/ContentPastePlugin';

import styled from 'styled-components'

function Placeholder(props) {
  const { text } = props
  return <div className="editor-placeholder">{text ?? ''}</div>;
}

const EditorCapturePlugin = React.forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    ref.current = editor;
    return () => {
      ref.current = null;
    };
  }, [editor, ref]);

  return null;
});


const editorConfig = {
  // The editor theme
  theme: adminTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    // HeadingNode,
    // ListNode,
    // ListItemNode,
    // QuoteNode,
    // CodeNode,
    // CodeHighlightNode,
    // TableNode,
    // TableCellNode,
    // TableRowNode,
    // AutoLinkNode,
    LinkNode,
    ImageNode,
    MentionNode,
    VideoNode
  ]
};


const InitialValue = ({value}) => {
  // const [initialValue, setInitialValue] = useState('')
  // const [defaultValue, setDefaultValue] = useState('')
  const [editor] = useLexicalComposerContext();
  
  useEffect(() => {
    
    return editor.registerUpdateListener((listener) => {
      if (!value || !listener.tags.has('history-merge')) return;
      
      editor.update(() => {
        
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        
        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);
        
        // Select the root
        $getRoot().select();
      
        // Insert them at a selection.
        $insertNodes(nodes);
        $setSelection(null);
      })
    })
  }, [editor, value])
  /*
  useEffect(() => {
    if (value === '') {
      editor.update(() => {
        $getRoot().clear()
        setDefaultValue('')
      })
    } else
    if (value && defaultValue === '') {
      
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        
        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);
        
        // Select the root
        $getRoot().select();
      
        // Insert them at a selection.
        $insertNodes(nodes);
        $setSelection(null);
        setDefaultValue(value)
      });
    } else
    if (value && value !== '' 
      && defaultValue !== '' && value === defaultValue) {
        // console.log('value is same as default')
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(defaultValue, 'text/html');
          
          // Once you have the DOM instance it's easy to generate LexicalNodes.
          const nodes = $generateNodesFromDOM(editor, dom);
          
          // Select the root
          $getRoot().clear();

          $insertNodes(nodes);
          
        });
    }
  }, [editor, value])
  */

  return null
}

const countCharacters = (html) => {
  const plain = (html ?? '').replace(/(<([^>]+)>)/gi, "")
  return plain.length
}

const CharacterCount = styled.div`
  
`

const MaxCharacterCount = styled.div`
`

const RichTextEditor = forwardRef((props, ref) => {
  const [charCount, setCharCount] = useState(0)
  const { 
    onChange, onBlur, value, 
    options, disabled, disableToolbar,
    renderBackElement,
    maxLength,
    placeholder,
    className,
    onPaste,
    ...rest
  } = props

  const {
    imageUpload = false,
    video = false,
    link = true
  } = options ?? {
    imageUpload: false,
    video: false,
    link: true
  }

  useEffect(() => {
    setCharCount(countCharacters(value))
  }, [value])

  return (
    <div className={`rich-editor-container ${className}`}>
      <LexicalComposer initialConfig={{
        ...editorConfig,
        editable: !disabled ?? true
        }}
      >
        <div className={`editor-container ${disableToolbar ? 'no-toolbar':'' }`}>
          <div className={`editor-inner`} >
            <RichTextPlugin
              InitialValue={value}
              contentEditable={<ContentEditable className="editor-input"/>}
              placeholder={<Placeholder text={placeholder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            {ref ? <EditorCapturePlugin ref={ref} /> : null}
            <ContentPastePlugin/>
            <HistoryPlugin />
            {false ? <AutoFocusPlugin /> : null}
            {link ? <LinkPlugin /> : null}
            {false && <AutoLinkPlugin />}
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <ImagePlugin />
            <MentionsPlugin />
            {video ? <EmbedVideoPlugin /> : null }
            <InitialValue value={value}/>
            <OnChangePlugin onChange={(editorState, editor) => {
              if (editorState && editor) {
                editorState.read(() => {
                  const htmlString = $generateHtmlFromNodes(editor, null);
                  setCharCount(countCharacters(htmlString))
                  onChange && onChange(htmlString)
                })
              }
            }} />
            <MaxLengthPlugin maxLength={maxLength}/>
          </div>
        </div>
        <div className="editor-bottom-bar">
            {renderBackElement ?? null}
            {maxLength === undefined ? <div className="character-count">
            {charCount} characters count
            </div> : null}
            {maxLength ? <div className="character-count">
            {maxLength - charCount} characters remaining
            </div> : null}
            {!disableToolbar ? <ToolbarPlugin options={{...{imageUpload, video, link}}} /> : null}
          </div>
      </LexicalComposer>
    </div>
  );
})

export default RichTextEditor;

/*
  moved the overflow y scroll to parent element, seems to be working fine
*/