import styled from 'styled-components'
import {
  colours,
  fonts,
  ScrollStyledBox,
  ScrollInvisibleStyledBox,
  ButtonPrimary,
  breakpoints
} from 'styles/global.styles'

export const ContentContainer = styled(ScrollInvisibleStyledBox)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: ${colours.lightgrey7};
  position: relative;

  @media (max-width:${breakpoints.sm}px) {
    overflow-y: visible !important;

    .image-area {
      height: 300px;
    }

    .content-area {
      .author-profile {
        .info {
          padding-right:10px;
        }
        .avatar {
          height:auto;
        }
      }
    }
  }
`

export const CoverImage = styled.div`
  width: 100%;
  height: 40vw;

  &>img {
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};
    width: 100%;
    height: 100%;
  }
`

const closeButtonBackground = (theme) => {
  switch (theme) {
    case 'flare':
      return colours.pink_alpha70
    case 'metro':
      return colours.darker_alpha70
    case 'light':
      return colours.light_alpha70
    case 'pink':
      return colours.pink_alpha70
    case 'dark':
      return colours.darker_alpha70
    case 'white':
      return colours.light_alpha70
    default:
      return colours.light_alpha70
  }
}

const closeButtonHoverBackground = (theme) => {
  switch (theme) {
    case 'flare':
      return colours.pink
    case 'metro':
      return colours.darker
    case 'light':
      return colours.light
    case 'pink':
      return colours.pink
    case 'dark':
      return colours.darker
    case 'white':
      return colours.light
    default:
      return colours.light
  }
}

export const StickWrapper = styled.div`
  position: absolute;
  left: auto;
  top: 0px;
  right: 16px;
  margin-right:0;
  width:auto;
  transform: translateY(16px);
  z-index: 1;

  @media (max-width:${breakpoints.sm}px) {
    
  }
`

export const CloseButton = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props => closeButtonBackground(props.theme)};
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &>img {
    display: block;
    width:16px;
  }

  
  &:hover {
    background-color: ${props => closeButtonHoverBackground(props.theme)}
  }

`

export const ContentArea = styled.div`
  width: 100%;
  max-width: 80%;
  height: auto;
  margin-left:auto;
  margin-right:auto;

  .comment-input-container {
    border-color: ${colours.lightgrey6};

    .rich-editor-container {
      border: 1px solid ${colours.lightgrey6};
    }
    
    .comment-input-container__inner {

      .submit-comment {
        border-color: ${colours.lightgrey6};
        color: ${colours.lightgrey6};
        
        
        &:hover {
          color: ${colours.pink};
        }
      
      }
  
      &:focus-within {
        .submit-comment {
          border-color: ${colours.pink};
        }
      }
    }
  }
  .comment-container {
    border-color: ${colours.lightgrey6};
  }
`

export const Caption = styled.div`
  color: ${colours.darkergrey};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
`

export const Heading = styled.div`
  margin-top: 50px;
`

export const ArticleHeading = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.bold};
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
`
export const ArticleSubHeading = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
`

export const AuthorSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  width: 100%;

  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

`

export const AuthorInfoArticleDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  b {
    font-weight: 700;
  }
`

export const Sharing = styled.div`
  margin-left: auto;
`

export const Content = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;

  img {
    display: block;
    width: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    height: 400px;
  }

  a {
    color: ${colours.pink};
    text-decoration: none;
  }
`

export const TagsContainer = styled.div`
  margin-bottom: 50px;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;

  &>* {
    margin-right:5px;
    margin-left:5px;
    text-transform: capitalize;

    &:first-child {
      margin-left:0;
    }
    &:last-child {
      margin-right:0;
    }
  }
`

export const CreatedDate = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
`

export const PublishedDate = styled.div`
  border-top: 1px solid ${colours.text};
  padding-top: 10px;
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`

export const OwnerProfile = styled.div`
  height: 100px;
  .first {
    &>* {
      padding-left:0;
      img {
        width: 40px;
      }
    }
  }
  .info {
    .name>* {
      color: ${colours.black};
      font-family: ${fonts.content.bold};
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    .info-engage {
      .acctypes-location > *{
        color: ${colours.black};
        font-family: ${fonts.content.regular};
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
      .engage {
        padding-bottom: 0;
        .engage-button:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const ShareBottom = styled.div`
  margin-top: 63px;
  display:flex;
  flex-direction: row;
  justify-content: center;
`

export const SignUpContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:50px;
  margin-bottom: 50px;

  .text {
    text-align:center;
    margin-bottom:30px;
  }
  .button {
  }
`

export const SignUpButton = styled(ButtonPrimary)`
  background-color: ${colours.pink};
  color: ${colours.dark2};

  
  &:hover {
    
  }

`