import React, { useState, useEffect, useMemo } from 'react'

// styled components
import {  
  Toast,
  TilesContainer,
  TilesInnerPositioned
} from '../../pages/gallery.styles';
import {
  Container
} from './articles.styles'
import {
  useLocation
} from 'react-router-dom'

import ArticleTile from '../../components/articles/article-tile';

// hooks
import useModal from '../../hooks/modal.hook'
import useMasonry from '../../hooks/masonry.hook'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import useSearchArtworksByKeywords from '../../hooks/search-articles-bykeywords.hook'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function Articles() {
  const { search } = useLocation()
  
  const queryParams = new URLSearchParams(search)
  const { setModal } = useModal()
  const {
    success,
    articles,
    nextPage,
    hasNext,
    loading,
    refresh
  } = useSearchArtworksByKeywords({
    page: 0,
    size: 10,
    query: queryParams.get('q')
  })

  const [expanded, setExpanded] = useState(null)

  const lastCount = useMemo((() => success ? articles?.length : 0), [articles])

  const { grid, expand, init, reset } = useMasonry()

  const articlesRenderItems = (success && grid.length > 0) ? (articles?? []).map((article, i) => (
    <ArticleTile
      disableOwnerActions={true}
      index={i}
      draggable={true}
      position={grid[i]}
      expanded={expanded === (i)}
      key={`article-${article._id}}`}
      {...article}
      id={article._id}
      onClose={() => {
        setExpanded(null)
        reset()
      }}
      onExpand={() => {
        if (expanded === (i)) {
          setExpanded(null)
          reset()
        } else {
          setExpanded(i)
          expand((i), 2)
        }
      }}
    />
  )) : null
  
  useEffect(() => {
    // todo - fix the arrangement for new articles added
    if (success) {
      init(articles?.length, 3)
    }
  }, [success, lastCount])

  useBottomScrollListener(() => {
    if (hasNext) {
      setExpanded(null)
      nextPage()
    }
  })

  useEffect(() => {
    refresh()
  }, [search])
  
  const maxRows = grid.length > 0 ? (Math.ceil(grid.length/ 3) + (expanded ? 1 : 0)): 0

  return (
    <Container>
      <TilesContainer>
        <TilesInnerPositioned rows={maxRows}>
          {articlesRenderItems}
        </TilesInnerPositioned>
        {loading && !success ? <ProgressLoader/> : null}
      </TilesContainer>
    </Container>
  )
}