import React, { useEffect } from 'react';

import { Container } from './zine.styles'
import FeaturedArtworkPreview from 'components/zine/preview-featured-artwork';
import FeaturedArtworkThemeProvider from 'components/zine/featured-artwork.context'
import ZineProvider from 'components/zine/zine.context';

export default function PreviewFeaturedArtwork() {

    useEffect(() => { 
        document.body.style.backgroundColor = '#494949' 
    }, [])

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <ZineProvider>
            <FeaturedArtworkThemeProvider>
                <Container>
                    <FeaturedArtworkPreview/> 
                </Container>
            </FeaturedArtworkThemeProvider>
        </ZineProvider>
    )
}