import { 
  AccountTypes, 
  getProfessionsText,
  AccountTypesName,
  CompanyTypesName
} from '../../constants'

export const getOccupation = (user) => {
  if (user) {
    let occupation = AccountTypesName[user.accountType]
    const professionsText = getProfessionsText(user)
    if (user.accountType === AccountTypes.FashionCompany) {
        occupation = occupation + ' / ' + CompanyTypesName[user.companyType]
    } else {
        occupation = occupation + (professionsText !== '' ? ' / ' + professionsText : '')
    }
    return occupation
  } else
    return ''
}

export const getAccountTypeAbbr = (accountType) => {
  if (accountType === AccountTypes.FashionArtist)
      return 'fa'
  else if (accountType === AccountTypes.FashionCompany)
      return 'fc'
  else if (accountType === AccountTypes.FashionLover)
      return 'fl'
  else return ''
}