import { useState, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getActivitiesOnCurrentUser } from '../apis/user.api';
import useAuth from './auth.hook'

const useGetActivitiesOnCurrentUser = () => {
  const [from, setFrom] = useState(null)
  const { fetching, session } = useAuth()
  const { isLoading, error, 
    data, isSuccess, isFetching, isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: [`current-user-activities-${session?.profile?.id}`], 
    queryFn: ({ pageParam = 0 }) => {
      return (!fetching && session) ? getActivitiesOnCurrentUser(pageParam, 20, from) : null
    },
    getNextPageParam: (lastPage, pages) => {
      if ((!fetching && session == null) || (!fetching && session) === undefined) return undefined

      if (lastPage) {
        if (lastPage.page + 1 < lastPage.totalPages)
          return Number(lastPage.page) + 1
      }
    },
    enabled: false
  })

  const getData = (from) => {
    setFrom(from)
  }

  useEffect(() => {
    refetch()
  }, [from])

  useEffect(() => {
    if (!fetching && session) {
      refetch()
    }
  }, [fetching, session])

  return {
    isLoading,
    isSuccess,
    data: (!fetching && session) ? (isSuccess ? (data.pages ?? []).reduce((acc, curr) => {
      return acc.concat(curr?.data)
    }, []) : []) : [],
    totalItems: (!fetching && session) ? (isSuccess ? data?.pages[0]?.totalRecords ?? 0 : 0) : 0,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    getData
  }
}

export default useGetActivitiesOnCurrentUser