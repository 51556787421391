import { useContext } from "react";
import { ToastContext } from "../contexts/toast.context";

const useToast = () => {
  const [toast, setToast] = useContext(ToastContext);

  return {
    toast,
    setToast
  }
}

export default useToast;