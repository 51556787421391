import styled from 'styled-components'
import {
  fonts,
  colours,
  breakpoints
} from 'styles/global.styles'
import {
  Link
} from 'react-router-dom'

const menuBackground = {
  'pink': colours.pink,
  'dark': colours.darkest1_alpha90,
  'transparent': 'transparent',
}

const menuItemColorHoverOnBackground = {
  'pink': {
    'dark': colours.darkergrey2,
    'pink': colours.darkergrey2,
    'transparent': colours.darkergrey2
  },
  'dark': {
    'dark': colours.pink,
    'pink': colours.darkergrey2,
    'transparent': colours.darkergrey2
  },
  'light': {
    'dark': colours.pink,
    'pink': colours.white,
    'transparent': colours.pink
  }, 
  'dark2': {
    'dark': colours.pink,
    'pink': colours.pink,
    'transparent': colours.pink
  }
}

const menuItemColorOnBackground = {
  'pink': colours.light,
  'light': colours.light,
  'dark': colours.darkergrey2,
  'dark2': colours.lightgrey2
}


export const Container = styled.div`
  position: fixed;
  z-index: 100;
  top: ${props => props.isLoggedIn ? 65: 0}px;
  left:0;
  width: 100%;
  height: 65px;
  padding: 0 25px 0 25px;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => menuBackground[props.background]};

  @keyframes flip {
    0% {
      transform: translateY(0%);
      background-color: transparent;
    }
    50% {
      transform: translateY(-100%);
      background-color: transparent;
    }
    51% {
      transform: translateY(-100%);
      background-color: ${colours.darkest1_alpha90};
    }
    100% {
      transform: translateY(0%);
      background-color: ${colours.darkest1_alpha90};
    }
  }

  @keyframes flip-reverse {
    0% {
      transform: translateY(0%);
      background-color: ${colours.darkest1_alpha90};
    }
    45% {
      transform: translateY(-100%);
      background-color: ${colours.darkest1_alpha90};
    }
    51% {
      transform: translateY(-100%);
      background-color: transparent;
    }
    52% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  animation-duration: .7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-delay:.2s;

  ${props => {
    if (!props.disableflip)
      return `
        ${props.flip ? 'animation-name: flip;' : 'animation-name: flip-reverse;'}
        .logo {
          transition-property: all;
          transition-duration: .3s;
          transition-timing-function: ease-in-out;
          transition-delay: .5s;
        }

        .menu-item {
          transition-property: all;
          transition-duration: .3s;
          transition-timing-function: ease-in-out;
          transition-delay: .5s;
        }

        .v-sep {
          transition-property: all;
          transition-duration: .3s;
          transition-timing-function: ease-in-out;
          transition-delay: .5s;
        }
      `
    else
      return ''
  }}
  
  @media (max-width: ${breakpoints.sm}px) {
    height: 45px;
    padding: 0;
    top: ${props => props.isLoggedIn ? '45px': '0px'};
    justify-content: center;
  }
`

export const Logo = styled.div`
  position: relative;
  visibility: ${props => props.showLogo ? 'visible' : 'hidden'};
  opacity: ${props => props.showLogo ? 1 : 0};
  
  img {
    width: 55px;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
  }

  img:first-child {
    opacity: 1;
  }

  img:last-child {
    opacity: 0;
    position: absolute;
    top:0;
    left:0;
  }

  ${props => props.menuTheme !== 'light' || props.background !== 'pink' ? `

  &:hover {
    img:first-child {
      opacity: 0;
    }

    img:last-child {
      opacity: 1;
    }
  }

  `:``}
  
  @media (max-width: ${breakpoints.sm}px) {
    width: ${props => props.showLogo ? '55px' : '0px'};

    &:hover {
      img:first-child {
        opacity: 1;
      }
  
      img:last-child {
        opacity: 0;
      }
    }
  
  }
`

export const Seperator = styled.div`
  height: 18px;
  width: 1px;
  background-color: ${props => menuItemColorOnBackground[props.menutheme]};
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
  opacity: ${props => props.hide ? 0 : 1};
  

  @media (max-width: ${breakpoints.sm}px) {
    display: ${props => props.hide ? 'none' : 'block'};
  }
`

export const MenuItem = styled(Link)`
  font-family: ${fonts.title.xbold};
  font-size: 13px;
  line-height: 15px;
  margin-left: 13px;
  margin-right: 13px;
  
  color: ${props => menuItemColorOnBackground[props.menutheme]};

  &:hover {
    color: ${props => menuItemColorHoverOnBackground[props.menutheme]?.[props.background]};
  }

  ${props => {
    if (props.disableflip)
      return `
        transition-property: none;
        transition-duration: 0s;
        transition-delay:0s !important;
      `
  }}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
    line-height: 13px;
    margin-left:12px;
    margin-right:12px;

    &:hover {
      color: ${props => menuItemColorOnBackground[props.menutheme]};
    }
  }
`

export const Underline = styled.div`
  position: absolute;
  bottom: 14px;
  height: 2px;
  transition: all .3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${colours.pink};

  @media (max-width: ${breakpoints.sm}px) {
    bottom: 4px;
  }
`