import { useQuery } from '@tanstack/react-query'
import { getUserUrlById } from 'apis/user.api'

export default function useGetUserUrl(id) {
  const {
    isLoading,
    isSuccess,
    data
  } = useQuery({
    queryKey: ['users-url', id],
    queryFn: () => getUserUrlById(id)
  })

  return {
    isLoading,
    isSuccess,
    itchUrl: isSuccess ? data.itchUrl : null
  }
}