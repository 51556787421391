import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import {
  Container,
  AvatarWrapper
} from './top-nav-avatar.styles'
import RightDrawer from '../right-drawer/right-drawer'
import { getAccountTypeMeta } from '../../../constants'
import {
  breakpoints
} from 'styles/global.styles'
import {
  useHistory,
  useLocation
} from 'react-router-dom'
import useSwipe from 'hooks/swipe.hook'
import {
  imageUrl
} from 'utils/utils'
import useDrawers from 'hooks/drawers.hook'

export default function TopNavAvatar({
  accountType,
  profileImage,
  profileImageVariants,
  ...rest
}) {
  const avatarRef = useRef()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const accountTypeMeta = getAccountTypeMeta(accountType);
  const fallBackAvatar = accountTypeMeta.fallBackAvatar;
  const { activeDrawer, setActiveDrawer } = useDrawers()

  const swipe = useSwipe({
    onSwipeRight: () => {
      setActiveDrawer(null)
    }
  })

  useEffect(() => {
    setActiveDrawer(null)
  }, [pathname])
  
  useEffect(() => {
    const header = document.getElementById('header')
    /*
    if (header)
      header.addEventListener('onclick', () => {
        onCloseDrawer()
      }) 

    document.addEventListener('onclick', () => {
      onCloseDrawer()
    })
    */ 
  }, []);


  const handleClick = (evt) => {
    evt.stopPropagation()
    if (window.screen.width > breakpoints.sm) {
      push('/my-profile')
    } else {
      if (activeDrawer !== 'right')
        setActiveDrawer('right')
      else
        setActiveDrawer(null)
    }
  }

  return (
    <Container {...rest}
      onClick={(evt) => {
        evt.stopPropagation()
      }}
      onMouseEnter={() => {
        if (window.screen.width > breakpoints.sm) {
          setActiveDrawer('right')
        }
      }}
      onMouseLeave={() => {
        if (window.screen.width > breakpoints.sm) {
          setActiveDrawer(null)
        }
      }}
    >
      <AvatarWrapper 
        ref={avatarRef}
        onClick={handleClick}
      >
        <img src={imageUrl(profileImageVariants?.thumb ?? profileImage) ?? fallBackAvatar} alt="" />
      </AvatarWrapper>
      {createPortal(
        <RightDrawer 
          excludeClickRefs={[avatarRef.current]}
          onClick={(evt) => {
            evt.stopPropagation()
            // setActiveDrawer(null)
          }} 
          active={activeDrawer === 'right'} {...swipe} />,
        document.body)}
    </Container>
  )
}