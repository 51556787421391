import styled from 'styled-components'
import {
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    padding: 40px; 
  }
`

export const NoResult = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 16px;
  color: ${colours.dark};
  line-height: 16px;
`