import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setMessagesRead } from 'apis/conversation.api'
import useAuth from './auth.hook'

export default function useSetMessagesRead() {
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (ids) => {
      return setMessagesRead(ids)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`messages`]
      })
      queryClient.invalidateQueries({
        queryKey: [`conversation-unread-count-${session?.profile?.id}`]
      })
      queryClient.invalidateQueries({
        queryKey: [`conversations-${session?.profile?.id}`]
      })
    },
  });

  return {
    setRead: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}