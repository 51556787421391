import React, { useRef } from 'react'
import { Orientation } from '../../constants'
import { 
  TileStyledPositioned,
  Inner
} from './tile.styles'
import { ThemeProvider } from 'styled-components'

export default function Tile({
  children,
  onClick,
  className = '',
  width = '33.3333333333%',
  height = '25vw',
  gap,
  addStyle = '',
  responsiveHeight = null,
  col = 0,
  row = 0,
  orientation = Orientation.Portrait,
  parentRowExpanded,
  onExpandCompleted,
  expanded,
  disabled = false,
  ...rest
}) {
  const elemRef = useRef(null)
  return (
    <TileStyledPositioned
      onTransitionEnd={(evt) => {
        /*
          expanded = property name = width or height, value is larger
        */
        if (evt.propertyName === 'height' && expanded && onExpandCompleted) { 
          onExpandCompleted()
        }
      }}
      gap={gap}
      addStyle={addStyle}
      tileWidth={width}
      tileHeight={height}
      responsiveHeight={responsiveHeight}
      disabled={disabled}
      parentRowExpanded={parentRowExpanded}
      col={col}
      row={row}
      orientation={orientation}
      className={`tile ${className} ${expanded ? 'expanded':''}`}
      ref={elemRef}
      {...rest} 
      onClick={() => {
        if (!disabled) {
          onClick && onClick()
        }
      }}
    >
      <Inner>
        <ThemeProvider theme={{
          expanded
        }}>
        {children}
        </ThemeProvider>
      </Inner>
    </TileStyledPositioned>
  )
}