import styled from 'styled-components'

import {
  fonts,
  colours,
  breakpoints,
  ButtonReset
} from 'styles/global.styles'
import {
  Link
} from 'react-router-dom'

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  padding-top:100px;
  padding-bottom:0px;
`

export const Logo = styled.div`
  width: 190px;
  height: 190px;

  img {
    display: block;
    width: 100%;
  }
`

export const Heading = styled.div`
  font-family: ${fonts.content.regular};
  color: ${colours.white};
  font-size: 24px;
  line-height: 24px;
  margin-top: 70px;
`

export const CallToAction = styled(Link)`
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 80px;
  font-family: ${fonts.content.regular};
  color: ${colours.pink};
  font-size: 21px;
  line-height: 24px;

  b {
    font-family: ${fonts.content.bold}; 
  }

  
  &:hover {
    color: ${colours.pink};
  }

`

export const ViewArchiveLink = styled(Link)`
  margin-top: 50px;
  margin-bottom: 50px;
  color: ${colours.text};
  height: 90px;
  width: 90px;
  border: 2px solid #a8a8a8;
  border-radius:50%;
  text-transform: uppercase;
  font-family: ${fonts.content.bold};
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  
  &:hover {
    color: ${colours.pink};
    border-color: ${colours.pink};
    background-color:${colours.pink_alpha60};
  }

`


