import React, { cloneElement } from 'react';
import styled from 'styled-components';
import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'
import FieldError from './field-error'

const errorBackground = (theme = 'light') => theme === 'light' ? colours.pink_alpha10 : colours.pink_alpha20

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  
  .label {
    background-color: ${props => props.error === 'true' ? 
      errorBackground(props.theme)
      : 'transparent'};
  }

  .input-wrapper {
    background-color: ${props => props.error === 'true' ? 
      errorBackground(props.theme) : 'transparent'};
    
    .error {
      background-color: transparent;
    }
  }

  input[type="text"],
  input[type="password"] {
    padding-left: 12px;
  }


  &:hover {
    &>.label {
      background-color: ${props => props.error === 'true' ? colours.pink_alpha40 : colours.pink_alpha50};
    }
    .input-wrapper {
      background-color: ${colours.pink_alpha40};
    }
  }


  &:focus-within {
    .label {
      background-color: transparent;
    }
    .input-wrapper {
      background-color: transparent;
    }
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    grid-area: ${props => props.area ?? ''};
    align-self: flex-start;
    margin-bottom: 4px;
  }
`;

const FieldLabel = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 12px;
  border-bottom: 2px solid ${colours.text};

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    border-bottom-width:1px !important;
  }
`;

const FieldInput = styled.div`
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
`;

export default function FormField(
  props
) {
  const { 
    className, label, children, area,
    error,
    suffix,
    ...rest
  } = props

  const isError = error ? 'true' : 'false'
  return (
    <FieldContainer className={className} area={area} error={isError}>
      {label ? <FieldLabel className="label">{label}</FieldLabel> : null}
      <FieldInput className="input-wrapper">
        {cloneElement(children, { error: isError , ...rest })}
        {suffix ?? null}
      </FieldInput>
      {error ? <FieldError error={error}>
      </FieldError>: null}
    </FieldContainer>
  );
}