import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  colours,
  fonts
} from '../../../styles/global.styles'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  opacity: ${props => props.disabled ? .5:1};
  padding: 10px 10px;
  
  position:fixed;
  top: 45px;
  left:0;
  z-index:10;
  margin-left:0;
  background-color: ${colours.black};
  width:100%;
  max-width:auto;
  height: 45px;
  justify-content: flex-start;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    padding-top:25px;
    position: static;
    top:0;
    margin-left:-20px;
    max-width: 918px;
    left: calc((100% - 918px)/2);
    background-color: transparent;
    height: auto;
  }
`

export const Icon = styled.div`
  border: 2px solid ${colours.pink};
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
  margin-right: 10px;

  &>img {
    display: block;
    width: 50%;
  }
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    flex: 0 0 36px;
    width: 36px;
    height: 36px;
    margin-right: 10px;

    img {
      width: 60%;
    }
  }
`

export const Title = styled.div`
  font-family: ${fonts.title.bold};
  font-size: 12px;
  line-height: 14px;
  color: ${colours.pink};
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {  
    font-size: 14px;
    line-height: 17px;
  }
`

const Container = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  padding-top:0;
  padding-bottom:0px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    justify-content: ${props => props.justifyStart ? 'flex-start':'center'};
    padding-top:0px;
  }
`

const Inner = styled.div`
  width: 100%;
  max-width: ${props => props.width?.[breakpoints.sm] ?? '100%'};
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    max-width: ${props => props.width?.default ?? '781px'};
    min-height: calc(100vh - 90px);
    height:100%;
    // min-height: 100%;
    padding-top:0;
    justify-content: ${props => props.justifyStart ? 'flex-start':'center'};
  }
`

const Body = styled.div`
  display:flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;  
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    padding-top: 18px;
    height: 100%;
  }
`

/*
  places used
    change password
    reveal artwork format type
*/
export default function CenteredFormLayout({
  icon,
  title,
  children,
  width,
  disabled = false,
  justifyStart = false
}) {
  return (
    <Container className="centered-form-layout" justifyStart={justifyStart}>
      <Inner width={width} justifyStart={justifyStart}>
        {title ? <Header
          className="centered-form-kayout__header"
          disabled={disabled}
        >
          <Icon>
            <img src={icon} alt="" />
          </Icon>
          <Title>{title}</Title>
        </Header> : null}
        <Body>
          {children}
        </Body>
      </Inner>
    </Container>
  )
}