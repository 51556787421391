import React, { useRef, useEffect } from 'react'
import { useIsElementVisible } from 'hooks/elementvisible.hook'

const VisibilityElement = (props) => {
  const { visibleTime = 100, onVisible, id, data, visibleAlready, ...rest } = props

  const elementRef = useRef(null)
  const timeoutRef = useRef(null)

  const isElementVisible = useIsElementVisible(elementRef.current, null, visibleAlready)

  useEffect(() => {
    if (isElementVisible) {
      timeoutRef.current = setTimeout(() => {
        onVisible && onVisible(data);
      }, visibleTime)
    }
    return () => {
      if (timeoutRef.current)
        clearTimeout(timeoutRef.current)
    }
  }, [isElementVisible]);

  return (
    <div ref={elementRef} id={id} {...rest}>
      {props.children}
    </div>
  )
}

export default VisibilityElement