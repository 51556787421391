import React, { forwardRef, useReducer, useImperativeHandle } from 'react'
import { useWindowDimensions} from '../../utils/helpers';
import Modal from 'react-bootstrap/Modal';
import { CloseButton  } from 'components';
import ReactPlayer from "react-player/vimeo";

const ImagePopup = forwardRef(({ img, video }, ref) => {
    const { windowWidth } = useWindowDimensions();
    const [showModal, toggleModal] = useReducer(
        showModal => !showModal,
        false
    );

    useImperativeHandle(ref, () => ({
        triggerModal() {
            toggleModal()
        }
    }))

    return (
        <Modal
            id="imagePopup"
            backdropClassName="fa-modal-backdrop"
            dialogClassName="fa-modal-dialog"
            contentClassName="fa-modal-content"
            show={showModal}
            onHide={toggleModal}
            animation={false}>
            <CloseButton closeModal={toggleModal} passOpacity={(opacity) => null} />
            {video ?
                // <iframe
                //     className='popup-player middle-xy'
                //     src={video}
                //     width="100%"
                //     height="100%"
                //     frameBorder="0"
                //     allow="autoplay; fullscreen; picture-in-picture"
                //     allowFullScreen
                //     title="fashion video"
                // ></iframe>

                // --------- !! NOTE: video can't be viewed in development. Uncomment iframe tag above to view ---------
                <ReactPlayer
                    className='popup-player middle-xy'
                    url={video}
                    controls={true}
                    playing={true}
                    width={windowWidth >= 1030 ? "60%" : "100%"}
                    height="50vh"
                />
                :
                <img src={img} className="expanded-artwork" alt="featured artwork" />
            }
        </Modal>
    )
})

export default ImagePopup