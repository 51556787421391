import axiosInstance from './api';
import {
  toQueryParams
} from '../utils/query-parameters.js'

export const sendArtworkAction = (id, action) => {
  // action: wowed | viewed
  if (axiosInstance.defaults.headers.common['Authorization'] === null) {
    return false
  }
  
  return axiosInstance.post(`/activities/artworks/${id}/${action}`)
}

export const getArtworkActivities = async ({
  id, 
  action,
  page = 0,
  size = 10
}) => {
  const res = await axiosInstance.get(`/activities/artworks/${id}?page=${page}&size=${size}&actions=${action}`)
  return res.data
}

export const getArtworkActivityHistory = async ({
  id, 
  action,
  page = 0,
  size = 10
}) => {
  const res = await axiosInstance.get(`/activities/artworks/history/${id}?page=${page}&size=${size}&actions=${action}`)
  return res.data
}
