import styled from 'styled-components'
import {
  fonts,
  breakpoints,
  colours,
  ButtonReset,
  BaseTransition
} from '../styles/global.styles'

export const SettingsContainer = styled.div`
  display:flex;
  flex-direction:column;
  background-color: ${colours.darkerlight2_alpha95};
  height: 100%;
  min-height:inherit;
  width: 100%;
  padding-top: 40px;

  &>form {
    width: inherit;
    height: inherit;
    display:flex;
    flex-direction: column;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    padding-top: 60px;
    align-items:center;

    &>form {
      align-items:center;
    }
  }
`;

export const Header = styled.div`
  height: 45px;
  padding:0;
  width: 100%;
  left:0;
  background-color: ${colours.darkerlight2};
  display:flex;
  flex-direction: column;
  
  justify-content: center;
  align-items:center;
  box-shadow: 0px 0px 3px 0px rgba(51, 51, 51, 0.2);

  position: fixed;
  z-index:10;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    height: 65px;
    padding: 10px 0 30px 0;
    width: calc(100% - 16px);
    position: fixed;
    justify-content: flex-start;
  }
`;

export const HeaderTitleWrapper = styled.div`
  width: 100%;
  max-width: 260px;
  padding:0px;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    max-width: 700px;
    padding:13px;
  }
`;

export const Body = styled.div`
  flex: 1 1 auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  
  padding: 30px 20px 25px 20px;

  h1 {
    font-family: ${fonts.content.bold};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  p {
    font-family: ${fonts.content.regular};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }

  h2 {
    color: ${colours.pink};
    font-family: ${fonts.content.bold};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .checkbox {
    margin-bottom: 12px;
  }

  button {
    float:left;
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    width: 100%;
    max-width: 700px;
    padding-bottom:75px;

    div.group {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      h2 {
        flex: 0 0 154px;
        margin-top: 14px;
      }
      
      div.group-options {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: flex-start;

        .input-wrapper {
          position:relative;
          top:-10px;
        }

        &.horizontal-center {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
`;

export const HintText = styled(BaseTransition)`
  font-family: ${fonts.content.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0px;
  opacity:0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }
`;

export const SolidLine = styled.hr`
  width: 100%;
  border-bottom:1px solid ${colours.pink};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const DashedLine = styled.hr`
  width: 100%;
  border-bottom: 1px dashed ${colours.lightpink};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top:10px;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    margin-top:23px;
  }
`;

export const SubMenuItem = styled.div`
  color: ${colours.darkergrey2};
  font-family: ${fonts.title.xbold};
  font-size: 12px;
  font-weight: 800;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding-left:10px;
  padding-right:10px;
  position: relative;
  height: 15px;
  display: flex;
  flex-direction:column;
  cursor: pointer;
  
  &:after {
    content: '';
    width: 100%;
    position: relative;
    bottom: -6px;
    border-bottom: 2px solid ${props => props.active ? colours.pink : 'transparent' };
  }

  &:nth-child(2n + 1) {
    border-right:1px solid ${colours.lightgrey};
  }
  
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    
  }
`;

export const Footer = styled.div`
  background-color: ${colours.darkerlight2};
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  height: 75px;
  flex: 0 0 75px;
  padding: 17px 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 15px);
  position:fixed;
  z-index: 100;
  top: auto;
  bottom: 0;
  left:0;

  button:nth-child(n+1) {
    border-right:1px solid ${colours.lightgrey2};
  }

  button:last-child {
    margin-left: auto;
  }

  @media screen and (max-width:${breakpoints.sm}px) {
    position:static;
    width:100%;
  }
`;

export const ButtonDanger = styled(ButtonReset)`
  color: ${colours.lightgrey2};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 0;
  letter-spacing: 0em;
  height: 42px;
  border:1px solid transparent;
  padding: 17px 10px 16px 0px;

  
  &:hover {
    color: ${colours.pink};
  }

`;

export const ButtonResetForm = styled(ButtonReset)`
  color: ${colours.lightgrey2};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 0;
  letter-spacing: 0em;
  height: 42px;
  border:1px solid transparent;
  padding: 17px 10px 16px 10px;
  
  opacity: ${props => props.active ? '1': '0.5'};
  pointer-events: ${props => props.active ? 'all':'none'};
  cursor: ${props => props.active ? 'pointer':'none'};
  
  
  &:hover {
    color: ${colours.pink};
  }

`;

export const AccountType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    flex: 0 0 40px;
  }

  .categories {
    flex: 1 1 auto;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;

    .tags {
      color: ${colours.darkergrey2};
      font-family: ${fonts.content.regular};
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: capitalize;
    }

    
    &:hover {
      color: ${colours.pink};
    }
  
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    
  }
`;

export const LinkButton = styled(ButtonReset)`
  color: ${colours.lightgrey3};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  display:inline;
  margin-left: 12px;

  
  &:hover {
    color: ${colours.pink};
  }

`;