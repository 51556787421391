import React, { useState } from 'react'
import { Container } from './comment.style'

import CommentInput from './comment-input'
import CommentThread from './comment-thread'

import useAuth from 'hooks/auth.hook'

import useSendComment from 'hooks/send-comment.hook'

const Comment = (props) => {
  const { session } = useAuth()
  const { id, contentType } = props
  const { comment, isLoading } = useSendComment(contentType, id)

  return (
    <Container>
      {session ? <CommentInput 
        sending={isLoading ?? false}
        onSend={(body)=> {
          comment(body)
        }} 
      /> : null}
      <CommentThread id={id} contentType={contentType} />
    </Container>
  )
}

export default Comment