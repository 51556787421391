import React from 'react';
import styled from 'styled-components'

import {
  colours,
  breakpoints,
} from 'styles/global.styles'

const Variants = {
  'pink': {
    color: colours['pink'], 
    color2: colours['pink'] 
  },
  'dark': {
    color: colours['metro'],
    color2: colours['pink']
  },
  'light': {
    color: colours['lightpink'],
    color2: colours['pink']
  }
}

const Sizes = {
  'small': {
    dotSize: 6,
    dotMargin: 2
  },
  'large': {
    dotSize: 20,
    dotMargin: 6
  }
}

const Container = styled.div`
  margin-left: -${props => props.dotMargin}px;
  margin-right: -${props => props.dotMargin}px;
  padding-left: ${props => props.dotMargin}px;
  padding-right: ${props => props.dotMargin}px;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  width: max-content;
  height: 100%;
`

const Dot = styled.div`
  display:inline-block;
  width: ${props => props.dotSize}px;
  height: ${props => props.dotSize}px;
  margin-left: ${props => props.dotMargin}px;
  margin-right: ${props => props.dotMargin}px;
  border-radius: 50%;
  background-color: ${props => props.color};

  @keyframes blip {
    0% {
      background-color: ${props => props.color};
    }
    13.5% {
      background-color: ${props => props.color2};
    }
    27% {
      background-color: ${props => props.color};
    }
  }

  @keyframes blip2 {
    27% {
      background-color: ${props => props.color};
    }
    40.5% {
      background-color: ${props => props.color2};
    }
    54% {
      background-color: ${props => props.color};
    }
  }

  @keyframes blipbang {
    54% {
      transform: scale(1);
      background-color: ${props => props.color};
    }
    63.2% {
      transform: scale(2);
      background-color: ${props => props.color2};
    }
    72.4% {
      transform: scale(1);
    }
    81.6% {
      transform: scale(2);
    }
    90.8% {
      transform: scale(1);
    }
    100% {
      background-color: ${props => props.color};
    }
  }

  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-duration: 1.2s;

  &:nth-child(1) {
    animation-name: ${props => props.animate ? 'blip' : ''};
  }
  &:nth-child(2) {
    animation-name: ${props => props.animate ? 'blip2' : ''};
  }
  &:nth-child(3) {
    animation-name: ${props => props.animate ? 'blipbang' : ''};
  }  
`

export default function DotProgress({
  variant = 'dark',
  size = 'small',
  animate = true,
  ...rest
}) {
  return (
    <Container {...Sizes[size]} {...rest}>
      <Dot {...Sizes[size]} {...Variants[variant]} animate={animate} />
      <Dot {...Sizes[size]} {...Variants[variant]} animate={animate} />
      <Dot {...Sizes[size]} {...Variants[variant]} animate={animate} />
    </Container>
  )
}