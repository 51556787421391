import React, { useContext, useState } from 'react';

const initialState = [
  'white',
  () => {},
  false,
  () => {}
]

export const FeaturedArtworkThemeContext = React.createContext(initialState)

export default function FeaturedArtworkThemeProvider ({ children }) {
  const [theme, setTheme] = useState('white')
  const [opened, setOpened] = useState(false)
  
  return (
    <FeaturedArtworkThemeContext.Provider value={[theme, setTheme, opened, setOpened]}>
      {children}
    </FeaturedArtworkThemeContext.Provider>
  )
}

export const useFeaturedArtworkTheme = () => { 
  const [theme, setTheme, opened, setOpened] = useContext(FeaturedArtworkThemeContext)

  const open = () => {
    setOpened(true)
  }

  const close = () => {
    setOpened(false)
  }

  return {
    theme,
    setTheme,
    opened,
    open,
    close
  }
}