import styled from 'styled-components'
import { 
  BaseTransition,
  breakpoints
} from '../../styles/global.styles'
import {
  Orientation
} from '../../constants'

const defaultGap = '10px'

const getHorizontalGap = ({gap}) => {
  if (Array.isArray(gap)) {
    return gap[0]
  } else {
    return gap ?? defaultGap
  }
}

const getVerticalGap = ({gap}) => {
  if (Array.isArray(gap)) {
    return gap[1]
  } else {
    return gap ?? defaultGap
  }
}

export const TileStyledPositioned = styled(BaseTransition)`
  transition-property: ${props => props.disableTransition ? 'none':'all'} !important;
  transition-duration: .3s;
  position:absolute;
  overflow: hidden;
  
  width: ${props => `calc(${props.tileWidth})`};
  height: ${props => `calc(${props.tileHeight})`};
  top: calc(${({ position }) => position?.top ?? 0} * ${props => props.tileHeight});
  left: calc(${({ position }) => position?.left ?? 0} * 100%);

  ${props => props.addStyle};

  &.expanded {
    width: ${props => `calc(${props.tileWidth} * ${props.orientation === Orientation.Portrait ? '2':'3'})`};
    height: ${props => `calc(${props.tileHeight} * 2)`};
  }
  
  @media screen and (max-width:${breakpoints.sm}px) {
    width:100%;
    height: ${props => props.responsiveHeight[breakpoints.sm] ?? 'auto'};
    top: unset;
    left: unset;
    position: static;
    margin-bottom: 21px;

    &.expanded {
      height: 100%;
      width: 100%;
    }
  }
`

export const Inner = styled(BaseTransition)`
  width: 100%;
  height: 100%;
  transition: all;
`