import countries from 'i18n-iso-countries'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const CountriesNameCode = () => {
  const d = countries.getNames("en", {select: "official"})
  return Object.entries(d).map(item => ({
    name: item[1],
    value: item[0]
  }))
}
