import React, { useEffect, useState, useMemo } from 'react';
import { 
  Container, 
  Toast,
  TilesContainer,
  TilesInnerPositioned
} from './gallery.styles';

import useGetitchbook from 'hooks/get-itchbook'

import ArtworkTile from '../components/gallery/artwork-tile'
import useModal from '../hooks/modal.hook';
import useMasonry from '../hooks/masonry.hook';
import { MediaType, ActivityActions } from '../constants';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { 
  useLocation,
  useParams
} from 'react-router-dom'

import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function ItchBook({userId}) {
  const { pathname } = useLocation()
  const { setModal } = useModal()
  const { success, fetching, artworks, 
    nextPage, hasNextPage 
  } = useGetitchbook(userId)

  useBottomScrollListener(() => {
    if (hasNextPage) {
      nextPage()
    }
  })

  const [expanded, setExpanded] = useState(null)

  const { grid, expand, init, reset } = useMasonry()

  const isOwner = pathname.indexOf('/my-profile') === 0;

  const lastCount = useMemo((() => success ? artworks.data?.length : 0), [success, artworks])

  const artworksRenderItems = (success && grid.length > 0) ? grid.map((gridItem, i) => {
    const artwork = artworks?.data[i]
    if (!artwork) return null

    return (
      <ArtworkTile
        index={i}
        onDragStart={(e) => {
          console.log(e)
          // e.target.classlist.add('dragged')
          console.log('drag start')
        }}
        onDragEnd={(e) => {
          // e.target.classlist.remove('dragged')
          console.log('drag end')
        }}
        disableOwnerActions={true}
        disableViewAnalytics={true}
        draggable={true}
        position={gridItem}
        expanded={expanded === (i)}
        key={`artwork-${artwork.id}}`}
        {...artwork}
        onExpand={() => {
          const offset_i = i
          if (expanded === offset_i) {
            setExpanded(null)
            reset()
          } else {
            setExpanded(offset_i)
            if (artwork.mediaType === MediaType.Image) {
              if (artwork.image.width > artwork.image.height) {
                expand(offset_i, 3)
              } else {
                expand(offset_i, 2)
              }
            } else {
              expand(offset_i, 3)
            }
          }
        }}
        onExit={() => {
          setExpanded(null)
          reset()
        }}
        onVotessClick={() => {
          setModal({
            name: 'activity-history',
            payload: {
              title: 'collectives who voted this',
              schemaName: 'artworks',
              action: ActivityActions.Voted,
              relatedId: artwork.id
            }
          })
        }}
        onViewsClick={() => {
          setModal({
            name: 'activity-history',
            payload: {
              title: 'collectives who viewed this',
              schemaName: 'artworks',
              action: ActivityActions.Viewed,
              relatedId: artwork.id
            }
          })
        }}
        onItchbooksClick={() => {
          setModal({
            name: 'activity-history',
            payload: {
              title: 'collectives who itchbooked this',
              schemaName: 'artworks',
              action: ActivityActions.Itchbooked,
              relatedId: artwork.id
            }
          })
        }}
      />
    )
  }) : null

  useEffect(() => {
    if (success) {
      init(artworks?.data?.length, 3)
    }
  }, [success, lastCount])

  const maxRows = grid.length > 0 ? (grid[grid.length - 1].top + 1) + (expanded !== null ? 1 : 0) : 0

  return (
    <Container>
      {isOwner && (artworks?.data?? []).length === 0 ? <Toast>
        <div>
        Click on the <b>"itchbook it"</b> button on any artwork to save them here. 
        Once <b>“itchbooked”</b>, you may also click on it again to remove it from your <b>itchbook</b>.
        </div>
      </Toast> : null }
      <TilesContainer>
        <TilesInnerPositioned rows={maxRows}>
          {artworksRenderItems}
        </TilesInnerPositioned>
      </TilesContainer>
      {fetching && !success ? <ProgressLoader/> : null}
    </Container>
  )
}