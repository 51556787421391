import React from 'react'
import styled from 'styled-components'
import {
  themes,
  colours,
  fonts,
  ButtonPrimary,
} from '../../../styles/global.styles'
import {
  preloaders,
  icons
} from '../../../constants'
import DotProgress from 'components/shared/dot-progress'

const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: inherit;
`

const Icon = styled.div`
  margin-bottom:50px;
`

const Header = styled.div`
  color: ${props => props.theme === themes.Flare ? colours.light : colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
`;

const Body = styled.div`
  color: ${colours.light};
  font-family: ${fonts.content.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 50px;
`;


export default function ModalProgress({
  title,
  body,
  theme,
  onOk
}) {
  return (
    <Content>
      <Icon>
        <img src={icons['icon-pending.svg']} alt="pending" />
      </Icon>
      <Header theme={theme} >{title ?? ''}</Header>
      <Body dangerouslySetInnerHTML={{__html: body ?? ''}}></Body>
      <ButtonPrimary onClick={() => {
        onOk && onOk()
      }}>Ok</ButtonPrimary>
    </Content>
  )
}