import { useQuery } from '@tanstack/react-query';
import { getOne } from '../apis/account-article.api';

export default function useGetArticle(id) {
  const { isLoading, error, data, isSuccess, isFetching } = useQuery(
    ['one-article', id], 
    () => getOne(id), 
    {
      enabled: id !== undefined
    }
  )

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    article: {
      ...data?.data
    }
  }
}