import React from 'react'
import styled from 'styled-components'
import {
  themes,
  colours,
  fonts,
  ButtonPrimary,
  BaseTransition,
  breakpoints
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'
import useNotifyAlert from '../../hooks/notify-alert.hook'
import useGetMyProfile from '../../hooks/get-user-profile.hook'

const Wrapper = styled.div`
  position:fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme === themes.Flare ? colours.darkpink_alpha95 : colours.black_alpha85 };
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.visible ? 'all': 'none'};
  visibility: ${props => props.visible ? 'visible':'hidden'};
  opacity: ${props => props.visible? 1: 0};
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
`;

const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`

const Icon = styled.div`
  display:flex;
  justify-content:center;
  margin-bottom:50px;
`

const Header = styled.div`
  color: ${props => props.theme === themes.Flare ? colours.light : colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
`;

const Body = styled.div`
  color: ${colours.light};
  font-family: ${fonts.content.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 50px;
`;

export default function NotifyAlert() {
  const { notifyAlert, setNotifyAlert } = useNotifyAlert()
  const { data } = useGetMyProfile()

  return (
    <>
      {notifyAlert !== null ? 
      <Wrapper className="notify-alert" visible={notifyAlert !== null} theme={data?.colourTheme}>
        <Content>
          <Icon>
            <img src={icons['logo-111px-ec0f69.svg']} alt="itch" />
          </Icon>
          <Header theme={data?.colourTheme} >{notifyAlert?.title ?? ''}</Header>
          <Body dangerouslySetInnerHTML={{__html: notifyAlert?.body ?? ''}}></Body>
          <ButtonPrimary onClick={() => {
            setNotifyAlert(null)
          }}>Ok</ButtonPrimary>
        </Content>
      </Wrapper>: null}
    </>
  );
}