import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendMessage } from 'apis/conversation.api'
import useAuth from './auth.hook'

export default function useSendMessage(id) {
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (body) => {
      return sendMessage(id, body)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`messages`, id]
      })
    },
  });
  

  return {
    send: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}