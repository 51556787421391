import { useMutation, useQueryClient } from '@tanstack/react-query';
import { triggerPasswordReset } from '../apis/auth.api';

export default function useTriggerPasswordReset() {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return triggerPasswordReset(data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`my-tasks`]
      })
    }
  });

  return {
    trigger: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}