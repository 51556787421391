import React, { useState } from 'react'

export const ToastContext = React.createContext(null);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({
    active: false
  });
  return (
    <ToastContext.Provider value={[toast, setToast]}>
      {children}
    </ToastContext.Provider>
  );
}