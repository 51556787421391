import { useMutation, useQueryClient } from '@tanstack/react-query';
import { create } from '../apis/account-artwork.api';

export default function useCreateArtwork(userId) {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return create(data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [`artworks-${userId}`, ] })
    }
  });

  return {
    create: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}