import React, { useState, useContext } from 'react';

export const PsstDrawerContext = React.createContext(null);

export const PsstDrawerProvider = ({ children }) => { 
  const [opened, setOpened] = useState(false)

  return (
    <PsstDrawerContext.Provider value={[opened, setOpened]}>
      {children}
    </PsstDrawerContext.Provider>
  )
}

export const usePsstDrawer = () => {
  const [opened, setOpen] = useContext(PsstDrawerContext)
  
  const toggleOpen = () => {
    if (opened) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const close = () => {
    setOpen(false)
  }

  const open = () => {
    setOpen(true)
  }

  return {
    opened,
    close,
    open,
    toggleOpen
  }
}
