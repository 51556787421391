import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getCurrentUserTasks } from 'apis/user.api'
import useAuth from './auth.hook'

export default function useGetTasks() {
  const { session } = useAuth();
  const { 
    isLoading, isSuccess, error, data, isFetching, isFetched, refetch
  } = useQuery({
    queryKey: [`my-tasks`], 
    queryFn: () => {
      return session ? getCurrentUserTasks() : []
    }
  })
  useEffect(() => {
    if (session) {
      refetch()
    }
  }, [session])

  return {
    loading: isLoading,
    fetching: isFetching,
    success: session ? (isSuccess || isFetched) : false,
    error,
    tasks: session ? (isSuccess ? (data ?? []) : []) : []
  }
}