import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDraft, updateSubmit } from '../apis/account-article.api';
import useAuth from './auth.hook'

export default function useUpdateMyArticle() {
  const queryClient = useQueryClient()
  const { session } = useAuth()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: ({id, data}) => {
      if (data.isDraft)
        return updateDraft(id, data)
      else
        return updateSubmit(id, data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: [`articles-${session?.profile?.id}`] })
    },
  });
  

  return {
    update: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}