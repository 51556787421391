import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSettings } from '../apis/user.api';
import useAuth from './auth.hook'

export default function useUpdateMyAccount() {
  const { session } = useAuth();
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: account => {
      return updateSettings(account)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`my-profile-${session?.profile?.id}`]
      })
      queryClient.invalidateQueries({
        queryKey: [`account-${session?.profile?.id}`], 
        data: data?.data
      })
      if (data?.data.isEmailChanged) {
        queryClient.invalidateQueries({
          queryKey: ['is-changing-email']
        })
        queryClient.invalidateQueries({
          queryKey: ['my-tasks']
        })
      }
    },
  });
  

  return {
    update: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}