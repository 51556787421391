import { useInfiniteQuery } from '@tanstack/react-query';
import { getPastItchArticle } from '../apis/zine.api';
export default function useGetPastItchArticles(
  size = 9
) {
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [`itch-articles`],
    queryFn: ({ pageParam = 0 }) => getPastItchArticle(pageParam, size),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    }
  })
  
  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])
  return {
    success: isSuccess,
    fetched: isFetched,
    loading: isLoading,
    fetching: isFetching || isFetchingNextPage,
    error,
    articles: flattenedData,
    nextPage: fetchNextPage,
    hasNextPage
  }
}