import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  images
} from '../constants'
import { HeaderBanner, Title, PageContainer, BodyContent } from './shared/page-layout.styles'
import slugify from 'slugify'
import styled from 'styled-components'
import { breakpoints } from 'styles/global.styles'

const FaqContent = styled.div`
  a[id] {
    padding-top: 75px;
    padding-top: -75px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const faqContent = [
  {
    question: `What’s the difference between zine and home on itch`,
    answer: `
      <p>You can think of <a href="/zine"><b>zine</b></a> as an editorial page, 
      which we’ll regularly publish trending artworks from <a href="/home"><b>home</b></a>. <a href="/my-profile/articles"><b>articles</b></a>
      submitted by you will too be on our <a href="/zine"><b>zine</b></a> page.</p>
      <p><a href="/home"><b>home</b></a> is where we display all artworks revealed by you and the <b>itch</b> community. Upload your artworks in your
      <a href="/my-profile/gallery"><b>gallery</b></a> and it’ll reflect on our <a href="/home"><b>home</b></a> page.</p>
    `
  },
  {
    question: `How we feature you`,
    answer: `
      <p>Artworks trending with the most <b>votes</b> in our <b>home</b> page will be featured next on our <b>zine</b>. Yes! Instead of creativity subject to opinions by a select few, everyone’s <b>vote</b> will collectively determine our next feature. Even better, active voters on <b>itch</b> will also be selected and featured in our <b>articles</b> and social media for keeping <b>itch</b> a decentralised fashion community. This way, fads and trends have purpose as it’s not based on opinions of a select few—but collectively by all of you. We believe that’s what makes fashion meaningful and fresh again. Finally, you have a say to what should trend and the opportunity to be heard.</p>

      <p>To <b>vote</b> for an artwork you love and support, simply click on our <b>itch</b> logo that’s on every artwork. You may only <b>vote</b> once for every artwork.</p>
    `
  },{
    question: `How does the timeline work in home page`,
    answer: `
      The order of our <a href="/home"><b>home</b></a> page is chronologically determined by your last <b>vote, view, itchbook</b> or <b>comment</b>. Good news is, the more you interact on our <a href="/home"><b>home</b></a> page, the more it’ll help to increase your profile visibility as you’re leaving your time stamp where others can easily access and view your <b>itch</b> profile.
    `
  },
  {
    question: `What artworks do we reject in your gallery`,
    answer: `
      <p>
      We understand that creativity is subjective, which is why we won't judge / reject artworks 
      based on your fashion expressions or artistic concepts. However, to truly inspire and 
      connect with one another professionally, we believe you'd also appreciate a 
      level of professional integrity in artworks that are showcased on <b>itch</b>’s <a href="/home"><b>home</b></a> page. 
      So here are some of the common reasons we’d disapprove a <a href="/my-profile/gallery"><b>gallery</b></a> artwork.</p>
      <ol>
        <li>
          <a id="irrelevant-content"></a>
          <b>Irrelevant content</b><br/>
          <p>
          Fashion is art performed on the human body. So artworks that are irrelevant to fashion such as images solely containing: sceneries, landscapes, animals, architectures, graphic shapes, instruments, furniture or objects like cutleries, books—just to name a few. This may also include wedding-focused or lifestyle-centric images. Please understand that we're ultimately a fashion platform.
          </p>
        </li>
        <li>
          <a id="unprofessional-content"></a>
          <b>Unprofessional content</b><br/>
          <p>
          Ultra-low resolution or poorly lighted images taken with mobile phones; screenshots, selfies, fashion illustrations, work-in-progress or moodboards etc.—basically images that are not portfolio material. Though we rarely reject unprofessional artworks, but when we do, it's only because we're looking out for you. As you know, a poor impression is all it takes to make the strongest impression. And we definitely won't want your reputation to be affected.
          </p>
        </li>
        <li>
          <a id="explicit-content"></a>
          <b>Explicit Content</b><br/>
          <p>
          While nudity has always been the essence of fashion and art, we all know there's a clear line that separates the spirit of artistic expressions and pornography. Any explicit / intended pornographic content will be disapproved.
          </p>
        </li>
        <li>
          <a id="legal-reasons"></a>
          <b>Legal Reasons</b><br/>
          <p>
          Artworks that don't belong to you. This also applies to artworks that were approved before, but are flagged by the <b>itch</b> community and proven not to be your intellectual property.
          </p>
        </li>
      </ol>
    `
  },
  {
    question: `What articles do we reject`,
    answer: `
      Whether it’s an editorial or a blog piece, we welcome your fashion views, 
      stories, news, ideas, experiences, industry encounters and even your ootds on our <a href="/zine">zine</a>. 
      However, we’ll reject <a href="/my-profile/articles">articles</a> that are not fashion-related, non-descriptive or 
      advertisements that are selling / promoting a fashion product or service without an opinion. 
      <a href="/my-profile/articles">articles</a> with a cover image that is poor in resolution may too be disapproved.
    `
  },
  {
    question: `Do I need to send a high-quality image if I’m getting featured on itch’s zine`,
    answer: `
      Our common practice is to use the image you’ve uploaded in your <a href="/my-profile/gallery">gallery</a> for your <b>zine</b> feature. 
      However, if your image doesn’t meet a standard or passable quality, we’ll contact you for one. 
      So to avoid the hassle, do ensure your images are high or decent in resolution when submitting them in your <a href="/my-profile/gallery">gallery</a>, 
      all so we can expedite your feature. And don't forget your team credits in your artwork description, 
      as well as an awesome fashion statement to match.
    `
  },
  {
    question: `What is my feed`,
    answer: `
      <a href="/my-profile">my feed</a> is designed to display only artworks from people you follow. 
      This way, you can do away the clutter from <a href="/home">home</a> and not miss any new artworks from them.`
  },
  {
    question: `How to I adjust the display of my artwork image`,
    answer: `
      After uploading an image, an eye icon will appear on the display box. 
      Click and drag the eye icon to adjust the image to your preferred position.
    `
  },
  {
    question: `How to itchbook an artwork`,
    answer: `
      Every artwork on <b>itch</b> has a "<b>itchbook it</b>" button. 
      Simply click on it to save your favourite artworks to your <b>itchbook</b>. 
      You may also click on it again to undo.
    `
  },
  {
    question: `How to change my account type`,
    answer: `
      To change your account type, simply go under your settings to do so. 
      For <b>FA</b> (Fashion Artist) account type, you can select up to 3 fashion professions. 
      Your first choice will reflect first in your <b>itch</b> profile. 
      For example, if you’re primarily a <b>photographer</b> and a freelance <b>hair stylist</b> and <b>makeup artist</b>, 
      and you’d like for it to appear in this order, select <b>photographer</b> first, 
      <b>hairstylist</b> second and <b>makeup artist</b> as your last choice.
    `
  },
  {
    question: `How does the ranking system work in collectives`,
    answer: `
      In the <a href="/collectives">collectives</a> page, everyone’s ranking is determined by the total number of your 
      followers and those you follow. Yes, this means you can now genuinely 
      follow your favourite fashion artists, lovers and companies, 
      while raising your profile visibility altogether.
    `
  },
  {
    question: `How to view artworks old and new`,
    answer: `
      At the bottom right of <a href="/home">home</a> page, there is a button we like to call the 
      “<b>shuffle</b>” button. No algorithms, just totally random. Click on it to explore 
      artworks old and new—simply because old can always be gold.
    `
  },
  {
    question: `How to create my unique itch URL`,
    answer: `
      In your settings, you will see <b>My itch URL</b> which you can change the default URL to your preferred URL. 
      For example: <b>https://www.itch.me/johndoe</b>. 
      Once done, click save and the URL will be exclusively yours. 
      Better still, pasting your unique <b>itch</b> URL link on your website and social sites will allow people to 
      view your itch profile even if they are not signed up on <b>itch</b>.
    `
  }
]

export default function Faq() {
  const { hash } = useLocation()

  useEffect(() => {
    setTimeout(() => {
      const anchor = document.getElementById(hash.substring(1))
      if (anchor) {
        window.scrollTo({
          top: anchor.offsetTop
        })
      }
    }, 300)
  }, [hash])

  return (
    <>
      <HeaderBanner imageSrc={images['faq-header.jpg']} >
        <Title>faq</Title>
      </HeaderBanner>
      <PageContainer>
        <BodyContent>
          <FaqContent>
            <ol>
            {faqContent.map(( {id, question, answer }, i) => 
              <li key={i}>
                <a id={id ?? slugify(question.split(' ').splice(0, 5).join('-'), { lower: true })} />
                <h1>{question}?</h1>
                <div dangerouslySetInnerHTML={{__html: answer}}>
                </div>
              </li>
            )}
            </ol>
          </FaqContent>
        </BodyContent>
      </PageContainer>
    </>
  )
}