import React, { useContext, useMemo, useState } from 'react'

const initialStateOpenSection = {
  name: null, 
}

export const Sections = Object.freeze({
  FeaturedArtwork: 'featured-artwork',
  ItchArticle: 'itch-article',
  UserArticle: 'user-article'
})

const initialStateSectionsClosed = {
  [Sections.FeaturedArtwork]: true,
  [Sections.ItchArticle]: true,
  [Sections.UserArticle]: true
}

const initialStateLoaded = {
  [Sections.FeaturedArtwork]: false,
  [Sections.ItchArticle]: false,
  [Sections.UserArticle]: false
}

/*
  intended behaviour:
  - 3 sections
    - featured artwork
    - itch article
    - trending users & articles

  each section has 2 state
  - opened
  - closed

  a section will only open after the previous opened section has closed

  feature artwork when start open
  if itch article is opened
    - close it
    - on closed
      - scroll to top of content
      - apply open transition to featured artwork
  else
    - scroll to top of content
    - apply open transition to featured artwork

  itch article when start open
  if featured artwork is opened
    - remove open transition from section
    - on closed
      - scroll to top of content
      - apply open transition to article
  else
    - scroll to top of content
    - apply open transition to article

  trending users & articles
  if feature artwork or itch article is opened
    - remove open transition from section
    - on closed
      - scroll to top of content
  else
    - scroll to top of content
*/

export const ZineContext = React.createContext({
  openedSection: initialStateOpenSection,
  setOpenSection: () => {},
  loaded: initialStateLoaded,
  setLoaded: () => {},
  sectionsClosed: initialStateSectionsClosed,
  setOpened: () => {},
  setClosed: () => {}
});

const ZineProvider = ({ children }) => {
  
  const [ openedSection, setOpenSection ] = useState(initialStateOpenSection)
  const [ loaded, setLoaded ] = useState(initialStateLoaded)
  const [ sectionsClosed, setSectionClosed ] = useState(initialStateSectionsClosed)

  return (
    <ZineContext.Provider value={{
      openedSection, setOpenSection, 
      loaded, setLoaded, 
      sectionsClosed, setSectionClosed }}>
      {children}
    </ZineContext.Provider>
  );
}

export const useZine = () => {
  const {
    openedSection, setOpenSection, 
    loaded, setLoaded, 
    sectionsClosed, setSectionClosed 
  } = useContext(ZineContext);

  const openSection = (name) => {
    setOpenSection({
      name
    })
    setSectionClosed({
      ...sectionsClosed,
      [name]: false
    })
  }

  const closeSection = () => {
    setOpenSection({
      name: null
    })
  }

  const setLoadedComponent = (name) => {
    setLoaded({
      ...loaded,
      [name]: true
    })
  }

  const setClosed = (section) => {
    setSectionClosed({
      ...sectionsClosed,
      [section]: true
    })
  }

  const allReady = useMemo(() => {
    return loaded[Sections.FeaturedArtwork] && loaded[Sections.ItchArticle] && loaded[Sections.UserArticle]
  }, [loaded])

  return {
    openedSectionName : openedSection.name,
    openSection,
    closeSection,
    Sections,
    allReady,
    setLoadedComponent,
    setClosed,
    sectionsClosed
  }
}

export default ZineProvider
