import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import {
  colours,
  fonts
} from '../../styles/global.styles'

const Wrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Radio = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:nth-child(2) {
    margin-right: 5px;
    margin-left: 5px;
  }

  &>div {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &>span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${props => props.theme === 1 ? colours.pink : colours.text};
    }
  }

  &>input[type="radio"] {
    visibility: hidden;
    position: absolute;
    left: 0;
  }
  
  &>input[type="radio"]:checked ~ div > span {
    width: 16px;
    height: 16px;
  }
  
  &:hover span {
    width: 16px;
    height: 16px;
  }
`;

const Selected = styled.div`
  position: relative;
  height: 22px;
`;

const ThemeItem = styled.span`
  position: absolute;
  right:0;
  top:0;

  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .2s;
  opacity: ${props => props.visible ? 1 : 0};

  display:flex;
  align-items: center;
  align-self: center;
  color: ${colours.text};
  font-family: ${fonts.content.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  margin-right: 5px;
  margin-bottom: 8px;

  &>span {
    color: ${colours.pink};
    font-style: italic;
    font-family: ${fonts.content.bold};
    padding-right: 5px;
  }
`;

const options = [
  {
    name: 'Flare',
    value: 1
  },
  {
    name: 'Metro',
    value: 2
  }
]

export default function SelectTheme({
  value,
  defaultValue,
  name,
  className,
  onChange,
  onBlur,
  ...rest
}) {
  const [selectedTheme, setSelectedTheme] = useState(value ?? 1)
  const [hovered, setHovered] = useState(null)

  return (
    <Wrapper className={className}>
      <Selected>
        <ThemeItem
          visible={hovered === null}>
          <span>{options.find(o => o.value === selectedTheme).name}</span> Theme
        </ThemeItem>
        {options.map((item, i) => 
          <ThemeItem key={i} 
            visible={hovered === item.value}
          >
            <span>{item.name}</span> Theme
          </ThemeItem>
        )}
      </Selected>

      {options.map((item, i) => (
        <Radio 
          theme={item.value} 
          key={i} 
          onMouseOver={() => {
            setHovered(item.value)
          }}
          onMouseOut={() => {
            setHovered(null)
          }}
        >
          <input 
            type="radio"
            name={name}
            value={item.value}
            defaultChecked={defaultValue === item.value}
            onClick={() => {
              setSelectedTheme(item.value)
              onChange && onChange(item.value)
              onBlur && onBlur()
            }}
          />
          <div>
            <span></span>
          </div>
        </Radio>
      ))}

    </Wrapper>
  );
}