import styled from 'styled-components'
import {
  fonts,
  breakpoints,
  colours,
  ButtonPrimary,
  BaseTransition
} from '../styles/global.styles'

export const Container = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colours.black_alpha85};
`

export const Content = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: underline;
  }
`

export const Icon = styled.div`
  width:105px;
`

export const Title = styled.h1`
  margin-top:50px;
  color: ${colours.pink};
  font-family: ${fonts.content.bold};
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
  
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    font-size: 21px;
    line-height: 30px;
  }
`

export const Body = styled.div`
  color: ${colours.grey};
  font-family: ${fonts.content.regular};
  font-size: 15px;
  line-height: 21px;

  b {
    font-family: ${fonts.content.bold};
  }
  
  @media screen and (min-width:${breakpoints.sm + 1}px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export const OkAction = styled(ButtonPrimary)`
  width: 240px;
  max-width: 100%;
  margin-top: 50px;
`

