import React from 'react'
import styled from 'styled-components'
import {
  breakpoints
} from '../../styles/global.styles'

const IconWrapper = styled.span`
  position: relative;
  display: block;
  height: 16px;
  cursor: pointer;
  &>img {
    display:block;
    width: 100%;
    height :100%;
    object-fit: contain;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
  }
  &>img:first-child {
    opacity: 1;
  }
  &>img:last-child {
    top:0;
    left:0;
    position: absolute;
    opacity: 0;
  }

  @media (hover: hover) {
    &:hover {
      &>img:first-child {
        opacity: 0;
      }
      &>img:last-child {
        opacity: 1;
      }
    }
  }
`;

export default function HoverIcon({
  active,
  hover,
  className,
  alt = '',
  onClick = () => {}
}) {
  return (
    <IconWrapper onClick={onClick} className={className}>
      <img src={active} alt={alt} className="icon_active" />
      <img src={hover} alt={alt} className="icon_hover" />    
    </IconWrapper>
  )
}