import { useContext } from "react";
import { ActionAlertContext } from "../contexts/action-alert.context";

const useActionAlert = () => {
  const [actionAlert, setActionAlert] = useContext(ActionAlertContext);

  const closeAlert = () => {
    setActionAlert({
      ...actionAlert,
      active: false
    })
  }

  return {
    actionAlert,
    setActionAlert,
    closeAlert
  }
}

export default useActionAlert;