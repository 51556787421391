import useAuth from 'hooks/auth.hook'
import { useHistory } from 'react-router-dom'

const useLoggedOutHandleRoute = () => {
  const { session, fetching } = useAuth()
  const { push } = useHistory()

  const handleAction = (func) => {
    if (!fetching && session) {
      func()
    } else {
      push('/signup')
    }
  }

  const linkProps = (to) => {
    if (!fetching && session) {
      return { to }
    } else {
      return {
        to: '/signup',
      }
    }
  }

  const link = (to) => {
    if (!fetching && session) {
      return to
    } else {
      return '/signup'
    }
  }

  return {
    handleAction,
    linkProps,
    link
  }
}

export default useLoggedOutHandleRoute