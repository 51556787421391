import styled from 'styled-components'
import {
  fonts,
  colours
} from 'styles/global.styles'

export const Container = styled.div`
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 12px;
  position: relative;
  flex:1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

  div.label {
    visibility: ${props => props.showInput ? 'hidden' : 'visible'};
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    font-family: ${fonts.title.xbold};
    font-weight: 400;
    color: ${props => props.theme.color === 'pink' ? colours.light : colours.text};
    
  
    &:hover {
      color: ${colours.white};
    }
  
  }

  div.search {
    position: absolute;
    visibility: ${props => props.showInput ? 'visible' : 'hidden'};
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    img.icon {
      flex: 0 0 15px;
      margin-right:6px;
    }
  }
`

export const Input = styled.input`
  font-size: 15px;
  line-height: 18px;
  font-family: ${fonts.title.xbold};
  color: ${props => props.theme.color === 'pink' ? colours.white : colours.text};
  background-color: transparent;
  border:none;
  outline:none;
  width: 100%;
  padding:0;
  vertical-align: bottom;

  &::placeholder {
    font-size: 15px;
    line-height: 18px;
    font-family: ${fonts.title.xbold};
    font-weight: 800;
    color: ${props => props.theme.color === 'pink' ? colours.white : colours.text};
    opacity: 0.55;
  }
`