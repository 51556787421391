import styled from 'styled-components'
import {
  breakpoints
} from 'styles/global.styles'

const Container = styled.div`
  height: 40vw;
  position: relative;
  overflow:hidden;

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};
    transform-origin: center center;
    transition: transform 0.5s, visibility 0.5s;
    transform: scale(${props => props.open ? 1.1 : 1});
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    height: 100%;
  }
`

export default function ItchArticleCover({ src, open, position }) {
  return (
      <Container position={position} open={open}>
          <img src={src} alt="article cover" />
      </Container>
  )
}