import styled from 'styled-components'
import {
  fonts,
  colours,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  min-height: 100vh;
  padding-top: 45px;
  background-color: ${colours.darkerlight2_alpha95};

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    padding-top: 65px;
  }
`

export const Header = styled.div`
  min-height: 80px;
  width: 100%;
  padding:10px 13px 20px;
  background-color: ${colours.darkerlight2};
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  box-shadow: 0px 0px 3px 0px rgba(51, 51, 51, 0.2);
  position:sticky;
  top: 45px;
  z-index:10;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    min-height: 125px;
    padding: 30px 0 30px 0;
    top: 65px;
  }
`

export const Title = styled.div`
  color: ${colours.dark};
  font-family: ${fonts.title.xbold};
  font-size: 18px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width: 100%;
  max-width: 721px;
  margin-bottom: 5px;
  margin-top: 5px;

  &:before {
    content: '';
    width:1px;
    flex: 1 1 auto;
    border-bottom: 1px solid ${colours.dark};
    margin-right: 10px;
  }

  &:after {
    content: '';
    width:1px;
    flex: 1 1 auto;
    border-bottom: 1px solid ${colours.dark};
    margin-left: 10px;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}) {
    margin-top: 17px;
    margin-bottom: 20px;
  }
`;

export const Categories = styled.div`
  justify-self:center;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:flex-end;
  position: relative;
`

export const CategorySlider = styled.div`
  min-width: 1px;
  height: 2px;
  background-color: ${colours.pink};
  position:absolute;
  margin-top:5px;
  top: 100%;
  left: 0px;
  width: auto;
  transition-duration: .2s;
  transition-timing-function: ease;
`

export const CategoryItem = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-left: 16px;
  padding-right:16px;
  
  cursor: pointer;

  img {
    margin-bottom: 10px;
  }

  span {
    color: ${colours.darkergrey2};
    font-family: ${fonts.title.xbold};
    font-size: 12px;
    line-height: 14px;
  }

  &:first-child {
    img {
      width: 25px;
    }
  }

  &:nth-child(3) {
    img {
      width: 20px;
    }
  }

  &:last-child {
    img {
      width: 18px;
    }
  }

  @media and (max-width:${breakpoints.sm}px) {
    
  }

`

export const Seperator = styled.div`
  height: 40px;
  width:0px;
  border-right:1px solid ${colours.lightgrey3};
  align-self: center;
`