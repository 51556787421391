import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDraft, createSubmit } from '../apis/account-article.api';

export default function useCreateArticle(userId) {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      if (data.isDraft)
        return createDraft(data)
      else
        return createSubmit(data)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [`articles-${userId}`, ] })
      queryClient.invalidateQueries({ queryKey: ['search-articles-by-keywords'] })
    }
  });

  return {
    create: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}