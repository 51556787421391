import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFollowedByMe } from '../apis/user.api';
import useAuth from 'hooks/auth.hook'

export default function useGetFollowedByMe() {
  const { session } = useAuth()

  const { 
    isLoading, 
    error, 
    data, 
    isSuccess, 
    isFetching,
    refetch
  } = useQuery({
    queryKey: [`followed-by-me`],
    queryFn: () => {
      return session ? getFollowedByMe() : () => ({
        userIds: []
      })
    },
    enabled: true
  })

  useEffect(() => {
    if (session) {
      refetch()
    }
  }, [session])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    userIds: session ? (isSuccess ? data.userIds : []) : [],
  }
}