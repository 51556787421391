import axiosInstance from './api';
import { ActivityActions } from '../constants';

const actions = [
  ActivityActions.Commented,
  ActivityActions.CommentedArticle,
  ActivityActions.CommentedArtwork,
  ActivityActions.Voted,
  ActivityActions.Itchbooked,
  ActivityActions.Featured,
  ActivityActions.Followed,
  ActivityActions.Tagged,
  ActivityActions.TaggedArtwork,
  ActivityActions.TaggedFashionStatement,
  ActivityActions.TaggedArtworkComment,
  ActivityActions.TaggedArticle,
  ActivityActions.TaggedArticleComment,
  ActivityActions.StartedConversation,
  ActivityActions.Approved,
  ActivityActions.ApprovedArticle,
  ActivityActions.ApprovedArtwork,
  ActivityActions.Rejected,
  ActivityActions.RejectedArticle,
  ActivityActions.RejectedArtwork,
  ActivityActions.SharedConversation
]

export const getProfile = () => {
  return axiosInstance.get('/account')
}

export const updateProfile = (profile) => {
  return axiosInstance.put('/account', profile)
}

export const getSettings = () => {
  return axiosInstance.get('/settings')
}

export const updateSettings = (settings) => {
  return axiosInstance.put('/settings', settings)
}

export const updateAccountType = (accountType) => {
  return axiosInstance.put('/settings/account-type', accountType)
}

export const checkAccountEmail = (email) => {
  return axiosInstance.post('/settings/email-check', {
    email,
  })
}

export const checkEmailExist = (email) => {
  return axiosInstance.post('/registrations/check-email', {
    email,
  })
}

export const registerUser = (data) => {
  return axiosInstance.post(`/registrations`, data)
} 

export const checkCurrentPassword = (password) => {
  return axiosInstance.post(`/settings/password-check`, {
    password
  })
}

export const triggerChangePassword = (data) => {
  return axiosInstance.post(`/settings/password`, data)
}

export const confirmChangePassword = (code) => {
  return axiosInstance.put(`/settings/password/${code}`)
}

export const confirmChangeEmail = (code) => {
  return axiosInstance.put(`/settings/email/${code}`)
}

export const resendChangeEmail = () => {
  return axiosInstance.post(`/settings/email-change`)
}

export const getIsChangingEmail = () => {
  return axiosInstance.get('/settings/email-change')
}

export const cancelChangeEmail = () => {
  return axiosInstance.delete(`/settings/email-change`)
}

export const triggerPasswordReset = () => {
  return axiosInstance.post('/settings/reset-password')
}

export const getCurrentUserProfile = () => {
  return axiosInstance.get('/profiles/current')
}

export const getActivitiesOnCurrentUser = async (page, size = 100, from) => {
  const resp = await axiosInstance.get(`/profiles/current/activities?page=${page}&size=${size}&actions=${actions.join(',')}${from ? '&from=' + (new Date(from)).toISOString() : ''}`)
  return resp.data
}

export const getActivitiesCountOnCurrentUser = (from) => {
  return axiosInstance.get(`/profiles/current/activities/count?actions=${actions.join(',')}`)
}

export const getActivitiesCountSinceDateOnCurrentUser = (from) => {
  return axiosInstance.get(`/profiles/current/activities/count?actions=${actions.join(',')}${from ? '&from=' + (new Date(from)).toISOString() : ''}`)
}

export const getUserProfile = (itchUrl) => {
  return axiosInstance.get('/profiles/' + itchUrl)
}

export const getUserUrlById = async (id) => {
  const response = await axiosInstance.get(`/users/${id}/itchUrl`)
  return response.data
}

export const checkItchUrlUsed = (itchUrl) => {
  return axiosInstance.post('/settings/itchurl-used', {
    itchUrl
  })
}

export const triggerDeactivateMe = (data) => {
  return axiosInstance.post('/settings/deactivate', data)
}

export const confirmDeactivateMe = (code) => {
  return axiosInstance.put('/settings/deactivate/' + code)
}

export const findUsersByNameStartWith = async (
  { name, excludeUsers, page = 0, size = 100 }
) => {
  if (name.length > 0) {
    const response = await axiosInstance.get(`/profiles/search-name/${name}?exclude=${excludeUsers.join(',')}&page=${page}&size=${size}`)
    return response.data
  } else {
    return {
      data: [],
      page,
      size,
      totalRecords: 0,
      totalPages: 0
    }
  }
}

export const getCurrentUserActivities = async ({
  page = 0,
  size = 100
}) => {
  const response = await axiosInstance.get(`/profiles/current/activities?page=${page}&size=${size}&actions=${actions.join(',')}`)
  return response.data
}

export const searchUserByKeywords = async ({
  query,
  page = 0,
  size = 10
}) => {
  const response = await axiosInstance.get(`/users/search?query=${query}&page=${page}&size=${size}`)
  return response.data
}

export const searchUserByAccountType = async ({
  accountType,
  subType = null,
  page = 0,
  size = 10
}) => {
  let response = null
  if (subType) {
    response = await axiosInstance.get(`/users/account-types/${accountType}/${subType ?? ''}?page=${page}&size=${size}`)
  } else {
    response = await axiosInstance.get(`/users/account-types/${accountType}?page=${page}&size=${size}`)
  }
  
  return response.data
}

export const searchUsers = async ({
  page = 0,
  size = 10
}) => {
  let response = await axiosInstance.get(`/users?page=${page}&size=${size}`)
  return response.data
}

export const getCurentUserFeed = async ({
  page = 0,
  size = 10
}) => {
  const response = await axiosInstance.get(`/profiles/current/feed?page=${page}&size=${size}`)
  return response.data
}

export const getFollowers = async ({
  id,
  page = 0,
  size = 10
}) => {
  const response = await axiosInstance.get(`/users/${id}/followers?page=${page}&size=${size}`)
  return response.data
}

export const getFollowing = async ({
  id,
  page = 0,
  size = 10
}) => {
  const response = await axiosInstance.get(`/users/${id}/following?page=${page}&size=${size}`)
  return response.data
}

export const getFollowedByMe = async () => {
  const response = await axiosInstance.get(`/account/following-ids`)
  return response.data
}

export const getCurrentUserTasks = async () => {
  const response = await axiosInstance.get('/settings/tasks')
  return response.data
}

export const cancelTask = async (id) => {
  await axiosInstance.delete('/settings/tasks/' + id)
}

export const getUserAcknowledgedVoting = async () => {
  return await axiosInstance.get('/account/ack-vote')
}

export const setUserAcknowledgedVoting = async () => {
  await axiosInstance.put('/account/ack-vote')
}
