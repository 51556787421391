import React from 'react'
import HoverIcon from '../shared/hover-icon'
import Tile from '../tile/tile'
import styled from 'styled-components'
import {
  icons
} from '../../constants'
import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'

const HoverIconStyled = styled(HoverIcon)`
  z-index: 2;
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  top: 7.5px;
  left: 7.5px;

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const TileInner = styled.div`
  display: block;
  position: relative;
  cursor: pointer;

  font-family: ${fonts.title.xbold};
  color: ${colours.grey};
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  height: 100%;
`

const Text = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:1;
  
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
`;

const HaveExistingDraft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NoExistingDraft = styled.div`
  pointer-events: ${props => props.disabled ? 'none':'all'};
  height: 100%;

  
  &:hover {
    .text {
      color: ${colours.pink};
    }
  }

`

const Action = styled.div`
  flex: 0 0 50%;
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.position === 'top' ? 'flex-start':'flex-end'};

  &>div.hover {
    position: absolute;
    ${props => props.position}: 0;
    left: 0;
    opacity: 0;
  }

  &>div {
    width: 100%;
    transition-property: all;
    transition-duration: .2s;
    position: relative;
    display: flex;
    flex-direction: column;

    &>img.icon {
      position: absolute;
      top: ${props => props.position === 'top' ? 110: 'unset'}%;
      bottom: ${props => props.position === 'bottom' ? 170: 'unset'}%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .text {
    position: absolute;
    top: ${props => props.position === 'top' ? 0 : 'unset'};
    bottom: ${props => props.position === 'bottom' ? '-13%' : 'unset'};
    width: 100%;
    text-align: center;
  }


  &:hover {
    .active {
      opacity: 0;
    }
    .hover {
      opacity: 1;
    }
    
    .text {
      color: ${colours.pink};
    }
  }

`

export default function RevealArticleTile({
  haveDraft = false,
  onNewArticle = () => {},
  onContinueDraft = () => {},
  onRestart = () => {},
  ...rest
}) {

  const disablePostArticle = window.innerWidth < 768
  return (
    <Tile {...rest} 
      disabled={disablePostArticle}
      responsiveHeight={{
        [breakpoints.sm]: '73.6vw'
      }}
    >
      <TileInner>
        {haveDraft ? 
        <HaveExistingDraft>
          <Action position="top" 
            onClick={() => {
              onContinueDraft()
            }}
          >
            <div className="active">
              <img className="background" src={icons['new-article-top.svg']} alt="" />
              <img className="icon" src={icons['continue-saved-article.svg']} alt="" />
            </div>
            <div className="hover">
              <img className="background" src={icons['new-article-top-pink.svg']} alt="" />
              <img className="icon" src={icons['continue-saved-article-pink.svg']} alt="" />
            </div>
            <Text className="text">continue saved article</Text>
          </Action>

          <Action position="bottom"
            onClick={() => {
              onRestart()
            }}
          >
            <div className="active">
              <img className="background"  src={icons['new-article-bottom.svg']} alt="" />
              <img className="icon" src={icons['new-article.svg']} alt="" />
            </div>
            <div className="hover">
              <img className="background"  src={icons['new-article-bottom-pink.svg']} alt="" />
              <img className="icon" src={icons['new-article-pink.svg']} alt="" />
            </div>
            <Text className="text">discard and post new article</Text>
          </Action>
        </HaveExistingDraft>
        : null}
        {!haveDraft ? 
        <NoExistingDraft 
          disabled={disablePostArticle} 
          onClick={() => {
              if (!disablePostArticle)
                onNewArticle()
          }}>
          <HoverIconStyled
            active={icons['reveal-article-grey.svg']}
            hover={icons['reveal-article-pink.svg']}
          >
          </HoverIconStyled>
          {disablePostArticle ? <Text className="text">use desktop to<br/>post an article</Text> : <Text className="text">publish an article</Text>}
        </NoExistingDraft>
        : null}
      </TileInner>
    </Tile>
  )
}
