import React, { useRef, useState, useEffect } from 'react'
import { 
    useUserStatus,
    ScrollToArticle,
    useWindowDimensions,
    WaitForClose
} from '../../../utils/helpers';
import { 
    getName,
    imageUrl
} from '../../../utils/utils'
import Emitter from 'utils/emitter'
import { 
    getOccupation
} from '../utils'
import scrollTo from 'utils/scrollTo'
import { useHistory, Link } from 'react-router-dom'
import { userArticleTextColor } from '../commons'
// import Emitter from 'utils/emitter';
import ArticleCover from '../article-cover'
import UserAvatar from 'components/avatar/user-avatar';
import ArticleExpanded from './article-expanded';
import useAuth from '../../../hooks/auth.hook'
import AccountTypeCategories from 'components/shared/account-type-categories'
import styled from 'styled-components'
import { colours, fonts } from 'styles/global.styles';

const colorFromTheme = {
    'white': colours.black,
    'dark': colours.lightgrey9,
    'pink': colours.lightgrey9,
}

const AccountTypeContainer = styled.div`
    display:inline-block;

    .account-type-categories {
        display: inline;
        color: ${$props => colorFromTheme[$props.theme] };
        font-size: 14px;
        line-height: 16px;
        font-family: ${fonts.content.bold};

        .account-type-category__link {
            font-size: 14px;
            line-height: 16px;
            font-family: ${fonts.content.bold};
        }
    }
`

export function AvatarWrapper({ user }) {
    const { 
        accountType,
        profileImage,
        profileImageVariants
    } = user
    const { session } = useAuth()

    const isOwner = session?.profile?.id === user._id
    return (
        <div className="avatar-wrapper">
            <UserAvatar 
                profileUrl={isOwner ? `/my-profile` : `/${user.itchUrl}`}
                onClick={(evt) => {
                    evt.stopPropagation();
                }} 
                accountType={accountType}
                url={imageUrl(profileImageVariants?.large ?? profileImage)}
                position={profileImage?.position}
                alt=''     
            />
        </div>
    )
}

export function useNavHeight() {
    const loggedIn = !!useUserStatus();
    const { windowWidth } = useWindowDimensions();
    let navHeight = 0;
    if (windowWidth <= 1030) {
        navHeight = 45;
    }
    else {
        navHeight = loggedIn ? 65 : 73;
    }
    return navHeight;
}


export default function UserArticle({ 
    article,
    expanded,
    open,
    onClose,
    onClick = () => {},
    allowed = null,
    parentGridElem = null,
    setElement = () => {},
    onCompleteExpand = () => {}
}) {
    const { 
        _id,
        slug,
        title,
        subHead,
        coverImage,
        coverImageVariants,
        theme,
        user,
    } = article;
    const { windowWidth, isMobile } = useWindowDimensions();
    const [initialHeight, setInitialHeight] = useState(0);
    const articleRef = useRef();
    // const history = useHistory();
    const { session } = useAuth()
    // const { openSection, openedSectionName, closeSection, Sections } = useZine()
    
    const topNavElem = document.getElementById('top-nav') || document.getElementById('public-menu')

    let a = userArticleTextColor[theme];

    useEffect(() => {
        if (isMobile) setInitialHeight(parentGridElem.current.clientHeight);
        else setInitialHeight(parentGridElem.current.clientHeight);
        // eslint-disable-next-line
    }, [windowWidth])
    
    // let navHeight = useNavHeight();

    const scrollToUserArticleTop = () => {
        if (expanded && parentGridElem.current) {
            const cellElem = document.getElementById(`cell-${expanded.index}`)
            const topNavElem = document.getElementById('top-nav') || document.getElementById('public-menu')
            const cellElemBox = cellElem.getBoundingClientRect()
            
            return scrollTo({
                ref: parentGridElem.current,
                addOffsetTop: topNavElem.clientHeight,
            });

            /*
            if (cellElemBox && topNavElem) {
                window.scrollTo({
                    top: parentGridElem.current.offsetTop + (cellElem.offsetTop - topNavElem.clientHeight)
                })
            }
            */
        }
    }
    
    /*
    const openArticle = () => {
        // only request to open when an article is closed
        if (!open) requestToOpen();
    }
    */
    
    const waitForScroll = async () => {   
        console.log('user article', expanded)    
        if (!open) {
            console.log('user waiting for scroll');
            // scroll to article
            const finishScrolling = await ScrollToArticle(articleRef, topNavElem.clientHeight);
            // check if scroll is finished
            if (finishScrolling) {
                // set opened article
                setElement();
            }
        }
    }
    
    const closeArticle = async (closeWithinArticle) => {
        if (open) {
            // close opened article
            // clearElement();
            console.log('waiting to close user article');
            const finishClosing = await WaitForClose(initialHeight, articleRef);
            if (finishClosing) {
                console.log('emit user is closed');
                Emitter.emit('userArticleIsClosed');
                if (closeWithinArticle) Emitter.emit('closeWithin');
            }
        }
    }
    
    useEffect(() => {
        // scroll when allowed, close when disallowed
        if (allowed) waitForScroll();
        else closeArticle();
        // eslint-disable-next-line
    }, [open, allowed])

    const image = coverImageVariants ? {
        ...coverImage,
        url: process.env.REACT_APP_UPLOADS_URL + '/' + coverImageVariants.medium.filename
    } : coverImage
    
    /*
    useEffect(() => {
        if (openedSectionName !== null && openedSectionName !== Sections.UserArticle && expanded) {
            onClose()
        }
    }, [openedSectionName, expanded])
    */

    return (
        <div
            ref={articleRef}
            className={"article-container user-article position-relative " + (expanded ? 'open' : '')}
        >
            {!expanded ? <ArticleCover src={imageUrl(image) ??''} /> : null}
            {!expanded ? <div className={`${theme}-tint tint`}>
                <div className="article-details">
                    {user ? <AvatarWrapper user={user} /> : null}
                    <div className="body-text pn-bold">
                        <div className={a.description + " title"}>{title}</div>
                        <div className={a.description + " subtitle pn"}>{subHead}</div>
                        {user ? <div className={a.description + " desc"}>
                            <span className="pn">By </span>
                            <Link to={session?.profile?.id !== user._id ? `/${user.itchUrl}`: '/my-profile'}>{getName(user)}</Link>,&nbsp;
                            <AccountTypeContainer theme={theme}>
                                <AccountTypeCategories {...user}/>
                            </AccountTypeContainer>
                        </div> : null}
                    </div>
                </div>
            </div> : null}
            {expanded ? <ArticleExpanded hideScroll={true} slug={article.slug} theme={article.theme} onClose={onClose} /> : null}
        </div>
    )
}