import axiosInstance from './api';

export const createDraft = (data) => {
  return axiosInstance.post('/account/articles/draft', data)
}

export const createSubmit = (data) => {
  return axiosInstance.post('/account/articles/submit', data)
}

export const updateDraft = (id, data) => {
  return axiosInstance.put('/account/articles/' + id + '/draft', data)
}

export const updateSubmit = (id, data) => {
  return axiosInstance.put('/account/articles/' + id + '/submit', data)
}

export const getAll = (page = 0, size = 10, sortBy) => {
  return axiosInstance.get(`/account/articles?page=${page}&size=${size}${sortBy ? '&sortBy=' + sortBy : ''}`)
}

export const getAllByUser = (userId, page = 0, size = 10, sortBy) => {
  return axiosInstance.get(`/users/${userId}/articles?page=${page}&size=${size}${sortBy ? '&sortBy=' + sortBy : ''}`)
}

export const getOne = (id) => {
  return axiosInstance.get('/account/articles/' + id)
}

export const deleteOne = (id) => {
  return axiosInstance.delete('/account/articles/' + id)
}

export const setReviewRespond = (id) => {
  return axiosInstance.put(`/account/articles/${id}/review-respond`)
}

export const countReviewNotResponded = () => {
  return axiosInstance.get(`/account/articles/not-responded`)
}

export const countUnseen = () => {
  return axiosInstance.get(`/account/articles/unseen-count`)
}

export const setSeen = () => {
  return axiosInstance.put(`/account/articles/unseen-count`)
}