import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components'
import {
  colours,
  breakpoints,
  fonts,
  BaseTransition
} from '../../styles/global.styles'
import {
  AccountTypesName,
  getProfessionLabelValues,
  Plurals,
  AccountTypes,
  AccountTypesSlugMapName,
  AccountTypesNameSlug,
  CompanyTypesLabelSlugsList
} from '../../constants'
import useAnimatedNavBar from 'hooks/animated-nav-bar.hook';
import { useWindowDimensions } from 'utils/helpers';

const Container = styled.div`
  margin-bottom: 18px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`

const AccountTypesContainer = styled.div`
  position: relative;
`

const Circles = styled.div`
  width: 80px;
  height: 63px;
  position: relative;
`

const Diagonals = styled.div`
  position: absolute;
  left: 50%;
  top: 12px;
  transition: opacity 0.1s ease-out;
  cursor: pointer;

  
  &:hover {
    .line {
      border-color: ${colours.pink};
    }
  }

`

const Line = styled.div`
  height: 60px;
  border-right: 2px solid ${colours.dark2};
  transition: all 0.1s ease-out;
  transform: rotate(${props => props.rotate === 'right' ? 45 : -45}deg);
  ${props => props.rotate === 'left' ? `
    position: absolute;
    top: 0;
  `: ''}
`

const Circle = styled.div`
  width: 25px;
  height: 25px;
  color: ${props => props.selected ? colours.pink : colours.dark2 };
  border: 2px solid ${props => props.selected ? colours.pink : colours.dark2};
  border-radius: 100%;
  position: absolute;
  font-size: 11px;
  line-height: 22px;
  font-family: ${fonts.title.xbold};


  &:hover {
    color: ${colours.pink};
    border-color: ${colours.pink};
  }


  &.fa {
    left: 50%;
    transform: translateX(-50%);
  }
  
  &.fc {
    top: 30px;
  }
  
  &.fl {
    top: 30px;
    right: 0;
  }
`

export const Title = styled.div`
  color: ${colours.dark2};
  font-family: ${fonts.title.xbold};
  font-weight: 800;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 12px;
  padding-top:12px;

  &:before {
    content: '';
    display: inline-block;
    justify-self: flex-start;
    width: 100%;
    border-bottom: 1px solid ${colours.dark2};
    margin-right: 10px;
    flex: 1 1 auto;
  }
  &:after {
    content: '';
    display: inline-block;
    justify-self: flex-end;
    width: 100%;
    border-bottom: 1px solid ${colours.dark2};
    margin-left: 10px;
    flex: 1 1 auto;
  }
  &>span {
    width: fit-content;
    flex: 0 0 auto;
    white-space: nowrap;
  }

  span.pink {
    color: ${colours.pink};
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0em;
    justify-content: center;
    padding-top:10px;
    
    &:before,
    &:after {
      max-width: 236px;
    }
  }
`;

export const Categories = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  position: relative;
  padding-bottom:10px;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.sm}px) {
  }
`

const LinkItem = styled(Link)`
  cursor: pointer;

  &>span {
    font-size: 12px;
    line-height: 12px;
    font-family: ${fonts.title.bold};  
  }

  &:before {
    content: '';
    margin-right: 12px;
  }

  &:after {
    content: '';
    display:inline-block;
    height: 8px;
    border-right:${props => props.hideSeperator ? 0 : 1}px solid ${colours.dark2};
    margin-left: 12px;
  }


  &:hover {
    &>span {
      color: ${colours.pink};
    }
  }


  @media (max-width: ${breakpoints.sm}px) {
    &>span {
      white-space: nowrap;
      border-bottom: ${props => props.selected ? '2px solid ' + colours.pink: 'none'};
    }

    &:before {
      margin-right: 6px;
    }

    &:after {
      margin-left: 6px;
    }
  }
`

export const NavItemActiveBar = styled(BaseTransition)`
  min-width: 0px;
  height: 2px;
  background-color: ${colours.pink};
  position:absolute;
  top: 80%;
  left: 0px;
  width: auto;
  transition-duration: .2s;
  transition-timing-function: ease;
  margin-left:12px;

  @media (max-width: ${breakpoints.sm}px) {
    display:none;
  }
`;

export const LinksRow = styled.div`
  &>a:last-child {
    :after {
      display:none;
    }
  }
`

const AccountTypesSmallSlugMapType = {
  'fa': AccountTypes.FashionArtist,
  'fc': AccountTypes.FashionCompany,
  'fl': AccountTypes.FashionLover    
}

export const FashionArtistProfessions = [
  {
      value: 'designer',
      label: 'Designers'
  },
  {
      value: 'model',
      label: 'Models'
  },
  {
      value: 'photographer',
      label: 'Photographers'
  },
  {
      value: 'videographer',
      label: 'Videographers'
  },
  {
      value: 'makeupartist',
      label: 'Makeup artists'
  },
  {
      value: 'hairstylist',
      label: 'Hair stylists'
  },
  {
      value: 'stylist',
      label: 'Stylists'
  },
  {
      value: 'nailartist',
      label: 'Nail artists'
  },
  {
      value: 'creativedirector',
      label: 'Creative directors'
  },
  {
      value: 'retoucher',
      label: 'Retouchers'
  },
];

export default function CollectiveHeader() {
  const { isMobile } = useWindowDimensions()
  const { accountType, subType } = useParams()
  const { push } = useHistory()
  const { 
    setBarPosition, 
    setBarPositionByElem,
    animatedBarRef
  } = useAnimatedNavBar({
    targetNavItemClassName: 'link-item',
    useWindowViewPort: false,
    useInnerElemTagName: 'span'
  })

  let tabs = [
    {
      link: `/collectives/fa`,
      type: 'fa'
    },
    {
      link: `/collectives/fc`,
      type: 'fc'
    },
    {
      link: `/collectives/fl`,
      type: 'fl'
    }
  ];

  const getItems = () => {
    if (AccountTypesSmallSlugMapType[accountType] === AccountTypes.FashionArtist) {
      return FashionArtistProfessions
    } else
    if (AccountTypesSmallSlugMapType[accountType] === AccountTypes.FashionCompany) {
      return CompanyTypesLabelSlugsList
    }
  }

  const getCurrentNavItem = () => {
    if (!subType) return null;

    const navItems = getItems()
    const i = navItems.findIndex(item => item.value === subType)
    const found = navItems[i]
    return found ? i : null
  }

  const onNavItemMouseOver = (evt) => {
    setBarPositionByElem(evt.currentTarget)
  }

  const onNavItemMouseLeave = (evt) => {
    setBarPosition(getCurrentNavItem());
  }

  const subNavigation = (items) => {
    return (
      <Categories>
        <NavItemActiveBar
          ref={animatedBarRef}
        />
        {items.map((item, i) => (
          <LinkItem 
            key={`collective-link-item-${item.value}`}
            hideSeperator={i === (items.length - 1)}
            onMouseOver={onNavItemMouseOver}
            onMouseLeave={onNavItemMouseLeave}
            className='link-item'
            selected={item.value === subType}
            to={`/collectives/${accountType}/${item.value}`}>
            <span>{item.label.toLowerCase()}</span>
          </LinkItem>
        ))}
      </Categories>
    )
  }

  const mobileSubNavigation = (items) => {
    const chunks = items.reduce((acc, curr) => {
      if (acc.length === 0) {
        acc.push([curr])
        return acc
      }

      const last = acc[acc.length - 1]
      if (last.length < 4) {
        last.push(curr)
      } else {
        acc.push([curr])
      }
      return acc
    }, [])
    return (
      <Categories>
        {chunks.map((chunk,k) => (
          <LinksRow key={`collective-link-chunk-${k}`}>{chunk.map((item, i) => (
            <LinkItem 
              key={`collective-link-item-${item.value}`}
              hideSeperator={i === (items.length - 1)}
              onMouseOver={onNavItemMouseOver}
              onMouseLeave={onNavItemMouseLeave}
              className='link-item'
              selected={item.value === subType}
              to={`/collectives/${accountType}/${item.value}`}>
              <span>{item.label.toLowerCase()}</span>
            </LinkItem>
          ))}
          </LinksRow>
        ))}
      </Categories>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setBarPosition(getCurrentNavItem())
    }, 300)
  }, [])

  return (
    <Container>
      <AccountTypesContainer>
        <Circles>
          {tabs.map(({ link, type }, i) => (
            <Link key={i + 1} to={link}>
              <Circle selected={type === accountType} className={type}>{type}</Circle>
            </Link>
          ))}
        </Circles>
        <Diagonals onClick={() => {
          push('/collectives')
        }}>
          <Line className="line" rotate="right" />
          <Line className="line" rotate="left" />
        </Diagonals>
      </AccountTypesContainer>
      <Title>{accountType ? <span><span className="pink">all</span>&nbsp;{Plurals[AccountTypesName[AccountTypesSmallSlugMapType[accountType]].toLowerCase()] }</span> : 'collectives'}</Title>
      {AccountTypesSmallSlugMapType[accountType] === AccountTypes.FashionArtist 
        ? (isMobile ? mobileSubNavigation(FashionArtistProfessions) 
        : subNavigation(FashionArtistProfessions)) : null}
      {AccountTypesSmallSlugMapType[accountType] === AccountTypes.FashionCompany 
        ? (isMobile ? mobileSubNavigation(CompanyTypesLabelSlugsList)
        : subNavigation(CompanyTypesLabelSlugsList)) : null}
    </Container>
  )
}
