import { useMutation } from '@tanstack/react-query';
import { unsubscribe } from '../apis/unsubscribe';

export default function useUnsubscribe() {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return unsubscribe(data)
    },
  });
  

  return {
    unsubscribeMe: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}