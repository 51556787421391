import PinkLogo from "../../assets/images/icons/pink-logo-with-word.svg";
import WhiteLogo from "../../assets/images/icons/white-logo-with-word.svg";
import BlackLogo from "../../assets/images/icons/black-logo-with-word.svg";
import BlackClose from "../../assets/images/icons/black-close-feature.png";
import WhiteClose from "../../assets/images/icons/white-close-feature.png";
import GreyClose from "../../assets/images/icons/grey-close-feature.png";
import BlackExpand from "../../assets/images/icons/black-expand-feature.png";
import WhiteExpand from "../../assets/images/icons/white-expand-feature.png";
import GreyExpand from "../../assets/images/icons/grey-expand-feature.png";

export const pinkText = 'pink-text';
export const blackText = 'black-text';
export const whiteText = 'white-text';

export const featuredTextColor = {
    'pink': {
        class: 'pink-tint',
        logo: PinkLogo,
        chapter: whiteText,
        year: blackText,
        author: whiteText,
        occupation: blackText,
        socialMedia: whiteText,
        title: blackText,
        text: whiteText,
        closeIcon: WhiteClose,
        expandIcon: WhiteExpand,
        mouseover: {
            author : blackText,
            socialMedia : blackText
        }
    },
    'dark': {
        class: 'black-tint',
        logo: BlackLogo,
        chapter: pinkText,
        year: whiteText,
        author: pinkText,
        occupation: whiteText,
        socialMedia: pinkText,
        title: pinkText,
        text: whiteText,
        closeIcon: GreyClose,
        expandIcon: GreyExpand,
        mouseover: {
            author : whiteText,
            socialMedia : whiteText
        }
    },
    'white': {
        class: 'white-tint',
        logo: WhiteLogo,
        chapter: pinkText,
        year: blackText,
        author: pinkText,
        occupation: blackText,
        socialMedia: blackText,
        title: pinkText,
        text: blackText,
        closeIcon: BlackClose,
        expandIcon: BlackExpand,
        mouseover: {
            author : blackText,
            socialMedia : pinkText
        }
    }
}


export const itchArticleTextColor = {
    'pink': {
        class: 'pink-tint',
        type: whiteText,
        title: whiteText,
        description: whiteText,
        closeIcon: WhiteClose
    },
    'dark': {
        class: 'black-tint',
        type: pinkText,
        title: whiteText,
        description: whiteText,
        closeIcon: GreyClose
    },
    'white': {
        class: 'white-tint',
        type: pinkText,
        title: pinkText,
        description: pinkText,
        closeIcon: BlackClose
    }
}

export const userArticleTextColor = {
    'pink': {
        class: 'pink-tint',
        title: whiteText,
        description: whiteText
    },
    'dark': {
        class: 'black-tint',
        title: whiteText,
        description: whiteText
    },
    'white': {
        class: 'white-tint',
        title: pinkText,
        description: blackText
    }
}
