import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import FormField from '../form/form-field'
import {
  breakpoints,
  colours,
  fonts,
  ButtonReset
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'
import ButtonSave from '../form/button-save';
import {
  FormActions,
  FormReset,
  FormButtonGeneric,
  Row,
  Col
} from '../shared/modal-form/modal-form.styles'

import InputText from '../form/input-text'

import InputTags from '../form/input-tags';
import { Controller } from 'react-hook-form';
import ImageUpload from '../form/image-upload';

import useDeleteMyArticle from '../../hooks/delete-my-article.hook'
import useActionAlert from '../../hooks/action-alert.hook';

import FullscreenEditorModal from './fullscreen-editor-modal'

const Container = styled.div`
  margin-left:-24px;
  margin-right:-24px;
  max-height: 530px;

  @media (max-width:${breakpoints.sm}px) {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    height:100%;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 75px;

  @media (max-width:${breakpoints.sm}px) {
    flex-direction: column;
  }
`

const FirstCol = styled(Col)`
  width: 340px;
  flex: 1 1 340px;

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
    flex: 0 0 auto;
  }
`

const SecondCol = styled(Col)`
  width: 459px;
  flex: 1 1 459px;
  height: unset;

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
    flex: 0 0 auto;
  }
`

const StyledField = styled(FormField)`
  margin-bottom: 12px;
  opacity: ${props => props.disabled ? .5:1};
  pointer-events: ${props => props.disabled ? 'none':'all'};

  input {
    width: 435px;
    font-weight: 400;
    &:focus {
      font-weight: 400;
    }
  }

  .label {
    border-bottom-width:1px;
  }

  input:hover {
    font-family: ${fonts.content.regular};
    font-weight: 400;
  }

  &:focus-within {
    .label {
      border-bottom-width:2px !important;
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    input {
      width:100%;
    }
  }
`

const ImageField = styled(FormField)`  
  opacity: ${props => props.disabled ? .5:1};
  pointer-events: ${props => props.disabled ? 'none':'all'};

  .label {
    border-bottom-width:1px;
  }
  .input-wrapper {
    padding-top: 10px;
    height: 223px;
  }

  @media (max-width:${breakpoints.sm}px) {
    .input-wrapper {
      height: 250px;
    }
  }
`
const ContentField = styled(StyledField)`
  height: 340px;

  .input-wrapper {
    height: calc(100% - 36px);
  }
  @media (max-width:${breakpoints.sm}px) {
    height: 300px;
  }
`

const InputTextStyled = styled(InputText)`
  border-bottom: none;
`

const TagsField = styled(StyledField)`
  width: 435px;
  height: 100px;
  input {
    width:unset;
  }
  
  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
  }
`

const InputTagsStyled = styled(InputTags)`
  max-width: 100%;

  @media (max-width:${breakpoints.sm}px) {
    max-width: 100%;
  }
`

const InputCaption = styled(InputText)`
  outline:none;
  background-color: transparent;
  width: 100%;
  margin-left:10px;
  margin-right:10px;
  border-top:2px solid transparent;
  
  &:focus {
    border-top-color: ${colours.white};
  }

  
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;


  &::placeholder {
    color: ${colours.lightgrey2};
    font-family: ${fonts.content.regular};
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }
`


const FieldArticleContent = styled.div`
  background: transparent;
  position: relative;
  height: calc(100% - 36px);
  overflow-y: scroll;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right:8px;
  margin-right:4px;
  color: ${colours.text};
  text-align: left;
  font-size: 14px;
  font-family: ${fonts.content.regular};
  line-height: 16px;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(236, 15, 105, 1);
  }

  img {
    display: block;
    width: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    aspect-ratio: 16/9;
  }

  &::selection {
    background-color: rgba(236, 15, 105, .2);
  }  

  a {
    color: rgba(236, 15, 105, 1);

    
    &:hover {
      color: rgba(236, 15, 105, 1);
    }
  
  }
`

const SecondaryButtons = styled.div`
`

const PrimaryButton = styled.div`
  margin-left:auto;

  @media (max-width: ${breakpoints.sm}px) {
    width: 160px;

    &>button {
      width: 100%;
      height: 42px;
    }
  }
`

export default function ArticleDetails({
  id,
  isEditing = false,
  register,
  control,
  errors,
  isDirty,
  isSubmitting,
  isSaving,
  disabled,
  hasCoverImage,
  getValues,
  onReset = () => {},
  onSave = () => {},
  onSubmit = () => {},
  onDelete = () => {}
}) {
  const { setActionAlert, closeAlert } = useActionAlert()
  const { deleteOne, isLoading, isSuccess } = useDeleteMyArticle()
  const [ modalActive, setModalActive ] = useState(false)
  
  const handleDelete = () => {
    setActionAlert({
      noClose: true,
      theme: 'dark',
      icon: icons['pink-alert-trash-triangle.svg'],
      active: true,
      title: 'Delete my article from articles?',
      body: `You can’t undo this action.<br/><br/>
        <b>Are you sure you want to continue?</b>`,
      yesLabel: 'Yes',
      noLabel: 'No',
      onYes: () => {
        deleteOne(id)
        closeAlert()
        onDelete()
      }
    })
  }

  return (
    <Container>
      <Inner className="fade-when-mouse-off">
        <FirstCol>
          <Row>
            <StyledField
              disabled={disabled}
              className="form-field-text"
              label="Title"
              error={errors['title']}
              area="title">
              <InputTextStyled
                disabled={disabled}
                maxLength={150}
                type="text"
                {...register("title", {
                  required: true
                })}
              />
            </StyledField>
          </Row>
          <Row>
            <StyledField
              disabled={disabled}
              className="form-field-text"
              label="Subhead"
              error={errors['subHead']}
              area="subHead">
              <InputTextStyled
                disabled={disabled}
                type="text"
                maxLength={200}
                {...register("subHead", {
                  required: false
                })}
              />
            </StyledField>
          </Row>
          <Row>
            <ImageField
              disabled={disabled || isEditing}
              label="My Cover Image"
              error={errors['coverImage']}
            >
              <Controller
                name="coverImage"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => 
                  <ImageUpload
                    noUpload={isEditing}
                    disabled={disabled}
                    {...field}
                  />
                }
              />
            </ImageField>
          </Row>
          <Row>
            {hasCoverImage ? 
            <InputCaption 
              className="input-caption"
              placeholder="Image caption" 
              maxLength={150}
              {...register('caption')}
            /> : null}
          </Row>
        </FirstCol>
        <SecondCol>
          
            <ContentField
              disabled={disabled}
              className="form-field-text"
              label="Article"
              error={errors['content']}
              area="content"
            >
              <FieldArticleContent 
                onClick={(evt) => {
                  evt.preventDefault()
                  evt.stopPropagation()
                  setModalActive(true)
                }}
                dangerouslySetInnerHTML={{__html:  getValues('content') }}
              />
            </ContentField>
          
            <TagsField
              disabled={disabled}
              label="Tags"
            >
              <Controller
                name="tags"
                control={control}
                render={({ field }) => 
                <InputTagsStyled
                  disabled={disabled}
                  {...field}
                />}
              />
            </TagsField>
          
        </SecondCol>
      </Inner>
      
      {modalActive && createPortal(
        <FullscreenEditorModal 
          control={control} 
          onDone={() => {
            setModalActive(false)
          }} 
          onBack={() => {
            setModalActive(false)
          }}
        />,
        document.body
      )}
      
      <FormActions>
        <SecondaryButtons>
          {id ? <FormButtonGeneric type="button" onClick={handleDelete}>Delete</FormButtonGeneric> : null}
          {false && <FormReset disabled={!isDirty || disabled} onClick={onReset} type={id ? "button": "reset"}>Reset</FormReset>}
          {false && <FormButtonGeneric active={isDirty && !disabled} type="submit" loading={isSaving} onClick={onSave}>Save</FormButtonGeneric>}
        </SecondaryButtons>
        <PrimaryButton>
          <ButtonSave active={isDirty && !disabled} loading={isSubmitting} onClick={onSubmit}>Publish</ButtonSave>
        </PrimaryButton>
      </FormActions>

    </Container>
  )
}