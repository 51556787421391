import React from 'react'
import { useWindowDimensions } from '../../../utils/helpers';
import {
    AccountTypes,
    AccountTypesName,
    CompanyTypesName,
    getProfessionsText,
    getAccountTypeMeta,
    UserThemes
} from '../../../constants'
import LocationIconWrapper from '../location-wrapper'
import ArticleCover from '../article-cover';
import Trending from "../../../assets/images/icons/trending.png";
import { 
    getName,
    imageUrl,
    trimHtml
} from '../../../utils/utils'
import { 
    getOccupation,
    getAccountTypeAbbr
} from '../utils'
import countries from 'i18n-iso-countries';
import useAuth from '../../../hooks/auth.hook'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function Mention({user}) {
    const {
        _id,
        itchUrl,
        accountType,
        profileImage,
        profileImageVariants,
        countryCode,
        colourTheme
    } = user
    const { windowWidth } = useWindowDimensions();
    const tintColor = colourTheme === UserThemes.Metro ? 'black' : 'pink';
    const { session } = useAuth()

    const isOwner = session?.profile.id === _id

    return (
        <div className="mention-container position-relative">
            <ArticleCover src={imageUrl(profileImageVariants?.expa ?? profileImage) ?? getAccountTypeMeta(accountType).fallBackAvatar} position={profileImage?.position} />
            <div className="tint tint1">
                <div className="article-details pn-bold">
                    <div className="body-text">
                        <img src={Trending} alt="trending icon" />
                        <div className="text-uppercase trending">trending</div>
                        <div className="author overflow-hidden text-overflow-ellipsis line-clamp-2">{getName(user)}</div>
                        <div className={"occupation overflow-hidden text-overflow-ellipsis " + (windowWidth > 1030 ? 'line-clamp-2' : 'line-clamp-3' )}>{getOccupation(user)}</div>
                    </div>
                </div>
            </div>
            <div className={tintColor + "-tint tint tint2 d-flex flex-column"}>
                <div className="author-details-wrapper position-relative">
                    <div className={"author-details-inner d-flex " + (windowWidth > 1030 ? '' : 'middle-y w-100')} >
                        <div className={"sprites sprite-" + getAccountTypeAbbr(accountType)} />
                        <div className="author-details flex-grow-1 overflow-hidden text-left">
                            <div className="author overflow-hidden text-overflow-ellipsis line-clamp-2 pn-bold">{getName(user)}</div>
                            <div className={"occupation overflow-hidden text-overflow-ellipsis " + (windowWidth > 1030 ? 'line-clamp-2' : 'line-clamp-3' )}>{getOccupation(user)}</div>
                            <LocationIconWrapper textColor='' location={countries.getName(countryCode, "en", { select: "official" })} />
                        </div>
                    </div>
                </div>
                <div className="line" />
                <div className={"statement overflow-hidden text-overflow-ellipsis text-left " + (windowWidth > 1030 ? 'line-clamp-5' : 'line-clamp-3' )} dangerouslySetInnerHTML={{__html: trimHtml(user.fashionStatement ?? '',300)}}>
            </div>
                <div className="view-profile-wrapper flex-grow-1 d-flex justify-content-end align-items-end">
                    <div className="view-profile-inner d-inline-flex align-items-center">
                        <a href={isOwner ? `/my-profile` : `/${itchUrl}`} className="view-profile url-underline d-inline pn-bold">View profile</a>
                        <div className="sprites eye-icon d-inline" alt="eye-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}