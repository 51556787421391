import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import Tile from '../tile/tile'

import {
  icons
} from '../../constants'

import {
  TileInner,
  ActionArea,
  HoverIconStyled,
  Text,
  StartConversationBackdrop,
  InputName,
  DropDownContainer
} from './reveal-conversation-tile.styles'
import SelectUsers from './select-users'
import {
  breakpoints
} from '../../styles/global.styles'
import useStartConversation from 'hooks/start-conversation.hook'
import useAuth from 'hooks/auth.hook'

export default function RevealConversationTile({
  onStartConversation = () => {},
  existingConversationUsers,
  ...rest
}) {
  const inputRef = useRef(null)
  const dropdownRef = useRef(null)

  const [ enterSearchVisible, setEnterSearchVisible ] = useState(false)
  const [ search, setSearch ] = useState('')
  const { startConversation, success, conversationId } = useStartConversation()
  const { session } = useAuth()

  useEffect(() => {
    if (success && conversationId) {
      onStartConversation({
        conversationId
      })
    }
  }, [success, conversationId])

  useEffect(() => {
    if (enterSearchVisible) {
      inputRef.current.value = ''
      inputRef.current.focus()
    }
  }, [enterSearchVisible])

  const onScrollHandler = () => {
    if (inputRef.current && dropdownRef.current) {
      const { top, left, height } = inputRef.current.getBoundingClientRect()
      dropdownRef.current.style.top = `${top + height}px` 
      dropdownRef.current.style.left = `${left}px`
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler)

    return () => {
      window.removeEventListener('scroll', onScrollHandler)
    }
  }, [])

  return (
    <Tile 
      width={'33.33333333vw'}
      height={'21vw'}
      responsiveHeight={{
        [breakpoints.sm]: '58vw'
      }} 
      {...rest}>
      <TileInner>
        <ActionArea show={!enterSearchVisible}
          onClick={() => {
            setEnterSearchVisible(true)
          }} >
          <HoverIconStyled
            className="icon"
            active={icons['reveal-conversation-grey.svg']}
            hover={icons['reveal-conversation-pink.svg']}
          >
          </HoverIconStyled>
          <Text className="text">start a conversation</Text>
        </ActionArea>
        <StartConversationBackdrop show={enterSearchVisible}>
          <InputName type="text" 
            id="start-conversation-search-user"
            ref={inputRef}
            autoComplete="off"
            placeholder="Enter a name"
            onChange={(evt) => {
              setSearch(evt.target.value)
            }}
          />
          {createPortal(<DropDownContainer ref={dropdownRef}>
            <SelectUsers 
              searchUserInput={inputRef}
              search={search} 
              excludeUsers={[session?.profile?.id]}
              onSelect={(userId) => {
                setEnterSearchVisible(false)
                setSearch('')
                if (!existingConversationUsers.includes(userId)) {
                  startConversation(userId)
                } else {
                  onStartConversation({
                    userId
                  })
                }
             }}
            />
          </DropDownContainer>, document.body)}
        </StartConversationBackdrop>
      </TileInner>
    </Tile>
  )
}
