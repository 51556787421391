import useAuth from 'hooks/auth.hook'
import styled from 'styled-components'
import {
  colours,
  fonts,
  ScrollStyledBox,
  ButtonPrimary,
  breakpoints
} from 'styles/global.styles'
import { useHistory } from 'react-router-dom'

export const SignUpContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top: 100px;
  margin-bottom: 100px;

  .text {
    text-align:center;
    margin-bottom:20px;
  }
`

export const SignUpButton = styled(ButtonPrimary)`
  background-color: ${colours.pink};
  color: ${colours.dark};
  border: none;

  
  &:hover {
    color: ${colours.white};
    background-color: ${colours.dark};
  }

`

export default function SignUpWidget() {
  const { fetching, session } = useAuth()
  const { push } = useHistory()

  return (
    <>
    {!fetching && !session ? <SignUpContainer>
      <div className="text">
        Sign up to share your version of fashion on <b>itch!</b>
      </div>
      <SignUpButton onClick={() => {
        push('/signup')
      }}>
        Sign up
      </SignUpButton>
    </SignUpContainer> : null}    
    </>
  )
}