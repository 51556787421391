import React, { useState } from 'react'

export default function useMasonry(props) {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [expandedSize, setExpandedSize] = useState(null)
  const startOffset = props?.startOffset ?? 0
  const fixedIndexes = props?.fixedIndexes ?? [] 
  // cannot be displaced, grid co-ords cannot change

  const [grid, setGrid] = useState([])
  const [shape, setShape] = useState({
    columns: 1,
    size: 0
  })

  const getPosition = (index, columns) => {
    const colWidthPercent = 1 / columns
    return {
      top: (index >= columns ? Math.floor(index / columns): 0),
      left: colWidthPercent * (index % columns)
    }
  }

  const generate = (size, _columns) => {
    const _grid = []
    // const colWidthPercent = 1 / _columns
    
    for (let i = startOffset; i < (size + startOffset); i++) {
      const item = getPosition(i, _columns)
      _grid.push(item)
    }
    return _grid;
  }

  const init = (size, _columns, offset, maintainExpanded = false) => {
    // console.log(`masonry init size ${size}, ${maintainExpanded} ${expandedIndex} ${expandedSize}`)
    if (maintainExpanded && expandedIndex) {
      setShape({
        columns: _columns,
        size
      })
      const _grid = create_grid_with_expanded(size, _columns, expandedIndex, expandedSize)
      setGrid(_grid)
    } else {
      const _grid = generate(size, _columns, offset)
      setGrid(_grid)
      setShape({
        columns: _columns,
        size
      })
    }
  }
  
  const reset = () => {
    const _grid = generate(shape.size, shape.columns) 
    setGrid(_grid)
  }

  const create_grid_with_expanded = (size, columns, targetIndex, expandSize) => {
    // console.log(`create grid ${size} ${columns}`)
    const _grid = generate(size, columns)
    // console.log(`generated grid size ${_grid.length}`)

    // expand size is always on columns
    // create displacement matrix of positions
    // [top, left, bottom, right]
    // displacement matrix is the row, col edge positions (4 points)
    // displacement rule - grow downwards, right wards
    let displacementMatrix = []
    const expandColDiff = expandSize - 1
    const displacementDiff = (expandSize * 2) - 1
    const colIndex = targetIndex % columns
    const rowIndex = Math.floor(targetIndex / columns)
    let isExpandLeft = false
    if (colIndex + expandColDiff >= columns) {
      // expand left wards
      const col = colIndex - expandColDiff < 0 ? 0 : colIndex - expandColDiff
      displacementMatrix = [
        rowIndex, 
        col,
        rowIndex + 1,
        col + expandColDiff
      ]
      isExpandLeft = true
      _grid[targetIndex].left = (1 / columns) * (displacementMatrix[1] % columns)
    } else {
      displacementMatrix = [
        rowIndex,
        colIndex,
        rowIndex + 1,
        colIndex + expandColDiff
      ]
    }

    // refill from beginning
    // console.log('displacement', displacementMatrix)
    
    let gridCounter = 0
    let gridSize = _grid.length + displacementDiff
    // console.log(`gridSize after deplacementdiff ${displacementDiff}`, gridSize)

    for (let index = 0; index < gridSize; index++) {
      const top = Math.floor(index / columns)
      const left = index % columns

      // if outside of displacement, assign new positions
      const inDisplacement = top >= displacementMatrix[0] 
                              && top <= displacementMatrix[2]
                              && left >= displacementMatrix[1]
                              && left <= displacementMatrix[3]
      
      // console.log(index, gridCounter, top, left, inDisplacement)
      if (fixedIndexes.includes(index)) {
        // console.log('skiping ignored index', fixedIndexes)
        gridCounter++
      } else
      if (gridCounter === targetIndex) {
        // console.log('skip target index', gridCounter, targetIndex)
        gridCounter++
        if (isExpandLeft)
          index = index - 1 // position stays still
      } else
      if (!inDisplacement) {
        // console.log('NOT inDisplacement - update grid', index, gridCounter)
        _grid[gridCounter] = getPosition(index, columns)
        gridCounter++
      }
    }

    return _grid
  }

  const expand = (targetIndex, expandSize) => {
    setExpandedIndex(targetIndex)
    setExpandedSize(expandSize)
    const { columns, size } = shape
    
    const _grid = create_grid_with_expanded(size, columns, targetIndex, expandSize)

    setGrid(_grid)
  }

  return {
    init,
    expand,
    grid,
    reset,
    expandedIndex
  }
}