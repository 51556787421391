import React from 'react'
import styled from 'styled-components'

import {
  colours,
  fonts,
  breakpoints
} from '../../../styles/global.styles'

import {
  Actions
} from '../../../constants'

import UserAvatar from '../../avatar/user-avatar'
import { Link } from 'react-router-dom'

import { 
  imageUrl,
  getRelativeDateText,
  getName
} from 'utils/utils'
import useAuth from 'hooks/auth.hook'
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'

const Container = styled.div`
  display:flex;
  flex-direction: row;
  height: 100%;
`

const AvatarContainer = styled.div`
  flex: 0 0 ${props => props.expanded ? 60:44}px;
  height: ${props => props.expanded ? 60:44}px;
`

const Col = styled.div`
  flex:1 1 auto;
  font-family: ${fonts.content.bold};
  font-size: ${props => props.expanded ? 12:10}px;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  padding: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: ${breakpoints.sm + 1}px) {
    font-size: ${props => props.expanded ? 12:10}px;
    line-height: ${props => props.expanded ? 13:11}px;
  }
`

const Action = styled.div`
  color: ${colours.lightgrey5};
`

const Name = styled(Link)`
  color: ${colours.darkergrey2};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;


  &:hover {
    color: ${colours.pink};
  }

`

const RelativeTime = styled.div`
  color: ${colours.lightgrey5};
`

// displays the last activity

export default function LastActivityPanel({
  userId,
  user,
  action,
  createdAt,
  expanded
}) {
  const { handleActio, linkProps, link } = useLoggedOutHandleRoute()
  const { session } = useAuth()
  let actionText = Actions[action]

  const userUrl = link(session?.profile?.id === userId ? `/my-profile` : `/${user?.itchUrl ?? userId}/gallery`)
  
  return (
    <Container className="last-activity">
      <AvatarContainer expanded={expanded}>
        <UserAvatar
          profileUrl={userUrl}
          accountType={user?.accountType}
          url={imageUrl(user?.profileImageVariants?.thumb ?? user?.profileImage)} 
          position={user?.profileImage?.position} />
      </AvatarContainer>
      <Col expanded={expanded}>
        <Action>{actionText}</Action>
        <Name to={userUrl}>{user ? getName(user): ''}</Name>
        <RelativeTime>{getRelativeDateText(new Date(createdAt))}</RelativeTime>
      </Col>
    </Container>
  )
}
