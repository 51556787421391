import { useQuery } from '@tanstack/react-query'
import { getOne } from 'apis/conversation.api'

export default function useGetOneConversation(id) {
  const {
    isLoading,
    isSuccess,
    data
  } = useQuery({
    queryKey: ['conversations', id],
    queryFn: () => getOne(id)
  })

  return {
    isLoading,
    isSuccess,
    conversation: data
  }
}