import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import FormField from '../form/form-field';
import {
  Footer,
  Body,
  SolidLine,
  DashedLine,
  ButtonDanger,
  ButtonResetForm
} from '../../pages/settings.styles'
import Checkbox from '../form/checkbox';
import ButtonSave from '../form/button-save';

import useGetMyEmailAlerts from 'hooks/get-email-alerts.hook';
import useUpdateMyEmailAlerts from 'hooks/update-email-alerts.hook';

export default function SettingsEmailAlerts(
  props
) {
  const { onClose } = props
  const { loading, success, emailAlerts } = useGetMyEmailAlerts()
  const { isLoading, isSuccess, isError, result, saveEmailAlerts } = useUpdateMyEmailAlerts()

  const { 
    reset,
    control,
    register, 
    handleSubmit,
    formState: {
      errors,
      isDirty
    }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      emailAlerts: ['follow-me'],
    }
  });

  const onSubmit = (data) => {
    saveEmailAlerts({
      alerts: data.emailAlerts.map(o => ({
        topic: o,
        enabled: true,
        frequency: 'd'
      }))
    })
    onClose()
  }

  useEffect(() => {
    window.gtag('event', 'modal_view', {
      page_title: 'setttings-email-alerts',
    });
  }, [])
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Body className="fade-when-mouse-off">
        <h1>Receive email when other people:</h1>
        <p>
        Set the type of email alerts you wish to receive from us. Simply click on an activity button to toggle on / off an email alert. Don't forget to Save for changes to take place!
        </p>
        <SolidLine/>
        <div className="group">
          <h2>General Activities</h2>
          <div className="group-options">
            <Checkbox 
              {...register('emailAlerts')}
              value={'follow-me'}
              label={`Follow me`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value={'start-conversation-with-me'}
              label={`Start <b>conversations</b> with me`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value={'comment-on-my-article'}
              label={`Comment on my article`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='tag-me-in-their-latest-statement'
              label={`Tag me in their fashion statement`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='tag-me-in-an-artwork'
              label={`Tag me in an artwork`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='tag-me-in-an-article'
              label={`Tag me in an artwork comment`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='tag-me-in-an-article'
              label={`Tag me in an article`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='tag-me-in-an-article-comment'
              label={`Tag me in an article comment`}
            />
          </div>
        </div>
        <DashedLine style={{
          marginTop: '12px'
        }}/>
        <div className="group">
          <h2>Gallery Activities</h2>
          <div className="group-options">
            <Checkbox 
              {...register('emailAlerts')}
              value='vote-my-artwork'
              label={`Vote my artwork`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='itchbook-my-artwork'
              label={`Itchbook my artwork`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='comment-on-my-artwork'
              label={`Comment on my artwork`}
            />
          </div>
        </div>
        <DashedLine style={{
          marginTop: '12px'
        }}/>
        <h1 style={{
          marginTop:'45px',
          marginBottom: '0'
          }}>I’ll also like to know when:</h1>
        <SolidLine style={{
          marginBottom: '12px'
        }}/>
        <div className="group">
          <h2></h2>
          <div className="group-options">
            <Checkbox 
              {...register('emailAlerts')}
              value='artworks-submitted-success'
              label={`Artworks submitted are successful`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='artworks-submitted-fail'
              label={`Artworks submitted are unsuccessful`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='articles-submitted-success'
              label={`Articles submitted are successful`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='articles-submitted-fail'
              label={`Articles submitted are unsuccessful`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='my-artwork-featued-on-zine'
              label={`My artwork is featured on <b>zine</b>`}
            />
            <Checkbox 
              {...register('emailAlerts')}
              value='itch-shares-conversation-with-me'
              label={`<b>itch</b> shares a conversation with me`}
            />
          </div>
        </div>
      </Body>
      <Footer>
        <ButtonDanger type="button">Deactivate</ButtonDanger>
        <ButtonResetForm type="button">Reset</ButtonResetForm>
        <ButtonSave active={true} loading={false}>Save</ButtonSave>
      </Footer>
    </form>
  );
}