import React from 'react'
import ProfileList from './profile-list'

import useGetFollowers from 'hooks/get-followers.hook'

export default function Followers({
  userId,
  name,
  onClose
}) {

  const {
    loading,
    fetching,
    success,
    users,
    nextPage
  } = useGetFollowers(userId)
  
  return (
    <>
      {success ? 
      <ProfileList 
        title={`collectives following ${name}`} 
        users={users} 
        onClose={onClose}
      /> : null}
    </>
  )
}