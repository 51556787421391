import styled from 'styled-components'
import {
  fonts,
  breakpoints,
  colours
} from '../styles/global.styles'

import {
  Container
} from './gallery.styles'

export const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${colours.dark2};
  font-family: ${fonts.title.xbold};
  font-weight: 800;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 350px;
  font-size: 14px;
  line-height: 15px;
  margin-left:auto;
  margin-right:auto;

  &:before {
    content: '';
    display: inline-block;
    justify-self: flex-start;
    width: 100%;
    border-bottom: 1px solid ${colours.dark2};
    margin-right: 10px;
    flex: 1 1 auto;
  }
  &:after {
    content: '';
    display: inline-block;
    justify-self: flex-end;
    width: 100%;
    border-bottom: 1px solid ${colours.dark2};
    margin-left: 10px;
    flex: 1 1 auto;
  }
  &>span {
    white-space: nowrap;
    width: fit-content;
    flex: 0 0 auto;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0em;
    justify-content: center;
    padding-top:10px;
    max-width: 721px;

    &:before {
      width: 100%;
      max-width: 236px;
    }

    &:after {
      width: 100%;
      max-width: 236px;
    }
  }
`;

export const ArtworkGalleryContainer = styled(Container)`
  padding-top:65px
`