import axiosInstance from './api';

export const authorize = async ({
  username,
  password,
}) => {
  return new Promise((resolve, reject) => {
    axiosInstance.post('/authorize', {
      username,
      password
    }).then((res) => {
      resolve(res.data)
    }).catch(err => {
      resolve(null)
    })
  })
}

export const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    axiosInstance.post('/authorize/refresh')
      .then((res) => {
        resolve(res)
      }).catch(err => {
        resolve(null)
      })
  })
}
