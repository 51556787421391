import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { 
  Container, 
  Toast,
  TilesContainer,
  TilesInnerGrid,
  TilesSubGridRow,
  TilesInnerPositioned,
  TilesRow
} from './gallery.styles';
import {
  ArtworkGalleryContainer,
  Title
} from './artwork-gallery.styles'

import ArtworkTile from '../components/gallery/artwork-tile'

import useFindArtworks from '../hooks/find-artworks.hook'
import useModal from '../hooks/modal.hook';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import useMasonry from '../hooks/masonry.hook';

import { useParams, useLocation } from 'react-router-dom';

import { 
  ActivityActions,
  MediaType,
  AccountTypes,
  AccountTypesNameSlug,
  AccountTypesSlugMapName,
  getProfessionMaps,
  CompanyTypesSlugToName,
  Plurals
} from '../constants';

import ProgressLoader from 'components/shared/progress-loader/progress-loader';
import Shuffle from 'components/shared/shuffle'
import styled from 'styled-components';

const TilesContainerTopPadding = styled(TilesContainer)`
  padding: 7.5px;
`;

export default function ArtworkGallery() {
  const { accountType, subType } = useParams()
  const { setModal } = useModal()
  const [ isShuffle, setIsShuffle ] = useState(0)
  const [ endOfShuffle, setEndOfShuffle] = useState(false)
  const { pathname } = useLocation()

  let userTags = []
  if (accountType) userTags.push(accountType)
  if (subType) userTags.push(subType)

  const query = {
    userTags
  }

  const {
    fetching,
    success,
    artworks,
    nextPage,
    hasNext,
    isFetched
  } = useFindArtworks(query, isShuffle)

  useBottomScrollListener(() => {
    // ignore scroll here if a modal is activated
    if (!document.getElementById('modal')) {
      if (hasNext) {
        setExpanded(null)
        nextPage()
      } else
      if (isShuffle) {
        setEndOfShuffle(true)
      }
    }
  })

  // const { scrolledEnd, watchElement } = useScrollEnd()
  const [expanded, setExpanded] = useState(null)

  const { grid, expand, init, reset } = useMasonry()

  const lastCount = useMemo((() => success ? artworks?.length : 0), [success, artworks])
 
  const artworksRenderItems = useMemo(() => {
    if (artworks && success && isFetched && grid) {
      return grid.length > 0 ? grid.map((gridItem, i) => {
        if (i >= artworks.length) return null
        
        return (
          <ArtworkTile
            index={i}
            disableTransition={false}
            disableOwnerActions={true}
            draggable={false}
            position={gridItem}
            expanded={expanded === i}
            key={`artwork-${artworks[i].id}}`}
            {...artworks[i]}
            onExpand={() => {
              if (expanded === i) {
                setExpanded(null)
                reset()
              } else {
                setExpanded(i)
                if (artworks[i].mediaType === MediaType.Image) {
                  if (artworks[i].image.width > artworks[i].image.height) {
                    expand(i, 3)
                  } else {
                    expand(i, 2)
                  }
                } else {
                  expand(i, 3)
                }
              }
            }}
            onExit={() => {
              setExpanded(null)
              reset()
            }}
            onVotesClick={() => {
              setModal({
                name: 'activity-history',
                payload: {
                  title: 'collectives who voted this',
                  schemaName: 'artworks',
                  action: ActivityActions.Voted,
                  relatedId: artworks[i].id
                }
              })
            }}
            onViewsClick={() => {
              setModal({
                name: 'activity-history',
                payload: {
                  title: 'collectives who viewed this',
                  schemaName: 'artworks',
                  action: ActivityActions.Viewed,
                  relatedId: artworks[i].id
                }
              })
            }}
            onItchbooksClick={() => {
              setModal({
                name: 'activity-history',
                payload: {
                  title: 'collectives who itchbooked this',
                  schemaName: 'artworks',
                  action: ActivityActions.Itchbooked,
                  relatedId: artworks[i].id
                }
              })
            }}
          />
        )
      }): null
    } else {
      return null
    }
  }, [artworks, success, isFetched, grid])

  useEffect(() => {
    if (success) {
      init(artworks?.length, 3, 0, expanded !== null)
      // this causes the react query hook to requery? but from cache
    }
  }, [success, isFetched, lastCount])
  
  const maxRows = grid.length > 0 ? (grid[grid.length - 1].top + 1) + (expanded !== null ? 1 : 0) : 0

  const getAccountTypeName = (accountType, subType) => {
    if (accountType && !subType) {
      return Plurals[AccountTypesSlugMapName[accountType].toLowerCase()]
    }
    if (accountType === AccountTypesNameSlug[AccountTypes.FashionCompany] && subType !== undefined) {
      return Plurals[CompanyTypesSlugToName[subType].toLowerCase()]
    } else
    if (accountType === AccountTypesNameSlug[AccountTypes.FashionArtist] && subType !== undefined) {
      return Plurals[getProfessionMaps()[subType].toLowerCase()]
    } else 
      return null
  }

  return (
    <>
      <ArtworkGalleryContainer id="main-body">
        {accountType || subType ? <Title><span>{getAccountTypeName(accountType, subType)}</span></Title> : null}
        <TilesContainerTopPadding>
          <TilesInnerPositioned rows={maxRows}>
            {artworksRenderItems}
          </TilesInnerPositioned>
          {pathname === '/home' ? <Shuffle 
            endOfShuffle={endOfShuffle}
            onShuffled={() => {
              setExpanded(null)
              setIsShuffle(isShuffle + 1)
            }}
            onClick={() => {
              setExpanded(null)
              setEndOfShuffle(false)
            }}
          /> : null}
          {fetching && success ? <ProgressLoader/> : null}
        </TilesContainerTopPadding>
      </ArtworkGalleryContainer>
    </>
  )
}