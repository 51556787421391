import styled from 'styled-components'
import {
  fonts,
  colours
} from 'styles/global.styles'

export const Container = styled.div`
  margin-top: 20px;
`

export const ViewMore = styled.div`
  margin-top:20px;
  margin-bottom:20px;
  text-decoration: underline;
  color: ${colours.darkergrey2};
  font-size: 12px;
  line-height: 12px;
  font-family: ${fonts.content.regular};
  text-align: center;
  cursor: pointer;
`