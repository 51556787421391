/*
  provides a way for child components to update the parent via the context and hook
*/
import React, { useState, useContext } from 'react'

export const PageReadyContext = React.createContext(null);

export const PageReadyProvider = ({ children }) => {
  const [ready, setReady] = useState(false);
  return (
    <PageReadyContext.Provider value={[ready, setReady]}>
      {children}
    </PageReadyContext.Provider>
  );
}

export const usePageReady = () => {
  const [ready, setReady] = useContext(PageReadyContext);
  return ({
    ready,
    setReady
  })
}