import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  colours,
  fonts
} from 'styles/global.styles'
import {
  icons
} from '../../constants'
import useFeatures from 'hooks/features.hook'
import useShuffleArtworks from 'hooks/shuffle-artworks.hook'
import useAuth from 'hooks/auth.hook'

function doOnce(userKey = '') {
  if (!doneOnce(userKey)) {
    // Note that we are setting `SameSite=None;` in this example because the example
    // needs to work cross-origin.
    // It is more common not to set the `SameSite` attribute, which results in the default,
    // and more secure, value of `SameSite=Lax;`
    document.cookie =
      `firstShuffle_${userKey}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; Secure`;
    
      return true
  } else {
    return false
  }
}

function doneOnce(userKey = '') {
  return document.cookie.split("; ")
    .find((row) => row.indexOf("firstShuffle_" + userKey) === 0) !== undefined
}

const CallOut = styled.div`
  position:absolute;
  z-index:-1;
  right: 92px;
  background-color: ${colours.pink};
  width: 200px;
  height: 40px;
  display:flex;
  justify-content:center;
  align-items:center;
  transition: all .2s;
  opacity: ${props => props.show ? 1:0};
  pointer-events: none;
  animation-name: ${props => (props.show && props.slideOut) ? 'slideOut':'none'};
  animation-timing-function: cubic-bezier(0.560, 0.160, 0.800, 0.545);
  animation-duration: .3s;
  animation-fill-mode: forward;

  &>span {
    color: ${colours.black};
    font-size: 16px;
    line-height: 16px;
    font-family: ${fonts.title.xbold};
    text-align: center;
  }

  &:after {
    content: '';
    position:absolute;
    right: -4px;
    top: 10px;
    background-color: ${colours.pink};
    display:block;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }

  @keyframes slideOut {
    0% {
      transform: translateX(500px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

const ShadowContainer = styled.div`
  position: fixed;
  z-index: 100;
  right: 30px;
  bottom: 40px;
  width: 79px;
  height: 79px;
  border-radius: 38px;
  box-shadow: 0 4px 8px rgba(0,0,0,.2);
`

const Container = styled.div`
  width: 80px;
  height: 80px;
  transform-origin:center center;
  background-color: ${colours.white_alpha90};
  border-radius: 40px;
  transition-property: all;
  transition-duration: .2s;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;

  &>.icon {
    position: relative;
    transition-property: all;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.560, 0.160, 0.800, 0.545);

    img {
      &:first-child {
  
      }
      &:last-child {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }


  &:hover {
    background-color: ${props => props.isShuffle ? colours.white_alpha90 : colours.pink_alpha90};

    .icon {
      transform: rotate(${props => props.isShuffle ? 0 : -180}deg);
    
      img {
        &:first-child {
          opacity: 0;
        }
        &:last-child {
          opacity: 1;
        }
      }
    }
  }


  &.active {
    background-color: ${colours.pink_alpha90};

    .icon {
      
      img {
        &:first-child {
          opacity: 0;
        }
        &:last-child {
          opacity: 1;
        }
      }
    }

    
    &:hover {
      .icon {
        transform: rotate(-270deg);
      }
    }
  
  }

  &.spin-from-start {
    .icon {
      animation: .32s linear 0s 4 spin-from-start;
    }
    
    &:hover {
      .icon {
        transform: rotate(-270deg);;
      }
    }
  
  }

  &.spin-from-active {
    .icon {
      animation: .32s linear 0s 4 spin-from-active;
    }
    
    &:hover {
      .icon {
        transform: rotate(-270deg);;
      }
    }
  
  }

  @keyframes spin-from-start {
    0% {
      transform:rotate(-180deg);
    }
    50% {
      transform:rotate(-360deg);
    }
    100% {
      transform:rotate(-540deg);
    }
  }

  @keyframes spin-from-active {
    0% {
      transform: rotate(-540deg);
    }
    50% {
      transform: rotate(-720deg);
    }
    100% {
      transform: rotate(-900deg);
    }
  }
`

export default function Shuffle({
  onClick,
  onShuffled,
  endOfShuffle = false,
  ...rest
}) {
  const shuffleRef = useRef(null)
  const calloutHint = useRef(null)
  const [ isShuffle, setIsShuffle ] = useState(false)
  const { shuffle, isSuccess } = useShuffleArtworks()
  const { session, fetching } = useAuth()

  useEffect(() => {
    if (isSuccess)
      onShuffled()
  }, [isSuccess, shuffle])

  return (
    <>
    {session && !fetching ? 
      <ShadowContainer>
        <Container 
          ref={shuffleRef}
          {...rest}
          isShuffle={isShuffle} 
          onClick={() => {
            window.scrollTo({
              top:0, 
              left: 0,
              behavior:'instant'
            });
            
            shuffle()
            if (calloutHint.current)
              calloutHint.current.style.visibility = 'hidden'
            shuffleRef.current.classList.add('spin-from-start')
            setTimeout(() => {
              setIsShuffle(true)
              shuffleRef.current.classList.remove('spin-from-start')
              onClick && onClick()
              doOnce(session?.profile?.id)
            }, 1600)
          }} 
          className={isShuffle ? `active`:''}>
          <div className="icon">
            <img src={icons['shuffle-colour.svg']} alt="" />
            <img src={icons['shuffle-white.svg']} alt="" />
          </div>
          <CallOut ref={calloutHint} show={!doneOnce(session?.profile?.id)}><span>shuffle artworks!</span></CallOut>
          <CallOut show={endOfShuffle} slideOut={true}><span>shuffle again!</span></CallOut>
        </Container>
      </ShadowContainer> : null}
    </>
  )
}
