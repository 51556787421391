import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from './page'

import Article from 'components/articles/article'
import SignUpPrompt from 'components/sign-up-prompt/sign-up-prompt'

import {
  Container,
  Inner
} from './solo-article.styles'
import { usePageReady } from 'contexts/page-ready.context'
import { SignUpPromptProvider } from 'contexts/sign-up-prompt.context'

export default function SoloArticle() {
  const { slug } = useParams()
  const { ready } = usePageReady()
  return (
    <SignUpPromptProvider>
      <Page>
        <Container>
          <Inner>
            {ready ? <SignUpPrompt/> : null}
            <Article slug={slug} />
          </Inner>
        </Container>
      </Page>
    </SignUpPromptProvider>
  )
}