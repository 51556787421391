import axiosInstance from './api';

export const ContentType = {
  Artworks: 'artworks',
  Articles: 'articles'
}

export const getAll = async (contentType, id, page = 0, size = 10) => {
  const response = await axiosInstance.get(`/comments/${contentType}/${id}?page=${page}&size=${size}`)
  return response.data
}

export const addComment = (contentType, id, body) => {
  return axiosInstance.post(`/comments/${contentType}/${id}`, {
    body
  })
}

export const addArtworkComment = (id, body) => {
  return axiosInstance.post(`/comments/${ContentType.Artworks}/${id}`, {
    body
  })
}

export const respondToComment = (id, body) => {
  return axiosInstance.post(`/comments/${id}`, {
    body
  })
}