import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  colours,
  fonts
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'
import InputEditor from '../form/input-editor';
import { Controller } from 'react-hook-form';

const ContentEditorModal = styled.div`
  position: fixed;
  z-index: 1110;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colours.black_alpha85}; 
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rich-editor-container {
    background-color: ${colours.lightgrey7};
    width: 864px;
    height: 100%;
    display:flex;
    flex-direction: column;
    padding: 40px 162px 0 162px;

    .editor-inner {
      .editor-placeholder {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        font-family: ${fonts.content.regular};
        
      }
      .editor-input {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        font-family: ${fonts.content.regular};
        color: ${colours.black} !important;

        img {
          display:block;
          width: 100%;
          max-width: fit-content;
        }

        iframe {
          width: 100%;
          height: 350px;
        }
              
        *::selection {
          background-color: rgba(236, 15, 105, .2);
        }  

        a {
          color: rgba(236, 15, 105, 1);
          &:hover {

          }
        }
      }
    }
    .editor-bottom-bar {
      display:flex;
      align-items: center;
      justify-content: space-between;
      margin-left: -162px;
      margin-right:-162px;
      padding-left:20px;
      padding-right:20px;
      box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.05);
      height: 50px;
      padding-bottom:10px;

      .character-count {
        color: ${colours.lightgrey3};
        font-size: 13px;
        line-height: 15p;
        font-family: ${fonts.content.bold};
        position: relative;
        top: -4px;
      }
      .toolbar {
        &>button.toolbar-item {
          &:hover,
          &:hover:not([disabled]) {
            background-color: rgba(236, 15, 105, .4) !important;
          }
        }
      }
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    .rich-editor-container {
      width: 100%;
      padding: 40px;
    }
    .editor-bottom-bar {
      box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      padding-top: 2px;
      height: 50px;

      .character-count {
        padding-top:0;
        top: unset;
      }
      .toolbar {
        flex: 0 0 40%;
        flex-wrap: wrap;
        &>button.toolbar-item {
          margin-bottom:4px;
          background-color: red;
          &:hover,
          &:hover:not([disabled]) {
            background-color: rgba(236, 15, 105, .4) !important;
          }
        }
      }
    }
  }
`

const ContentEditorDoneButton = styled.div`
  cursor: pointer;
  position:fixed;
  z-index:5;
  right: 15px;
  top: 15px;

  &>div.column {
    display: block;
    height: 100vh;
    width: 50px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    
    &>div.inner {
      position: relative;
      &>span {
        &>img {
          display:block;
        }
        &>img.noop {
          visibility: visible;
        }
        &>img.spot-hover {
          visibility: hidden;
          position: absolute;
          top:0;
          left:0;
        }
        &>img.area-hover {
          visibility: hidden;
          position: absolute;
          top:0;
          left:0;
        }
      
      
        &:hover {
          &>img.noop {
            visibility: hidden !important;
          }
          &>img.area-hover {
            visibility: hidden !important;
          }
          &>img.spot-hover {
            visibility: visible !important;
          }
        }
      
      }
      
    } 
    
    
    &:hover {
      &>div.inner>span {
        &>img.spot-hover {
          visibility: hidden;
        }
        &>img.area-hover {
          visibility: visible;
        }
        &>img.noop {
          visibility: hidden;
        }
      }
    }
  
  }
`

const BackButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  img {
    width: 15px;
    margin-right: 6.5px
  }
  span {
    font-family: ${fonts.content.regular};
    font-size: 13px;
    line-height: 15px;
    color: ${colours.lightgrey3};
  }
`

const FullscreenEditorModal = (props) => {
  const { control, onDone, onBack } = props

  return (
    <ContentEditorModal id="fullscreen-editor">
      <ContentEditorDoneButton onClick={() => {
        // close this modal
        onDone && onDone()
      }}>
        <div className="column">
          <div className="inner">
            <span>
              <img className="spot-hover" src={icons['pink-tick.svg']} alt="" />
              <img className="noop" src={icons['grey-tick.svg']} alt="" />
              <img className="area-hover" src={icons['white-tick.svg']} alt="" />
            </span>
          </div>
        </div>
      </ContentEditorDoneButton>
      <Controller
        name="content"
        control={control}
        rules={{
          required: true
        }}
        render={({ field }) => 
          <InputEditor
            {...field}
            maxLength={30000}
            options={{
              imageUpload: true,
              video: true,
              link: true
            }}
            renderBackElement={
              <BackButton onClick={() => {
                onBack && onBack()
              }}>
                <img src={icons['arrow-left.svg']} alt="Back" />
                <span>Back to form</span>
              </BackButton>
            }
            height={'100%'}
            placeholder="Start writing your article..."
          />
        }
      />
    </ContentEditorModal>
  )
}

export default FullscreenEditorModal