import React, { useEffect, useState, useMemo } from 'react';
import { 
  Container, 
  Toast,
  TilesContainer,
  TilesInnerPositioned
} from './gallery.styles';
import RevealArtworkTile from '../components/gallery/reveal-artwork-tile'

import useGetArtworks from '../hooks/get-artworks.hook'

import ArtworkTile from '../components/gallery/artwork-tile'
import useModal from '../hooks/modal.hook';
import useMasonry from '../hooks/masonry.hook';
import { MediaType, ActivityActions } from '../constants';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useLocation, useParams } from 'react-router-dom'
import { breakpoints } from 'styles/global.styles';
import useGetProfile from 'hooks/get-profile.hook';
import { getName } from 'utils/utils'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';
import useSetArtworkSeen from 'hooks/set-artwork-seen.hook'

export default function Gallery({
  userId
}) {
  const { itchUrl } = useParams()
  const getProfile = useGetProfile(itchUrl)
  const location = useLocation()
  const { pathname } = location
  const isOwner = pathname.indexOf('/my-profile') === 0;

  const { setModal } = useModal()
  const { success, fetching, artworks, nextPage, hasNextPage } = useGetArtworks(userId, isOwner, 9, isOwner ? 'reviewStatusPending,createdAt' : 'createdAt')
  
  const [expanded, setExpanded] = useState(null)

  const { grid, expand, init, reset } = useMasonry()
  const { setSeen } = useSetArtworkSeen()

  const lastCount = useMemo((() => success ? artworks.data?.length : 0), [artworks])

  useBottomScrollListener(() => {
    if (hasNextPage) {
      setExpanded(null)
      nextPage()
    }
  })

  const artworksRenderItems = (success && artworks?.data?.length > 0 && grid.length > 0) ? grid.map((gridItem, i) => {
    const currIndex = i + (isOwner ? 1 : 0)
    if (i >= artworks.data.length) return null;
    
    const artwork = artworks.data[i]
    return (
      <ArtworkTile
        index={currIndex}
        onDragStart={(e) => {
          if (isOwner) {

          }
          // e.target.classlist.add('dragged')
          // console.log('drag start')
        }}
        onDragEnd={(e) => {
          if (isOwner) {
            
          }
          // e.target.classlist.remove('dragged')
          // console.log('drag end')
        }}
        responsiveHeight={{
          [breakpoints.sm]: '64vw'
        }}
        disableOwnerActions={!isOwner}
        draggable={isOwner}
        position={grid[currIndex]}
        expanded={expanded === currIndex}
        key={`artwork-${artwork.id}}`}
        {...artwork}
        onExpand={() => {
          if (expanded === currIndex) {
            setExpanded(null)
            reset()
          } else {
            setExpanded(currIndex)
            if (artwork.mediaType === MediaType.Image) {
              if (artwork.image.width > artwork.image.height) {
                expand(currIndex, 3)
              } else {
                expand(currIndex, 2)
              }
            } else {
              expand(currIndex, 3)
            }
          }
        }}
        onExit={() => {
          setExpanded(null)
          reset()
        }}
        onVotesClick={() => {
          setModal({
            name: 'activity-history',
            payload: {
              title: 'collectives who voted this',
              action: ActivityActions.Voted,
              schemaName: 'artworks',
              relatedId: artwork.id || artwork._id
            }
          })
        }}
        onViewsClick={() => {
          setModal({
            name: 'activity-history',
            payload: {
              title: 'collectives who viewed this',
              action: ActivityActions.Viewed,
              schemaName: 'artworks',
              relatedId: artwork.id || artwork._id
            }
          })
        }}
        onItchbooksClick={() => {
          setModal({
            name: 'activity-history',
            payload: {
              title: 'collectives who itchbooked this',
              action: ActivityActions.Itchbooked,
              schemaName: 'artworks',
              relatedId: artwork.id || artwork._id
            }
          })
        }}
      />
    )
  }) : null

  useEffect(() => {
    if (success) {
      const size = artworks?.data?.length + (isOwner ? 1 : 0)
      init(size, 3)
      setSeen()
    }
  }, [success, lastCount])

  const maxRows = grid.length > 0 ? (grid[grid.length - 1].top + 1) + (expanded !== null ? 1 : 0) : 0

  return (
    <Container>
      {isOwner && (artworks?.data?? []).length === 0 ? <Toast>
        <div>
        Showcase your professional fashion artworks here. Once approved, they’ll appear on the timeline of <b>itch</b>’s home page!
        </div>
      </Toast> : null }
      {!isOwner && getProfile.profile && (artworks?.data?? []).length === 0 ? <Toast>
        <div>
        {getName(getProfile.profile)} hasn't uploaded any artworks.
        </div>
      </Toast> : null }
      <TilesContainer>
        <TilesInnerPositioned rows={maxRows}>
          {isOwner ? <RevealArtworkTile 
            className="reveal-tile"
            responsiveHeight={{
              [breakpoints.sm]: '66.666666667vw'
            }}
            index={0}
            position={grid[0]}
            key={'reveal'}
            onClick={() => {
              console.log('set modal')
              setModal({
                name:'reveal-artwork'
              })
            }}
          /> : null}
          {artworksRenderItems}
        </TilesInnerPositioned>
        {fetching && !success ? <ProgressLoader/> : null}
      </TilesContainer>
    </Container>
  )
}