
import { countUnseen } from 'apis/account-artwork.api'
import { useQuery } from '@tanstack/react-query'
import useAuth from './auth.hook'

const useGetArtworkUnseenCount = () => {
  const { session } = useAuth()
  const {
    isSuccess,
    isLoading,
    data
  } = useQuery({
    queryFn: countUnseen,
    queryKey: [`artwork-unseen-count-${session?.profile?.id}`]
  })

  return {
    value: isSuccess ? data.data : 0,
    isSuccess,
    isLoading
  }
}

export default useGetArtworkUnseenCount