import React from 'react';
import styled from 'styled-components';
import {
  colours,
  fonts,
} from '../../styles/global.styles';

const Wrapper = styled.div`
  position: relative;
`;

const ItemsContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: ${colours.black_alpha85};
  
  display: flex;
  flex-direction: column;

  height: max-content;
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
`;

const ItemRow = styled.div`
  padding: 10px;
  color: ${colours.text};
  font-family: ${fonts.content.regular};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  cursor: pointer;

  
  &:hover {
    color: ${colours.pink};
  }

`;

export default function Dropdown(
  props
) {
  const { items, onSelect } = props
  return (
    <Wrapper>
      <ItemsContainer>
        {(items ?? []).map((item, i) => 
          <ItemRow
            key={i}
            onClick={() => {
              onSelect && onSelect(item);
            }}
          >{item.name ?? item}
          </ItemRow>
        )}
      </ItemsContainer>
    </Wrapper>
  )
}