import { useQueryClient, useMutation } from '@tanstack/react-query';
import { respondToComment } from 'apis/comments.api'

const useAddCommentResponse = (contentType, relatedId, id) => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (body) => {
      return respondToComment(id, body)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`comments-${contentType}`, relatedId]
      })
    }
  });

  return {
    respondToComment: mutate,
    isLoading, 
    isSuccess,
    isError
  }
}

export default useAddCommentResponse