import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  $getSelection,
  SELECTION_CHANGE_COMMAND,
  $isRangeSelection,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND, SET } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import styled from 'styled-components'
import { 
  colours,
  fonts,
  breakpoints,
} from '../../../styles/global.styles'

const LinkEditorContainer = styled.div`
  background-color: ${colours.darklight};
  position: absolute;
  z-index: 1200;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  padding: 10px;
  transition: opacity 0.5s;
  filter: drop-shadow(0px 0px 3px rgba(51, 51, 51, 0.05));
  display:flex;
  flex-direction:column;

  &>div {
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
  }
`

const Button = styled.div`
  cursor: pointer;
  font-size: 13px;
  font-family: ${fonts.content.bold};
  line-height: 16px;
  color: ${colours.darkergrey2};
  padding-left:4px;
  padding-right:4px;

  
  &:hover {
    color: ${colours.pink};
  }

`

const LinkInput = styled.input`
  display: block;
  width: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 13px;
  font-weight: 300;
  font-family: ${fonts.content.regular};
  line-height: 16px;
  color: ${colours.darkergrey2};
  border: none;
  outline: none;
  margin-bottom: 6px;

  &:placeholder {
    color: ${colours.lightgrey2};
  }
`

const LowPriority = 1;

export function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    
    let left = rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    if (left + editor.offsetWidth > window.innerWidth) {
      left = window.innerWidth - editor.offsetWidth;
    } else if (left < 0) {
      left = 0;
    }
    editor.style.left = `${left}px`;
  }
}


export default function FloatingLinkEditor({ editor, onDone }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [initialLink, setInitialLink] = useState(null)
  // const [isEditMode, setEditMode] = useState(true);
  const isEditMode = true
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    // console.log('update link editor')
    
    // get selection and place link editor
    const selection = $getSelection();
    
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
        setInitialLink(parent.getURL())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
        setInitialLink(node.getURL())
      } else {
        setLinkUrl("");
      }
    }

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    
    if ( 
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
        if (inputRef.current)
          inputRef.current.focus()
        
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      // setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);
  
  return (
    <LinkEditorContainer id="link-editor" ref={editorRef}>
      {isEditMode ? (
        <>
        <LinkInput
          ref={inputRef}
          className="link-input"
          placeholder="Paste or type a link..."
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, event.target.value);
                  if (editorRef.current)
                    positionEditorElement(editorRef.current, null);
                    setLastSelection(null);
                    onDone && onDone()
                }
                // setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              // setEditMode(false);
              if (editorRef.current)
                positionEditorElement(editorRef.current, null);
                setLastSelection(null);
                // reset the link to its original or null
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                onDone && onDone()
            }
          }}
        />
        <div>
          {initialLink?.length > 0 ? <Button onClick={() => {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            onDone && onDone()
          }}>Unlink</Button> : <Button onClick={() => {
            onDone && onDone()
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            // editor.dispatchCommand(TOGGLE_LINK_COMMAND, initialLink);
          }}>Cancel</Button>}
          <Button onClick={() => {
            if (editorRef.current) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                url: linkUrl,
                target: '_blank'
              });
              onDone && onDone()
            }
          }}>Link</Button>
        </div>
        </>
      ) : (
        <>
          <div className="link-input">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                // setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </LinkEditorContainer>
  );
}