import styled from 'styled-components'
import {
  BaseTransition,
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1000px;
`;