import React, { useState, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  icons
} from '../../constants'
import {
  fonts
} from '../../styles/global.styles'

const Wrapper = styled.label`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: flex-start;
  padding-left: 6px;
  cursor: pointer;
  
  &>span {
    font-family: ${fonts.content.regular};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const Toggle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 11px;
  overflow: hidden;
  img {
    top: -6px;
    left:-6px;
    position: absolute;
  }
  img:first-child {
    visibility: ${props => props.active ? 'visible':'hidden'}
  }
  img:nth-child(2) {
    visibility: ${props => props.active ? 'hidden':'visible'}
  }
`;

const InputRadio = styled.input`
  position: absolute;
  visibility: hidden;
`;

export default forwardRef(function Checkbox(
  props,
  ref
) {
  const { name, label, value, defaultChecked, onChange, ...rest } = props
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (onChange) {
      onChange(checked)
    }
  }, [checked])

  return (
    <Wrapper {...rest} className="checkbox">
      <Toggle active={checked}>
        <img src={icons['on.svg']} alt="on" />
        <img src={icons['off.svg']} alt="off" />
        <InputRadio
          ref={ref} 
          value={value ?? null}
          checked={checked}
          type="checkbox" 
          name={name}
          onChange={(evt) => {
            setChecked(evt.target.checked)
          }}
        />
      </Toggle>
      <span dangerouslySetInnerHTML={{
        __html: label
      }}>
      </span>
    </Wrapper>
  ); 
});