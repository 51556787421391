import React, { useState, useRef, useEffect, forwardRef } from 'react';
import {
  BaseImage
} from '../../styles/global.styles'
import Sticky from '../shared/sticky'
import {
  icons
} from '../../constants';
import UserProfileLine from './user-profile-line';
import {
  UserNavBarWrapper,
  UserNavBar,
  Menu,
  CurrentSectionTitle,
  Nav,
  NavItemActiveBar,
  NavItemStyled,
  CounterStyled,
  ScrollTopButton,
  CloseButton
} from './usernav.style'
import {
  useRouteMatch,
  useLocation
} from 'react-router-dom'
import useGetConversationUnreadCount from 'hooks/get-conversation-unread-count.hook'
import useGetArtworkUnseenCount from 'hooks/get-artwork-unseen-count.hook';
import useGetArticleUnseenCount from 'hooks/get-article-unseen-count.hook';
import useAnimatedNavBar from 'hooks/animated-nav-bar.hook';
import { useWindowDimensions } from 'utils/helpers';
import useDrawers from 'hooks/drawers.hook'

function NavItem({
  selected,
  children,
  to,
  className,
  onMouseOver,
  onMouseLeave,
  counter,
  ...rest
}) {
  return (
    <NavItemStyled 
      className={`${className} ${counter && counter > 0 ? 'has-counter':''}`}
      to={to}
      selected={selected}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      <span>
        {children}
      </span>
      {counter ? <CounterStyled>{counter > 99 ? '99+': counter}</CounterStyled> : null}
    </NavItemStyled>
  )
}

export default function UserNav({
  profile
}) {
  const { activeDrawer, setActiveDrawer } = useDrawers()
  const scrollTimeoutRef = useRef(null)
  const navRef = useRef(null)
  const userNavRef = useRef(null)
  const { pathname } = useLocation()
  const prevPathname = useRef(null)
  const { url } = useRouteMatch()
  const [navStucked, setNavStuck] = useState(false);
  const [navCollapsed, setNavCollapse] = useState(false);
  const userNavElem = useRef(null);
  const conversationCounter = useGetConversationUnreadCount()
  const artworkCounter = useGetArtworkUnseenCount()
  const articleCounter = useGetArticleUnseenCount()
  const { 
    setBarPosition,
    setBarPositionByElem,
    animatedBarRef
  } = useAnimatedNavBar({
    targetNavItemClassName: 'nav-item',
  })
  const { isMobile } = useWindowDimensions()

  const isOwner = pathname.indexOf('/my-profile') === 0;
  const subPath = isOwner ? pathname.split('/')[2] ?? '' : pathname.split('/')[2] ?? 'gallery'

  const allNavItems = [
    {
      name: 'my feed',
      subPath: ''
    },
    {
      name: 'conversations',
      subPath: 'conversations'
    },
    {
      name: 'itchbook',
      subPath: 'itchbook'
    },
    {
      name: 'gallery',
      subPath: 'gallery'
    },
    {
      name: 'articles',
      subPath: 'articles'
    }
  ]

  const guestNavItems = allNavItems.filter(n => [
    'gallery',
    'articles'
  ].includes(n.name))

  const getCurrentNav = (nav, subPath) => {
    if (subPath) {
      const i = nav.findIndex(item => item.subPath === subPath)
      const found = nav[i]
      return {
        title: found?.name ?? '',
        navSelectedIndex: i
      }
    } else {
      return {
        title: nav[0].name,
        navSelectedIndex: 0
      }
    }
  }

  const { title } = getCurrentNav(isOwner ? allNavItems: guestNavItems, subPath)
  
  const onNavItemMouseOver = (evt) => {
    setBarPositionByElem(evt.currentTarget)
  }

  const onNavItemMouseLeave = (evt) => {
    const { navSelectedIndex } = getCurrentNav(isOwner ? allNavItems: guestNavItems, subPath)
    setBarPosition(navSelectedIndex);
  }
  
  useEffect(() => {
    // update the navbar    
    if (!isMobile) {
      setTimeout(() => {
        const { navSelectedIndex } = getCurrentNav(isOwner ? allNavItems: guestNavItems, subPath)
        setBarPosition(navSelectedIndex)
      }, 500)
    }

    // for my profile and other user profile    
    if (prevPathname.current !== pathname && pathname.split('/').length === 3 && navRef.current) {
      const topNav = document.getElementById('top-nav')
      const header = document.getElementById('header')
      if (topNav) {
        clearTimeout(scrollTimeoutRef.current)
        scrollTimeoutRef.current = setTimeout(() => {
          window.scrollTo({
            top: header.clientHeight - topNav.clientHeight,
            behavior: 'smooth' 
          })
          prevPathname.current = pathname
        }, 300)
      }
    }

    // setMenuActive(false)
  }, [pathname])

  useEffect(() => {
    window.onresize = () => {
      if (!isMobile) {
        const { navSelectedIndex } = getCurrentNav(isOwner ? allNavItems: guestNavItems, subPath)
        setBarPosition(navSelectedIndex)
      }
    }
    window.onclick = () => {
      // setMenuActive(false)
      if (activeDrawer === 'user-nav')
        setActiveDrawer(null)
    }
    
  }, [])

  const closeDrawer = (event) => {
    if (userNavRef.current 
      && !userNavRef.current.contains(event.target) 
      && activeDrawer === 'user-nav') {
        // this has to be in the top nav avatar icon
        console.log(event.target)
        setActiveDrawer(null)
    }
  }

  const handleScroll = (event) => {
    if (isMobile) {
        if (userNavRef.current 
          && userNavRef.current.contains(event.target) 
          && activeDrawer === 'user-nav') {
          event.stopPropagation()
          event.preventDefault()
        }
    }   
  }

  useEffect(() => {
    const elem = userNavRef.current
    document.body.addEventListener('click', closeDrawer)
    document.body.addEventListener('touchstart', closeDrawer)
    elem.addEventListener('touchmove', handleScroll)
    elem.addEventListener('mousewheel', handleScroll)
    return () => {
        document.body.removeEventListener('click', closeDrawer)
        document.body.removeEventListener('touchstart', closeDrawer)
        elem.removeEventListener('touchmove', handleScroll)
        elem.removeEventListener('mousewheel', handleScroll)
    }
  }, [activeDrawer])

  const menuActive = activeDrawer === 'user-nav'
  
  return (
    <Sticky 
      id="user-nav-sticky"
      ref={navRef}
      onStick={() => {
        setNavStuck(true);
      }}
      onUnStick={() => {
        setNavStuck(false);
        setNavCollapse(false)
      }}
    >
      <UserNavBarWrapper 
        id="user-nav"
        ref={userNavElem} 
        collapse={navCollapsed}
      >
        {!navCollapsed ? <CloseButton 
          visible={navStucked}
          onClick={() => {
            setNavCollapse(true)
          }}
        /> : null}
        <UserNavBar 
          hidden={navCollapsed}
          menuActive={menuActive} 
        >
          <Menu onClick={(evt) => {
            evt.stopPropagation();
            if (activeDrawer === 'user-nav') {
              setActiveDrawer(null)
            } else {
              setActiveDrawer('user-nav')
              const topNav = document.getElementById('top-nav')
              const header = document.getElementById('header')
              if (topNav) {
                clearTimeout(scrollTimeoutRef.current)
                scrollTimeoutRef.current = setTimeout(() => {
                  window.scrollTo({
                    top: header.clientHeight - topNav.clientHeight,
                    behavior: 'smooth' 
                  })
                  prevPathname.current = pathname
                }, 100)
              }
            }
          }}>
            <BaseImage src={icons['3-line-16x12px.svg']} />
          </Menu>
          <CurrentSectionTitle>
            {title}
          </CurrentSectionTitle>
          <Nav ref={userNavRef} active={menuActive}>
            {!isMobile ? <NavItemActiveBar
              ref={animatedBarRef} 
              className="nav-item-active-bar" 
            /> : null}
            {profile.isOwner ? 
            <NavItem 
              className="nav-item"
              onMouseOver={onNavItemMouseOver}
              onMouseLeave={onNavItemMouseLeave}
              selected={subPath === ``} 
              to={`${url}`}>
              my feed
            </NavItem>: null}
            {profile.isOwner ? 
            <NavItem 
              className="nav-item"
              onMouseOver={onNavItemMouseOver}
              onMouseLeave={onNavItemMouseLeave}
              selected={subPath === `conversations`} 
              to={`${url}/conversations`}
              counter={profile.isOwner ? (conversationCounter.isSuccess ? conversationCounter.value : 0) : null}
            >
              conversations
            </NavItem>: null}
            {profile.isOwner ? <NavItem 
              className="nav-item"
              onMouseOver={onNavItemMouseOver}
              onMouseLeave={onNavItemMouseLeave}
              selected={subPath === `itchbook`} 
              to={`${url}/itchbook`}
            >
              itchbook
            </NavItem>: null}
            <NavItem
              className="nav-item"
              onMouseOver={onNavItemMouseOver}
              onMouseLeave={onNavItemMouseLeave}
              selected={subPath === `gallery`} 
              to={`${url}/gallery`}
              counter={profile.isOwner ? (artworkCounter.isSuccess ? artworkCounter.value : 0) : null}
            >
              gallery
            </NavItem>
            <NavItem
              className="nav-item"
              onMouseOver={onNavItemMouseOver}
              onMouseLeave={onNavItemMouseLeave}
              selected={subPath === `articles`} 
              to={`${url}/articles`}
              counter={profile.isOwner ? (articleCounter.isSuccess ? articleCounter.value : 0) : null}
            >
              articles
            </NavItem>
          </Nav>
          {!profile.isOwner && !menuActive && navStucked ? <UserProfileLine profile={profile}/> : null}
        </UserNavBar>
        <ScrollTopButton
          collapse={navCollapsed}
          visible={navStucked}
          onClick={() => {
            /*
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
            */
          }}
        ></ScrollTopButton>
      </UserNavBarWrapper>
    </Sticky>
  );
}