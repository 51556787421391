import React from 'react'
import styled from 'styled-components'

import {
  colours,
  fonts
} from '../../../styles/global.styles'

import {
  ActivityActions
} from '../../../constants'

import { Link } from 'react-router-dom'

const Container = styled(Link)`
  display:flex;
  flex-direction: row;
  padding:4px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;


  &:hover {
    .icons {
      &>img:first-child {
        opacity: 0;
      }
      &>img:last-child {
        opacity: 1;
      }
    }
    .label {
      visibility: visible;
    }
    .count {
      color: ${colours.pink};
    }
  }

`

const Icon = styled.div`
  width: ${props => props.expanded ? 16:12}px;
  height: ${props => props.expanded ? 16:12}px;
  margin-right:5px;
`

const Label = styled.div`
  font-family: ${fonts.content.regular};
  color: ${colours.pink};
  font-size: ${props => props.expanded ? 12:10}px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  visibility: hidden;
`

const Counter = styled.div`
  font-family: ${fonts.content.regular};
  color: ${colours.lightgrey3};
  font-size: ${props => props.expanded ? 12:10}px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 5px;
`
const Col = styled.div`
  flex: 1 1 auto;
  display:flex;
  flex-direction: row;
`

const IconWrapper = styled.span`
  position: relative;
  display: block;
  cursor: ${props => props.noClick ? 'default' :'pointer'};
  &>img {
    display:block;
    width: 100%;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
  }
  &>img:first-child {
    opacity: 1;
  }
  &>img:last-child {
    top:0;
    left:0;
    position: absolute;
    opacity: 0;
  }
`;

export default function CounterItem({
  iconActive,
  iconHover,
  label,
  count,
  action,
  onClick,
  expanded,
  noClick = false,
  url = null,
  ...rest
}) {
  return (
    <Container to={url ?? '#'} onClick={onClick} {...rest}>
      <Icon expanded={expanded}>
        <IconWrapper noClick={noClick} className="icons">
          <img src={iconActive} alt={label}/>
          <img src={iconHover} alt={label}/>    
        </IconWrapper>
      </Icon>
      <Col>
        {action !== ActivityActions.Featured ? <Counter expanded={expanded} className="count">{count}</Counter> : null}
        <Label expanded={expanded} className="label">{label}</Label>
      </Col>
    </Container>
  )
}