import styled from 'styled-components';
import {
  fonts,
  breakpoints,
  colours,
  ButtonReset
} from '../styles/global.styles'
import RadioButtons from 'components/form/radio-buttons';

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 430px;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    
  }
`

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 12px;
  margin-top: auto;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    margin-top: unset;
  }
`

export const Label = styled.div`
  font-size: 14px;
  line-height: 15px;
  font-family: ${fonts.content.bold};
  color: ${colours.pink};
`

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

export const RadioButtonsStyled = styled(RadioButtons)`
  flex-direction: row !important;
  margin-left:12px;

  .radio-button-item {
    margin-bottom: 2px;
    .label {
      color: ${colours.grey};
      font-weight: 700;
    }
  }
`;

export const SecondaryButtons = styled.div`
`

export const PrimaryButton = styled.div`
  margin-left:auto;
`