import ButtonSave from 'components/form/button-save'
import styled from 'styled-components'

import {
  breakpoints,
  fonts,
  colours
} from 'styles/global.styles'

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 100%;
`

export const Inner = styled.div`
  width: 100%;
  max-width: 500px;
  display:flex;
  flex-direction:column;
  align-items:center;
`

export const Logo = styled.div`
  margin-bottom: 50px;
  img {
    width: 135px;
  }
`

export const Title = styled.div`
  font-size: 21px;
  font-family: ${fonts.content.bold};
  line-height:31.5px;
  color: ${colours.pink};
  margin-bottom: 10px;
`

export const Body = styled.div`
  font-size: 16px;
  font-family: ${fonts.content.regular};
  line-height:21px;
  color: ${colours.grey};
  text-align: center;
`

export const CreateAccountButton = styled(ButtonSave)`
  margin-top: 40px;
  font-size: 14px;
  width: 240px;
  height: 48px;
  font-family: ${fonts.content.bold};
  margin-left: auto;
  margin-right: auto;
`

export const LaterLink = styled.div`
  margin-top:10px;
  font-size: 14px;
  font-family: ${fonts.content.regular};
  line-height:21px;
  color: ${colours.grey};
  text-decoration: underline;
  cursor:pointer;
`