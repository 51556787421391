import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import {
  colours,
  breakpoints,
  fonts
} from 'styles/global.styles'
import useToastPop from 'hooks/toast-pop.hook'

const Container = styled.div`
  position: fixed;
  z-index: ${props => props.zIndex ?? 10};
  top: 0px;
  left: 0;
`

const Toast = styled.div`
  border-bottom: 1px solid ${colours.pink};
  text-align: center;
  background-color: ${colours.lightgreypink_alpha92};
  color: ${colours.pink};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  z-index: 1;
  height: 36px;
  width: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  position: fixed;
  z-index: 10;
  top: ${props => props.positionedTop ?? 0}px;
  left: 0;
  width: 100%;
  height: 36px;

  transform: translateY(-100%);
  opacity: 0;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    top: ${props => props.positionedTop ?? 0}px;
  }

  b {
    font-weight: 700;
  }

  &.animate {
    animation-name: ${props => props.show ? 'slideInFromTop': 'slideInToTop'};
    animation-duration: .3s;
    animation-delay: .3s;
    animation-fill-mode: both;
    animation-direction: normal;
  }

  span {
    color: ${colours.pink};
    font-family:${fonts.content.bold};
    font-size: 14px;
    line-height: 17px;
    text-align: center;  
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform:translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideInToTop {
    0% {
      transform:translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
`

const ToastPop = () => {
  const timerRef = useRef(null)
  const toastRef = useRef(null)
  const {
    toastPopped,
    hide
  } = useToastPop()

  useEffect(() => {
    clearTimeout(timerRef.current)
    if (toastPopped.active) {
      toastRef.current.classList.add('animate');
      if (toastPopped?.ui?.autoHide !== null ? toastPopped?.ui?.autoHide : true)
        timerRef.current = setTimeout(() => {
          hide()
        }, 2000)
    }
  }, [toastPopped])

  const targetElement = toastPopped?.ui?.elementId ? document.getElementById(toastPopped.ui.elementId) : document.body 

  return (
    <>
    {toastPopped?.active ? createPortal(
      <Container id="toast-pop" zIndex={toastPopped?.ui?.zIndex}>
        <Toast
          className=""
          positionedTop={toastPopped?.ui?.positionedTop}
          ref={toastRef}
          show={toastPopped?.active} >
            <span>{toastPopped?.message ?? ''}</span>
        </Toast>
      </Container>, targetElement) : null}
    </>
  )
}

export default ToastPop