import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getSettings } from '../apis/user.api';
import useAuth from './auth.hook'

export default function useGetMyAccount() {
  const { session } = useAuth();
  const { 
    isLoading, isSuccess, 
    error, data, isFetching,
    refetch
  } = useQuery([`account-${session?.profile?.id}`], 
  getSettings)

  useEffect(() => {
    if (session) {
      refetch()
    }
  }, [session])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    accountSettings: session ? {
      ...data?.data
    } : null
  }
}