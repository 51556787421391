import { useInfiniteQuery } from '@tanstack/react-query';
import { getFollowers } from '../apis/user.api';

export default function useGetFollowers(userId = null) {
  
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [`followers-${userId}`, ],
    queryFn: ({ pageParam = 0 }) => getFollowers({id: userId, page: pageParam}),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    }
  })

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data)
  }), [])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    users: flattenedData,
    nextPage: fetchNextPage
  }
}