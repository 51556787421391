import { useInfiniteQuery } from '@tanstack/react-query';
import { getCurentUserFeed } from '../apis/user.api';
import useAuth from './auth.hook'

export default function useGetMyFeed() {
  const { session } = useAuth()
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [`my-feed-${session?.profile?.id}`],
    queryFn: ({ pageParam = 0 }) => getCurentUserFeed({
      page: pageParam,
      size: 9
    }),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.page) + 1 < Number(lastPage.totalPages))
        return Number(lastPage.page) + 1
    }
  })

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr?.data)
  }), [])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    artworks: flattenedData,
    nextPage: fetchNextPage,
    hasNextPage
  }
}