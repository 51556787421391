import React from 'react'
import CenteredFormLayout from '../shared/modal-form/centered-form.layout'
import styled from 'styled-components'
import {
  icons
} from '../../constants'
import SelectFormatType from './select-format-type'
import {
  colours,
  fonts,
  breakpoints
} from '../../styles/global.styles'

const FieldLabel = styled.div`
  color: ${colours.grey};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
  width: 100%;
`;

const Container = styled.div`
  @media (max-width:${breakpoints.sm}px) {
    height: calc(100vh - 175px); 
    display:flex;
    flex-direction: column;
    justify-content: center;
    
    &>.center {

    }
  }
`

export default function FormatType({
  visible,
  register
}) {
  return (
    <Container className="edit-article_select-format-type">
      <div className='center'>
        <FieldLabel>
          Choose a format type:
        </FieldLabel>
        <SelectFormatType register={register} />
      </div>
    </Container>
  )
}