import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours,
  ButtonPrimary
} from '../../styles/global.styles'
import {
  icons 
} from '../../constants'
import { onSubModalLoadElevate } from '../shared/modal';

const ChangePasswordEmailStyled = styled.div`
  height: inherit;
  width: 100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:0 25px;
  img {
    margin-bottom:20px;  
  }

  h1 {
    color: ${colours.pink};
    font-family: ${fonts.content.bold};
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  p {
    font-family: ${fonts.content.regular};
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  b {
    font-family: ${fonts.content.bold};
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0em;
  }
`;

const ButtonOk = styled(ButtonPrimary)`
  margin-top: 50px;
  width:180px;
  height: 52px;
`;

export default function ChangePasswordEmail(
  props
) {
  useEffect(onSubModalLoadElevate, [])

  return (
    <ChangePasswordEmailStyled>
      <img src={icons['email.svg']} alt="email" />
      <h1>Just one more step!</h1>
      <p>For safety reasons, we’ve sent you an email at <b>{props.email ?? ''}<br/></b> to confirm your new password.</p>
      <ButtonOk type="button" 
        onClick={() => {
          props.onClose && props.onClose();
        }}>Ok!</ButtonOk>
    </ChangePasswordEmailStyled>
  );
}