import axiosInstance from './api';
import {
  toQueryParams
} from '../utils/query-parameters.js'

export const getAll = async ({userTags, page = 0, size = 12}) => {
  const response = await axiosInstance.get(`/artworks?userTags=${userTags.join(',')}&page=${page}&size=${size}`)
  return response.data
}

export const getOne = (id) => {
  return axiosInstance.get('/artworks/' + id)
}

export const searchArtworksByKeywords = async ({
  query,
  page = 0,
  size = 100
}) => {
  const response = await axiosInstance.get(`/artworks/search?query=${query}&page=${page}&size=${size}`)
  return response.data
}

export const shuffleArtworks = async(size = 12) => {
  const response = await axiosInstance.post(`/artworks-shuffle?size=${size}`)
  return response.data
}

export const getShuffledArtworks = async({
  page = 0, 
  size = 12
}) => {
  const response = await axiosInstance.get(`/artworks-shuffle?page=${page}&size=${size}`)
  return response.data
}