import React from 'react'
import styled from 'styled-components'
import Facebook from 'components/social-share/facebook'
import Twitter from 'components/social-share/twitter'
import Pinterest from 'components/social-share/pinterest'
import CopyLink from 'components/social-share/copy-link'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
  &>* {
    margin-left: 10px;
    margin-right: 10px;
    
    &:first-child {
      margin-left:0;
    }

    &:last-child {
      margin-right:0;
    }
    
    img {
      height: ${props =>props.size}px;
    }
  }
`

export default function Share({
  url,
  size = 16,
  theme = 'black'
}) {
  return (
    <Container className="share" size={size}>
      <Facebook className="share-btn" theme={theme} url={url} />
      <Twitter className="share-btn" theme={theme} url={url} />
      <Pinterest className="share-btn" theme={theme} url={url} />
      <CopyLink className="share-btn" theme={theme} url={url} />
    </Container>    
  )
}