import React, { useState } from 'react'
import styled from 'styled-components'

const Link = styled.div`
    cursor: pointer
`

export default function SocialMedia({ type, states, ...rest }) {
    const [hovered, setHovered] = useState(false);
    return (
        <Link
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            {...rest}
        >
            <div className={type + " sprites " + (hovered ? states.hovered : states.normal)}></div>
        </Link>
    )
}