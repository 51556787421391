import { forwardRef } from "react"

export default forwardRef(function CloseFeatureButton({ 
    src, 
    addClass, 
    passCloseSection, 
    hide 
}, ref) {
    return (
        <div
            ref={ref}
            id="close-feature"
            className={"close-feature d-flex justify-content-center align-items-center " + (addClass ? addClass : "")}
            onClick={passCloseSection}
            style={{ visibility: hide ? 'hidden' : 'visible' }} >
            <img src={src} className="align-self-center" alt="" />
        </div>
    )
})
