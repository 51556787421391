import { useRef } from "react"

export default function useAnimatedNavBar({
  targetNavItemClassName,
  useWindowViewPort = true,
  useInnerElemTagName = 'span'
}) {
  const animatedBarRef = useRef()

  const setBarPositionByElem = (elem) => {
    if (animatedBarRef.current) {
      if (elem) {
        animatedBarRef.current.style.visibility = 'visible'
        const left = useWindowViewPort ? (elem.getBoundingClientRect().left ?? 0) : elem.offsetLeft;

        const navSelected = useInnerElemTagName ? elem.querySelector(useInnerElemTagName) : elem
        const compStyles = window.getComputedStyle(elem);

        const paddingLeft = parseInt(compStyles.paddingLeft.replace('px',''))
        animatedBarRef.current.style.left = `${left + paddingLeft}px`;
        animatedBarRef.current.style.width = `${navSelected?.offsetWidth ?? 1}px`;
      } else {
        animatedBarRef.current.style.visibility = 'hidden'
      }
    }
  }

  const setBarPosition = (navSelectedIndex) => {
    if (animatedBarRef.current) {
      if (navSelectedIndex === null) {
        animatedBarRef.current.style.visibility = 'hidden'
      } else {
        animatedBarRef.current.style.visibility = 'visible'
        const navItems = document.getElementsByClassName(targetNavItemClassName);
        const navSelectedElem = navItems[navSelectedIndex]
        setBarPositionByElem(navSelectedElem)
      }
    }
  }

  return {
    setBarPosition,
    animatedBarRef,
    setBarPositionByElem,
  }
}