import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOne } from 'apis/conversation.api'
import useAuth from './auth.hook'

export default function useDeleteConversation() {
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (id) => {
      return deleteOne(id)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`conversations`, variables.id]
      })
      queryClient.invalidateQueries({
        queryKey: [`conversations-${session?.profile?.id}`]
      })
    },
  });

  return {
    deleteConversation: mutate,
    isLoading,
    isSuccess,
    isError,
    error
  }
}