import React from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'
import {
  icons
} from '../../constants'

const TagContainer = styled.div`
  display:inline-block;
  padding: 1.2px 7.5px;
  border:1px solid ${colours.pink};
  margin-right: 6px;
  margin-bottom: 6px;
  height: 26px;
`

const Text = styled.span`
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.text};
  position: relative;
  top: -2px;
`

const Delete = styled.span`
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  top: -2px;
`

export default function Tag({
  text,
  onRemove,
  disabled = false,
}) {
  return (
    <TagContainer>
      <Text>{text}</Text>
      <Delete onClick={()=> {
        if (!disabled)
          onRemove && onRemove()
      }}>
        <img src={icons['close-12px-pink.svg']} alt="remove" />
      </Delete>
    </TagContainer>
  )
}