import axios from 'axios';
import { getToken } from '../utils/token';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API + '/api'
});

const token = getToken();

axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null

const isSameOrigin = url => {
  return new URL(window.location.href).origin === new URL(url).origin;
}

axiosInstance.interceptors.response.use(response => response, 
  function(error) {
    if (error.response?.status === 401 && getToken() !== null) {
      console.log('clear session')
      updateAuthorizationHeader(null)
      localStorage.clear();
    }
    return Promise.reject(error)
  }
)

export const updateAuthorizationHeader = (token) => {
  if (token)
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  else 
    delete axiosInstance.defaults.headers.common['Authorization'];
}

export default axiosInstance;