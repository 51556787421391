import React, { useRef } from 'react';
import { createPortal } from 'react-dom'
import Modal from '../shared/modal';
import ProfileListItem from './profile-list-item';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import {
  Title,
  Center,
  Container,
  List,
  ListItem
} from './profile-list.style'

export default function ProfileList({
  title,
  users,
  onClose,
  onBottom
}) {
  return createPortal(
    <Modal 
      onClose={() => {
        onClose && onClose()
      }}
      onBottom={onBottom}
    >
      <Center>
        <Container className="profile-list">
          <Title>
            <span>{title}</span>
          </Title>
          <List>
            {users.map((user, i) => (
              <ListItem key={i}>
                <ProfileListItem profile={user} />
              </ListItem>
            ))}
          </List>
        </Container>
      </Center>
    </Modal>
  ,document.body);
}