import { useMutation } from '@tanstack/react-query';
import { triggerPasswordReset } from 'apis/user.api'

export default function useLoggedinTriggerPasswordReset() {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: triggerPasswordReset
  });

  return {
    trigger: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}