import React, { useState } from 'react'
import { ArtworkGalleryContainer } from 'pages/artwork-gallery.styles'

export default function useGrid(
  columns = 5 // actual columns unit
) {
  const [cells, setCells] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [rowCount, setRowCount] = useState(0)

  const layoutStandardPattern = [
    [1, 2, 0, 2, 0],
    [1, 0, 0, 0, 0],
    [2, 0, 2, 0, 1],
    [0, 0, 0, 0, 1],
    [2, 0, 1, 2, 0],
    [0, 0, 1, 0, 0]
  ];

  const layoutOnesPatterns = [[1, 1, 1, 1, 1]];

  const layoutTwosPatterns = [
    [2, 0, 2, 0, 0],
    [0, 0, 0, 0, 0]
  ];

  const expand = (index) => {
    // expanded tile must only be a size 2
    // fill([...cells], 0, 0, layoutStandardPattern, null);
    const cellsCopy = [...cells];

    if (cellsCopy[index].size === 3) {
      // close expanded
      cellsCopy[index].height = 2
      cellsCopy[index].width = 2
      cellsCopy[index].size = 2

      fill(cellsCopy, 0, 0, layoutStandardPattern, null);
      setExpanded(null);
      
      // setRowCount(rowCount - 2)
    } else
    if (cellsCopy[index].size === 2) {
      // new expanded
      // reset all cell sizes
      if (expanded) {
        for (const item of cellsCopy) {
          if (item.size === 3) {
            item.height = 2
            item.width = 2
            item.size = 2
          }
        }
        fill(cellsCopy, 0, 0, layoutStandardPattern, null, 0);
      }

      let cellsArranged = [];
      const { x, y } = cellsCopy[index];
      cellsCopy[index].original = {
        size: 2
      }
      cellsCopy[index].size = 3;
      cellsCopy[index].width = 3;
      cellsCopy[index].height = 4;
      // console.log(x, y, index, cellsCopy[index].data);

      // get items before index from same row
      // append those to items after
      let patternOnce = [];
      let startX = 0
      if (x <= 1) {
        patternOnce = [
          [3, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 0, 0]
        ];
        // const rowType = y % 3;
      } else {
        // right side aligned
        patternOnce = [
          [2, 0, 3, 0, 0],
          [0, 0, 0, 0, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 0, 0]
        ];
        //cellsArranged = [...cellsCopy.slice(index)];
      }

      let beforeCells = [];
      let sameRowBeforeCells = [];
      // for each layout role take out the other items
      const prevRowIndex = Math.floor(index / 4) - 1;
      const start = 4 * Math.floor(index / 4);
      if (prevRowIndex > 0) {
        beforeCells = cellsCopy.slice(0, index);
      }
      sameRowBeforeCells = [...cellsCopy.slice(start, index)];
      const after = cellsCopy.slice(index + 1);

      cellsArranged = [
        cellsCopy[index],
        ...sameRowBeforeCells,
        ...after
      ];
      
      fill(cellsArranged, startX, y, layoutStandardPattern, patternOnce, index);
      setExpanded({index, x,y});

      // setRowCount(rowCount + 2)
    }
  };

  const fill = ( 
    items,
    startX = 0,
    startY = 0,
    activePattern = layoutStandardPattern,
    patternOnce = null
  ) => {
    // console.log("fill", items, columns);
    // sort items
    const items_sorted = items.sort((a, b) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return 1;
      return 0;
    });

    const itemSizeOnes = items_sorted.filter((item) => item.size === 1);
    const itemSizeTwos = items_sorted.filter((item) => item.size === 2);
    const itemSizeThrees = items_sorted.filter((item) => item.size === 3);
    
    const maxRows = startY + (itemSizeTwos.length > 0 
      ? ((itemSizeThrees.length * 4) + (Math.ceil(itemSizeTwos.length / 2) * 2) + Math.ceil((itemSizeOnes.length - (Math.ceil(itemSizeTwos.length / 2) * 2)) / 5)) 
      : Math.ceil(itemSizeOnes.length / 5) + (itemSizeThrees.length * 4))

    // create a coords grid to assign x,y to items
    const coords = [];
    for (let y = 0; y < maxRows; y++) {
      const row = [];
      for (let x = 0; x < columns; x++) {
        row.push({
          filled: false
        })
      }
      coords.push(row);
    }

    let pattern = patternOnce ?? activePattern;
    let counter = 0;
    let patternRow = 0;
    const sorted = [];
    // iterate thru coords and assign the positons to the each item
    for (let y = startY; y < maxRows; y++) {
      for (let x = startX; x < columns; x++) {
        // console.log("fill at coord ", y, x, coords[y][x].filled);
        if (!coords[y][x].filled) {
          let nextItem = null;

          // change pattern when either sizes run out and sizeThree is alrready placed

          // when pattern is completed
          if (patternOnce && (y - startY) === pattern.length) {
            /*
            console.log(
              "change pattern to active at " + y + "," + x + " - " + counter
            );
            */
            pattern = activePattern;
            patternOnce = null;
            patternRow = 0; // reset row Type
          }
          
          // change pattern when either of the item sizes run out
          
          if (
            pattern[patternRow][x] === 1 &&
            itemSizeOnes.length === 0 &&
            itemSizeTwos.length > 0 && 
            itemSizeThrees.length === 0
          ) {
            pattern = layoutTwosPatterns;
            patternRow = 0;
          } else if (
            pattern[patternRow][x] === 2 &&
            itemSizeTwos.length === 0 &&
            itemSizeOnes.length > 0 && 
            itemSizeThrees.length === 0
          ) {
            pattern = layoutOnesPatterns;
            patternRow = 0;
          }

          if (pattern[patternRow][x] === 3 && itemSizeThrees.length > 0) {
            nextItem = itemSizeThrees.shift();
          } else
          if (pattern[patternRow][x] === 2 && itemSizeTwos.length > 0) {
            nextItem = itemSizeTwos.shift();
          } else 
          if (itemSizeOnes.length > 0) {
            nextItem = itemSizeOnes.shift();
          }
          /*
          if (pattern[patternRow][x] === 1 && itemSizeOnes.length > 0) {
            nextItem = itemSizeOnes.shift();
          }
          */

          if (nextItem) {
            // console.log("nextitem to fill", nextItem);
            nextItem.x = x;
            nextItem.y = y;
            for (let h = y; h < y + (nextItem.height ?? nextItem.size); h++) {
              for (let w = x; w < x + (nextItem.width ?? nextItem.size); w++) {
                // console.log('fill item space ', h, w);
                coords[h][w].filled = true;
              }
            }
            counter++;

            sorted.push(nextItem);
          }
        }
      }
      if (pattern.length > 1) {
        patternRow++;
        if (patternRow === pattern.length) {
          patternRow = 0;
        }
      } else {
        patternRow = 0;
      }
    }

    if (cells.length < items.length) {
      setCells(sorted);

      // calculate the "modulus" of the cells
      const remainder = items.length % 4
      const adjustedMaxRows = remainder > 0 ? maxRows - (expanded ? 0:2): maxRows

      setRowCount(adjustedMaxRows)
    } 

  };

  return {
    cells,
    fill,
    expand,
    expanded,
    rowCount
  };
} 