import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  colours,
  fonts,
  breakpoints
} from '../../styles/global.styles'
import {
  FashionArtistProfessions,
  CompanyTypesList
} from '../../constants'
import MultipleSelect from '../form/multiple-select';
import RadioButtons from '../form/radio-buttons';
import { AccountTypes } from '../../constants';

const AccountTypesInfo = {
  [AccountTypes.FashionArtist]: {
    abbr:'fa',
    title: 'fashion artist',
    content: `
      For <b>fashion designers, models, photographers, videographers, 
      hair stylists, makeup artists, stylists, nail artists, 
      creative directors & retouchers</b>—select your fashion profession 
      & restore the art of fashion with a world of like-minded enthusiasts.`
    ,
    categories: FashionArtistProfessions,
    afterFirstSelection: `You can select 2 more account types if you have secondary fashion professions. Click Reset to refresh your selections or Save to continue.`
  },
  [AccountTypes.FashionCompany]: {
    abbr:'fc',
    title: 'fashion company',
    content: `
      For any fashion <b>brand</b>, <b>service</b> or <b>agency</b> such as clothing brands, model agencies, salons, studios, production companies, etc.
    `,
    categories: CompanyTypesList
  },
  [AccountTypes.FashionLover]: {
    abbr:'fl',
    title: 'fashion lover',
    content: `
      For anyone who’s a fashion editor, blogger, merchandiser, forecaster, researcher, buyer, marketing and PR or just the fashion fanatic.
    `
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    text-align: left;
    font-family: ${fonts.content.regular};
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }
`;

const AccountTypesSelector = styled.div`
  position: relative;
  width: 217px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .options {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .cross {
    position: relative;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
    &:before {
      content: '';
      width:100%;
      height: 2px;
      background-color: ${colours.pink};
      display: block;
    }
    &:after {
      position: absolute;
      top:0;
      left:50%;
      transform: translateX(-50%);
      content: '';
      width: 2px;
      height: 100%;
      background-color: ${colours.pink};
      display: block;
    }
  }
`;

const AccountTypeItem = styled.div`
  position: absolute;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 2px solid ${colours.pink};
  font-family: ${fonts.title.bold};
  font-size: 22px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items:center;
  color: ${colours.pink};
  background-color: ${props => props.isSelected ? colours.pink_alpha15 : 'transparent'};

  cursor: pointer;

  
  &:hover {
    background-color: ${colours.pink_alpha40}
  }


  &:first-child {
    left: 0;
    top: 0;
    transform: translate(40%, 80%);
  }
  &:nth-child(2) {
    top: 0;
    left: 50%;
    transform: translate(-50%, -35%);
  }
  &:last-child {
    right: 0;
    top: 0;
    transform: translate(-40%, 80%);
  }
`;

const Title = styled.div`
  margin-top: -12px;
  color: ${colours.pink};
  font-family: ${fonts.title.xbold};
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    margin-bottom: 15px;
  }
`;

const FashionArtistOptions = styled.div`
  display:flex; 
  flex-direction: row;
  width: 100%;
  margin-top: 8px;
`;

const FashionArtistProfessionsSelect = styled.div`
  display:flex;
  flex-direction: column;
  flex:0 0 50%;

  h2 {
    color: ${colours.pink};
    font-family: ${fonts.content.bold};
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .multiple-select {
    margin-left: 8px;
    .option-item {
      .label {
        font-family: ${fonts.content.bold};
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.sm +1}px) {
    flex: 0 0 57%;
  }
`;

const FashionArtistSelectionTip = styled.div`
  padding-top: 30px;
  padding-right: 24px;
  color: ${colours.pink};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  flex:0 0 50%;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    flex: 0 0 43%;
  }
`;

const FashionCompanyType = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;

  h2 {
    margin-top: 8px;
    color: ${colours.pink};
    font-family: ${fonts.content.bold};
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  .options {
    display:flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;

    .radio-button-item {
      margin-left: 8px;
      margin-right: 8px;
      .label {
        font-family: ${fonts.content.bold};
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
`;

export default function AccountTypeSelect({
  accountType,
  primaryProfession,
  secondaryProfessions = [],
  companyType,
  onChange
}) {
  const [accType, setAccType] = useState(accountType)
  // const [faProfessions, setFaProfessions] = useState([primaryProfession, ...secondaryProfessions].filter(o => o !==undefined && o !== null))
  const [faProfessions, setFaProfessions] = useState([])
  const [compType, setCompType] = useState(null)

  //const watchPrimaryProfession = watch('primaryProfession')
  //const watchAccType = watch('accountType')

  const handleOnChange = (update) => {
    onChange({
      accountType: accType,
      primaryProfession: faProfessions.shift(),
      secondaryProfessions: faProfessions,
      companyType: compType,
      ...update
    })
  }

  const accountTypeInfo = AccountTypesInfo[accType];
  return (
    <Wrapper>
      <AccountTypesSelector>
        <div className="cross"></div>
        <div className="options">
          <AccountTypeItem className="item" 
            isSelected={accountType === AccountTypes.FashionCompany} 
            onClick={() => {
              // field.onChange(AccountTypes.FashionCompany)
              setAccType(AccountTypes.FashionCompany)
              setFaProfessions([])
              handleOnChange({
                accountType: AccountTypes.FashionCompany
              });
            }}>fc</AccountTypeItem>
          <AccountTypeItem className="item" 
            isSelected={accountType === AccountTypes.FashionArtist} 
            onClick={() => {
              //field.onChange(AccountTypes.FashionArtist)
              setAccType(AccountTypes.FashionArtist)
              setFaProfessions([])
              handleOnChange({
                accountType: AccountTypes.FashionArtist
              });
            }}>fa</AccountTypeItem>
          <AccountTypeItem className="item" 
            isSelected={accountType === AccountTypes.FashionLover} 
            onClick={() => {
              // field.onChange(AccountTypes.FashionLover)
              setAccType(AccountTypes.FashionLover)
              setFaProfessions([])
              handleOnChange({
                accountType: AccountTypes.FashionLover
              });
            }}>fl</AccountTypeItem>
        </div>
      </AccountTypesSelector>
      {accType === null ? <Title style={{
        marginTop:'-20px'
      }}>select an account type</Title> : null}
      {accType !== null ? 
      <>
        <Title>{accountTypeInfo.title}</Title>
        <div className="content" dangerouslySetInnerHTML={{
          __html: accountTypeInfo.content
        }}></div>
      </> : null}
      {accType === AccountTypes.FashionArtist ? 
        <FashionArtistOptions>
          <FashionArtistSelectionTip>
            {faProfessions.length >= 1 ? accountTypeInfo.afterFirstSelection : null}
          </FashionArtistSelectionTip>
          <FashionArtistProfessionsSelect>
            <h2>I'm a fashion:</h2>
            <MultipleSelect 
              selected={faProfessions}
              options={accountTypeInfo.categories.map(item => ({
                value: item.value,
                name: item.label
              }))}
              maxSelectable={3}
              name="professions"
              onChange={(professions) => {
                
                setFaProfessions(professions)
                const tmp = [...professions]
                handleOnChange({
                  primaryProfession: tmp.shift(),
                  secondaryProfessions: tmp
                });
                
              }}
            />
          </FashionArtistProfessionsSelect>
        </FashionArtistOptions>
      : null}
      {accType === AccountTypes.FashionCompany ? 
        <FashionCompanyType>
          <h2>We're a fashion:</h2>
          <RadioButtons
            name="companyType"
            value={companyType}
            className="options"
            options={accountTypeInfo.categories.map(item => ({
              value: item.value,
              name: item.label
            }))}
            onChange={(selected) => {
              handleOnChange({
                companyType: selected
              });
            }}
          />
        </FashionCompanyType>
      : null}
    </Wrapper>
  )
}