import React, { useRef, useEffect } from 'react';
import TopNav from "components/shared/top-nav";
import {
    icons
} from '../../constants'
import {
    Container,
    Header,
    Title,
    Categories,
    CategoryItem,
    Seperator,
    CategorySlider
} from './search.styles'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory
} from 'react-router-dom'
import Collectives from './collectives'
import Artworks from './artworks'
import Articles from './articles'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function Search() {
    const sliderRef = useRef()
    const { push } = useHistory()
    const { path, url } = useRouteMatch()
    const location = useLocation()
    const { pathname, search } = location
    const query = search.substring(1).split('&')[0]?.split('=')[1]??''

    const navItems = [
        {
            name:'collectives',
            icon: 'collectives-fcfafl.svg',
            path: `${path}/collectives`
        },
        {
            name:'artworks',
            icon: 'artwork-grey.svg',
            path: `${path}/artworks`
        },
        {
            name:'articles',
            icon: 'article-grey.svg',
            path: `${path}/articles`
        }
    ]
    
    const getCurrentNav = (nav, path) => {
        if (path) {
            const i = nav.findIndex(item => item.path === path)
            const found = nav[i]
            return {
                title: found?.name ?? '',
                navSelectedIndex: i
            }
        } else {
            return {
                title: nav[0].name,
                navSelectedIndex: 0
            }
        }
    }

    const updateSlider = (targetElem) => {
        if (targetElem) {
            const left = targetElem.offsetLeft ?? 0;
            //const navSelectedSpan = targetElem.querySelector(`span`);
            
            const compStyles = window.getComputedStyle(targetElem);
            
            // const compNavTextStyles = window.getComputedStyle(navSelectedSpan)
            const paddingLeft = parseInt(compStyles.paddingLeft.replace('px',''))
            const paddingRight = parseInt(compStyles.paddingRight.replace('px',''))
            
            sliderRef.current.style.left = `${left + paddingLeft}px`;
            sliderRef.current.style.width = `${targetElem?.clientWidth - paddingLeft - paddingRight}px`;
        }
    }
    
    const resetSliderPosition = () => {
        const { navSelectedIndex } = getCurrentNav(navItems, pathname)

        const navItemsElems = document.getElementsByClassName('nav-item');
        
        const navSelected = navItemsElems[navSelectedIndex]

        updateSlider(navSelected)
    }

    const onMouseOver = (evt) => {
        updateSlider(evt.currentTarget)
    }

    const onMouseLeave = (evt) => {
        resetSliderPosition();
    }

    useEffect(() => {
        setTimeout(() => {
            resetSliderPosition();
        }, 500)
    }, [pathname, search])

    return (
        <>
            <Container>
                <Header id="search-header">
                    {query !== '' ? <Title>{decodeURI(query)}</Title> : null}
                    <Categories>
                        <CategorySlider ref={sliderRef} />
                        {navItems.map((item, i) => (
                            <>
                                <CategoryItem
                                    className="nav-item"
                                    onMouseOver={onMouseOver}
                                    onMouseLeave={onMouseLeave}
                                    active={pathname === url}
                                    onClick={() => {
                                        push(`${item.path}?q=${query}`);
                                    }}>
                                    <img src={icons[item.icon]} alt=""/>
                                    <span>{item.name}</span>
                                </CategoryItem>
                                {i < navItems.length - 1 ? <Seperator/> : null}
                            </>
                        ))}
                    </Categories>
                </Header>
                <Switch location={location}>
                    <Route path={`${path}`} exact>
                        <Redirect to={`${path}/collectives?q=${query}`} />
                    </Route>
                    <Route path={`${path}/collectives`}>
                        <Collectives/>
                    </Route>
                    <Route path={`${path}/artworks`}>
                        <Artworks/>
                    </Route>
                    <Route path={`${path}/articles`}>
                        <Articles/>
                    </Route>    
                </Switch>
            </Container>
        </>
    )
}