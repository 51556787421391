import React from 'react';
import styled from 'styled-components';
import {
  preloaders
} from '../../constants';

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default function Progress(
  props
) {
  return (
    <ProgressWrapper {...props}>
      <img src={preloaders['01-preloader-10px-000000.gif']} alt="loading" />
    </ProgressWrapper>
  )
}