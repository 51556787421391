import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  fonts,
  colours,
  BaseTransition,
  ScrollStyledBox,
  ScrollInvisibleStyledBox,
  ButtonReset,
  breakpoints,
} from '../../styles/global.styles'

export const TileStatusContainer = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${colours.light2_alpha85};
`

export const Status = styled.div`
  border-bottom: 1px solid ${colours.pink};
  width: 100%;
  height: auto;
  flex: 0 0 48px;
`

export const StatusMessage = styled.div`
  padding: 12px;
  flex: 1 1 auto;
`

const TintColor = (theme) => {
    switch (theme) {
      case 'light':
        return colours.tint.light
      case 'pink':
        return colours.tint.flare
      case 'dark':
        return colours.tint.metro
      default:
        return colours.tint.light
    }
}

export const Tint = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  top: 0;
  left: 0;
  background-color: ${props => TintColor(props.theme)};
`

export const ContentContainer = styled(ScrollInvisibleStyledBox)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: ${props => props.expanded ? 'auto':'visible'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding-bottom: ${props => props.expanded ? '150px':'0'};

  @media (max-width:${breakpoints.sm}px) {
    height: 100%;
  }
`

export const CoverImage = styled.div`
  width: 100%;
  height: ${props => props.expanded ? '700px':'100%'};
  cursor: pointer;
  position: relative;

  &>img {
    object-fit: cover;
    object-position: ${props => props.position ?? 'center center'};
    width: 100%;
    height: 100%;
  }

  @media (max-width:${breakpoints.sm}px) {
    height: ${props => props.expanded ? '463px':'100%'};
  }
`

export const AuthorContainer = styled(Link)`
  position: absolute;
  left: 20px;
  bottom: 12px;
  z-index: 3;
  display:flex;
  flex-direction: row;
  align-items: flex-end;

  
  @media (max-width:${breakpoints.sm}px) {
    left:10px;
  }
`

export const AuthorInfo = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 10px;
`

export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 80px;

  @media (max-width:${breakpoints.sm}px) {
    width: 72px;
    height: 72px;
    flex: 0 0 72px;
  }
`

export const Title = styled.div`
  color: ${props => props.theme === 'white' ? colours.darkergrey2 : colours.text};
  font-family: ${fonts.content.bold};
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3px;

  @media (max-width:${breakpoints.sm}px) {
    font-size: 18px;
    line-height: 18px;
  }
`

export const SubHead = styled.div`
  color: ${props => props.theme === 'white' ? colours.darkergrey2 : colours.text};
  font-family: ${fonts.content.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3px;

  @media (max-width:${breakpoints.sm}px) {
    font-size: 13px;
    line-height: 14px;
  }
`

export const Author = styled.div`
  color: ${props => props.theme === 'white' ? colours.darkergrey2 : colours.text};
  font-family: ${fonts.content.bold};
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  
  @media (max-width:${breakpoints.sm}px) {
    font-size: 11px;
    line-height: 12px;
  }
`

const closeButtonBackground = (theme) => {
  switch (theme) {
    case 'pink':
      return colours.pink_alpha70
    case 'dark':
      return colours.darker_alpha70
    case 'light':
      return colours.light_alpha70
    default:
      return colours.light_alpha70
  }
}

const closeButtonHoverBackground = (theme) => {
  switch (theme) {
    case 'pink':
      return colours.pink
    case 'dark':
      return colours.darker
    case 'light':
      return colours.light
    default:
      return colours.light
  }
}

export const StickWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: calc(100% - 50px);
  width:0;
  height:0;
  z-index: 1;
`

export const CloseButton = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props => closeButtonBackground(props.theme)};
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  &>img {
    display: block;
    width:16px;
  }

  
  &:hover {
    background-color: ${props => closeButtonHoverBackground(props.theme)}
  }

`

export const EditButton = styled(ButtonReset)`
  background-color: ${colours.white};
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  padding: 8px;
  margin-top: 4px;

  
  &:hover {
    border-color:${colours.pink};

    img.icon_active {
      opacity: 0;
    }
    img.icon_hover {
      opacity: 1;
    }
  }

`

export const EditButtonContainer = styled.div`
  position: absolute;
  z-index: 15;
  bottom: 10px;
  right: 10px;

  &>button {
    margin-left:4px;
    margin-right:4px;

    &:button-last-child {
      margin-right:0;
    }
  }
`

export const ContentArea = styled.div`
  width: 100%;
  max-width: 540px;
  display:flex;
  flex-direction:column;
  margin-left:auto;
  margin-right:auto;

  @media (max-width:${breakpoints.sm}px) {
    max-width:100%;
    padding-left:30px;
    padding-right:30px;
  }
`

export const Caption = styled.div`
  color: ${colours.darkergrey};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;

  @media (max-width:${breakpoints.sm}px) {
    margin-top:5px;
    margin-left:-20px;
    margin-right:-20px;
    font-size: 12px;
  }
`

export const Heading = styled.div`
  margin-top: 50px;

  @media (max-width:${breakpoints.sm}px) {
    margin-top:60px;
  }
`

export const ArticleHeading = styled(Link)`
  width: 100%;
  display:block;
  color: ${colours.black};
  font-family: ${fonts.content.bold};
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width:${breakpoints.sm}px) {
    font-size: 36px;
    line-height: 40px;
    margin-bottom:8px;
  }
`
export const ArticleSubHeading = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
  
  @media (max-width:${breakpoints.sm}px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 40px;
  }
`

export const AuthorSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  width: 100%;

  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border-top:1px solid ${colours.text};
  padding-top: 10px;

  @media (max-width:${breakpoints.sm}px) {
    
  }
`

export const AuthorInfoArticleDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .name {
    font-family: ${fonts.content.bold};
  }
`

export const AuthorCategories = styled.span`
  
`

export const Sharing = styled.div`
  margin-left: auto;
  
  .share-btn {
    margin-left: 6px;
    margin-right: 6px;
    width:16px;
    height:16px;
  }
`

export const Content = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;

  img {
    display: block;
    width: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    height: 400px;
  }

  a {
    color: ${colours.pink};
    text-decoration: none;
  }

  @media (max-width:${breakpoints.sm}px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TagsContainer = styled.div`
  margin-bottom: 50px;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  &>* {
    cursor:pointer;
    margin:5px;
    text-transform: capitalize;
    background-color: ${colours.darkerlight6};

    
    &:hover {
      background-color: ${colours.darkerlight7};
    }
  
  }
`

export const CreatedDate = styled.div`
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
  
  @media (max-width:${breakpoints.sm}px) {
    font-size: 12px;
    line-height: 14px;
  }
`

export const PublishedDate = styled.div`
  border-top: 1px solid ${colours.text};
  padding-top: 10px;
  color: ${colours.black};
  font-family: ${fonts.content.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 80px;

  @media (max-width:${breakpoints.sm}px) {
    font-size: 12px;
    line-height: 14px;
  }
`

export const OwnerProfile = styled.div`
  margin-bottom: 40px;
  .first {
    &>* {
      padding-left:0;
      img {
        width: 40px;
      }
    }
  }
  .info {
    .name>* {
      color: ${colours.black};
      font-family: ${fonts.content.bold};
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    .info-engage {
      .acctypes-location {
        .account-type-categories {
          color: ${colours.black};
          font-family: ${fonts.content.regular};
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }
        .location {
          color: ${colours.black};
          font-family: ${fonts.content.regular};
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }

    .engage {
      padding-bottom: 0;
      .engage-button:last-child {
        margin-bottom: 0;
      }
    }
  }
  .avatar {
    height: ${props => props.expanded ? 100 : 56}px;
    flex: 0 0 ${props => props.expanded ? 100 : 56}px;
  }

  @media (max-width:${breakpoints.sm}px) {
    margin-top: 60px;
    .info {
      margin-top: 10px;
      .name>* {
        font-size: 16px;
        line-height: 18px;
      }
      .info-engage {
        height: 100px;
        position: relative;
        .acctypes-location>* {
          font-size: 12px;
          line-height: 14px;
        }
      }
      .engage {
        position: absolute;
        right: -46px;
      }
    }

    .avatar {
      flex: 0 0 ${props => props.expanded ? 66 : 66}px;
    }
  }
`

export const ShareBottom = styled.div`
  padding-top: 63px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 80px;

  @media (max-width:${breakpoints.sm}px) {
    margin-bottom: 100px;
  }
`

export const OwnerActions = styled.div`
  position: absolute;
  z-index: 2;
  left: auto;
  right: 12px;
  bottom: 14px;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  transition-property: all;
  transition-duration: .2s;
  visibility: hidden;
  opacity: 0;

  @media (max-width:${breakpoints.sm}px) {
    visibility: ${props => props.expanded ? 'visible':'hidden'};
    display: ${props => props.expanded ? 'flex':'none'};
    flex-direction: row;
    left: unset;
    right: 0;
    width: 100%;
  }
`

export const OwnerButton = styled(ButtonReset)`
  background-color: ${colours.white};
  width: 36px;
  height: 36px;
  border: 1px solid transparent;
  padding: 8px;
  margin-top: 4px;

  img {
    width: 13px;
  }
  
  
  &:hover {
    border-color:${colours.pink};
  }


  @media (max-width:${breakpoints.sm}px) {
    width: 36px;
    height: 36px;
    margin-top: 0;
    margin-left: 6px;
    margin-right: 6px;
    border: none;
  }
`

export const CommentsContainer = styled.div`
  .comment-input-container {
    border-color: ${colours.lightgrey6};
    
    .comment-input-container__inner {

      .rich-editor-container {
        border: 1px solid ${colours.lightgrey6};
      }

      .submit-comment {
        border-color: ${colours.lightgrey6};
        color: ${colours.lightgrey6};
        
        
        &:hover {
          color: ${colours.pink};
        }
      
      }
  
      &:focus-within {
        .submit-comment {
          border-color: ${colours.pink};
          color: ${colours.pink};
        }
      }
    }
  }
  .comment-container {
    border-color: ${colours.lightgrey6};
  }
`