import React, { useEffect } from 'react'
import useTasks from 'hooks/tasks.hook'
import Toast from 'components/shared/toast'
import { TaskTypes } from '../../constants'
import useGetMyAccount from 'hooks/get-user-account.hook';
import { 
  resendChangeEmail,
  cancelChangeEmail,
} from 'apis/user.api'
import useToast from 'hooks/toast.hook';

const Tasks = () => {
  const getAccountSettings = useGetMyAccount()
  const { tasks, cancel } = useTasks()
  const { setToast } = useToast()

  const handleCancelTask = (id) => {
    cancel(id)
  }

  const tasksContent = () => {
    let out = []
    out = tasks.map((task) => {
      if (task.taskType === TaskTypes.ConfirmEmailChange) {
        return (<div key={`toast-item-${task._id}`}>
          <b>You changed your email!</b> &nbsp; Please check your inbox {getAccountSettings?.accountSettings?.email} and verify your email address. &nbsp;
          <span className="action" 
            onClick={async () => {
              await resendChangeEmail();
            }}
          >Resend email</span>. 
            Or &nbsp; 
          <span className="action" 
            onClick={() => {
              handleCancelTask(task._id)
            }}
          >cancel this change</span>.
        </div>)
      }

      return null
    })

    return out.length > 0 ? <Toast>{out}</Toast> : null
  }

  useEffect(() => {
    if (tasks.length > 0) {
      setToast({
        active: true
      })
    } else {
      setToast({
        active: false
      })
    }
  }, [tasks])

  return (
    <>
    {tasksContent()}
    </>
  )
}

export default Tasks
