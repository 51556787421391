import React from 'react'
import styled from 'styled-components'

import {
  icons
} from '../../../constants'

import HoverIconLabel from '../../shared/hover-icon-label'

import useFollowUser from 'hooks/follow-user.hook'
import useAuth from 'hooks/auth.hook'
import useFollowedByMe from 'hooks/followed-by-me.hook'

import {
  fonts,
  themes
} from 'styles/global.styles'

const Container = styled.div`
  .label {
    font-weight: 400;
    font-family: ${fonts.content.regular};
  }
`

export default function FollowWidget({
  userId,
  theme
}) {
  const { session } = useAuth()
  // const isFollowerResult = useGetIsFollower(userId)
  const { follow } = useFollowUser(userId)
  const { isFollowed } = useFollowedByMe()

  let followIconActive = null
  if (theme === themes.Flare) 
    followIconActive = "white-follow.svg"
  else
  if (theme === themes.Metro)
    followIconActive = "plus-18x12-grey.svg"
  else
    followIconActive = "follow-dark-grey.svg"

  return (
    <>{session ? <Container className="follow-widget">
    {(session?.profile?.id !== userId) ?
      <>
      {!isFollowed(userId) ? <HoverIconLabel 
        label="follow" 
        className="follow"
        active={icons[followIconActive]}
        hover={icons["follow-pink.svg"]}
        onClick={(evt) => {
          follow(true)
          evt.preventDefault();
        }}
      />
      : <HoverIconLabel 
        label="following"
        className="unfollow"
        active={icons["following-pink.svg"]}
        hover={icons["unfollow.svg"]}
        onClick={(evt) => {
          follow(false)
          evt.preventDefault();
        }}
      />}
      </> 
      : null}
    </Container> : null}
    </>
  )
}