import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours
} from '../../../styles/global.styles'
import {
  icons
} from '../../../constants'

import ExpandButton from './button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  max-height: ${props => props.showFull ? 'unset':'25vh'};
  overflow: ${props => props.showFull ? 'visible':'hidden'};
`

const Content = styled.div`  
  flex: 1 1 20vh;
  visibility: visible;
  max-height: ${props => props.showFull ? 'unset':'20vh'};
  overflow: ${props => props.showFull ? 'visible':'hidden'}; 
`

const Actions = styled.div`
  flex: 0 0 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 20px;
`

const Footer = styled.div`
  flex: 1 1 auto;
`

export default function Expandable({
  content,
  footer,
  children,
  onExpand,
  disableExpand = false,
  ...rest
}) {
  const [showFull, setShowFull] = useState(disableExpand)
  const contentRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    /*
    if (contentRef.current && containerRef.current) {

      const { height } = contentRef.current.getBoundingClientRect();

      if (contentRef.current.scrollHeight > height) {
        setOverflow(true)
      }
    }
    */
  }, [])

  return (
    <Container 
      ref={containerRef}
      showFull={showFull}
      {...rest}
    >
      <Content 
        showFull={showFull}
        ref={contentRef}
      >
        {children}
      </Content>
      {!disableExpand ? <Actions>
        <Footer>{footer}</Footer>
        <ExpandButton
          inverted={showFull}
          onClick={(e) => {
            setShowFull(!showFull)
            onExpand && onExpand()
            e.preventDefault()
            e.stopPropagation()
          }}  
        />
      </Actions> : null}
    </Container>
  )
}