import React, { useState } from 'react'

export const NotifyAlertContext = React.createContext(null);

export const NotifyAlertProvider = ({ children }) => {
  const [notifyAlert, setNotifyAlert] = useState(null);
  return (
    <NotifyAlertContext.Provider value={[notifyAlert, setNotifyAlert]}>
      {children}
    </NotifyAlertContext.Provider>
  );
}