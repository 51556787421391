import styled from 'styled-components';
import {
  fonts,
  breakpoints,
  colours
} from '../../styles/global.styles'

export const Center = styled.div`
  min-height: inherit;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left: -19px;
  margin-right: -19px;
  position: relative;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    margin-left:0;
    margin-right:0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
  position: relative;
  z-index: 5;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {  
    max-width:720px;
    padding-top: 0;
  }
`;

export const Title = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.title.xbold};
  font-weight: 800;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  line-height: 15px;
  margin-top: 8px;
  position: static;
  z-index:1;
  top: 85px;

  &:before {
    content: '';
    display: inline-block;
    justify-self: flex-start;
    width: 236px;
    border-bottom: 1px solid ${colours.pink};
    margin-right: 10px;
    flex: 1 1 auto;
  }
  &:after {
    content: '';
    display: inline-block;
    justify-self: flex-end;
    width: calc(50% - 10px);
    border-bottom: 1px solid ${colours.pink};
    margin-left: 10px;
    flex: 1 1 auto;
  }
  &>span {
    width: fit-content;
    flex: 0 0 auto;
  }

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0em;
    justify-content: center;
    margin-top: 48px;
    padding-top:10px;
    padding-right:0px;
    max-width: 720px;
    margin-top: 0px;
    position: static;

    &:before {
      width: 236px;
      margin-right: 10px;
      flex: 1 1 auto;
    }
    &:after {
      width: 236px;
      margin-left: 10px;
      flex: 1 1 auto;
    }
  }
`;

export const List = styled.div`
  display:flex;
  flex-direction: column;
  width:100%;
  margin-top: 12px;
  padding-left:10px;
  padding-right:10px;
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    padding-left:0;
    padding-right:0;
    width: 360px;
  }
`;

export const ListItem = styled.div`
  padding: 24px 20px;
  border-bottom: 1px solid ${colours.darkergrey};
  width: 100%;

  &>div {
    padding:0px;
  }

  &:last-child {
    border-bottom: none;
  }


  &:hover {
    background-color: ${colours.pink_alpha50};
  }

`;