import styled from 'styled-components'
import {
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'
import {
  Link
} from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: ${colours.lightgrey8};
  font-size: 11px;
  font-family: ${fonts.content.regular};
  line-height: 16px;
  padding-bottom: 20px;
  
  .break {
    display:none;
  }

  @media (max-width:${breakpoints.sm}px) {
    display:block !important;

    .break {
      display:block !important;
      width:100%;
    }

    a {
      display: inline-block;
    }
  }
`

export const HLink = styled(Link)`
  color: ${colours.lightgrey8};
  font-size: 11px;
  font-family: ${fonts.content.regular};
  line-height: 16px;
  padding-left:5px;
  padding-right: 5px;
  text-decoration: underline;
`
