import React from 'react'
import styled from 'styled-components'
import {
  fonts,
  colours
} from '../../styles/global.styles'
import {
  ReviewStatus,
  RejectReason,
  RejectReasonLinks,
  Links
} from '../../constants'

const Container = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2px;
  color: ${colours.pink};
  margin-bottom: 15px;

  b {
    font-family: ${fonts.content.bold};
    font-weight: 700;
  }

  a {
    text-decoration:underline;
  }
`

export default function TileMessage({
  contentType = 'artwork',
  reviewStatus,
  rejectedReason
}) {
  const rejectReadLink = RejectReasonLinks[rejectedReason]
  return (
    <Container>
      {reviewStatus === ReviewStatus.Pending ? <>We’ll confirm this shortly. Meanwhile, do continue to upload your awesome works!</> : null }
      {reviewStatus === ReviewStatus.Rejected && rejectedReason === RejectReason.Legal ? <><b>Oops!</b><br />This {contentType} is disapproved for <a href={rejectReadLink} rel="noreferrer" target="_blank">legal reasons</a>. Please kindly refer to our <a href={Links.ProtectingRights} rel="noreferrer" target="_blank">Terms and Conditions</a>. Thank you.</> : null }
      {reviewStatus === ReviewStatus.Rejected && rejectedReason === RejectReason.Explicit ? <><b>Oops!</b><br />This {contentType} is disapproved for <a href={rejectReadLink} rel="noreferrer" target="_blank">explicit content</a>. Please kindly refer to our <a href={Links.MisUse} rel="noreferrer" target="_blank">Terms and Conditions</a>. Thank you.</> : null }
      {reviewStatus === ReviewStatus.Rejected && rejectedReason === RejectReason.Irrelevant ? <><b>Oops!</b><br />This {contentType} is disapproved for <a href={rejectReadLink} rel="noreferrer" target="_blank">irrelevant content</a>. Kindly upload {contentType} that are fashion related, within your field of expertise, and with proper descriptions. Thank you.</> : null }
      {reviewStatus === ReviewStatus.Rejected && rejectedReason === RejectReason.Unprofessional ? <><b>Oops!</b><br />This {contentType} is disapproved for <a href={rejectReadLink} rel="noreferrer" target="_blank">unprofessional content</a>. Thank you.</> : null }
    </Container>
  )
}