import { useMutation, useQueryClient } from '@tanstack/react-query'
import { startConversation } from 'apis/conversation.api'
import useAuth from './auth.hook'

export default function useStartConversation() {
  const { session } = useAuth()
  const queryClient = useQueryClient()
  const {
    mutate,
    isLoading,
    isSuccess,
    data,
  } = useMutation({
    mutationFn: data => startConversation(data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: [`conversations-${session?.profile?.id}`] })
    },
  })

  return {
    startConversation: mutate,
    success: isSuccess,
    loading: isLoading,
    conversationId: data
  }
}
