import React from 'react'
import { useParams } from 'react-router-dom'
import { getInvite } from 'apis/invite.api'
import { useQuery } from '@tanstack/react-query'

const useInvite = () => {
  const { code } = useParams()
  const { isLoading, error, isSuccess, data, isFetching } = useQuery(
    ['invite', code], 
    () => getInvite(code),
    {
      enabled: code !== undefined
    }
  )

  return {
    isLoading,
    isSuccess,
    data: isSuccess ? data?.data : null
  }
}

export default useInvite