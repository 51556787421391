import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addComment } from 'apis/comments.api'
import { ActivityActions } from '../constants'

export default function useSendComment(contentType, id) {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: ({body}) => {
      return addComment(contentType, id, body)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`comments-${contentType}`, id]
      })
      
      if (contentType === 'artworks') {
        queryClient.invalidateQueries({
          queryKey: ['artwork-comment-thread', variables.id]
        })
        queryClient.invalidateQueries({
          queryKey: ['my-artworks', variables.id]
        })
      } else 
      if (contentType === 'articles') {
        queryClient.invalidateQueries({
          queryKey: ['articles-comment-thread', variables.id]
        })
        queryClient.invalidateQueries({
          queryKey: ['my-articles', variables.id]
        })
      }
      queryClient.invalidateQueries({queryKey: [`activities-${contentType}-${id}-${contentType === 'artworks' ? ActivityActions.CommentedArtwork : ActivityActions.CommentedArticle}`]})
    },
  });
  

  return {
    comment: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}