import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  PASTE_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages
} from "@lexical/code";
import {
  icons
} from '../../../constants';

import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";
import { INSERT_VIDEO_COMMAND } from "./EmbedVideoPlugin";

import FloatingLinkEditor, { getSelectedNode } from "./FloatingLinkEditor";
import { getVideoIdPlatformFromUrl } from '../nodes/VideoNode'

import useUploadMakeVariant from "hooks/upload-make-variant.hook";
import useToastPop from "hooks/toast-pop.hook";
import ProgressLoader from "components/shared/progress-loader/progress-loader";

const INLINE_STYLES = [
  { label:'Bold', icon: icons['editor-toolbar-icon-bold.svg'], style: 'BOLD'},
  { label:'Italic', icon: icons['editor-toolbar-icon-italic.svg'], style: 'ITALIC'},
  { label:'Underline', icon: icons['editor-toolbar-icon-underline.svg'], style: 'UNDERLINE'},
];

const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "code",
  "h1",
  "h2",
  "ul",
  "ol"
]);

const blockTypeToBlockName = {
  code: "Code Block",
  h1: "Large Heading",
  h2: "Small Heading",
  h3: "Heading",
  h4: "Heading",
  h5: "Heading",
  ol: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
  ul: "Bulleted List"
};


function FillImageURL() {
  const srcfile = prompt("Enter the URL of the image:", "");

  return srcfile;
}

function FillVideoURL() {
  const url = prompt("Enter the URL of the YouTube or Vimeo video:", "");

  if (url !== null) {
    return getVideoIdPlatformFromUrl(url) 
  }

  return null;
}

function Divider() {
  return <div className="divider" />;
}


function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}


function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown
}) {
  const dropDownRef = useRef(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <div className="dropdown" ref={dropDownRef}>
      <button className="item" onClick={formatParagraph}>
        <span className="icon paragraph" />
        <span className="text">Normal</span>
        {blockType === "paragraph" && <span className="active" />}
      </button>
      <button className="item" onClick={formatLargeHeading}>
        <span className="icon large-heading" />
        <span className="text">Large Heading</span>
        {blockType === "h1" && <span className="active" />}
      </button>
      <button className="item" onClick={formatSmallHeading}>
        <span className="icon small-heading" />
        <span className="text">Small Heading</span>
        {blockType === "h2" && <span className="active" />}
      </button>
      <button className="item" onClick={formatBulletList}>
        <span className="icon bullet-list" />
        <span className="text">Bullet List</span>
        {blockType === "ul" && <span className="active" />}
      </button>
      <button className="item" onClick={formatNumberedList}>
        <span className="icon numbered-list" />
        <span className="text">Numbered List</span>
        {blockType === "ol" && <span className="active" />}
      </button>
      <button className="item" onClick={formatQuote}>
        <span className="icon quote" />
        <span className="text">Quote</span>
        {blockType === "quote" && <span className="active" />}
      </button>
      <button className="item" onClick={formatCode}>
        <span className="icon code" />
        <span className="text">Code Block</span>
        {blockType === "code" && <span className="active" />}
      </button>
    </div>
  );
}

const ToolbarConfig = {
  UndoRedo: false,
  FormattingDropdown: false,
  StrikeThrough: false,
  Code: false,
  Link: true,
  Alignment: false
}

export default function ToolbarPlugin(props) {
  const { options: { imageUpload, video, link } } = props

  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(
    false
  );
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const [isLinkEditMode, setLinkEditMode] = useState(false);

  const { 
    uploadFiles,
    isLoading: uploadIsLoading,
    isSuccess: uploadIsSuccess,
    isError: uploadIsError,
    result: uploadResult,
    error: uploadError
  } = useUploadMakeVariant()

  const { show, hide } = useToastPop()

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links state to know if i am in a link
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      // console.log(parent, node)

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
      
      // const hasSelectedText = selection.anchor.offset !== selection.focus.offset
      // setIsLink(hasSelectedText)
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        PASTE_COMMAND,
        (event) => {
          if (event.clipboardData) {
            // const pastedText = event.clipboardData.getData('text/html');
            // const cleanedData = pastedText.replace(/<[^>]*>?/gm, '');
            
            // event.clipboardData.setData('text/html', cleanedData)
            // console.log(cleanedData, event.clipboardData.getData('text/html'));
          }
          return false
        },
        LowPriority,
      )
      
    );
  }, [editor, updateToolbar]);
  
  const insertLink = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
    })
    setLinkEditMode(true)
    
    if (!isLink) {
      // set the initial link so the editor knows it's a link
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, ""); 
    }
    
  }, [editor, isLink]);
  
  const insertImageSets = (payload) => {
    const { file, variants } = payload

    const variantsMap = {}
    variants.forEach(v => {
      variantsMap[v.format] = v
    })
    // todo - might be better to switch to a responsive picture tag
    /*
    const srcset = [
      `${process.env.REACT_APP_UPLOADS_URL}/${variantsMap['small'].filename}`,
      `${process.env.REACT_APP_UPLOADS_URL}/${variantsMap['medium'].filename} 1.5x`,
      `${process.env.REACT_APP_UPLOADS_URL}/${variantsMap['large'].filename} 2x`,
    ].join(', ')
    // const sizes = null
    */

    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      altText: file.name,
      src: `${process.env.REACT_APP_UPLOADS_URL}/${variantsMap['medium'].filename}`
    });
  };

  const insertVideoUrl = (payload) => {
    editor.dispatchCommand(INSERT_VIDEO_COMMAND, payload);
  };

  useEffect(() => {
    if (uploadIsSuccess) {
      if (uploadResult.length === 1) {
        insertImageSets(uploadResult[0])
      }
    }
  }, [uploadIsSuccess, uploadResult])

  useEffect(() => {
    if (uploadIsLoading) {
      show(<ProgressLoader/>, {
        zIndex: 3,
        positionedTop: 0,
        autoHide: false,
        elementId: 'fullscreen-editor'
      })
    } else {
      hide()
    }
  }, [uploadIsLoading])


  return (
    <div className="toolbar" ref={toolbarRef}>
      {ToolbarConfig.UndoRedo ? 
      <>
      <button
        type="button"
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        type="button"
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      </> : null}
      {ToolbarConfig.FormattingDropdown ?
      <>
      {supportedBlockTypes.has(blockType) && (
        <>
          <button
            type="button"
            className="toolbar-item block-controls"
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            aria-label="Formatting Options"
          >
            <span className={"icon block-type " + blockType} />
            <span className="text">{blockTypeToBlockName[blockType]}</span>
            <i className="chevron-down" />
          </button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body
            )}
          <Divider />
        </>
      )}
      </> : null}
      <>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
          }}
          className={"toolbar-item spaced " + (isBold ? "active" : "")}
          aria-label="Format Bold"
        >
          <img src={INLINE_STYLES[0].icon} />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
          }}
          className={"toolbar-item spaced " + (isItalic ? "active" : "")}
          aria-label="Format Italics"
        >
          <img src={INLINE_STYLES[1].icon} />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
          }}
          className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
          aria-label="Format Underline"
        >
          <img src={INLINE_STYLES[2].icon} />
        </button>
        {ToolbarConfig.StrikeThrough ?
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
          }}
          className={
            "toolbar-item spaced " + (isStrikethrough ? "active" : "")
          }
          aria-label="Format Strikethrough"
        >
          <i className="format strikethrough" />
        </button> : null}
        {ToolbarConfig.Code ?
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
          }}
          className={"toolbar-item spaced " + (isCode ? "active" : "")}
          aria-label="Insert Code"
        >
          <i className="format code" />
        </button> : null}
        {link ?
        <>
        <button
          type="button"
          onClick={insertLink}
          className={"toolbar-item spaced " + (isLink ? "active" : "")}
          aria-label="Insert Link"
        >
          <img src={icons['link.svg']} />
        </button>
        {isLinkEditMode && createPortal(<FloatingLinkEditor editor={editor} onDone={() => { setLinkEditMode(false) }} />, document.body)}
        <Divider /> 
        </>: null}

        {imageUpload ? <>
        <button
          type="button"
          style={{position:'relative', cursor:'pointer'}}
          className={"toolbar-item image-upload spaced " + (isLink ? "active" : "")}
          aria-label="Insert Image "
        >
          <input
            style={{style:'pointer'}}
            max={1}
            accept="image/jpg, image/gif, image/png, image/bmp, image/jpeg"
            onChange={(evt) => {
              uploadFiles(evt.target.files)
            }}
            type="file" />
          <img src={icons['image.svg']} />
        </button>
        <Divider /> 
        </> : null}
        
        {video ? <>
        <button
          type="button"
          onClick={() => {
            const videoPayload = FillVideoURL()
            if (videoPayload) {
              insertVideoUrl(videoPayload)
            }
          }}
          className={"toolbar-item spaced " + (isLink ? "active" : "")}
          aria-label="Insert YouTube or Vimeo link"
        >
          <img src={icons['video.svg']} />
        </button>
        <Divider /> 
        </> : null}

        {ToolbarConfig.Alignment ?
        <>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
          }}
          className="toolbar-item spaced"
          aria-label="Left Align"
        >
          <i className="format left-align" />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
          }}
          className="toolbar-item spaced"
          aria-label="Center Align"
        >
          <i className="format center-align" />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
          }}
          className="toolbar-item spaced"
          aria-label="Right Align"
        >
          <i className="format right-align" />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
          }}
          className="toolbar-item"
          aria-label="Justify Align"
        >
          <i className="format justify-align" />
        </button>{" "}
        </> : null}
      </>
    </div>
  );
}
