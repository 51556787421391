import React, { useState, useEffect } from 'react'
import useGetMyProfile from '../hooks/get-user-profile.hook';
import {
  themes
} from '../styles/global.styles'

export const UserThemeContext = React.createContext(null);

export const UserThemeProvider = ({ children }) => {
  const { profile, success } = useGetMyProfile()
  const [userTheme, setUserTheme] = useState(themes.Flare);

  useEffect(() => {
    if (success && profile) {
      setUserTheme(profile.colourTheme)
    }
  }, [success, profile])

  return (
    <UserThemeContext.Provider value={[userTheme, setUserTheme]}>
      {children}
    </UserThemeContext.Provider>
  );
}