import { useMutation, useQueryClient } from '@tanstack/react-query';
import { followUser, unFollowUser } from '../apis/follow.api';

export default function useFollowUser(userId) {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (isFollow) => {
      console.log('follow', isFollow, userId);
      if (isFollow) {
        return followUser(userId)
      } else {
        return unFollowUser(userId)
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({queryKey: ['followed'], data: userId})
      queryClient.invalidateQueries({queryKey: ['followed-by-me']})
    },
  });

  return {
    follow: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}