import { useContext } from "react";
import { AuthContext } from "../contexts/auth.context";

const useAuth = () => {
  const [session, setSession, fetching] = useContext(AuthContext);
  
  return {
    session,
    fetching,
    setSession
  }
}

export default useAuth;