import React from 'react';
import SocialShareButton from './social-share-button'

export default function XPlatform({
  url,
  ...rest
}) {
  return (
    <SocialShareButton
      socialType={'x'}
      onClick={() => {
        window.open(`https://twitter.com/intent/tweet?url=${url ?? window.location.href}`)
      }}
      {...rest}
    />
  )
}