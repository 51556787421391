import React from 'react'
import styled from 'styled-components'

import HoverIcon from './hover-icon'

import useFlag from '../../hooks/flag.hook'
import useModal from 'hooks/modal.hook'
import useActionAlert from 'hooks/action-alert.hook'

import useAuth from 'hooks/auth.hook'

import {
  FlagReason,
  icons
} from '../../constants'

const Container = styled.div`
`

export default function Flag({
  id,
  content,
  imageUrl,
  title
}) {
  const { fetching, session } = useAuth()
  const { flagContent, isLoading, isSuccess } = useFlag(content, id)
  const { setModal } = useModal()
  const { setActionAlert } = useActionAlert();

  // hook to open the flag artowrk modal form
 const handleClick = () => {
    if (content === 'artwork')
      setModal({
        name: 'flag-artwork',
        payload: {
          id, 
          content,
          imageUrl,
          title
        }
      })
    else
    if (content === 'comment') {
      setActionAlert({
        icon: icons['flag-confirm-icon.svg'],
        active: true,
        theme: 'dark',
        title: 'Flag this as spam or offensive content?',
        body: `We'll review this privately to help make <b>itch</b> a creative<br/> yet respectful community for all`,
        yesLabel: 'Yes',
        noLabel: 'No',
        onYes: () => {
          setActionAlert({
            active: false
          })
          flagContent({
            reason: FlagReason.OFFENSIVE_IRRELEVANT,
            comments: ''
          })
          setModal(null)
        }
      })
    }
  }
  
  return (
    <>
    {!fetching && session ? 
    <Container onClick={handleClick}>
      <HoverIcon
        active={icons['flag-grey.svg']}
        hover={icons['flag-pink.svg']}
      />
    </Container> : null}
    </>
  )
}