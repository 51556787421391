import React, { useEffect, useRef ,forwardRef } from 'react';
import styled from 'styled-components';
import {
  breakpoints
} from '../../styles/global.styles';

const StickyContainer = styled.div`
  position: sticky;
  top: 45px;
  z-index: 3;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    top: 65px;
  }
`;

const OutOfViewPort = styled.div`
  visibility: hidden;
  position: absolute;
  height: 46px;
  top: -46px;
  left:0;
  right:0;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    height: 66px;
    top: -66px;
  }
`;

export default forwardRef(function Sticky(
  props,
  ref,
  ...rest
) {
  const elem = useRef(null);
  
  const observer = new IntersectionObserver( 
    ([e]) => {
      if (e.intersectionRatio < 1) {
        props.onStick();
      } else {
        props.onUnStick();
      }
    },
    { 

      threshold: [0.99] }
  );
  
  useEffect(() => {
    observer.observe(elem.current)
  }, [])

  return (
    <StickyContainer ref={ref} {...rest}>
      <OutOfViewPort ref={elem}/>
      {props.children}
    </StickyContainer>
  )
});