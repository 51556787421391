import React from 'react'
import { 
  useRouteMatch,
  Switch,
  Redirect,
  Route,
  useLocation
} from 'react-router-dom'
import Gallery from '../gallery';
import Articles from '../articles';
import Conversations from 'pages/conversations';
import ItchBook from 'pages/itchbook';
import Feed from 'pages/feed';

export default function ProfileRoutes({
  userId
}) {
  const { path, url } = useRouteMatch()
  const location = useLocation()
  const { pathname } = location
  const isOwner = pathname.indexOf('/my-profile') === 0;

  return (
    <Switch location={location}>
      <Route path={`${path}`} exact>
        {isOwner ? <Feed/> : <Gallery userId={userId} /> }
      </Route>
      {isOwner ? <Route path={`${path}/conversations`}>
        <Conversations/>
      </Route> : null}
      {isOwner ?  <Route path={`${path}/itchbook`}>
        <ItchBook userId={userId} />
      </Route> : null}
      <Route path={`${path}/gallery`}>
        <Gallery userId={userId} />
      </Route>
      <Route path={`${path}/articles`}>
        <Articles userId={userId} />
      </Route>
    </Switch>
  )
}