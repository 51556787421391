import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getItchBookArtworkIds } from '../apis/itchbook.api';
import useAuth from 'hooks/auth.hook'

export default function useGetitchbookArtworkIds() {
  const { fetching, session } = useAuth()
  const { 
    isLoading,
    isSuccess,
    error,
    data,
    refetch
  } = useQuery({
    queryKey: [`user-${session?.profile?.id ?? 'user'}-itchbook-artwork-ids`],
    queryFn: getItchBookArtworkIds,
    enabled: false
  })

  useEffect(() => {
    if (!fetching && session) {
      refetch()
    }
  }, [fetching, session])
  
  return {
    success: isSuccess,
    loading: isLoading,
    error,
    artworksIds: data
  }
}