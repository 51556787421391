import React, { useEffect, useMemo } from 'react'
import { 
  Link, 
  useRouteMatch
}  from 'react-router-dom'

import {
  Container,
  MenuLink,
  MenuLinkNotPage,
  Counter
} from './menu.styles'
import Search from './search'

import { usePsstDrawer } from '../psst/psst.drawer.context'
import useGetActivityCount from 'hooks/get-activity-count.hook'
import usePsst from 'hooks/psst.hook'
import useFeatures from 'hooks/features.hook'
import { FeatureKeys } from 'contexts/features.context'

export default function Menu() {
  const featureEnabledPing2 = useFeatures(FeatureKeys.PING2)
  
  const {opened, close, open} = usePsstDrawer()
  const { ready, readAt, hasRead } = usePsst()
  const { success, activityCount, getUpdate} = useGetActivityCount(readAt)

  const activityUnreadCount = useMemo(() => {
    if (success) {
      return activityCount > 99 ? '99+' : activityCount
    } else {
      return null
    }
  }, [success, activityCount])

  useEffect(() => {
    if (ready && readAt && hasRead) {
      getUpdate(readAt) // refresh the activity count
    }
  }, [ready, hasRead, readAt])

  return (
    <Container>
      <MenuLink className="manu-link" to="/zine">zine</MenuLink>
      <MenuLinkNotPage className="manu-link" selected={opened} onClick={() => {
        if (opened) {
          close()
        } else {
          open()
        }
      }}>
        {activityUnreadCount ? <Counter>{activityUnreadCount}</Counter> : null}
        psst
        {opened ? <div className="small-triangle show"></div> : null}
      </MenuLinkNotPage>
      {featureEnabledPing2 ? 
      <MenuLinkNotPage className="menu-link" onClick={() => {

      }}>ping2</MenuLinkNotPage>
      : null}
      <Search/>
    </Container>
  )
}