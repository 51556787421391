import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  fonts,
  breakpoints,
  colours
} from 'styles/global.styles'

export const Container = styled.div`
  display:flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: auto;
  flex: 1 1 auto;
`

export const MenuLink = styled(Link)`
  font-size: 15px;
  line-height: 18px;
  font-family: ${fonts.title.xbold};
  font-weight: 400;
  color: ${props => props.selected ? colours.white : (props.theme.color === 'pink' ? colours.light : colours.text)};
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 12px;
  position: relative;

  .small-triangle {
    border-top-color: ${props => props.theme.color === 'dark' ? colours.dark : colours.pink};
    bottom: -10px;
    z-index: 1100;
  }

  
  &:hover {
    color: ${colours.white};
  }


  @media (max-width: ${breakpoints.sm}px) {
    .small-triangle {
      bottom:-5px;
    }
  }
`

export const MenuLinkNotPage = styled.div`
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  font-family: ${fonts.title.xbold};
  font-weight: 400;
  color: ${props => props.selected ? colours.white : (props.theme.color === 'pink' ? colours.light : colours.text)};
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 12px;
  position: relative;

  .small-triangle {
    border-top-color: ${props => props.theme.color === 'dark' ? colours.dark : colours.pink};
    bottom: -10px;
    z-index: 1100;
  }

  
  &:hover {
    color: ${colours.white};
  }


  @media (max-width: ${breakpoints.sm}px) {
    .small-triangle {
      bottom:-5px;
    }
  }
`

export const Counter = styled.div`
  font-size: 13px;
  font-family: ${fonts.content.bold};
  line-height: 18px;
  text-align: left;
  color: ${props => props.theme.color === 'pink' ? colours.white : colours.text}; 
  position: absolute;
  top: -10px;
  right: 12px;
`
