import React, { useState, useEffect } from 'react'
import Modal from '../components/shared/modal';
import CenteredFormLayout from '../components/shared/modal-form/centered-form.layout';

import {
  EditContainer,
  Error
} from '../components/shared/modal-form/modal-form.styles'
import {
  FormStyled
} from './edit-article.styles'
import {
  icons, ReviewStatus
} from '../constants'

import ArticleDetails from '../components/article-form/article-details.section';

import { useForm, Controller } from 'react-hook-form'

import useActionAlert from '../hooks/action-alert.hook';

// hooks to api, create, update, get
import useCreateArticle from '../hooks/create-article.hook'
import useUpdateMyArticle from '../hooks/update-article.hook'
import useGetArticle from '../hooks/get-my-one-article.hook';

import useModal from '../hooks/modal.hook'
import useAuth from '../hooks/auth.hook'
import {
  stripHtml,
  preventEnterKeySubmission
} from '../utils/utils'

export default function EditArticle({
  id
}) {
  const { session } = useAuth()
  const { setModal } = useModal()
  const [ submitting, setSubmitting ] = useState(false)
  const { setActionAlert, closeAlert } = useActionAlert()

  // init hooks 
  const { create, isSuccess, isLoading } = useCreateArticle(session?.profile?.id)
  const { 
    update, 
    isSuccess: isSuccessUpdate,
    isLoading: isLoadingUpdate
  } = useUpdateMyArticle()
  const { 
    article, 
    success: getArticleSuccess, 
    fetching: getArticleFetching 
  } = useGetArticle(id)

  
  
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    trigger,
    formState: {
      errors,
      isDirty
    }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      isDraft: false
    }
  })

  const isEditing = (id !== undefined);

  const onSubmit = (data) => {
    if (stripHtml(data.content).trim().length === 0) {
      setError('content', { type: 'required', message: 'Article is required'})
    } else {
      if (!isEditing) {
        create(data)
        setSubmitting(true)
      } else {
        update({
          id, data
        })
        setModal(null)
      }
    }
  }
  
  const isAnyRequiredFail = Object.keys(errors).reduce((acc, curr) => {
    return acc + (errors[curr].type === 'required' ? 1 : 0)
  }, 0) > 0

  useEffect(() => {
    if (getArticleSuccess) {
      window.gtag('event', 'modal_view', {
        page_title: 'edit-article',
        article_id: id,
        article_title:  article.title
      });

      reset({
        ...article
      })
    }
  }, [reset, getArticleSuccess, id])

  useEffect(() => {
    if (!id) {
      window.gtag('event', 'modal_view', {
        page_title: 'post-article',
        action: 'open-form'
      });
    }
  }, [])

  const disabled = ReviewStatus.Rejected === getValues('reviewStatus')

  const coverImage = watch('coverImage');

  function onClose(force){
    if (!disabled) {
        if (force) {
          setModal(null)
        } else
        if (isDirty && !isSuccess && !isEditing) {
          setActionAlert({
              noClose: true,
              theme: 'dark',
              icon: icons['alert.svg'],
              active: true,
              title: 'Ooops',
              body: `You haven't completed your submission<br/><br/>
              <b>Close anyway?</b>`,
              yesLabel: 'Yes',
              noLabel: 'No',
              onYes: () => {
              closeAlert()
              setModal(null)
              }
          })
        } else {
          setModal(null)
        }
    } else {
        setModal(null)
    }
  }

  return (
    <Modal
      isSubmitting={getValues('isDraft') ? false: submitting}
      onSubmitted={() => {
        setModal(null)
      }}
      submitting={{
        title: `Thank you, we've got it!`,
        body: `Let us proceed to review and confirm your article`
      }}
      isDirty={isDirty}
      onClose={onClose}
    >
      <EditContainer className="edit-container">
        <CenteredFormLayout
          justifyStart={true}
          className="centered-form-layout"
          disabled={disabled}
          icon={icons['article-pink.svg']}
          title="publish an article"
        >
          {isAnyRequiredFail ? <Error>Oops! The highlighted fields are required</Error> : null} 
          <FormStyled onKeyPress={preventEnterKeySubmission} onSubmit={handleSubmit(onSubmit)}>
            <ArticleDetails 
              id={id}
              isEditing={isEditing}
              register={register} 
              Controller={Controller}
              control={control}
              errors={errors}
              hasCoverImage={coverImage !== undefined && coverImage !== null}
              isDirty={isDirty}
              disabled={disabled}
              isSubmitting={isLoading}
              getValues={getValues}
              onReset={() => {
                if (id) {
                  reset({
                    ...article
                  })
                } else {
                  reset()
                }
              }}
              onSave={async () => {
                setValue('isDraft', true)
                const result = await trigger()
              }}
              onSubmit={async () => {
                // send for approval
                setValue('isDraft', false)
                const result = await trigger()
              }}
              onDelete={() => {
                setModal(null)
              }}
            />
          </FormStyled>
        </CenteredFormLayout>
      </EditContainer>
    </Modal>
  )
}