import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import TileOwnerAccountType from './tile-owner-account-type'
import TileOwnerName from './tile-owner-name'
import UserAvatar from '../../avatar/user-avatar'
import OwnerAccountTypes from './tile-owner-types'

import Flag from '../../shared/flag'
import FollowWidget from './follow-widget'
import ChatWidget from './chat-widget'

import countries from 'i18n-iso-countries';

import {
  fonts,
  colours,
  breakpoints
} from '../../../styles/global.styles'

import {
  icons,
  AccountTypes
} from '../../../constants'

import {
  deriveUserName
} from '../../../utils/helpers'

import {
  imageUrl
} from 'utils/utils'
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'

const Avatar = styled.div`
  flex: 0 0 ${props => props.expanded ? (props.isOwner ? 100 : 123):44}px;
  height: 100%;
  display:flex;
  flex-direction:row;
  align-items:flex-end;
  justify-content:flex-end;
  
  .engage {
    flex: 0 0 25px;
    width: 25px;
    padding-right: 5px;

    .engage-button:first-child {
      margin-bottom:5px;
    }
  }

  @media (max-width:${breakpoints.sm}px) {
    flex: 0 0 ${props => props.expanded ? 66:44}px;
    width: ${props => props.expanded ? 66:44}px;
    height: ${props => props.expanded ? 66:44}px;
    
    .engage { 
      margin-bottom: 4px;

      .engage-button:first-child {
        margin-bottom:2px;
      }

      .engage-button {
        margin-bottom: 0px;
        width:18px;

        .follow-widget {
          
          &>span {
            &>.label {
              visibility: visible !important;
              padding: 0;
              color: ${colours.lightgrey2};
              text-align: center;
              margin-right:0;
            }

          
            &:hover {
              &>.icon {
                img:first-child {
                  opacity: 1;
                  display:block;
                }
                img:last-child {
                  opacity: 0;
                  display:none;
                }
              }             
            }
          

            &.unfollow {
              &>.label {
                display:none;
              }
            }
          }
        }
      } 
    }
  }

`

const AvatarWraapper = styled.div`
  flex: 0 0 ${props => props.expanded ? 98:44}px;
  width: ${props => props.expanded ? 98:44}px;
  height: ${props => props.expanded ? 98:44}px;

  @media (max-width:${breakpoints.sm}px) {
    flex: 0 0 ${props => props.expanded ? 66:44}px;
    width: ${props => props.expanded ? 66:44}px;
    height: ${props => props.expanded ? 66:44}px;
    overflow: hidden;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`

const First = styled.div`
  display:flex;
  flex-direction: column;
  flex: 0 0 ${props => props.expanded ? 48 : 33}px;
  height: 100%;

  @media (max-width:${breakpoints.sm}px) {
    flex: 0 0 ${props => props.expanded ? 44 : 33}px;
  }
`

const Info = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: ${props => props.expanded ? 'flex-start':'center'};
  flex: 1 1 auto;
  height: 100%;
  padding-left: 5px;
  padding-top: ${props => props.expanded ? 24:8}px;

  div.info-engage {
    display:flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 100%;

    div.acctypes-location {
      display:flex;
      flex-direction: column;
      flex: 1 1 auto;

      .account-type-categories {
        font-size: 12px;
        line-height: 13px;
        color: ${colours.lightgrey6};
      }
    }

    .engage {
      display:flex;
      flex-direction: column;
      justify-content: flex-end;
      flex:0 0 40px;
      height: 100%;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 9px;

      .engage-button {
        margin-bottom: 6px;
        width:18px;
      }
    }
  
  }

  @media (max-width:${breakpoints.sm}px) {
    padding-top: ${props => props.expanded ? 6:6}px;
    padding-right: 8px;

    div.info-engage {
      position: relative;
      
      div.acctypes-location {
        .account-type-categories {
          font-size: 12px;
        }
      }
    }

    .name {
      padding-top:4px;
      &>a {
        font-size: 13px;
      }
    }
  }
`

const Location = styled(Link)`
  color: ${colours.lightgrey6};
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  &>span {
    margin-right: 4px;
    position:relative;
    &>img {
      margin:0;
      &:first-child {
        visibility:visible;
      }
      &:last-child {
        top:0;
        left:0;
        position:absolute;
        visibility:hidden;
      }
    }
  }

  
  &:hover {
    color: ${colours.pink};
    &>span {
      &>img {
        &:first-child {
          visibility:hidden;
        }
        &:last-child {
          visibility:visible;
        }
      }
    }
  }

`

const FlagContainer = styled.div`
  width: 9px; 
  margin-top: auto;
  margin-left: 10px;
  padding-bottom: 15px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-bottom:6px;
  }
`

export default function TileOwnerPanel({
  user,
  isOwner,
  expanded = false,
  avatarFormat = null,
  disableFlagging = false,
  locationIcon = 'location-efefef-18px.svg',
  locationIconHover= 'location-pink.svg',
  content,
  artwork
}) {
  const { handleAction, linkProps, link } = useLoggedOutHandleRoute()
  const { 
    _id,
    itchUrl,
    firstName,
    lastName,
    companyName,
    accountType,
    profileImage,
    profileImageVariants,
    primaryProfession,
    secondaryProfessions, 
    companyType, 
    countryCode,
  } = user
  const name = deriveUserName({
    accountType, firstName, lastName, companyName
  })

  return (
    <Container expanded={expanded}>
      <First expanded={expanded} className="first">
        <TileOwnerAccountType 
          expanded={expanded}
          accType={accountType} 
        />
        {expanded && !disableFlagging ? <FlagContainer>
          <Flag content={content}  {...artwork} />
        </FlagContainer> : null}
      </First>
      <Info expanded={expanded} className="info">
        <TileOwnerName 
          expanded={expanded} 
          url={link(isOwner ? `/my-profile` :`/${itchUrl}`)}
          className="name" name={name} 
        />
        {expanded ? 
        <div className="info-engage">
          <div className="acctypes-location">
            <OwnerAccountTypes
              {...{
                accountType,
                primaryProfession,
                secondaryProfessions,
                companyType
              }}
            />
            <Location className="location" 
              {...linkProps(`/search/collectives?q=${countries.getName(countryCode, "en", { select: "official" })}`)} >
              <span>
                <img src={icons[locationIcon]} alt=""/>
                <img src={icons[locationIconHover]} alt=""/>
              </span>
              {countries.getName(countryCode, "en", { select: "official" })}
            </Location>
          </div>
        </div> : null }
      </Info>
      <Avatar isOwner={isOwner} expanded={expanded} className="avatar">
        {!isOwner ?
        <div className="engage">
          <div className="engage-button">
            <FollowWidget userId={_id} />
          </div>
          <div className="engage-button">
            <ChatWidget userId={_id} />
          </div>
        </div>: null }
        <AvatarWraapper expanded={expanded}>
          <UserAvatar
            profileUrl={link(isOwner ? `/my-profile` : `/${itchUrl}`)}
            format={avatarFormat}
            accountType={accountType}
            url={imageUrl(profileImageVariants?.small ?? profileImage)} 
            position={profileImage?.position} />
        </AvatarWraapper>
      </Avatar>
    </Container>
  )
}
