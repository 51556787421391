import React from 'react'
import styled from 'styled-components'

import {
  colours,
} from '../../styles/global.styles'

import {
  icons,
  getAccountTypeMeta,
  AccountTypes
} from '../../constants'

import { Link } from 'react-router-dom'

const Container = styled(Link)`
  width: inherit;
  height: inherit;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display:block;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index:1;
    content: '';
    background-image:url(${icons['muted.svg']});
    background-size: contain;
    display: ${props => props.muted ? 'block':'none'};
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display:block;
    border-radius: ${props => props.format === 'circle' ? '50%':'0'};
    background-color: transparent;
    top:0;
    left:0;
  }
  
  img {
    display:block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => props.format === 'circle' ? '50%':'0'};
    object-position: ${props => props.position ?? '50% 50%'};
  }
  
  
  &:hover {
    &:after {
      background-color: ${colours.pink_alpha50};
    }
  }

`

export default function UserAvatar({
  profileUrl,
  url,
  position,
  accountType,
  onClick,
  format = null,
  muted = false
}) {
  
  const accountTypeMeta = getAccountTypeMeta(Number(accountType));
  const { fallBackAvatar } = accountTypeMeta;
  
  return (
    <Container 
      className="user-avatar"
      muted={muted}
      position={position ?? 'center center'} 
      format={format}
      to={profileUrl}
      onClick={(evt) => {
        onClick && onClick(evt)
      }}
    >
      {accountType !== AccountTypes.Itch ? <img src={url ?? fallBackAvatar} alt="" /> : <img src={icons['itch-icon-21-pink.svg']} alt="" /> }
    </Container>
  )
}