import { IoLocationSharp } from 'react-icons/io5';

export default function LocationIconWrapper({ textColor, location }) {
  return (
      <div className={textColor + " location-wrapper d-inline-flex align-items-center"}>
          <IoLocationSharp className="location-icon d-inline" />
          {/* <img src={Location} className="location-icon d-inline" alt="location icon" /> */}
          <div className="location d-inline">{location}</div>
      </div>
  )
}