import React, { useEffect } from 'react';
import ProfileList from '../profile/profile-list';

import useModal from '../../hooks/modal.hook';
import useGetActivities from 'hooks/get-activities.hook'
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function ActivityUsers({
  title,
  action,
  schemaName,
  relatedId,
  onClose
}) {
  const { setModal } = useModal()
  
  const {
    success,
    loading,
    fetching,
    error,
    users,
    nextPage,
    hasNextPage
  } = useGetActivities(schemaName, relatedId, action)

  useEffect(() => {
    window.gtag('event', 'modal_view', {
      page_title: 'view-activity-users',
      entity_type: schemaName,
      entity_id: relatedId,
      actions: action
    });
  }, [])

  return (
    <>
      {fetching && success ? <ProgressLoader/> : null}
      {success ? <ProfileList 
        title={title}
        users={users}
        onClose={() => {
          setModal(null)
        }}
        onBottom={() => {
          if (hasNextPage)
            nextPage()
        }}
      /> : null}
    </>
  )
}