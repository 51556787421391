
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/vimeo'

import {
  colours
} from '../../styles/global.styles'

const Container = styled.iframe`
  outline:none;
  border:none;
  padding:0;
  margin:0;
  background-color: ${colours.black};
`

const PlayerContainer = styled.div`
  background-color: ${colours.black};
`

/*
    <Container 
      {...rest} 
      src={`https://player.vimeo.com/video/${id}?autoplay=1`} 
      allow="autoplay; fullscreen; picture-in-picture" 
      allowfullscreen>
    </Container>
*/

export default function VimeoEmbed({
  url,
  onPlay,
  ...rest
}) {
  const parts = url.split('/')
  const id = parts[parts.length - 1]

  return (
    <PlayerContainer {...rest}>
      <ReactPlayer 
        width={'100%'}
        height={'100%'}
        playing={true}
        controls={true}
        onStart={() => {
          onPlay && onPlay()
        }} 
        url={`https://player.vimeo.com/video/${id}`} 
      />
    </PlayerContainer>
  )
}