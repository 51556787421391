import { useInfiniteQuery } from '@tanstack/react-query';
import { getByUserId, getAll } from '../apis/account-artwork.api';
import useAuth from './auth.hook'

export default function useGetArtworks(userId = null,  isOwner = false, size = 10, sortBy = null) {
  const { session } = useAuth()
  
  const { 
    isLoading, error, 
    data, isSuccess, 
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [`artworks-${isOwner ? session?.profile?.id : userId}`],
    queryFn: ({ pageParam = 0 }) => !isOwner ? getByUserId(userId, pageParam, size, sortBy) : getAll(pageParam, size, sortBy),
    getNextPageParam: (lastPage, pages) => {
      if (Number(lastPage.data.page) + 1 < Number(lastPage.data.totalPages))
        return Number(lastPage.data.page) + 1
    }
  })

  const flattenedData = data?.pages.reduce(((acc, curr) => {
    return acc.concat(curr.data?.data)
  }), [])

  return {
    success: isSuccess,
    loading: isLoading,
    fetching: isFetching,
    error,
    artworks: {
      data: flattenedData
    },
    hasNextPage,
    nextPage: fetchNextPage
  }
}