import React from 'react'
import ProfileList from './profile-list'

import useGetFollowing from 'hooks/get-following.hook'

export default function Following({
  userId,
  name,
  onClose
}) {

  const {
    loading,
    fetching,
    success,
    users,
    nextPage
  } = useGetFollowing(userId)
  
  return (
    <>
      {success ? 
      <ProfileList 
        title={`${name} is following`} 
        users={users} 
        onClose={onClose}
      /> : null}
    </>
  )
}