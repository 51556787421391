import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import useGetArtwork from '../hooks/get-one-artwork.hook'

import Page from './page'
import Progress from '../components/shared/progress'

import {
  Wrapper,
  Container,
  ImageContainer,
  Image,
  Right,
  Info,
  Title,
  Description,
  UserPanelContainer,
  Body,
  StatsContainer,
  TagsContainer,
  TimeStamp,
  ActivityPanelContainer,
  PlayButton,
  VideoPlayer,
  Actions,
} from './solo-artwork.style'

import HoverIcon from '../components/shared/hover-icon'
import YouTubeEmbed from '../components/youtube/youtube'
import VimeoEmbed from '../components/vimeo/vimeo'
import ViewTag from '../components/shared/view-tag'

import Expandable from '../components/shared/expandable/expandable'

import TileOwnerPanel from '../components/gallery/owner-panel/tile.owner-panel'

import LastActivityPanel from '../components/gallery/activity-panel/last-activity-panel'
import Counters from '../components/gallery/activity-panel/counters'
// import Comments from '../components/gallery/activity-panel/comments'
import Comment from '../components/comment/comment'
import TileActions from '../components/gallery/tile-actions'

import { format } from 'date-fns'

import {
  icons,
  MediaType,
  Orientation,
  ReviewStatus,
  ActivityActions
} from '../constants'

import { getPoster } from '../components/gallery/artwork-tile'
import useFullScreen from '../hooks/fullscreen.hook'
import useModal from '../hooks/modal.hook';
import SignUpPrompt from 'components/sign-up-prompt/sign-up-prompt'
import useAuth from '../hooks/auth.hook'

import { imageUrl, ensureLinks } from 'utils/utils'

import useActivityViews from 'hooks/activity-views.hook'
import useArtworkActions from 'hooks/artwork-actions.hook'

import CounterItem from 'components/gallery/activity-panel/counter-item'

import OGMetaTags from 'components/social-share/og-meta-tags'

import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook'

import { usePageReady } from 'contexts/page-ready.context'
import { SignUpPromptProvider } from 'contexts/sign-up-prompt.context'

export default function SoloArtwork() {
  const { setReady } = usePageReady()
  const { handleAction, linkProps, link }= useLoggedOutHandleRoute()
  const { isViewed, pushViewed } = useActivityViews()
  const { createActivity } = useArtworkActions()
  const { session } = useAuth()
  const { id } = useParams()
  const { artwork, success, fetching } = useGetArtwork(id)
  
  const { setFullScreen } = useFullScreen()
  const [showFull, setShowFull] = useState(false)
  const { push } = useHistory()
  const { setModal } = useModal()
  
  const tagsRendered = success ? artwork?.tags.map((tag, i) => 
    <ViewTag key={`tag-${i}`} text={tag} />
  ) : null

  const isLandscape = artwork?.mediaType === MediaType.VideoLink || (artwork?.image && (artwork?.image.width > artwork?.image.height))
  const isVimeo = artwork?.video?.url.indexOf('https://vimeo.com/') >= 0
  const isYoutube = artwork?.video?.url.indexOf('https://www.youtube.com/') >= 0 || artwork?.video?.url.indexOf('https://youtu.be/') >= 0
  const isOwner = session?.profile?.id === artwork?.user?._id
  const isMobile = window.innerWidth <= 768;

  const poster = success ? getPoster({
    image: artwork?.image,
    mediaType: artwork?.mediaType,
    imageVariants: artwork?.imageVariants,
    video: artwork?.video
  }) : null

  useEffect(() => {
    if (!isViewed({
      name: 'artworks',
      id
    })) {
      createActivity({
        id, 
        action: ActivityActions.Viewed
      })
      pushViewed({
        name: 'artworks',
        id
      })
    }
  })

  useEffect(() => {
    if (success) {
      setReady(true)
    }
  }, [success])

  return (
    <SignUpPromptProvider>
      <Page className="page" isLoggedIn={session !== undefined && session !== null}>
        <Wrapper>
        {success ?
          <>
          <SignUpPrompt/>
          { artwork?.reviewStatus === ReviewStatus.Approved ? <Container className="solo-artwork" isLandscape={isLandscape}>
            <UserPanelContainer className="mobile">
              <TileOwnerPanel
                expanded={true}
                isOwner={isOwner}
                user={artwork?.user}
              /> 
            </UserPanelContainer>
            <ImageContainer
              isLandscape={isLandscape}
            >
              <Actions className="actions">
                <TileActions 
                  artworkId={artwork?.id}
                  showFullScreen={artwork?.mediaType === MediaType.Image}
                  showMinimise={false}
                  onFullScreen={() => {
                    setFullScreen({
                      active: true,
                      id: artwork?.id,
                      url: imageUrl(artwork?.image),
                      height: artwork?.image?.height,
                      width: artwork?.image?.width,
                      mediaType: artwork?.mediaType,
                      votedByUser: artwork?.votedByUser,
                      viewedByUser: artwork?.viewedByUser,
                      itchbookedByUser: artwork?.itchbookedByUser
                    })
                  }}
                  mediaType={artwork?.mediaType} 
                  votedByUser={artwork?.votedByUser}
                  viewedByUser={artwork?.viewedByUser}
                  itchbookedByUser={artwork?.itchbookedByUser}
                />
              </Actions> 
            
              {artwork?.mediaType === MediaType.Image ? 
                <Image 
                  className="image"
                  position={artwork?.image.position} 
                  orientation={artwork?.image.orientation}
                  
                >
                  <img src={poster?.expandedUrl} alt={artwork?.title} />
                </Image> 
              : null}

              {artwork?.mediaType === MediaType.VideoLink ? 
              <VideoPlayer
                position={poster?.position} 
                orientation={poster?.orientation}
              >
                <PlayButton>
                  <HoverIcon 
                    active={icons['white-play-10px.svg']}
                    hover={icons['white-play-10px-over.svg']}
                  />
                </PlayButton>
                <div className="poster">
                  <img src={poster?.expandedUrl} alt={artwork?.title} />
                </div>
                {isYoutube ? <YouTubeEmbed className="videoplayer" url={artwork?.video?.url} /> : null}
                {isVimeo ? <VimeoEmbed className="videoplayer" url={artwork?.video?.url} /> : null}
              </VideoPlayer> : null}

            </ImageContainer>
            <Right>
              <UserPanelContainer className="desktop">
                <TileOwnerPanel
                  expanded={true}
                  isOwner={isOwner}
                  user={artwork?.user}
                /> 
              </UserPanelContainer>
              <Info>
                <Title to={`/artworks/${artwork?.id}`}>{artwork?.title.substr(0, 50)}</Title>
                <Body className="artwork-body">
                  <Expandable
                    className="expandable"
                    disableExpand={isMobile}
                    footer={<TimeStamp>{format(new Date(artwork?.createdAt), 'MMMM d yyyy h:mm a')}</TimeStamp>}
                  >
                    <Description className="description" dangerouslySetInnerHTML={{__html: ensureLinks(artwork?.description) }} />
                  </Expandable>
                  <TagsContainer>
                    {tagsRendered}
                  </TagsContainer>
                </Body> 
                
                <StatsContainer>
                  {artwork?.featuredChapter > 0 ? 
                  <CounterItem
                    expanded={true}
                    iconActive={icons['featured-grey.svg']}
                    iconHover={icons['featured-pink.svg']}
                    action={ActivityActions.Featured}
                    label={`Featured on zine #${artwork?.featuredChapter}`}
                    url={`/zine/${artwork?.featuredChapter}`}
                  /> : null}
                  
                  <Counters 
                    expanded={true}
                    activityAggregates={artwork?.activityAggregates} 
                    onVotesClick={() => {
                      handleAction(() => {
                        setModal({
                          name: 'activity-history',
                          payload: {
                            title: 'collectives who voted this',
                            schemaName: 'artworks',
                            action: ActivityActions.Voted,
                            relatedId: id
                          }
                        })
                      })
                    }}
                    onCommentsClick={() => {
                      
                    }}
                    onViewsClick={() => {
                      handleAction(() => {
                        setModal({
                          name: 'activity-history',
                          payload: {
                            title: 'collectives who viewed this',
                            schemaName: 'artworks',
                            action: ActivityActions.Viewed,
                            relatedId: id
                          }
                        })
                      })
                    }}
                    onItchbooksClick={() => {
                      handleAction(() => {
                        setModal({
                          name: 'activity-history',
                          payload: {
                            title: 'collectives who itchbooked this',
                            schemaName: 'artworks',
                            action: ActivityActions.Itchbooked,
                            relatedId: id
                          }
                        })
                      })
                    }}
                  />
                </StatsContainer>

                <Comment id={id} contentType={'artworks'} /> 
                
              </Info>
              <ActivityPanelContainer>
                {artwork?.lastActivity ? <LastActivityPanel
                  {...artwork?.lastActivity}
                  expanded={true}
                /> : null}
              </ActivityPanelContainer>
            </Right>
          </Container>
          : <></>}
          </>
        : <Progress/>}
        </Wrapper>
      </Page>
    </SignUpPromptProvider>
  )
}