import styled from 'styled-components'
import {
  fonts,
  colours,
  BaseTransition,
  ScrollStyledBox,
  ButtonReset,
  breakpoints
} from '../../styles/global.styles'
import { Link } from 'react-router-dom'

// border: ${props => props.borderColor ? 1 : 0}px solid ${props => props.borderColor ?? colours.transparent};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border:none;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  top: 7.5px;
  left: 7.5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  
  position: relative;
  background-color: ${props => props.expanded ? colours.lightgrey7 : 'transparent'};

  &.dragged {
    * {
      visibility: none;
    }
  }

  
  &:hover {
    .actions {
      visibility: visible;
      opacity: 1;
    }
  }


  ${props => !props.expanded ? `
    .image>img {
      transition-property: all;
      transition-duration: .1s;
      transform: translateX(-1%);
      width: 102%;
    }
    .poster>img {
      transition-property: all;
      transition-duration: .1s;
      transform: translateX(-1%);
      width:105% !important;
    }

  
    &:hover {
      .image>img {
        transform: translateX(0);
      }
      .poster>img {
        transform: translateX(0);
      }
    }
  
  `: null}

  @media (max-width:${breakpoints.sm}px) {
    flex-direction: ${props => props.expanded ? 'column' : 'row'};
    height: ${props => props.expanded ? '100%' : '64vw' };
    width: 100%;
    top:0;
    left: 0;
    padding: 0px;
  }
`

export const ImageContainer = styled(BaseTransition)`
  flex: 0 0 ${props => props.expanded ? `${props.isLandscape ? 75:60}%`:'62.5%' };
  border-right: none;
  height: 100%;
  cursor: pointer;
  transition-duration: .1s;
  display:block;
  padding-bottom: 0;

  
  &:hover {
    .actions {
      visibility: visible;
    }
  }


  @media (max-width:${breakpoints.sm}px) {
    position: relative;
    flex: ${props => props.expanded ? '1 1 auto' : '0 0 50%'};
    display: ${props => props.expanded ? 'flex':'block'};
    flex-direction: ${props => props.expanded ? 'column':'unset'};
    padding-bottom: ${props => props.expanded ? 60:0}px;
    background-color: ${colours.light2};

    .actions {
      visibility: ${props => props.expanded ? 'visible':'hidden'};
      display: ${props => props.expanded ? 'block':'none'};
      width: calc(100% / 7 * 4);
      left:0;
    }

    
    &:hover {
      .actions {
        visibility: unset;
      }
    }
  
  }
`

export const Image = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &>img {
    display:block;
    object-fit: cover;
    object-position: ${props => props.position};
    width: 100%;
    height: 100%;
    transition-property: all;
  }
`

export const VideoPlayer = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .poster {
    width: 100%;
    height: 100%;
    &>img {
      display:block;
      object-fit: cover;
      object-position: ${props => props.position};
      width: 100%;
      height: 100%;
      transition-property: all;
    }
  }

  .videoplayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display:${props => props.expanded ? 'block':'none'}; 
  }
`

export const OwnerButton = styled(ButtonReset)`
  background-color: ${colours.white};
  width: 36px;
  height: 36px;
  border: 1px solid ${colours.white};
  padding: 8px;
  margin-top: 4px;

  img {
    width: 16px;
    margin-left:auto;
    margin-right:auto;
  }


  &:hover {
    border-color: ${colours.pink};

    &>span {
      &>img.icon_active {
        opacity: 0;
      }
      &>img.icon_hover {
        opacity: 1;
      }
    }
  }


  @media (max-width:${breakpoints.sm}px) {
    width: 36px;
    height: 36px;
    margin-top: 0;
    margin-left: 6px;
    margin-right: 6px;
    border: none;

    
    &:hover {
      border: 1px solid ${colours.pink};
    }
  
  }
`

export const OwnerActions = styled.div`
  position: absolute;
  left: 8px;
  bottom: 14px;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width:${breakpoints.sm}px) {
    visibility: ${props => props.expanded ? 'visible':'hidden'};
    display: ${props => props.expanded ? 'flex':'none'};
    flex-direction: row;
    left: unset;
    right: 0;
    padding-right:10px;
    width: calc(100% / 7 * 3);

    &>button {
      margin-left: 3.5px;
      margin-right: 3.5px;

      &:last-child {
        margin-right:0;
      }
    }
  }
`

export const EditButton = styled(OwnerButton)`
  
`

export const StatsButton = styled(OwnerButton)`
  
`

export const DeleteButton = styled(OwnerButton)`
  
`

export const PlayButton = styled(ButtonReset)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index:2;
  transform: translate(-50%, -50%);

  &>span {
    height:100%;
  }
`

export const Title = styled.div`
  font-family: ${fonts.content.bold};
  font-size: ${props => props.expanded ? 13:12}px;
  line-height: ${props => props.expanded ? 15:14}px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.darkergrey2};
  margin-bottom: 10px;
  cursor: pointer;

  
  &:hover {
    color: ${colours.pink};
  }


  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
    line-height: 12px;
  }
`

export const Body = styled.div`
  flex: 0 1 auto;
  width: 100%;
  display:flex;
  flex-direction:column;
`

export const Description = styled.div`
  text-align: left;
  font-family: ${fonts.content.regular};
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  overflow-wrap: break-word;
  width: 100%;
  
  * {
    overflow-wrap: break-word;
  }
`

export const TagsContainer = styled.div`
  margin-top: 30px;
  margin-left: -4px;
  margin-right: -4px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &>.tag {
    background-color: ${colours.darklight};
    color: ${colours.lightgrey3};
    margin: 4px;
    cursor: pointer;
  }
`

export const TimeStamp = styled.div`
  font-family: ${fonts.content.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.grey};
  margin-top: 4px;
`

export const Right = styled.div`
  flex: 1 1 auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow:hidden;

  @media (max-width:${breakpoints.sm}px) {
    width: 100%;
  }
`

export const Info = styled(ScrollStyledBox)`
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.expanded ? 15:6}px;
  overflow-y: ${props => props.expanded ? 'scroll':'hidden'};
  overflow-x: hidden;
  background-color: ${colours.light2};
`

export const OwnerPanelContainer = styled.div`
  border-bottom:1px solid ${props => props.borderColor ?? colours.transparent};
  height: ${props => props.expanded ? 98:44}px;
  flex: 0 0 ${props => props.expanded ? 98:44}px;
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${colours.light4};
  ${props => props.dropShadow ? `
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  `: ``}

  &.mobile-expanded {
    display:none;
  }

  .user-avatar {
    width: ${props => props.expanded ? 98: 44}px;
  }

  .engage {
    display: ${props => props.expanded ? 'block': 'none'};
    flex: 0 0 25px;
    margin-bottom: 10px;

    .engage-button {
      width: 18px;
    }
  }
  
  @media (max-width:${breakpoints.sm}px) {
    &.mobile-expanded {
      display: ${props => props.expanded ? 'flex' : 'none'};
      height: ${props => props.expanded ? 66:44}px;
      flex: 0 0 ${props => props.expanded ? 66:44}px;
      &>div {
        &>.avatar {
          width: 91px;
          flex: 0 0 91px;
        }
      }
    }
    &.desktop {
      display: ${props => props.expanded ? 'none' : 'flex'};
    }
  }
`

export const ActivityPanelContainer = styled.div`
  border-top: 1px solid ${props => props.borderColor ?? colours.transparent};
  flex: 0 0 ${props => props.expanded ? 60: 44}px;
  width: 100%;
  background-color: ${colours.light4};
`

export const Actions = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 3;
  visibility: hidden;

  @media (max-width:${breakpoints.sm}px) {
    visibility: ${props => props.expanded ? 'visible' : 'hidden' };
    bottom: 0;
    top: auto;
    width: 100%;
    flex: 0 0 auto;
    height: 60px;

    padding: 10px;
  }
`

export const StatsContainer = styled.div`
  width:100;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top:auto;
`
