import { useContext } from "react";
import { ToastPopContext } from "../contexts/toast-pop.context";

const useToastPop = () => {
  const {toastPopped, setToastPopped} = useContext(ToastPopContext);

  const show = (message, ui) => {
    setToastPopped({
      active: true,
      message,
      ui
    })
  }

  const hide = () => {
    setToastPopped({
      ...toastPopped,
      active: false
    })
  }

  const initialize = ({
    autoHide = true,
    positionedTop = 45,
    zIndex
  }) => {
    setToastPopped({
      ...toastPopped,
      ui: {
        autoHide,
        positionedTop,
        zIndex
      }
    })
  }

  return {
    toastPopped,
    show,
    hide,
    initialize
  }
}

export default useToastPop;