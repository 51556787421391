import styled from 'styled-components'
import HoverIcon from '../shared/hover-icon'
import {
  fonts,
  colours,
  breakpoints
} from '../../styles/global.styles'

export const HoverIconStyled = styled(HoverIcon)`
  z-index: 2;
`

export const TileInner = styled.div`
  display: flex;
  position: relative;

  font-family: ${fonts.title.xbold};
  color: ${colours.grey};
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  top: 7.5px;
  left: 7.5px;

  
  &:hover {
    .text {
      color: ${colours.pink};
    }
  }


  @media (max-width:${breakpoints.sm}px) {
    height:100%;
    width:100%;
    top:0;
    left:0;
  }
`

export const ActionArea = styled.div`
  cursor: pointer;
  display: ${props => props.show ? 'block':'none'};
  width: 100%;
  height: 100%;

  img {
    height:100%;
    object-fit: contain;
  }

  .icon {
    width: 100%;
    height: 100%;
  }

  @media and (max-width:${breakpoints.sm}px) {
    
  }
`

export const Text = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:1;
  
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
`;

export const StartConversationBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${colours.darkerlight2}; 
  padding: 48px 30px;
  border: 3px solid ${colours.text};
  opacity: ${props => props.show ? 1:0};
  pointer-events: ${props => props.show ? 'all':'none'};
`

export const InputName = styled.input`
  font-family: ${fonts.content.regular};
  font-size: 16px;
  line-height: 16px;
  color: ${colours.grey};
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  overflow-x: hidden;
`

export const DropDownContainer = styled.div`
  position: fixed;
  z-index: 1;
  max-height: 400px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: ${breakpoints.sm}px) {
    max-width: 300px;
  }
`
