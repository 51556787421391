import React, { useEffect, useMemo, useState } from 'react';
import TopNav from "components/shared/top-nav";
import {
  useParams
} from 'react-router-dom'
import {
  NoResult
} from './search/collectives.styles'
import {
  Container,
  Title
} from './collectives.styles'
import {
  AccountTypes
} from '../constants'
import CollectiveItem from 'components/collective-item/collective-item';
import useGetUsersByAccountType from 'hooks/get-users-by-account-type.hook'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Followers from 'components/profile/followers';
import Following from 'components/profile/following';
import { 
  useFollowingFollowersPopup
} from 'components/collective-item/following';
import CollectiveHeader from 'components/collectives-header/collective-header';
import ProgressLoader from 'components/shared/progress-loader/progress-loader';

export default function CollectivesPage() {
  const { popup, setPopup } = useFollowingFollowersPopup()
  const { accountType: accountTypeSlug, subType } = useParams()

  let accountTypeValue = null

  if (accountTypeSlug === 'fa') {
    accountTypeValue = AccountTypes.FashionArtist
  } else
  if (accountTypeSlug === 'fc') {
    accountTypeValue = AccountTypes.FashionCompany
  } else
  if (accountTypeSlug === 'fl') {
    accountTypeValue = AccountTypes.FashionLover
  }
  
  const { 
    success,
    users,
    nextPage,
    hasNext,
    refresh,
    loading
  } = useGetUsersByAccountType({
    page: 0,
    size: 10,
    accountType: accountTypeValue,
    subType
  })
    
  useBottomScrollListener(() => {
    if (hasNext) {
      nextPage()
    }
  })

  useEffect(() => {
    refresh()
  }, [accountTypeValue])

  return (
    <>
      <Container>
        <CollectiveHeader/>
        {success ? (users ?? []).map(user => (
          <CollectiveItem 
            {...user} 
          />
        )) : null}
        {success && (users ?? []).length === 0 ? 
        <NoResult>There are no results</NoResult>: null}  
        {loading && !success ? <ProgressLoader/> : null}
      </Container>
      {popup?.which === 'following' ? 
        <Following 
          userId={popup.userId}
          name={popup.name} 
          onClose={() => {
            setPopup({
              which: null,
              userId: null,
              name: null
            })
          }}
          /> : null}
      {popup?.which === 'followers' ? 
        <Followers 
          userId={popup.userId}
          name={popup.name} 
          onClose={() => {
            setPopup({
              which: null,
              userId: null,
              name: null
            })
          }}
          /> : null}
    </>
  )
}
