import React from 'react'
import styled from 'styled-components'
import HoverIcon from '../shared/hover-icon'

import {
  icons,
  MediaType,
  ActivityActions
} from '../../constants'

import {
  colours,
  fonts,
  breakpoints
} from '../../styles/global.styles'

import useArtworkActions from 'hooks/artwork-actions.hook'
import useToggleItchbook from 'hooks/itchbook.hook'
import useAuth from 'hooks/auth.hook'
import useActions from 'hooks/actions.hook'

import Facebook from 'components/social-share/facebook'
import XPlatform from 'components/social-share/x'
import Pinterest from 'components/social-share/pinterest'
import CopyLink from 'components/social-share/copy-link'

import { useWindowDimensions } from 'utils/helpers'

const Container = styled.div`
  display:flex;
  flex-direction: column;
  
  @media (max-width:${breakpoints.sm}px) {
    flex-direction: row;
  }
`

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;

  &>.label {
    display: none;
    height: 26px;
    visibility: hidden;
    opacity: 0;
    
    &>.mobile-text {
      display:none;
    }
  }

  
  &:hover {
    &>.label {
      display: block;
      visibility: visible;
      opacity: 1;
    }
    &>.button {
      border-color: ${colours.pink};
      img.active {
        visibility: hidden;
      }
      img.hover {
        visibility: visible;
        
      }
    }
  }


  @media (max-width:${breakpoints.sm}px) {
    flex-direction: column;
    position:relative;

    &:first-child {
      margin-left: -3.5px;
    }

    &>.label {
      display: block;
      visibility: visible;
      opacity:1;
      white-space: nowrap;

      position: absolute;
      bottom: -12px;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
      height: 10px;
      text-transform: capitalize;

      &>.mobile-text {
        
        display:${props => props.expanded ? 'block':'none'};
        text-align: center;
        font-size: 9px;
        font-family: ${fonts.content.bold};
        line-height:10px;
        color: ${colours.lightgrey2};
      }
    }

    ${props => props.shareMenu ? `
  
    &:hover {
      .share-methods {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position:absolute;
        top: -74px;
        left: 50%;
        transform: translateX(-50%);
        width: 139px;
        height: 26px;
        background-color: ${colours.dark};
        
        &:after {
          content: '';
          position: absolute;
          top: auto;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid ${colours.dark};
        }

        &>span.share-btn {
          width: 16px;
          height: 16px;
          padding: 0;
          margin-left: 6px;
          margin-right: 6px;
        }
      }
    }
  
    `: null}
  }
`

const Button = styled.div`
  background-color: ${colours.dark};
  border: 1px solid ${props => props.clicked ? colours.pink : colours.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: relative;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }

  img.active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  img.hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    visibility: hidden;
  }

  @media (max-width:${breakpoints.sm}px) {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    
    margin-left: 3.5px;
    margin-right: 3.5px;
  }
`

const Label = styled.div`
  background-color: ${colours.dark};
  padding: 3px 10px;
  height: 26px;

  color: ${colours.pink};
  font-family: ${fonts.title.xbold};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 3px;


  @media (max-width:${breakpoints.sm}px) {
    background: transparent;
    padding:0;
    margin:0;
    top:0;
    text-align: center;
    font-size: 9px;
    font-family: ${fonts.content.bold};
    line-height:10px;
    color: ${colours.lightgrey2};
  }
`

const ShareMethods = styled.div`
  background-color: ${colours.dark};
  height:inherit;

  margin-left: 3px;
  display:flex;
  flex-direction: row;
  align-items: center;

  &>.share-btn {
    height: 12px;
    padding-left:7px;
    padding-right:7px;
  }

  @media (max-width:${breakpoints.sm}px) {
    display:none;
  }
`

export default function TileActions({
  artworkId,
  mediaType,
  expanded,
  showMinimise,
  showFullScreen,
  votedByUser = false,
  viewedByUser = false,
  itchbookedByUser = false,
  onExit = () => {},
  onFullScreen = () => {}
}) {
  const { isMobile } = useWindowDimensions()
  const { actions, isActioned, pushAction, pullAction } = useActions()
  const { createActivity } = useArtworkActions()
  const { itchbookIt } = useToggleItchbook()
  const { session } = useAuth()
  const url = `${window.location.protocol}//${process.env.REACT_APP_HOST}/artworks/${artworkId}`

  const isVoted = votedByUser || isActioned({
    name: 'artworks', 
    id: artworkId, 
    action: ActivityActions.Voted
  })

  const isItchbooked = isActioned({
    name: 'artworks',
    id: artworkId, 
    action: ActivityActions.Itchbooked
  })

  return (
    <Container className="tile-actions"> 
      {session ? 
      <ActionItem onClick={() => {
        if (!isVoted) {
          createActivity({
            id: artworkId,
            action: ActivityActions.Voted
          })
          pushAction({
            name: 'artworks', 
            id: artworkId, 
            action: ActivityActions.Voted
          })
        }
      }}>
        <Button className="button" 
          clicked={isVoted}>
          <img className="active" src={isVoted ? icons['wow-pink-16px.svg'] : icons['wow-grey-px16.svg']} alt="" />
          <img className="hover" src={icons['wow-pink-16px.svg']} alt="" />
        </Button>
        <div className="label">
          <Label>{isVoted ? (isMobile ? 'voted':'voted') : 'vote'}</Label>
        </div>
      </ActionItem> : null}
      {session ? 
      <ActionItem onClick={() => {
        itchbookIt(artworkId)
        if (!isItchbooked) {
          pushAction({
            name: 'artworks', 
            id: artworkId, 
            action: ActivityActions.Itchbooked
          })
        } else {
          pullAction({
            name: 'artworks', 
            id: artworkId, 
            action: ActivityActions.Itchbooked
          })
        }
      }}>
        <Button className="button" 
          clicked={isItchbooked}>
          <img className="active" src={isItchbooked ? icons['itchbook-pink-16px.svg'] : icons['itchbook-grey-16px.svg']} alt="" />
          <img className="hover" src={icons['itchbook-pink-16px.svg']} alt="" />
        </Button>
        <div className="label"> 
          <Label>{isItchbooked ? 'itchbooked' : 'itchbook it'}</Label>
        </div>
      </ActionItem> : null}
      <ActionItem expanded={expanded} shareMenu={isMobile}>
        <Button className="button">
          <img className="active" src={icons['share-grey-16px.svg']} alt="" />
          <img className="hover" src={icons['share-pink-16px.svg']} alt="" />
        </Button>
        <div className="label"> 
          <div className="mobile-text">Share</div>
          <ShareMethods className="share-methods">
            <Facebook className="share-btn" url={url} />
            <XPlatform className="share-btn" url={url} />
            <Pinterest className="share-btn" url={url} />
            <CopyLink className="share-btn" url={url} />
          </ShareMethods>
        </div>
      </ActionItem> 
      {showFullScreen ? 
        <ActionItem onClick={() => {
          onFullScreen()
        }}>
        <Button className="button">
          <img className="active" src={icons['fullscreen-grey-16px.svg']} alt="" />
          <img className="hover" src={icons['fullscreen-pink-16px.svg']} alt="" />
        </Button>
        <div className="label"> 
          <Label>fullscreen</Label>
        </div>
      </ActionItem> : null}
      {showMinimise ? <ActionItem>
        <Button className="button" 
          onClick={() => {
            onExit()
          }}
        >
          <img className="active" src={icons['expand-collapse-grey-16px.svg']} alt="" />
          <img className="hover" src={icons['expand-collapse-pink-16px.svg']} alt="" />
        </Button>
        <div className="label"> 
          <Label>exit</Label>
        </div>
      </ActionItem> : null}
    </Container>
  )
}