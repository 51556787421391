import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import styled from 'styled-components';
import { 
  themes,
  colours,
  fonts,
  breakpoints,
  ButtonReset,
  BaseImage
} from '../../styles/global.styles';
import { 
  icons,
  AccountTypes,
  CompanyTypes,
  getAccountTypeMeta,
  getCompanyTypeName,
  AccountTypesNameSlug,
  CompanyTypesNameSlug,
  getProfessionLabelValues
} from '../../constants';
import {
  nameConcat
} from '../../utils/helpers';
import countries from 'i18n-iso-countries';
import Followers from './followers';
import Following from './following'
import HoverIcon from '../shared/hover-icon';
import {
  Wrapper,
  Container,
  Background,
  AccountInfoAvatarWrapper,
  AccountTypeIcon,
  Info,
  BaseThemedText,
  BaseThemedTitle,
  Name,
  AccountTypeProfessions,
  CompanyType,
  Location,
  LocationPinIcon,
  LocationName,
  FashionStatement,
  FashionStatementContent,
  TextTruncate,
  ExpandButton,
  MobileSection,
  FashionStatementMobile,
  FollowingActionsContainer,
  FollowingActionsContainerMobile,
  Actions,
  GuestActions,
  ActionButton,
  EditButton,
  FollowButton,
  ChatButton,
  TextCounter,
  StatLabel,
  StatBox,
  UserAvatarWrapper,
  UserAvatar,
  UserAvatarMobileWrapper,
  UserAvatarMobile
} from './userinfo.style'
import { stripHtml, forceLinksBlankTarget, ensureLinks } from '../../utils/utils'
import FollowWidget from 'components/gallery/owner-panel/follow-widget'
import ChatWidget from 'components/gallery/owner-panel/chat-widget'
import useAuth from 'hooks/auth.hook'
import {
  useHistory
} from 'react-router-dom'
import {
  imageUrl
} from 'utils/utils'
import useLoggedOutHandleRoute from 'hooks/loggedout-handle-route.hook';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function UserInfo({
  profile,
  onExpandFashionStatement,
  onEdit
}) {

  const {
    id,
    colourTheme,
    accountType,
    firstName,
    lastName,
    companyName,
    countryCode,
    companyType,
    primaryProfession,
    secondaryProfessions,
    fashionStatement,
    profileImage,
    profileImageVariants,
    isOwner,
    followersCount,
    followingCount,
    isFollowedByUser
  } = profile

  const { push } = useHistory()
  const { handleAction } = useLoggedOutHandleRoute()

  const [followersVisible, setFollowersVisible] = useState(false);
  const [followingVisible, setFollowingVisible] = useState(false);

  let companyTypeName = null;
  const accountTypeMeta = getAccountTypeMeta(accountType);
  const accountTypeName = accountTypeMeta.name;
  const fallBackAvatar = accountTypeMeta.fallBackAvatar;

  let name = accountType === AccountTypes.FashionCompany ? 
    companyName : nameConcat(firstName, lastName);

  companyTypeName = getCompanyTypeName(companyType)

  const countryName = countries.getName(countryCode, "en", { select: "official" })

  const followingActionsFragment = <>
    <Actions theme={colourTheme}>
      {isOwner ? 
        <EditButton 
          type="button" 
          onClick={() => {
            onEdit && onEdit();
          }}
        >
          <HoverIcon 
            className="edit-icon"
            active={icons['white-edit.svg']} 
            hover={icons['pink-pencil-14.svg']}  
          /> 
          <span className="label">Edit Profile</span>
        </EditButton> 
        : null
      }
      {!isOwner ? 
      <GuestActions>
        <FollowWidget userId={id} theme={colourTheme} isFollower={isFollowedByUser} />
        <ChatWidget userId={id} theme={colourTheme} />
      </GuestActions> 
      : null}
    </Actions>
    <StatBox 
      theme={colourTheme}
      onClick={() => {
        handleAction(() => setFollowersVisible(true))
      }}
    >
      <TextCounter theme={colourTheme}>
        {followersCount ?? 0}
      </TextCounter>
      <StatLabel theme={colourTheme}>
        Followers
      </StatLabel>
    </StatBox>
    <StatBox
      theme={colourTheme}
      onClick={() => {
        handleAction(() => setFollowingVisible(true))
      }}
    >
      <TextCounter theme={colourTheme}>
        {followingCount ?? 0}
      </TextCounter>
      <StatLabel theme={colourTheme}>
        Following
      </StatLabel>
    </StatBox>
  </>
  
  
  return (
    <Wrapper>
      {followersVisible ? 
        createPortal(<Followers 
          name={name}
          userId={id}
          onClose={() => {
            setFollowersVisible(false);
          }}
        />, document.body) : null}
      {followingVisible ? 
        createPortal(<Following
          name={name}
          userId={id}
          onClose={() => {
            setFollowingVisible(false);
          }}
        />, document.body) : null}
      <Container theme={colourTheme}>
        <AccountInfoAvatarWrapper>
          <AccountTypeIcon 
            accountType={accountType}
            theme={colourTheme}>
          </AccountTypeIcon>
          <Info>
            <Name theme={colourTheme}>{name}</Name>
            {accountType === AccountTypes.FashionCompany ? 
            <CompanyType theme={colourTheme}>
              {accountTypeName} / <span onClick={() => {
                handleAction(() => {
                  push(`/home/fashion-company/${CompanyTypesNameSlug[companyType]}`)
                })
              }}>{companyTypeName}</span>
            </CompanyType>
            :
            <AccountTypeProfessions theme={colourTheme}>
              <span onClick={() => {
                handleAction(() => {
                  push(`/home/${AccountTypesNameSlug[accountType]}`)
                })
              }}>{accountTypeName}</span> 
              {accountType === AccountTypes.FashionArtist ? <> / {secondaryProfessions?.length > 0 ? <>&nbsp;</> : null}
              {getProfessionLabelValues([
                primaryProfession,
                ...(secondaryProfessions ?? [])
              ]).map(({value, label}, i) => (
                <span
                  key={`profession-${i}`}
                  className="profession"
                  onClick={() => {
                    handleAction(() => {
                      push(`/home/${AccountTypesNameSlug[accountType]}/${value}`)
                    })
                  }}
                >{label}</span>
              ))}
              </> : null}
            </AccountTypeProfessions>}
            
            <Location onClick={() => {
              handleAction(() => {
                push(`/search/collectives?q=${countryName}`)
              })
            }}>
              <LocationPinIcon>
                <HoverIcon
                  className="location-icon"
                  active={icons['location-white.svg']}
                  hover={icons['location-pink.svg']}
                />
              </LocationPinIcon>
              <LocationName theme={colourTheme}>
                {countryName}
              </LocationName>
            </Location>

            <FashionStatement theme={colourTheme}>
              <FashionStatementContent
                dangerouslySetInnerHTML={{
                  __html: forceLinksBlankTarget(fashionStatement ?? '')
                }}
              >
              </FashionStatementContent>
              {fashionStatement ? <ExpandButton onClick={() => {
                handleAction(() => {
                  onExpandFashionStatement()
                })
              }}>
                <BaseImage src={icons['white-expand-feature.png']} alt="Expand" />
              </ExpandButton>
              : null}
            </FashionStatement>
          </Info>
          <UserAvatarMobileWrapper>
            <UserAvatarMobile 
              src={imageUrl(profileImageVariants?.large ?? profileImage) ?? fallBackAvatar} 
              position={profileImage?.position ?? null} 
              alt=""
            />
          </UserAvatarMobileWrapper>
        </AccountInfoAvatarWrapper>
        <FollowingActionsContainer>
          {followingActionsFragment}
        </FollowingActionsContainer>
        <UserAvatarWrapper>
          <UserAvatar 
            src={imageUrl(profileImageVariants?.large ?? profileImage) ?? fallBackAvatar} 
            position={profileImage?.position ?? null}
            alt=""
          />
        </UserAvatarWrapper>

        <MobileSection>
          <FashionStatementMobile theme={colourTheme}>
            <FashionStatementContent>
              <TextTruncate>
                {stripHtml(fashionStatement)}
              </TextTruncate>
            </FashionStatementContent>
            <ExpandButton onClick={() => {
              handleAction(() => {
                onExpandFashionStatement()
              })
            }}>
              <BaseImage src={icons['white-expand-feature.png']} alt="Expand" />
            </ExpandButton>
          </FashionStatementMobile>
          <FollowingActionsContainerMobile>
            {followingActionsFragment}
          </FollowingActionsContainerMobile>
        </MobileSection>
      </Container>
      <Background theme={colourTheme}/>
    </Wrapper>
  );
}