import styled from 'styled-components'
import {
  colours,
  fonts,
  breakpoints
} from 'styles/global.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (min-width:${breakpoints.sm}px) {
    padding-left:15px;
    padding-right:15px;
  }
`

export const FollowingItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: flex-end;

  label, span { 
    font-family: ${fonts.content.regular};
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    border-bottom:none;
    padding:0;
    margin:0;
  }

  label {
    padding-right: 4px;
  }

  &:first-child {
    border-right: 1px solid ${colours.text};
    padding-right: 8px;
  }

  &:last-child {
    padding-left: 8px;
  }

  @media screen and (min-width:${breakpoints.sm}px) {
    flex-direction: column;

    label {
      font-family: ${fonts.content.bold};
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: ${colours.lightgrey3};
      border-bottom: 1px solid ${colours.text};
      padding-bottom: 5px;
      width: 100%;
    }
    span {
      padding-top: 5px;
      font-family: ${fonts.content.bold};
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: ${colours.lightgrey3};
    }

    &:first-child {
      border:none;
    }
  }
`