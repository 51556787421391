import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

export default function SubNavItem({
  label,
  to,
  activeOnlyWhenExact,
  counter = 0
}) {
  const match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
  });
  return (
    <li className={`submenu-item ${match ? "selected": ""}`}>
      <Link to={to} className="position-relative">
        <span>{label}</span>
        {counter > 0 ? <span className="submenu-item-count pink-text">
          <span>{counter < 99 ? counter : 99}{counter > 99 ? '+' : ''}</span>
        </span> : null}
      </Link>
    </li>
  );
}