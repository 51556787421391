import React, { useState } from 'react'
import { PostHogProvider } from 'posthog-js/react'

export const FeatureKeys = {
  PING2: 'PING2',
  FLAGGING: 'FLAGGING',
  PORTRAITS: 'PORTRAITS'
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  bootstrap: {
    featureFlags: {
      [FeatureKeys.PING2]: false,
      [FeatureKeys.FLAGGING]: false,
      [FeatureKeys.PORTRAITS]: false
    }
  }
}

export const FeaturesContext = React.createContext();

export const FeaturesProvider = ({ children }) => {

  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
      >
      <FeaturesContext.Provider value={null}>
        {children}
      </FeaturesContext.Provider>
    </PostHogProvider>
  );
}