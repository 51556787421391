import { useMutation } from '@tanstack/react-query';
import { confirmChangeEmail } from '../apis/user.api';

export default function useConfirmEmailChange() {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: data => {
      return confirmChangeEmail(data)
    },
  });
  

  return {
    confirm: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data,
    error
  }
}