import { itchArticleTextColor } from './commons'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'

export function ItchArticleDetails({ 
  open, title, subHead, category, theme = 'pink', slug
}) {
  // const { tint, type, title, description } = articles[0];
  let a = itchArticleTextColor[theme];
  const textColor = (color) => open ? 'pink-text' : color;
  return (
      <div className="article-details">
          {category ? <div className={textColor(a.type) + " text-uppercase pn-bold type"}>{category}</div> : null}
          <div className={textColor(a.type) + " body-text"}>
              {slug ? <Link className={textColor(a.title) + " nexa-x-bold title"} to={`/itch-articles/${slug}`}>{parse(title)}</Link> : 
              <div className={textColor(a.title) + " nexa-x-bold title"} >{parse(title)}</div>}
              <div className={textColor(a.description) + " desc"}>{subHead}</div>
          </div>
      </div>
  )
}