import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components'
import {
  Footer,
  Body,
  SolidLine,
  DashedLine,
  ButtonDanger,
  ButtonResetForm,
  AccountType,
  LinkButton,
  HintText
} from '../../pages/settings.styles'
import ButtonSave from '../form/button-save';
import InputText from '../form/input-text';
import RadioButtons from '../form/radio-buttons';
import {
  AccountTypes,
  icons,
  getAccountTypeMeta,
  getProfessionLabels,
  getCompanyTypeName
} from '../../constants';
import ChangeAccountType from './settings.change-account-type';
import ChangePassword from './settings.change-password';

import useGetMyAccount from '../../hooks/get-user-account.hook';
import useUpdateMyAccount from '../../hooks/update-user-account.hook';

import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

import FormField from '../form/form-field';

import { 
  checkAccountEmail,
  checkItchUrlUsed
} from '../../apis/user.api'

import useToast from '../../hooks/toast.hook'

import { differenceInDays } from 'date-fns'
import useModal from '../../hooks/modal.hook';
import { copyToClipboard } from 'utils/utils'
import useToastPop from 'hooks/toast-pop.hook'

const ModalIds = {
  AccountType: 'account-type',
  Password: 'password',
  PasswordEmail: 'password-email'
}

const ItchUrlField = styled.div`
  width: 100%;

  .itchurl-field>.input-wrapper>.inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .itchurl-hint {
    position: relative;
    top: -10px;
  }
  .itchurl-field:hover + .itchurl-hint,
  .itchurl-field:focus-within + .itchurl-hint {
    visibility: visible;
    opacity: 1;
  }

  .copy-link {
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    width: 16px;
    height: 16px;
    margin-top: 10px;
    img:first-child {
      top:0;
      left:0;
      position: absolute;
    }
    img:last-child {
      top:0;
      left:0;
      position: absolute;
      opacity:0;
    }
    
    &:hover {
      img:last-child {
        opacity: 1;
      }
    }
  
  }
`

Yup.addMethod(Yup.mixed, 'uniqueEmail', function(args) {
  const message = args
  return this.test('unique-email', message, function(value) {
    const { path, createError } = this;
    return new Promise((resolve, reject) => {
      checkAccountEmail(value).then(res => {
        if (res.data.result)
          reject(createError({ path, message }))
        else
          resolve(true)
      })
    })
  })
})

Yup.addMethod(Yup.mixed, 'uniqueItchUrl', function(args) {
  const message = args
  return this.test('unique-itchurl', message, function(value) {
    const { path, createError } = this
    if (value?.length >= 5) {
      return new Promise((resolve, reject) => {
        checkItchUrlUsed(value).then(res => {
          if (!res.data.result)
            resolve(true)
          else
            reject(createError({ path, message }))
        })
      })
    }
  })
})

export default function SettingsAccount() {
  const { show } = useToastPop()
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email('Oops! This isn’t exactly an email address...')
      .uniqueEmail('Oops! This email address is taken.'),
    itchUrl: Yup.string()
      .required("This is required")
      .min(5, "Oops! Your itch URL must be at least 5 characters")
      .uniqueItchUrl("Oops! This itch URL has been taken"),
    homepage: Yup.string()
      .required("Home page is required")
  })

  const globalModal = useModal()
  
  const [ modal, setModal] = useState(null)
  const { 
    loading,
    success,
    fetching,
    error,
    accountSettings
  } = useGetMyAccount()
  const updateMyAccount = useUpdateMyAccount()
  
  const { 
    reset,
    control,
    register, 
    handleSubmit,
    getValues,
    formState: {
      errors,
      isDirty
    }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema)
  });

  const onSubmit = (data) => {
    updateMyAccount.update({
      ...data
    })
    reset({
      ...data
    })
  }

  const handleDeactivate = () => {
    globalModal.setModal({ name: 'deactivate' })
  }

  useEffect(() => {
    if (success) {
      window.gtag('event', 'modal_view', {
        page_title: 'setttings-account',
      });

      if (accountSettings.homepage === undefined) {
        reset({
          ...accountSettings,
          homepage: 1
        })
      } else {
        reset({
          ...accountSettings
        })
      }
    }
  }, [success])

  const { name } = getAccountTypeMeta(accountSettings.accountType)

  const getAccountSubTypesText = () => {
    if (success) {
      if (accountSettings.accountType === AccountTypes.FashionCompany) {
        return [getCompanyTypeName(accountSettings.companyType)]
      } else
      if (accountSettings.accountType === AccountTypes.FashionArtist) {
        return getProfessionLabels([accountSettings.primaryProfession, ...accountSettings.secondaryProfessions])
      } else
        return []
    }
  }

  const accountSubTypesText = getAccountSubTypesText()

  const isItchUrlChangedWithinDay = accountSettings.itchUrlChangedDate ? 
  (differenceInDays(new Date(), new Date(accountSettings.itchUrlChangedDate)) === 0): false

  return (
    <>
    {success ? <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Body className="fade-when-mouse-off">
          <h1>Account Settings</h1>
          <p>
          Here's where you can edit your itch account. To edit your profile (i.e. name, gender, etc.), 
          go to my account and click on the pencil icon beside your avatar.
          </p>
          <SolidLine/>
          <div className="group">
            <h2>Account type</h2>
            <div className="group-options">
              <AccountType>
                <div className="icon">
                  {accountSettings.accountType === AccountTypes.FashionArtist ? <img className="fa" src={icons['fa-40-3f3e3c.svg']} alt="Fashion artist" /> : null}
                  {accountSettings.accountType === AccountTypes.FashionCompany ? <img className="fc" src={icons['fc-40-3f3e3c.svg']} alt="Fashion company" /> : null}
                  {accountSettings.accountType === AccountTypes.FashionLover ? <img className="fl" src={icons['fl-40-3f3e3c.svg']} alt="Fashion lover" /> : null}
                </div>
                <div className="categories">
                  <div className="tags">
                    {name} {accountSubTypesText.length > 0 ? '/' : ''} {accountSubTypesText.join(', ')}
                  </div>
                  {false && <LinkButton 
                    type="button" 
                    onClick={() => {
                      setModal(ModalIds.AccountType)
                    }}>Change account type</LinkButton>}
                </div>
              </AccountType>
            </div>
          </div>
          <DashedLine style={{
            marginTop: '20px'
          }}/>
          
          <div className="group">
            <h2>Email</h2>
            <div className="group-options">
              <FormField
                error={errors.email}
              >
                <InputText theme="light" type="text" {...register("email")} />
              </FormField>
            </div>
          </div>
          <DashedLine style={{
            marginTop: '20px'
          }}/>
          <div className="group">
            <h2>Password</h2>
            <div className="group-options">
              <LinkButton 
                type="button"
                onClick={() => {
                  setModal(ModalIds.Password)
                }}
              >Change password</LinkButton>
            </div>
          </div>
          <DashedLine style={{
            marginTop: '20px'
          }}/>
          <div className="group">
            <h2>My itch URL</h2>
            <div className="group-options">
              <ItchUrlField>
                <FormField 
                  error={errors['itchUrl']}
                  className="itchurl-field"
                  >
                  <div className='inner'>
                    <InputText 
                      prePadText={`${window.location.protocol}//${window.location.hostname}/`}
                      theme="light" 
                      {...register("itchUrl")}
                    />
                    <div className="copy-link" onClick={() => {
                      copyToClipboard(`${window.location.protocol}//${window.location.hostname}/${getValues('itchUrl')}`)
                      let positionedTop = window.screen.width > 768 ? 65 : 45
                      show('Link copied!', {
                        autoHide: true,
                        positionedTop,
                        zIndex: 120
                      })
                    }}>
                      <img src={icons['copyurl-grey.svg']} alt="copy url"/>
                      <img src={icons['copyurl-hover.svg']} alt="copy url"/>
                    </div>
                  </div>
                  
                </FormField>
                {!errors['itchUrl'] ? <HintText className="itchurl-hint">
                  {isItchUrlChangedWithinDay ? 
                    <span>Oops! You’ve already changed your itch URL in the past 1 day.</span> : 
                    <span>Changing your <b>itch URL</b>? Note that you won't be able to change it for the next 1 day.</span>}
                </HintText> : null}
              </ItchUrlField>
            </div>
          </div>
          <DashedLine style={{
            marginTop: '20px'
          }}/>
          <div className="group">
            <h2>Set Homepage as</h2>
            <div className="group-options">
              <Controller
                name="homepage"
                control={control}
                render={({ field }) => 
                  <RadioButtons
                    {...field}
                    options={[
                      { name: '<b>itch</b> Home', value:'1' },
                      { name: '<b>itch</b> Zine', value:'2' },
                      { name: 'My Account', value:'3' }
                    ]}
                  />
                }
              />
              {!errors['homepage'] ? <HintText className="itchurl-hint">
                
              </HintText> : null}
            </div>
          </div>
        </Body>
        <Footer className="action-footer">
          {false && <ButtonDanger type="button" onClick={handleDeactivate}>Deactivate</ButtonDanger>}
          {false && <ButtonResetForm active={isDirty} onClick={reset}>Reset</ButtonResetForm>}
          <ButtonSave active={isDirty} loading={updateMyAccount.isLoading} >Save</ButtonSave>
        </Footer>
      </form>
      {modal === ModalIds.AccountType ? 
        createPortal(<ChangeAccountType
          onClose={() => {
            setModal(null)
          }}
          accountType={accountSettings.accountType}
          primaryProfession={accountSettings.primaryProfession}
          secondaryProfession={accountSettings.secondaryProfession}
          companyType={accountSettings.companyType} 
        />, document.getElementById('modal')) : null}
      {modal === ModalIds.Password ? 
        createPortal(<ChangePassword
          onClose={() => {
            setModal(null)
          }}
          email={accountSettings.email}
        />, document.getElementById('modal')) : null}
    </>: null}
    </>
  );
}