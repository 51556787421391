import React, { forwardRef, useState } from 'react'

import CommentItem from './comment-item'
import CommentInput from './comment-input'
import { 
  Container,
  ResponsesContainer
} from './comment-thread-item.styles'

import useAddCommentResponse from 'hooks/add-comment-response.hook'

const CommentThreadItem = forwardRef(({ 
  relatedId, contentType, id,
  user, body, responses, createdAt, updatedAt, schemaName,
  ...rest 
}, ref) => {
  const { respondToComment } = useAddCommentResponse(contentType, relatedId, id)
  const [showResponseInput, setShowResponseInput] = useState(false)

  const handleRespond = () => {
    setShowResponseInput(true)
  }

  return (
    <Container {...rest} ref={ref}>
      <CommentItem
        id={id}
        user={user} 
        body={body} 
        createdAt={createdAt} 
        handleRespond={handleRespond} />
      <ResponsesContainer className="comment-responses">
      {responses.map((response) => (
        <CommentItem {...response} />
      ))}
      {showResponseInput ? 
        <CommentInput 
          className="respond-input"
          id={relatedId} 
          contentType={contentType}
          onSend={(content) => {
            respondToComment(content.body)
            setShowResponseInput(false)
          }}
        /> : null}
      </ResponsesContainer>
    </Container>
  )
})

export default CommentThreadItem