import styled from 'styled-components';
import {
  fonts,
  colours,
  breakpoints,
  BaseTransition
} from '../../styles/global.styles'
import {
  icons,
} from '../../constants';
import {
  Link
} from 'react-router-dom'

export const GenericTransitionBase = styled.div`
  transition-property: all;
  transition-duration: .3s;
`;

export const UserNavBarWrapper = styled.div`
  height:45px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  background-color: ${colours.dark};
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    height: 50px;

    width: ${props => props.collapse ? '65px' : 'unset'};
    position: ${props => props.collapse ? 'absolute' : 'static'};
    right: ${props => props.collapse ? 0 : 'unset'};
  }
`;

export const UserNavBar = styled(GenericTransitionBase)`
  display:flex;
  flex-direction:row;
  left: ${props => props.menuActive ? '190px' : '0'};
  position:absolute;
  width: ${props => props.menuActive ? 'calc(100% - 190px)' : '100%'};;
  max-width: 1160px;
  padding: 10px 15px 10px 15px;
  
  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    position: static;
    padding-left: 42px;
    padding-right: 90px;

    display: ${props => props.hidden ? 'none' : 'flex'};
  }
`;

export const Menu = styled.div`
  margin-right: 29px;
  display:flex;
  align-items:center;
  position: relative;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    display:none;
  }
`;

export const CurrentSectionTitle = styled.div`
  color: ${colours.pink};
  padding-top: 0px;
  font-family: ${fonts.title.xbold};
  font-size: 21px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (min-width: ${breakpoints.sm + 1}px) {
    font-size: 24px;
    line-height: 24px;
    order:1;
  }
`;

export const Nav = styled(GenericTransitionBase)`
  width: 190px;
  height: calc(100vh - 45px);
  display:flex;
  flex-direction:column;
  background-color: rgba(0,0,0, .8);

  position: absolute;
  left: -190px;
  top: 0;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    margin-left: auto;
    position: static;
    background-color:transparent;
    width: unset;
    height: unset;
    left:unset;
    top: unset;
    flex-direction: row;
    align-items: center;
    order:3;
    &:before {
      content: url(${icons['pink-slash.svg']});
      color: ${colours.pink};
      margin-right: 10px;
    }
  }
`;

export const NavItemActiveBar = styled(BaseTransition)`
  min-width: 1px;
  height: 2px;
  background-color: ${colours.pink};
  position:absolute;
  top: 80%;
  left: 0px;
  width: auto;
  transition-duration: .2s;
  transition-timing-function: ease;
`;

export const NavItemStyled = styled(Link)`
  font-family: ${fonts.title.xbold};
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.selected ? colours.pink : colours.text};
  border-bottom: solid thin #000;
  height: 50px;
  width: 100%;
  text-align:left;
  padding: 17px 28px;
  position:relative;
  cursor: pointer;

  &.has-counter {
    color: ${colours.light}; 
  }
  
  &>span {
    display:inline-block;
    transition-property: all;
    transition-duration: .3s;
    padding-bottom: 1px;
    border-bottom: 2px solid transparent;
  }

  &:before {
    content: "${props => props.selected ? '/ ':''}";
  }

  &:hover,
  &:active {
    color: ${colours.pink};
    border-bottom:2px solid ${colours.pink}
  }

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    height: auto;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    line-height: 24px;
    border-bottom:none;

    &:before {
      display: none;
    }

    &>span {
      padding-bottom: 3px;
      border-bottom:none;
    }

    &:hover,
    &:active {
      color: ${colours.pink};
      border-bottom:none;
    }
  }
`;

export const CounterStyled = styled.div`
  color: ${colours.pink};
  font-family: ${fonts.title.xbold};
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  bottom: 10px;
  margin-left:2px;
  position: relative;
  display:inline-block;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    bottom:8px;
    font-size: 12px;
    line-height: 13px;
  }
`;

export const AnimatedHidden = styled.div`
  transition-property: opacity;
  transition-duration: .3s;
  
  opacity: ${props => props.visible ? 1 : 0};
`;

export const ScrollTopButton = styled(AnimatedHidden)`
  display:none;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    cursor: pointer;
    width:65px;
    height: 100%;
    margin-left: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    border-left: ${props => props.collapse ? 'none': `1px solid ${colours.darkgrey}`};

    &:before {
      content: url(${icons['grey-scroll-top.svg']});
      margin-top:4px;
    }
  }
`;

export const CloseButton = styled(AnimatedHidden)`
  display:none;

  @media screen and (min-width:${breakpoints.sm + 1}px) {
    cursor: pointer;
    width:65px;
    height: 100%;
    margin-right: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    border-right:1px solid ${colours.darkgrey};

    &:before {
      content: url(${icons['grey-close.svg']});
      margin-top:4px;
    }
  }
`;
